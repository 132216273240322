import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

// Cookides Primary
const PRIMARY = {
  main: '#51C1CF',
  light: '#87DBE3',
  lighter: '#B1E7EC',
  dark: '#289EA9',
  darker: '#35C2CF',
  contrastText: '#FFFFFF',
};

// Cookides Secondary
const SECONDARY = {
  main: '#6F3B66',
  light: '#BE84B4',
  lighter: '#CFA5C8',
  dark: '#8F4C84',
  darker: '#AC629F',
  contrastText: '#FFFFFF',
};

const INFO = {
  main: '#00B8D9',
  lighter: '#CAFDF5',
  light: '#61F3F3',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  main: '#22C55E',
  light: '#BDEFAE',
  lighter: '#DCF7D4',
  dark: '#43AF22',
  darker: '#8AE26E',
  contrastText: '#ffffff',
};

const WARNING = {
  main: '#FFC107',
  light: '#F7E8B7',
  lighter: '#FCF6E3',
  dark: '#E7B632',
  darker: '#F2D98A',
  contrastText: GREY[800],
};

const ERROR = {
  main: '#EB5757',
  light: '#FCCED4',
  lighter: '#FEEBEF',
  dark: '#CF3637',
  darker: '#E17678',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: '#637381',
      disabled: GREY[500],
    },
    background: {
      paper: '#F9FAFB',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}

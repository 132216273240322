// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    dashboard: {
      root: `${ROOTS.DASHBOARD}/statistics`,
      content: `${ROOTS.DASHBOARD}/statistics/content`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      addUser: `${ROOTS.DASHBOARD}/users/addUser`,
      editUser: (id: string) => `${ROOTS.DASHBOARD}/users/editUser/${id}`,
      // Dashbaord User
      admins: `${ROOTS.DASHBOARD}/users/admins`,
      addAdmin: `${ROOTS.DASHBOARD}/users/admins/addAdmin`,
      editAdmin: (id: string) => `${ROOTS.DASHBOARD}/users/admins/editAdmin/${id}`,
    },
    content: {
      root: `${ROOTS.DASHBOARD}/content`,
      // Channels
      addChannel: `${ROOTS.DASHBOARD}/content/addChannel`,
      editChannel: (id: string) => `${ROOTS.DASHBOARD}/content/editChannel/${id}`,
      channelInfo: (id: string) => `${ROOTS.DASHBOARD}/content/channelInfo/${id}`,
      videos: (id: string) => `${ROOTS.DASHBOARD}/content/videos/${id}`,
      addVideo: (id: string) => `${ROOTS.DASHBOARD}/content/videos/${id}/addVideo`,
      editVideo: (id: string, videoId: string) =>
        `${ROOTS.DASHBOARD}/content/videos/${id}/editVideo/${videoId}`,
      // Categories
      categories: `${ROOTS.DASHBOARD}/content/categories`,
      addCategory: `${ROOTS.DASHBOARD}/content/categories/addCategory`,
      editCategory: (id: string) => `${ROOTS.DASHBOARD}/content/categories/editCategory/${id}`,
      // Series
      series: `${ROOTS.DASHBOARD}/content/series`,
      addSeries: `${ROOTS.DASHBOARD}/content/series/addSeries`,
      editSeries: (id: string) => `${ROOTS.DASHBOARD}/content/series/editSeries/${id}`,
      // Seasons
      seasons: (id: string) => `${ROOTS.DASHBOARD}/content/series/${id}/seasons`,
      addSeason: (id: string) => `${ROOTS.DASHBOARD}/content/series/${id}/seasons/addSeason`,
      editSeason: (id: string, seasonId: string) =>
        `${ROOTS.DASHBOARD}/content/series/${id}/seasons/editSeason/${seasonId}`,
      // Episodes
      episodes: (id: string, seasonId: string) =>
        `${ROOTS.DASHBOARD}/content/series/${id}/seasons/${seasonId}/episodes`,
      addEpisode: (id: string, seasonId: string) =>
        `${ROOTS.DASHBOARD}/content/series/${id}/seasons/${seasonId}/episodes/addEpisode`,
      // Coming soon videos
      comingSoon: `${ROOTS.DASHBOARD}/content/comingSoon`,
      comingSoonVideoInfo: (videoId: string) => `${ROOTS.DASHBOARD}/content/comingSoon/${videoId}`,
      editComingSoonVideo: (videoId: string) =>
        `${ROOTS.DASHBOARD}/content/comingSoon/editComingSoonVideo/${videoId}`,
    },
    aiSuggestions: {
      root: `${ROOTS.DASHBOARD}/aiSuggestions`,
      addKeyword: `${ROOTS.DASHBOARD}/aiSuggestions/addKeyword`,
      suggestions: `${ROOTS.DASHBOARD}/aiSuggestions/suggestions`,
      suggestionDetails: (id: string) => `${ROOTS.DASHBOARD}/aiSuggestions/suggestions/${id}`,
      suggestionSettings: `${ROOTS.DASHBOARD}/aiSuggestions/suggestions/settings`,
      publishSuggestion: (suggestedVideoId: string) =>
        `${ROOTS.DASHBOARD}/aiSuggestions/suggestions/${suggestedVideoId}/publish`,
    },
    badges: {
      root: `${ROOTS.DASHBOARD}/badges`,
      addbadge: `${ROOTS.DASHBOARD}/badges/addbadge`,
      editBadge: (id: string) => `${ROOTS.DASHBOARD}/badges/editBadge/${id}`,
      videos: (id: string) => `${ROOTS.DASHBOARD}/badges/videos/${id}`,
      addVideo: (id: string, badgeCategoryId: string) =>
        `${ROOTS.DASHBOARD}/badges/videos/${id}/addVideo/${badgeCategoryId}`,
    },
    chromeExtension: {
      root: `${ROOTS.DASHBOARD}/chromeExtension`,
      users: `${ROOTS.DASHBOARD}/chromeExtension/users`,
      // User Suggestions
      userSuggestions: (id: string) => `${ROOTS.DASHBOARD}/chromeExtension/userSuggestions/${id}`,
      userSuggestionDetails: (extensionUserId: string, suggestedVideoId: string) =>
        `${ROOTS.DASHBOARD}/chromeExtension/userSuggestions/${extensionUserId}/info/${suggestedVideoId}`,
      publishUserSuggestion: (extensionUserId: string, suggestedVideoId: string) =>
        `${ROOTS.DASHBOARD}/chromeExtension/userSuggestions/${extensionUserId}/info/${suggestedVideoId}/publish`,
      userSuggestionSettings: (extensionUserId: string) =>
        `${ROOTS.DASHBOARD}/chromeExtension/userSuggestions/${extensionUserId}/settings`,
      // All Suggestions
      suggestions: `${ROOTS.DASHBOARD}/chromeExtension/suggestions`,
      suggestionDetails: (suggestedVideoId: string) =>
        `${ROOTS.DASHBOARD}/chromeExtension/suggestions/${suggestedVideoId}`,
      publishSuggestion: (suggestedVideoId: string) =>
        `${ROOTS.DASHBOARD}/chromeExtension/suggestions/${suggestedVideoId}/publish`,
      suggestionSettings: `${ROOTS.DASHBOARD}/chromeExtension/suggestions/settings`,
    },
    roles: {
      root: `${ROOTS.DASHBOARD}/roles`,
      addRole: `${ROOTS.DASHBOARD}/roles/addRole`,
      editRole: (id: string) => `${ROOTS.DASHBOARD}/roles/editRole/${id}`,
    },
    notifications: {
      root: `${ROOTS.DASHBOARD}/notifications`,
      addNotification: `${ROOTS.DASHBOARD}/notifications/addNotification`,
    },
    helpCenter: {
      root: `${ROOTS.DASHBOARD}/helpCenter`,
      // FAQ
      faq: `${ROOTS.DASHBOARD}/helpCenter/faq`,
      addFaq: `${ROOTS.DASHBOARD}/helpCenter/faq/addFaq`,
      editFaq: (id: string) => `${ROOTS.DASHBOARD}/helpCenter/faq/editFaq/${id}`,
      // Tickets
      tickets: `${ROOTS.DASHBOARD}/helpCenter/tickets`,
      ticketInfo: (id: string) => `${ROOTS.DASHBOARD}/helpCenter/tickets/${id}`,
      // Reporting
      reporting: `${ROOTS.DASHBOARD}/helpCenter/reporting`,
      reportedVideoInfo: (id: string) => `${ROOTS.DASHBOARD}/helpCenter/reporting/${id}`,
      // App Discovey
      appDiscovery: `${ROOTS.DASHBOARD}/helpCenter/appDiscovery`,
      addAppDiscovery: `${ROOTS.DASHBOARD}/helpCenter/appDiscovery/addAppDiscovery`,
      editAppDiscovery: (id: string) =>
        `${ROOTS.DASHBOARD}/helpCenter/appDiscovery/editAppDiscovery/${id}`,
      // Privacy Policy
      privacy: `${ROOTS.DASHBOARD}/helpCenter/privacy`,
    },
  },
};

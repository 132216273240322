import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from 'src/api/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
};

export type AiSuggestedVideo = {
  __typename?: 'AISuggestedVideo';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  keywords: Scalars['String']['output'];
  lang: Scalars['String']['output'];
  link: Scalars['String']['output'];
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AiSuggestedVideoReview = {
  __typename?: 'AISuggestedVideoReview';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  suggestedVideoId: Scalars['String']['output'];
};

export type Admin = {
  __typename?: 'Admin';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  role?: Maybe<AdminRole>;
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type AdminAuthResponse = {
  __typename?: 'AdminAuthResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Admin>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type AllKidsSpentTimeStatistics = {
  __typename?: 'AllKidsSpentTimeStatistics';
  daysStatistics: Array<DayStatistics>;
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
  totalUsageMinutes: Scalars['Int']['output'];
};

export type AllKidsWatchedCategoriesStatistics = {
  __typename?: 'AllKidsWatchedCategoriesStatistics';
  categoriesDetails: Array<CategoryStatistics>;
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
  totalCategoriesMinutes: Scalars['Int']['output'];
};

export type Badge = {
  __typename?: 'Badge';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['JSON']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BadgeCardItem = {
  __typename?: 'BadgeCardItem';
  avatar?: Maybe<Scalars['String']['output']>;
  currentCount: Scalars['Int']['output'];
  description: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  isClaimed: Scalars['Boolean']['output'];
  name: Scalars['JSON']['output'];
  totalCount: Scalars['Int']['output'];
};

export type BadgesResult = {
  __typename?: 'BadgesResult';
  generalBadges: Array<BadgeCardItem>;
  specialBadges: Array<BadgeCardItem>;
};

export type BadgesStatistics = {
  __typename?: 'BadgesStatistics';
  monthsStatistics: Array<MonthBadgeStatistics>;
  totalBadges: Scalars['Int']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CategoriesCountStatistics = {
  __typename?: 'CategoriesCountStatistics';
  category: Scalars['JSON']['output'];
  count: Scalars['Int']['output'];
};

export type CategoryStatistics = {
  __typename?: 'CategoryStatistics';
  categoryName: Scalars['JSON']['output'];
  color: Scalars['String']['output'];
  totalMinutes: Scalars['Int']['output'];
};

export type Channel = {
  __typename?: 'Channel';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  maxTargetAge: Scalars['Int']['output'];
  minTargetAge: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ChannelFollow = {
  __typename?: 'ChannelFollow';
  channel: Channel;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ChromeExtGoogleSignInInput = {
  credential: Scalars['String']['input'];
  isLogin?: InputMaybe<Scalars['Boolean']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
};

export type ChromeExtSuggestedVideo = {
  __typename?: 'ChromeExtSuggestedVideo';
  chromeExtId: Scalars['String']['output'];
  chromeUser?: Maybe<ChromeExtUser>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ChromeExtUser = {
  __typename?: 'ChromeExtUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type CreateAdminInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateChildAccountInput = {
  birthDate: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateChromeExtUserInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};

export type CreateGeneralMessageInput = {
  channels: Array<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  privacy: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateSuggestedVideoInput = {
  description: Scalars['String']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateSuggestedVideoReviewInput = {
  status: Scalars['String']['input'];
  suggestedVideoId: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  parentEmail?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  pin?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CreateVideoInput = {
  channelId: Scalars['String']['input'];
  classification: Scalars['String']['input'];
  contentIds: Array<Scalars['String']['input']>;
  description: TranslateInput;
  episode?: InputMaybe<Scalars['Int']['input']>;
  genre: Scalars['String']['input'];
  link: Scalars['String']['input'];
  musicType: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
  title: TranslateInput;
  trendingFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  trendingToDate?: InputMaybe<Scalars['DateTime']['input']>;
  uploadingDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DatesDto = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type DayStatistics = {
  __typename?: 'DayStatistics';
  day: Scalars['DateTime']['output'];
  totalMinutes: Scalars['Int']['output'];
};

export type FetchUsersArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type FollowRequest = {
  __typename?: 'FollowRequest';
  createdAt: Scalars['DateTime']['output'];
  receiver: User;
  receiverId: Scalars['String']['output'];
  sender: User;
  senderId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FollowRequestsCount = {
  __typename?: 'FollowRequestsCount';
  receivedCount: Scalars['Int']['output'];
  sentCount: Scalars['Int']['output'];
};

export type FollowingUpdatesChannelItem = {
  __typename?: 'FollowingUpdatesChannelItem';
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['JSON']['output'];
};

export type FollowsCount = {
  __typename?: 'FollowsCount';
  followeesCount: Scalars['Int']['output'];
  followersCount: Scalars['Int']['output'];
};

export type ForgetPasswordInput = {
  email: Scalars['String']['input'];
};

export type GeneralMessage = {
  __typename?: 'GeneralMessage';
  channels: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  message: Scalars['String']['output'];
  privacy: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GetLeaderboardDatesDto = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type GetUserCountStatisticsDto = {
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetUserStatisticsDto = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  userId: Scalars['String']['input'];
};

export type GoogleSignInInput = {
  credential: Scalars['String']['input'];
  pin?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type KidSettings = {
  __typename?: 'KidSettings';
  audio: Scalars['String']['output'];
  channelsUpdatesNotifications: Scalars['Boolean']['output'];
  displayLanguage: Scalars['String']['output'];
  friendsUpdatesNotifications: Scalars['Boolean']['output'];
  musicControl: Scalars['String']['output'];
  nextEpisode: Scalars['Boolean']['output'];
  previews: Scalars['Boolean']['output'];
  remindersNotifications: Scalars['Boolean']['output'];
  requestsNotifications: Scalars['Boolean']['output'];
  searchBar: Scalars['Boolean']['output'];
  subtitle: Scalars['String']['output'];
  timerDays: Array<Scalars['Int']['output']>;
  timerEndTime: Scalars['Int']['output'];
  timerStartingTime: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type Like = {
  __typename?: 'Like';
  createdAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
  video: Video;
  videoId: Scalars['String']['output'];
};

export type LoginAdminInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginByPinInput = {
  pin: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type LoginUserInput = {
  email: Scalars['String']['input'];
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type MonthBadgeStatistics = {
  __typename?: 'MonthBadgeStatistics';
  month: Scalars['Int']['output'];
  totalBadges: Scalars['Int']['output'];
};

export type MonthReportStatistics = {
  __typename?: 'MonthReportStatistics';
  month: Scalars['Int']['output'];
  totalReports: Scalars['Int']['output'];
};

export type MonthStatistics = {
  __typename?: 'MonthStatistics';
  count: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
};

export type NeedsToPublishVideos = {
  __typename?: 'NeedsToPublishVideos';
  today: Array<Video>;
  tomorrow: Array<Video>;
};

export type PaginatedCardVideos = PaginatedResult & {
  __typename?: 'PaginatedCardVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedComingSoonVideos = PaginatedResult & {
  __typename?: 'PaginatedComingSoonVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedEarnedBadge = PaginatedResult & {
  __typename?: 'PaginatedEarnedBadge';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedFollowRequestResult = PaginatedResult & {
  __typename?: 'PaginatedFollowRequestResult';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedFollowerResult = PaginatedResult & {
  __typename?: 'PaginatedFollowerResult';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedFollowingUpdatesVideos = PaginatedResult & {
  __typename?: 'PaginatedFollowingUpdatesVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedHistoryVideos = PaginatedResult & {
  __typename?: 'PaginatedHistoryVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedLeaderboard = PaginatedResult & {
  __typename?: 'PaginatedLeaderboard';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedLibraryVideos = PaginatedResult & {
  __typename?: 'PaginatedLibraryVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedNotifications = PaginatedResult & {
  __typename?: 'PaginatedNotifications';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedResult = {
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedSearchResult = PaginatedResult & {
  __typename?: 'PaginatedSearchResult';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedSharedVideos = PaginatedResult & {
  __typename?: 'PaginatedSharedVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedShortVideos = PaginatedResult & {
  __typename?: 'PaginatedShortVideos';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PagingArgs = {
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
};

export type PagingMeta = {
  __typename?: 'PagingMeta';
  currentPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  next?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
  prev?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type ParentSettings = {
  __typename?: 'ParentSettings';
  badgesUpdatesNotifications: Scalars['Boolean']['output'];
  childUpdatesNotifications: Scalars['Boolean']['output'];
  displayLanguage: Scalars['String']['output'];
  requestsNotifications: Scalars['Boolean']['output'];
  statisticsUpdatesNotifications: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  systemCriteria: Scalars['String']['output'];
};

export type PublishComingSoonVideoInput = {
  id: Scalars['ID']['input'];
  link: Scalars['String']['input'];
};

export type PublishSuggestedVideoInput = {
  channelId: Scalars['String']['input'];
  classification: Scalars['String']['input'];
  contentIds: Array<Scalars['String']['input']>;
  description: TranslateInput;
  musicType: Scalars['String']['input'];
  suggestedVideoId: Scalars['String']['input'];
  title: TranslateInput;
};

export type ReplyTicketInput = {
  content: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
};

export type RespondFollowRequestInput = {
  senderId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type SearchAllResult = {
  __typename?: 'SearchAllResult';
  channels: Array<SearchedChannel>;
  profiles: Array<SearchedProfile>;
  videos: Array<VideoCardItem>;
};

export type SearchDto = {
  searchFilter: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
};

export type SearchedChannel = {
  __typename?: 'SearchedChannel';
  avatar?: Maybe<Scalars['String']['output']>;
  description: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  isFollowed: Scalars['Boolean']['output'];
  name: Scalars['JSON']['output'];
};

export type SearchedProfile = {
  __typename?: 'SearchedProfile';
  avatar?: Maybe<Scalars['String']['output']>;
  followRequestStatus?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type SendVerificationCodeInput = {
  token: Scalars['String']['input'];
};

export type SharedVideo = {
  __typename?: 'SharedVideo';
  createdAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
  video?: Maybe<User>;
  videoId: Scalars['String']['output'];
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export type SpentTimeStatistics = {
  __typename?: 'SpentTimeStatistics';
  daysStatistics: Array<DayStatistics>;
  endDate: Scalars['DateTime']['output'];
  fullName: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  totalUsageMinutes: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type StatisticsCount = {
  __typename?: 'StatisticsCount';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type SuggestedVideoReview = {
  __typename?: 'SuggestedVideoReview';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  suggestedVideoId: Scalars['String']['output'];
};

export type SuggestedVideosStatusStatistics = {
  __typename?: 'SuggestedVideosStatusStatistics';
  approved: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
};

export type SuggestionSetting = {
  __typename?: 'SuggestionSetting';
  approvalRules: Array<SuggestionSettingRule>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rejectionRules: Array<SuggestionSettingRule>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SuggestionSettingRule = {
  __typename?: 'SuggestionSettingRule';
  adminsRequiredCount: Scalars['Int']['output'];
  parentsRequiredCount: Scalars['Int']['output'];
};

export type SuggestionSettingRuleInput = {
  adminsRequiredCount: Scalars['Int']['input'];
  parentsRequiredCount: Scalars['Int']['input'];
};

export type Ticket = {
  __typename?: 'Ticket';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  platform: Scalars['String']['output'];
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  ticketReplies: Array<TicketReply>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TicketReply = {
  __typename?: 'TicketReply';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ticketId: Scalars['String']['output'];
};

export type TranslateInput = {
  ar: Scalars['String']['input'];
  en: Scalars['String']['input'];
};

export type UpdateAdminInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateParentUserProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  oldPin?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePrivacyDto = {
  badges: Scalars['String']['input'];
  follows: Scalars['String']['input'];
  posts: Scalars['String']['input'];
};

export type UpdateSuggestionSettingInput = {
  approvalRules: Array<SuggestionSettingRuleInput>;
  rejectionRules: Array<SuggestionSettingRuleInput>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  sendUpdatedPasswordEmail?: InputMaybe<Scalars['Boolean']['input']>;
  verified: Scalars['Boolean']['input'];
};

export type UpdateUserProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVideoInput = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<TranslateInput>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslateInput>;
  trendingFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  trendingToDate?: InputMaybe<Scalars['DateTime']['input']>;
  uploadingDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  chromeExtUser?: Maybe<ChromeExtUser>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPreferencesChoosen: Scalars['Boolean']['output'];
  kidSettings?: Maybe<KidSettings>;
  kids: Array<User>;
  parentSettings?: Maybe<ParentSettings>;
  parents: Array<User>;
  pin?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<UserPrivacy>;
  signinMethod: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};

export type UserAuthResponse = {
  __typename?: 'UserAuthResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export type UserBadge = {
  __typename?: 'UserBadge';
  badgeId: Scalars['String']['output'];
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  isClaimed: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type UserPrivacy = {
  __typename?: 'UserPrivacy';
  badges: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  follows: Scalars['String']['output'];
  posts: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type UserProfileInfo = {
  __typename?: 'UserProfileInfo';
  avatar?: Maybe<Scalars['String']['output']>;
  chatId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type UsersAccountsStatusStatistics = {
  __typename?: 'UsersAccountsStatusStatistics';
  accountStatus: Array<StatisticsCount>;
  accountType: Array<StatisticsCount>;
};

export type UsersAgeStatistics = {
  __typename?: 'UsersAgeStatistics';
  age: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type UsersCountriesStatistics = {
  __typename?: 'UsersCountriesStatistics';
  count: Scalars['Int']['output'];
  country: Scalars['String']['output'];
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type Video = {
  __typename?: 'Video';
  aspectRatio: Scalars['Float']['output'];
  channelId: Scalars['String']['output'];
  classification: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['JSON']['output'];
  duration: Scalars['Int']['output'];
  episode?: Maybe<Scalars['Int']['output']>;
  genre: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  musicType: Scalars['String']['output'];
  seasonId?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  thumbnailUrlHigh?: Maybe<Scalars['String']['output']>;
  title: Scalars['JSON']['output'];
  trendingFromDate?: Maybe<Scalars['DateTime']['output']>;
  trendingToDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadedDate: Scalars['DateTime']['output'];
  uploadingDate?: Maybe<Scalars['DateTime']['output']>;
  viewCount: Scalars['Int']['output'];
};

export type VideoCardItem = {
  __typename?: 'VideoCardItem';
  channel: FollowingUpdatesChannelItem;
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLiked: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['JSON']['output'];
};

export type VideoDetails = {
  __typename?: 'VideoDetails';
  aspectRatio: Scalars['Float']['output'];
  badge?: Maybe<Badge>;
  badgeProgress?: Maybe<BadgeCardItem>;
  channel: VideoDetailsChannel;
  description: Scalars['JSON']['output'];
  duration: Scalars['Int']['output'];
  episode?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isFinished: Scalars['Boolean']['output'];
  isLiked: Scalars['Boolean']['output'];
  lastSecond: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  playlists: Array<Scalars['ID']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  serieId?: Maybe<Scalars['ID']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  thumbnailUrlHigh?: Maybe<Scalars['String']['output']>;
  title: Scalars['JSON']['output'];
};

export type VideoDetailsChannel = {
  __typename?: 'VideoDetailsChannel';
  avatar?: Maybe<Scalars['String']['output']>;
  description: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  isFollowed?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['JSON']['output'];
};

export type WatchedCategoriesStatistics = {
  __typename?: 'WatchedCategoriesStatistics';
  categoriesDetails: Array<CategoryStatistics>;
  endDate: Scalars['DateTime']['output'];
  fullName: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  totalCategoriesMinutes: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type YoutubeVideoGeneralInfo = {
  __typename?: 'YoutubeVideoGeneralInfo';
  description: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** columns and relationships of "actions" */
export type Actions = {
  __typename?: 'actions';
  /** An array relationship */
  action_roles: Array<Roles_Actions>;
  /** An aggregate relationship */
  action_roles_aggregate: Roles_Actions_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  systemCriteria: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "actions" */
export type ActionsAction_RolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsAction_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};

/** aggregated selection of "actions" */
export type Actions_Aggregate = {
  __typename?: 'actions_aggregate';
  aggregate?: Maybe<Actions_Aggregate_Fields>;
  nodes: Array<Actions>;
};

/** aggregate fields of "actions" */
export type Actions_Aggregate_Fields = {
  __typename?: 'actions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Actions_Max_Fields>;
  min?: Maybe<Actions_Min_Fields>;
};


/** aggregate fields of "actions" */
export type Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export type Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Actions_Bool_Exp>>;
  _not?: InputMaybe<Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Actions_Bool_Exp>>;
  action_roles?: InputMaybe<Roles_Actions_Bool_Exp>;
  action_roles_aggregate?: InputMaybe<Roles_Actions_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  systemCriteria?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "actions" */
export enum Actions_Constraint {
  /** unique or primary key constraint on columns "systemCriteria", "name" */
  ActionsNameSystemCriteriaKey = 'actions_name_systemCriteria_key',
  /** unique or primary key constraint on columns "id" */
  ActionsPkey = 'actions_pkey'
}

/** input type for inserting data into table "actions" */
export type Actions_Insert_Input = {
  action_roles?: InputMaybe<Roles_Actions_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Actions_Max_Fields = {
  __typename?: 'actions_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  systemCriteria?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Actions_Min_Fields = {
  __typename?: 'actions_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  systemCriteria?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "actions" */
export type Actions_Mutation_Response = {
  __typename?: 'actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Actions>;
};

/** input type for inserting object relation for remote table "actions" */
export type Actions_Obj_Rel_Insert_Input = {
  data: Actions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};

/** on_conflict condition type for table "actions" */
export type Actions_On_Conflict = {
  constraint: Actions_Constraint;
  update_columns?: Array<Actions_Update_Column>;
  where?: InputMaybe<Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "actions". */
export type Actions_Order_By = {
  action_roles_aggregate?: InputMaybe<Roles_Actions_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  systemCriteria?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: actions */
export type Actions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "actions" */
export enum Actions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SystemCriteria = 'systemCriteria',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "actions" */
export type Actions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "actions" */
export type Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Actions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "actions" */
export enum Actions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SystemCriteria = 'systemCriteria',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Actions_Bool_Exp;
};

/** columns and relationships of "admins" */
export type Admins = {
  __typename?: 'admins';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  role: Roles;
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  username: Scalars['String']['output'];
};

/** aggregated selection of "admins" */
export type Admins_Aggregate = {
  __typename?: 'admins_aggregate';
  aggregate?: Maybe<Admins_Aggregate_Fields>;
  nodes: Array<Admins>;
};

export type Admins_Aggregate_Bool_Exp = {
  count?: InputMaybe<Admins_Aggregate_Bool_Exp_Count>;
};

export type Admins_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Admins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Admins_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "admins" */
export type Admins_Aggregate_Fields = {
  __typename?: 'admins_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Admins_Max_Fields>;
  min?: Maybe<Admins_Min_Fields>;
};


/** aggregate fields of "admins" */
export type Admins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Admins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "admins" */
export type Admins_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Admins_Max_Order_By>;
  min?: InputMaybe<Admins_Min_Order_By>;
};

/** input type for inserting array relation for remote table "admins" */
export type Admins_Arr_Rel_Insert_Input = {
  data: Array<Admins_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};

/** Boolean expression to filter rows from the table "admins". All fields are combined with a logical 'AND'. */
export type Admins_Bool_Exp = {
  _and?: InputMaybe<Array<Admins_Bool_Exp>>;
  _not?: InputMaybe<Admins_Bool_Exp>;
  _or?: InputMaybe<Array<Admins_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  roleId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "admins" */
export enum Admins_Constraint {
  /** unique or primary key constraint on columns "email" */
  AdminsEmailKey = 'admins_email_key',
  /** unique or primary key constraint on columns "id" */
  AdminsPkey = 'admins_pkey'
}

/** input type for inserting data into table "admins" */
export type Admins_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Admins_Max_Fields = {
  __typename?: 'admins_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "admins" */
export type Admins_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Admins_Min_Fields = {
  __typename?: 'admins_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "admins" */
export type Admins_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "admins" */
export type Admins_Mutation_Response = {
  __typename?: 'admins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Admins>;
};

/** input type for inserting object relation for remote table "admins" */
export type Admins_Obj_Rel_Insert_Input = {
  data: Admins_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};

/** on_conflict condition type for table "admins" */
export type Admins_On_Conflict = {
  constraint: Admins_Constraint;
  update_columns?: Array<Admins_Update_Column>;
  where?: InputMaybe<Admins_Bool_Exp>;
};

/** Ordering options when selecting data from "admins". */
export type Admins_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admins */
export type Admins_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "admins" */
export enum Admins_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "admins" */
export type Admins_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "admins" */
export type Admins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admins_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "admins" */
export enum Admins_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export type Admins_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admins_Bool_Exp;
};

/** columns and relationships of "ai_suggested_videos" */
export type Ai_Suggested_Videos = {
  __typename?: 'ai_suggested_videos';
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  keywords: Scalars['String']['output'];
  lang: Scalars['String']['output'];
  link: Scalars['String']['output'];
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  published_video?: Maybe<Videos>;
  sourceKeyword: Scalars['String']['output'];
  status: Scalars['String']['output'];
  /** An array relationship */
  suggested_videos_reviews: Array<Ai_Suggested_Videos_Reviews>;
  /** An aggregate relationship */
  suggested_videos_reviews_aggregate: Ai_Suggested_Videos_Reviews_Aggregate;
  title: Scalars['String']['output'];
};


/** columns and relationships of "ai_suggested_videos" */
export type Ai_Suggested_VideosSuggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


/** columns and relationships of "ai_suggested_videos" */
export type Ai_Suggested_VideosSuggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};

/** aggregated selection of "ai_suggested_videos" */
export type Ai_Suggested_Videos_Aggregate = {
  __typename?: 'ai_suggested_videos_aggregate';
  aggregate?: Maybe<Ai_Suggested_Videos_Aggregate_Fields>;
  nodes: Array<Ai_Suggested_Videos>;
};

/** aggregate fields of "ai_suggested_videos" */
export type Ai_Suggested_Videos_Aggregate_Fields = {
  __typename?: 'ai_suggested_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Suggested_Videos_Max_Fields>;
  min?: Maybe<Ai_Suggested_Videos_Min_Fields>;
};


/** aggregate fields of "ai_suggested_videos" */
export type Ai_Suggested_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Suggested_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ai_suggested_videos". All fields are combined with a logical 'AND'. */
export type Ai_Suggested_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Suggested_Videos_Bool_Exp>>;
  _not?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Suggested_Videos_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  keywords?: InputMaybe<String_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  publishedVideoId?: InputMaybe<String_Comparison_Exp>;
  published_video?: InputMaybe<Videos_Bool_Exp>;
  sourceKeyword?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  suggested_videos_reviews?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
  suggested_videos_reviews_aggregate?: InputMaybe<Ai_Suggested_Videos_Reviews_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_suggested_videos" */
export enum Ai_Suggested_Videos_Constraint {
  /** unique or primary key constraint on columns "link" */
  AiSuggestedVideosLinkKey = 'ai_suggested_videos_link_key',
  /** unique or primary key constraint on columns "id" */
  AiSuggestedVideosPkey = 'ai_suggested_videos_pkey'
}

/** input type for inserting data into table "ai_suggested_videos" */
export type Ai_Suggested_Videos_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  published_video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  sourceKeyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggested_videos_reviews?: InputMaybe<Ai_Suggested_Videos_Reviews_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Suggested_Videos_Max_Fields = {
  __typename?: 'ai_suggested_videos_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  sourceKeyword?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Suggested_Videos_Min_Fields = {
  __typename?: 'ai_suggested_videos_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  sourceKeyword?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai_suggested_videos" */
export type Ai_Suggested_Videos_Mutation_Response = {
  __typename?: 'ai_suggested_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Suggested_Videos>;
};

/** input type for inserting object relation for remote table "ai_suggested_videos" */
export type Ai_Suggested_Videos_Obj_Rel_Insert_Input = {
  data: Ai_Suggested_Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Suggested_Videos_On_Conflict>;
};

/** on_conflict condition type for table "ai_suggested_videos" */
export type Ai_Suggested_Videos_On_Conflict = {
  constraint: Ai_Suggested_Videos_Constraint;
  update_columns?: Array<Ai_Suggested_Videos_Update_Column>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_suggested_videos". */
export type Ai_Suggested_Videos_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  keywords?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  publishedVideoId?: InputMaybe<Order_By>;
  published_video?: InputMaybe<Videos_Order_By>;
  sourceKeyword?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggested_videos_reviews_aggregate?: InputMaybe<Ai_Suggested_Videos_Reviews_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_suggested_videos */
export type Ai_Suggested_Videos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** columns and relationships of "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews = {
  __typename?: 'ai_suggested_videos_reviews';
  /** An object relationship */
  admin?: Maybe<Admins>;
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  chrome_extension_user?: Maybe<Chrome_Extension_Users>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  suggestedVideoId: Scalars['String']['output'];
  /** An object relationship */
  suggested_video: Ai_Suggested_Videos;
};

/** aggregated selection of "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Aggregate = {
  __typename?: 'ai_suggested_videos_reviews_aggregate';
  aggregate?: Maybe<Ai_Suggested_Videos_Reviews_Aggregate_Fields>;
  nodes: Array<Ai_Suggested_Videos_Reviews>;
};

export type Ai_Suggested_Videos_Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ai_Suggested_Videos_Reviews_Aggregate_Bool_Exp_Count>;
};

export type Ai_Suggested_Videos_Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Aggregate_Fields = {
  __typename?: 'ai_suggested_videos_reviews_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Suggested_Videos_Reviews_Max_Fields>;
  min?: Maybe<Ai_Suggested_Videos_Reviews_Min_Fields>;
};


/** aggregate fields of "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Suggested_Videos_Reviews_Max_Order_By>;
  min?: InputMaybe<Ai_Suggested_Videos_Reviews_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Arr_Rel_Insert_Input = {
  data: Array<Ai_Suggested_Videos_Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Suggested_Videos_Reviews_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ai_suggested_videos_reviews". All fields are combined with a logical 'AND'. */
export type Ai_Suggested_Videos_Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Bool_Exp>>;
  _not?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Bool_Exp>>;
  admin?: InputMaybe<Admins_Bool_Exp>;
  adminId?: InputMaybe<String_Comparison_Exp>;
  chromeExtId?: InputMaybe<String_Comparison_Exp>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  suggestedVideoId?: InputMaybe<String_Comparison_Exp>;
  suggested_video?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};

/** unique or primary key constraints on table "ai_suggested_videos_reviews" */
export enum Ai_Suggested_Videos_Reviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  AiSuggestedVideosReviewsPkey = 'ai_suggested_videos_reviews_pkey'
}

/** input type for inserting data into table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Insert_Input = {
  admin?: InputMaybe<Admins_Obj_Rel_Insert_Input>;
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  suggested_video?: InputMaybe<Ai_Suggested_Videos_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ai_Suggested_Videos_Reviews_Max_Fields = {
  __typename?: 'ai_suggested_videos_reviews_max_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestedVideoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Max_Order_By = {
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ai_Suggested_Videos_Reviews_Min_Fields = {
  __typename?: 'ai_suggested_videos_reviews_min_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestedVideoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Min_Order_By = {
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Mutation_Response = {
  __typename?: 'ai_suggested_videos_reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Suggested_Videos_Reviews>;
};

/** on_conflict condition type for table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_On_Conflict = {
  constraint: Ai_Suggested_Videos_Reviews_Constraint;
  update_columns?: Array<Ai_Suggested_Videos_Reviews_Update_Column>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_suggested_videos_reviews". */
export type Ai_Suggested_Videos_Reviews_Order_By = {
  admin?: InputMaybe<Admins_Order_By>;
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
  suggested_video?: InputMaybe<Ai_Suggested_Videos_Order_By>;
};

/** primary key columns input for table: ai_suggested_videos_reviews */
export type Ai_Suggested_Videos_Reviews_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ai_suggested_videos_reviews" */
export enum Ai_Suggested_Videos_Reviews_Select_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestedVideoId = 'suggestedVideoId'
}

/** input type for updating data in table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Set_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ai_suggested_videos_reviews" */
export type Ai_Suggested_Videos_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Suggested_Videos_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Suggested_Videos_Reviews_Stream_Cursor_Value_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ai_suggested_videos_reviews" */
export enum Ai_Suggested_Videos_Reviews_Update_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestedVideoId = 'suggestedVideoId'
}

export type Ai_Suggested_Videos_Reviews_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Suggested_Videos_Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Suggested_Videos_Reviews_Bool_Exp;
};

/** select columns of table "ai_suggested_videos" */
export enum Ai_Suggested_Videos_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Lang = 'lang',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedVideoId = 'publishedVideoId',
  /** column name */
  SourceKeyword = 'sourceKeyword',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "ai_suggested_videos" */
export type Ai_Suggested_Videos_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  sourceKeyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ai_suggested_videos" */
export type Ai_Suggested_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Suggested_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Suggested_Videos_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  sourceKeyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ai_suggested_videos" */
export enum Ai_Suggested_Videos_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Lang = 'lang',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedVideoId = 'publishedVideoId',
  /** column name */
  SourceKeyword = 'sourceKeyword',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

export type Ai_Suggested_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Suggested_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Suggested_Videos_Bool_Exp;
};

/** columns and relationships of "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords = {
  __typename?: 'ai_suggestion_keywords';
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
};


/** columns and relationships of "ai_suggestion_keywords" */
export type Ai_Suggestion_KeywordsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Aggregate = {
  __typename?: 'ai_suggestion_keywords_aggregate';
  aggregate?: Maybe<Ai_Suggestion_Keywords_Aggregate_Fields>;
  nodes: Array<Ai_Suggestion_Keywords>;
};

/** aggregate fields of "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Aggregate_Fields = {
  __typename?: 'ai_suggestion_keywords_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Suggestion_Keywords_Max_Fields>;
  min?: Maybe<Ai_Suggestion_Keywords_Min_Fields>;
};


/** aggregate fields of "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Suggestion_Keywords_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Suggestion_Keywords_Append_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "ai_suggestion_keywords". All fields are combined with a logical 'AND'. */
export type Ai_Suggestion_Keywords_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Suggestion_Keywords_Bool_Exp>>;
  _not?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Suggestion_Keywords_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_suggestion_keywords" */
export enum Ai_Suggestion_Keywords_Constraint {
  /** unique or primary key constraint on columns "name" */
  AiSuggestionKeywordsNameKey = 'ai_suggestion_keywords_name_key',
  /** unique or primary key constraint on columns "id" */
  AiSuggestionKeywordsPkey = 'ai_suggestion_keywords_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Suggestion_Keywords_Delete_At_Path_Input = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Suggestion_Keywords_Delete_Elem_Input = {
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Suggestion_Keywords_Delete_Key_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Ai_Suggestion_Keywords_Max_Fields = {
  __typename?: 'ai_suggestion_keywords_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Suggestion_Keywords_Min_Fields = {
  __typename?: 'ai_suggestion_keywords_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Mutation_Response = {
  __typename?: 'ai_suggestion_keywords_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Suggestion_Keywords>;
};

/** on_conflict condition type for table "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_On_Conflict = {
  constraint: Ai_Suggestion_Keywords_Constraint;
  update_columns?: Array<Ai_Suggestion_Keywords_Update_Column>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_suggestion_keywords". */
export type Ai_Suggestion_Keywords_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_suggestion_keywords */
export type Ai_Suggestion_Keywords_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Suggestion_Keywords_Prepend_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "ai_suggestion_keywords" */
export enum Ai_Suggestion_Keywords_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "ai_suggestion_keywords" */
export type Ai_Suggestion_Keywords_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Suggestion_Keywords_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Suggestion_Keywords_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "ai_suggestion_keywords" */
export enum Ai_Suggestion_Keywords_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ai_Suggestion_Keywords_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Suggestion_Keywords_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Suggestion_Keywords_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Suggestion_Keywords_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Suggestion_Keywords_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Suggestion_Keywords_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Suggestion_Keywords_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Suggestion_Keywords_Bool_Exp;
};

/** columns and relationships of "badge_videos" */
export type Badge_Videos = {
  __typename?: 'badge_videos';
  /** An object relationship */
  badge: Badges;
  badgeId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "badge_videos" */
export type Badge_Videos_Aggregate = {
  __typename?: 'badge_videos_aggregate';
  aggregate?: Maybe<Badge_Videos_Aggregate_Fields>;
  nodes: Array<Badge_Videos>;
};

export type Badge_Videos_Aggregate_Bool_Exp = {
  count?: InputMaybe<Badge_Videos_Aggregate_Bool_Exp_Count>;
};

export type Badge_Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Badge_Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "badge_videos" */
export type Badge_Videos_Aggregate_Fields = {
  __typename?: 'badge_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Badge_Videos_Max_Fields>;
  min?: Maybe<Badge_Videos_Min_Fields>;
};


/** aggregate fields of "badge_videos" */
export type Badge_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "badge_videos" */
export type Badge_Videos_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Badge_Videos_Max_Order_By>;
  min?: InputMaybe<Badge_Videos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "badge_videos" */
export type Badge_Videos_Arr_Rel_Insert_Input = {
  data: Array<Badge_Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Badge_Videos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "badge_videos". All fields are combined with a logical 'AND'. */
export type Badge_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Badge_Videos_Bool_Exp>>;
  _not?: InputMaybe<Badge_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Badge_Videos_Bool_Exp>>;
  badge?: InputMaybe<Badges_Bool_Exp>;
  badgeId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge_videos" */
export enum Badge_Videos_Constraint {
  /** unique or primary key constraint on columns "id" */
  BadgeVideosPkey = 'badge_videos_pkey',
  /** unique or primary key constraint on columns "videoId" */
  BadgeVideosVideoIdKey = 'badge_videos_videoId_key'
}

/** input type for inserting data into table "badge_videos" */
export type Badge_Videos_Insert_Input = {
  badge?: InputMaybe<Badges_Obj_Rel_Insert_Input>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Badge_Videos_Max_Fields = {
  __typename?: 'badge_videos_max_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "badge_videos" */
export type Badge_Videos_Max_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Badge_Videos_Min_Fields = {
  __typename?: 'badge_videos_min_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "badge_videos" */
export type Badge_Videos_Min_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "badge_videos" */
export type Badge_Videos_Mutation_Response = {
  __typename?: 'badge_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge_Videos>;
};

/** input type for inserting object relation for remote table "badge_videos" */
export type Badge_Videos_Obj_Rel_Insert_Input = {
  data: Badge_Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Badge_Videos_On_Conflict>;
};

/** on_conflict condition type for table "badge_videos" */
export type Badge_Videos_On_Conflict = {
  constraint: Badge_Videos_Constraint;
  update_columns?: Array<Badge_Videos_Update_Column>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "badge_videos". */
export type Badge_Videos_Order_By = {
  badge?: InputMaybe<Badges_Order_By>;
  badgeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: badge_videos */
export type Badge_Videos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "badge_videos" */
export enum Badge_Videos_Select_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "badge_videos" */
export type Badge_Videos_Set_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "badge_videos" */
export type Badge_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Badge_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badge_Videos_Stream_Cursor_Value_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "badge_videos" */
export enum Badge_Videos_Update_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  VideoId = 'videoId'
}

export type Badge_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Badge_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Badge_Videos_Bool_Exp;
};

/** columns and relationships of "badges" */
export type Badges = {
  __typename?: 'badges';
  avatar?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  badge_videos: Array<Badge_Videos>;
  /** An aggregate relationship */
  badge_videos_aggregate: Badge_Videos_Aggregate;
  /** An object relationship */
  category?: Maybe<Categories>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamp']['output'];
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  description: Scalars['jsonb']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  name: Scalars['jsonb']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "badges" */
export type BadgesBadge_VideosArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


/** columns and relationships of "badges" */
export type BadgesBadge_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


/** columns and relationships of "badges" */
export type BadgesDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "badges" */
export type BadgesNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "badges" */
export type Badges_Aggregate = {
  __typename?: 'badges_aggregate';
  aggregate?: Maybe<Badges_Aggregate_Fields>;
  nodes: Array<Badges>;
};

/** aggregate fields of "badges" */
export type Badges_Aggregate_Fields = {
  __typename?: 'badges_aggregate_fields';
  avg?: Maybe<Badges_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Badges_Max_Fields>;
  min?: Maybe<Badges_Min_Fields>;
  stddev?: Maybe<Badges_Stddev_Fields>;
  stddev_pop?: Maybe<Badges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badges_Stddev_Samp_Fields>;
  sum?: Maybe<Badges_Sum_Fields>;
  var_pop?: Maybe<Badges_Var_Pop_Fields>;
  var_samp?: Maybe<Badges_Var_Samp_Fields>;
  variance?: Maybe<Badges_Variance_Fields>;
};


/** aggregate fields of "badges" */
export type Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Badges_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Badges_Avg_Fields = {
  __typename?: 'badges_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "badges". All fields are combined with a logical 'AND'. */
export type Badges_Bool_Exp = {
  _and?: InputMaybe<Array<Badges_Bool_Exp>>;
  _not?: InputMaybe<Badges_Bool_Exp>;
  _or?: InputMaybe<Array<Badges_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  badge_videos?: InputMaybe<Badge_Videos_Bool_Exp>;
  badge_videos_aggregate?: InputMaybe<Badge_Videos_Aggregate_Bool_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isDeleted?: InputMaybe<Boolean_Comparison_Exp>;
  isPublished?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "badges" */
export enum Badges_Constraint {
  /** unique or primary key constraint on columns "domain" */
  BadgesDomainKey = 'badges_domain_key',
  /** unique or primary key constraint on columns "name" */
  BadgesNameKey = 'badges_name_key',
  /** unique or primary key constraint on columns "id" */
  BadgesPkey = 'badges_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Badges_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Badges_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Badges_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "badges" */
export type Badges_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "badges" */
export type Badges_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  badge_videos?: InputMaybe<Badge_Videos_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Badges_Max_Fields = {
  __typename?: 'badges_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Badges_Min_Fields = {
  __typename?: 'badges_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "badges" */
export type Badges_Mutation_Response = {
  __typename?: 'badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Badges>;
};

/** input type for inserting object relation for remote table "badges" */
export type Badges_Obj_Rel_Insert_Input = {
  data: Badges_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};

/** on_conflict condition type for table "badges" */
export type Badges_On_Conflict = {
  constraint: Badges_Constraint;
  update_columns?: Array<Badges_Update_Column>;
  where?: InputMaybe<Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "badges". */
export type Badges_Order_By = {
  avatar?: InputMaybe<Order_By>;
  badge_videos_aggregate?: InputMaybe<Badge_Videos_Aggregate_Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isDeleted?: InputMaybe<Order_By>;
  isPublished?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: badges */
export type Badges_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Badges_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "badges" */
export enum Badges_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsPublished = 'isPublished',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "badges" */
export type Badges_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Badges_Stddev_Fields = {
  __typename?: 'badges_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Badges_Stddev_Pop_Fields = {
  __typename?: 'badges_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Badges_Stddev_Samp_Fields = {
  __typename?: 'badges_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "badges" */
export type Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badges_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Badges_Sum_Fields = {
  __typename?: 'badges_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "badges" */
export enum Badges_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsPublished = 'isPublished',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Badges_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Badges_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Badges_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Badges_Var_Pop_Fields = {
  __typename?: 'badges_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Badges_Var_Samp_Fields = {
  __typename?: 'badges_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Badges_Variance_Fields = {
  __typename?: 'badges_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  avatar?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  categories_contents: Array<Categories_Contents>;
  /** An aggregate relationship */
  categories_contents_aggregate: Categories_Contents_Aggregate;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  color: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  users_categories: Array<Users_Categories>;
  /** An aggregate relationship */
  users_categories_aggregate: Users_Categories_Aggregate;
};


/** columns and relationships of "categories" */
export type CategoriesCategories_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesCategories_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "categories" */
export type CategoriesNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "categories" */
export type CategoriesUsers_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesUsers_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Categories_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  categories_contents?: InputMaybe<Categories_Contents_Bool_Exp>;
  categories_contents_aggregate?: InputMaybe<Categories_Contents_Aggregate_Bool_Exp>;
  channels?: InputMaybe<Channels_Bool_Exp>;
  channels_aggregate?: InputMaybe<Channels_Aggregate_Bool_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  users_categories?: InputMaybe<Users_Categories_Bool_Exp>;
  users_categories_aggregate?: InputMaybe<Users_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "name" */
  CategoriesNameKey = 'categories_name_key',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey'
}

/** columns and relationships of "categories_contents" */
export type Categories_Contents = {
  __typename?: 'categories_contents';
  /** An object relationship */
  category: Categories;
  categoryId: Scalars['String']['output'];
  /** An object relationship */
  content: Contents;
  contentId: Scalars['String']['output'];
};

/** aggregated selection of "categories_contents" */
export type Categories_Contents_Aggregate = {
  __typename?: 'categories_contents_aggregate';
  aggregate?: Maybe<Categories_Contents_Aggregate_Fields>;
  nodes: Array<Categories_Contents>;
};

export type Categories_Contents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Categories_Contents_Aggregate_Bool_Exp_Count>;
};

export type Categories_Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Categories_Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "categories_contents" */
export type Categories_Contents_Aggregate_Fields = {
  __typename?: 'categories_contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Categories_Contents_Max_Fields>;
  min?: Maybe<Categories_Contents_Min_Fields>;
};


/** aggregate fields of "categories_contents" */
export type Categories_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "categories_contents" */
export type Categories_Contents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Categories_Contents_Max_Order_By>;
  min?: InputMaybe<Categories_Contents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "categories_contents" */
export type Categories_Contents_Arr_Rel_Insert_Input = {
  data: Array<Categories_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_Contents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "categories_contents". All fields are combined with a logical 'AND'. */
export type Categories_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Contents_Bool_Exp>>;
  _not?: InputMaybe<Categories_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Contents_Bool_Exp>>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  contentId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories_contents" */
export enum Categories_Contents_Constraint {
  /** unique or primary key constraint on columns "contentId", "categoryId" */
  CategoriesContentsPkey = 'categories_contents_pkey'
}

/** input type for inserting data into table "categories_contents" */
export type Categories_Contents_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Categories_Contents_Max_Fields = {
  __typename?: 'categories_contents_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "categories_contents" */
export type Categories_Contents_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Contents_Min_Fields = {
  __typename?: 'categories_contents_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "categories_contents" */
export type Categories_Contents_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "categories_contents" */
export type Categories_Contents_Mutation_Response = {
  __typename?: 'categories_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories_Contents>;
};

/** on_conflict condition type for table "categories_contents" */
export type Categories_Contents_On_Conflict = {
  constraint: Categories_Contents_Constraint;
  update_columns?: Array<Categories_Contents_Update_Column>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "categories_contents". */
export type Categories_Contents_Order_By = {
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: categories_contents */
export type Categories_Contents_Pk_Columns_Input = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};

/** select columns of table "categories_contents" */
export enum Categories_Contents_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  ContentId = 'contentId'
}

/** input type for updating data in table "categories_contents" */
export type Categories_Contents_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "categories_contents" */
export type Categories_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Contents_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "categories_contents" */
export enum Categories_Contents_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  ContentId = 'contentId'
}

export type Categories_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Contents_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Categories_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Categories_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Categories_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  categories_contents?: InputMaybe<Categories_Contents_Arr_Rel_Insert_Input>;
  channels?: InputMaybe<Channels_Arr_Rel_Insert_Input>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  users_categories?: InputMaybe<Users_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  avatar?: InputMaybe<Order_By>;
  categories_contents_aggregate?: InputMaybe<Categories_Contents_Aggregate_Order_By>;
  channels_aggregate?: InputMaybe<Channels_Aggregate_Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_categories_aggregate?: InputMaybe<Users_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Categories_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Categories_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Categories_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp;
};

/** columns and relationships of "channels" */
export type Channels = {
  __typename?: 'channels';
  avatar?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category: Categories;
  categoryId: Scalars['String']['output'];
  /** An array relationship */
  channels_contents: Array<Channels_Contents>;
  /** An aggregate relationship */
  channels_contents_aggregate: Channels_Contents_Aggregate;
  /** An array relationship */
  channels_follows: Array<Channels_Follows>;
  /** An aggregate relationship */
  channels_follows_aggregate: Channels_Follows_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  maxTargetAge: Scalars['Int']['output'];
  minTargetAge: Scalars['Int']['output'];
  name: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "channels" */
export type ChannelsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "channels" */
export type ChannelsVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  __typename?: 'channels_aggregate';
  aggregate?: Maybe<Channels_Aggregate_Fields>;
  nodes: Array<Channels>;
};

export type Channels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channels_Aggregate_Bool_Exp_Count>;
};

export type Channels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  __typename?: 'channels_aggregate_fields';
  avg?: Maybe<Channels_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Channels_Max_Fields>;
  min?: Maybe<Channels_Min_Fields>;
  stddev?: Maybe<Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Channels_Sum_Fields>;
  var_pop?: Maybe<Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Channels_Var_Samp_Fields>;
  variance?: Maybe<Channels_Variance_Fields>;
};


/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels" */
export type Channels_Aggregate_Order_By = {
  avg?: InputMaybe<Channels_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channels_Max_Order_By>;
  min?: InputMaybe<Channels_Min_Order_By>;
  stddev?: InputMaybe<Channels_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Channels_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Channels_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Channels_Sum_Order_By>;
  var_pop?: InputMaybe<Channels_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Channels_Var_Samp_Order_By>;
  variance?: InputMaybe<Channels_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channels_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "channels" */
export type Channels_Arr_Rel_Insert_Input = {
  data: Array<Channels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** aggregate avg on columns */
export type Channels_Avg_Fields = {
  __typename?: 'channels_avg_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "channels" */
export type Channels_Avg_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Bool_Exp>>;
  _not?: InputMaybe<Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  channels_contents?: InputMaybe<Channels_Contents_Bool_Exp>;
  channels_contents_aggregate?: InputMaybe<Channels_Contents_Aggregate_Bool_Exp>;
  channels_follows?: InputMaybe<Channels_Follows_Bool_Exp>;
  channels_follows_aggregate?: InputMaybe<Channels_Follows_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  maxTargetAge?: InputMaybe<Int_Comparison_Exp>;
  minTargetAge?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  videos?: InputMaybe<Videos_Bool_Exp>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "channels" */
export enum Channels_Constraint {
  /** unique or primary key constraint on columns "name" */
  ChannelsNameKey = 'channels_name_key',
  /** unique or primary key constraint on columns "id" */
  ChannelsPkey = 'channels_pkey'
}

/** columns and relationships of "channels_contents" */
export type Channels_Contents = {
  __typename?: 'channels_contents';
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['String']['output'];
  /** An object relationship */
  content: Contents;
  contentId: Scalars['String']['output'];
};

/** aggregated selection of "channels_contents" */
export type Channels_Contents_Aggregate = {
  __typename?: 'channels_contents_aggregate';
  aggregate?: Maybe<Channels_Contents_Aggregate_Fields>;
  nodes: Array<Channels_Contents>;
};

export type Channels_Contents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channels_Contents_Aggregate_Bool_Exp_Count>;
};

export type Channels_Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channels_Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels_contents" */
export type Channels_Contents_Aggregate_Fields = {
  __typename?: 'channels_contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Channels_Contents_Max_Fields>;
  min?: Maybe<Channels_Contents_Min_Fields>;
};


/** aggregate fields of "channels_contents" */
export type Channels_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels_contents" */
export type Channels_Contents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channels_Contents_Max_Order_By>;
  min?: InputMaybe<Channels_Contents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channels_contents" */
export type Channels_Contents_Arr_Rel_Insert_Input = {
  data: Array<Channels_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_Contents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channels_contents". All fields are combined with a logical 'AND'. */
export type Channels_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Contents_Bool_Exp>>;
  _not?: InputMaybe<Channels_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Contents_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channelId?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  contentId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_contents" */
export enum Channels_Contents_Constraint {
  /** unique or primary key constraint on columns "channelId", "contentId" */
  ChannelsContentsPkey = 'channels_contents_pkey'
}

/** input type for inserting data into table "channels_contents" */
export type Channels_Contents_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Channels_Contents_Max_Fields = {
  __typename?: 'channels_contents_max_fields';
  channelId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "channels_contents" */
export type Channels_Contents_Max_Order_By = {
  channelId?: InputMaybe<Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_Contents_Min_Fields = {
  __typename?: 'channels_contents_min_fields';
  channelId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "channels_contents" */
export type Channels_Contents_Min_Order_By = {
  channelId?: InputMaybe<Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels_contents" */
export type Channels_Contents_Mutation_Response = {
  __typename?: 'channels_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels_Contents>;
};

/** on_conflict condition type for table "channels_contents" */
export type Channels_Contents_On_Conflict = {
  constraint: Channels_Contents_Constraint;
  update_columns?: Array<Channels_Contents_Update_Column>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_contents". */
export type Channels_Contents_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channelId?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  contentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels_contents */
export type Channels_Contents_Pk_Columns_Input = {
  channelId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};

/** select columns of table "channels_contents" */
export enum Channels_Contents_Select_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ContentId = 'contentId'
}

/** input type for updating data in table "channels_contents" */
export type Channels_Contents_Set_Input = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "channels_contents" */
export type Channels_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Contents_Stream_Cursor_Value_Input = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "channels_contents" */
export enum Channels_Contents_Update_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ContentId = 'contentId'
}

export type Channels_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channels_Contents_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channels_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channels_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channels_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "channels_follows" */
export type Channels_Follows = {
  __typename?: 'channels_follows';
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "channels_follows" */
export type Channels_Follows_Aggregate = {
  __typename?: 'channels_follows_aggregate';
  aggregate?: Maybe<Channels_Follows_Aggregate_Fields>;
  nodes: Array<Channels_Follows>;
};

export type Channels_Follows_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channels_Follows_Aggregate_Bool_Exp_Count>;
};

export type Channels_Follows_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channels_Follows_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels_follows" */
export type Channels_Follows_Aggregate_Fields = {
  __typename?: 'channels_follows_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Channels_Follows_Max_Fields>;
  min?: Maybe<Channels_Follows_Min_Fields>;
};


/** aggregate fields of "channels_follows" */
export type Channels_Follows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels_follows" */
export type Channels_Follows_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channels_Follows_Max_Order_By>;
  min?: InputMaybe<Channels_Follows_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channels_follows" */
export type Channels_Follows_Arr_Rel_Insert_Input = {
  data: Array<Channels_Follows_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_Follows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channels_follows". All fields are combined with a logical 'AND'. */
export type Channels_Follows_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Follows_Bool_Exp>>;
  _not?: InputMaybe<Channels_Follows_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Follows_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channelId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_follows" */
export enum Channels_Follows_Constraint {
  /** unique or primary key constraint on columns "channelId", "userId" */
  ChannelsFollowsPkey = 'channels_follows_pkey'
}

/** input type for inserting data into table "channels_follows" */
export type Channels_Follows_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Channels_Follows_Max_Fields = {
  __typename?: 'channels_follows_max_fields';
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "channels_follows" */
export type Channels_Follows_Max_Order_By = {
  channelId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_Follows_Min_Fields = {
  __typename?: 'channels_follows_min_fields';
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "channels_follows" */
export type Channels_Follows_Min_Order_By = {
  channelId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels_follows" */
export type Channels_Follows_Mutation_Response = {
  __typename?: 'channels_follows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels_Follows>;
};

/** on_conflict condition type for table "channels_follows" */
export type Channels_Follows_On_Conflict = {
  constraint: Channels_Follows_Constraint;
  update_columns?: Array<Channels_Follows_Update_Column>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_follows". */
export type Channels_Follows_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channelId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels_follows */
export type Channels_Follows_Pk_Columns_Input = {
  channelId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "channels_follows" */
export enum Channels_Follows_Select_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "channels_follows" */
export type Channels_Follows_Set_Input = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "channels_follows" */
export type Channels_Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Follows_Stream_Cursor_Value_Input = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "channels_follows" */
export enum Channels_Follows_Update_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId'
}

export type Channels_Follows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Follows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channels_Follows_Bool_Exp;
};

/** input type for incrementing numeric columns in table "channels" */
export type Channels_Inc_Input = {
  maxTargetAge?: InputMaybe<Scalars['Int']['input']>;
  minTargetAge?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "channels" */
export type Channels_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  channels_contents?: InputMaybe<Channels_Contents_Arr_Rel_Insert_Input>;
  channels_follows?: InputMaybe<Channels_Follows_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxTargetAge?: InputMaybe<Scalars['Int']['input']>;
  minTargetAge?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videos?: InputMaybe<Videos_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  __typename?: 'channels_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxTargetAge?: Maybe<Scalars['Int']['output']>;
  minTargetAge?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "channels" */
export type Channels_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  __typename?: 'channels_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxTargetAge?: Maybe<Scalars['Int']['output']>;
  minTargetAge?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "channels" */
export type Channels_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type Channels_Obj_Rel_Insert_Input = {
  data: Channels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** on_conflict condition type for table "channels" */
export type Channels_On_Conflict = {
  constraint: Channels_Constraint;
  update_columns?: Array<Channels_Update_Column>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  avatar?: InputMaybe<Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  channels_contents_aggregate?: InputMaybe<Channels_Contents_Aggregate_Order_By>;
  channels_follows_aggregate?: InputMaybe<Channels_Follows_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channels_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "channels" */
export enum Channels_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxTargetAge = 'maxTargetAge',
  /** column name */
  MinTargetAge = 'minTargetAge',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxTargetAge?: InputMaybe<Scalars['Int']['input']>;
  minTargetAge?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Channels_Stddev_Fields = {
  __typename?: 'channels_stddev_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "channels" */
export type Channels_Stddev_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channels_Stddev_Pop_Fields = {
  __typename?: 'channels_stddev_pop_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "channels" */
export type Channels_Stddev_Pop_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channels_Stddev_Samp_Fields = {
  __typename?: 'channels_stddev_samp_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "channels" */
export type Channels_Stddev_Samp_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxTargetAge?: InputMaybe<Scalars['Int']['input']>;
  minTargetAge?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Channels_Sum_Fields = {
  __typename?: 'channels_sum_fields';
  maxTargetAge?: Maybe<Scalars['Int']['output']>;
  minTargetAge?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "channels" */
export type Channels_Sum_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** update columns of table "channels" */
export enum Channels_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxTargetAge = 'maxTargetAge',
  /** column name */
  MinTargetAge = 'minTargetAge',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Channels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Channels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Channels_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Channels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channels_Var_Pop_Fields = {
  __typename?: 'channels_var_pop_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "channels" */
export type Channels_Var_Pop_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channels_Var_Samp_Fields = {
  __typename?: 'channels_var_samp_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "channels" */
export type Channels_Var_Samp_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Channels_Variance_Fields = {
  __typename?: 'channels_variance_fields';
  maxTargetAge?: Maybe<Scalars['Float']['output']>;
  minTargetAge?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "channels" */
export type Channels_Variance_Order_By = {
  maxTargetAge?: InputMaybe<Order_By>;
  minTargetAge?: InputMaybe<Order_By>;
};

/** columns and relationships of "chrome_extension_notifications" */
export type Chrome_Extension_Notifications = {
  __typename?: 'chrome_extension_notifications';
  chromeExtId: Scalars['String']['output'];
  /** An object relationship */
  chrome_extension_user: Chrome_Extension_Users;
  createdAt: Scalars['timestamp']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


/** columns and relationships of "chrome_extension_notifications" */
export type Chrome_Extension_NotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Aggregate = {
  __typename?: 'chrome_extension_notifications_aggregate';
  aggregate?: Maybe<Chrome_Extension_Notifications_Aggregate_Fields>;
  nodes: Array<Chrome_Extension_Notifications>;
};

/** aggregate fields of "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Aggregate_Fields = {
  __typename?: 'chrome_extension_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chrome_Extension_Notifications_Max_Fields>;
  min?: Maybe<Chrome_Extension_Notifications_Min_Fields>;
};


/** aggregate fields of "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chrome_Extension_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chrome_Extension_Notifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "chrome_extension_notifications". All fields are combined with a logical 'AND'. */
export type Chrome_Extension_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Chrome_Extension_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Chrome_Extension_Notifications_Bool_Exp>>;
  chromeExtId?: InputMaybe<String_Comparison_Exp>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chrome_extension_notifications" */
export enum Chrome_Extension_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChromeExtensionNotificationsPkey = 'chrome_extension_notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chrome_Extension_Notifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chrome_Extension_Notifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chrome_Extension_Notifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Insert_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chrome_Extension_Notifications_Max_Fields = {
  __typename?: 'chrome_extension_notifications_max_fields';
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Chrome_Extension_Notifications_Min_Fields = {
  __typename?: 'chrome_extension_notifications_min_fields';
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Mutation_Response = {
  __typename?: 'chrome_extension_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chrome_Extension_Notifications>;
};

/** on_conflict condition type for table "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_On_Conflict = {
  constraint: Chrome_Extension_Notifications_Constraint;
  update_columns?: Array<Chrome_Extension_Notifications_Update_Column>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "chrome_extension_notifications". */
export type Chrome_Extension_Notifications_Order_By = {
  chromeExtId?: InputMaybe<Order_By>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chrome_extension_notifications */
export type Chrome_Extension_Notifications_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chrome_Extension_Notifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "chrome_extension_notifications" */
export enum Chrome_Extension_Notifications_Select_Column {
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Set_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chrome_extension_notifications" */
export type Chrome_Extension_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chrome_Extension_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chrome_Extension_Notifications_Stream_Cursor_Value_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chrome_extension_notifications" */
export enum Chrome_Extension_Notifications_Update_Column {
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

export type Chrome_Extension_Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Chrome_Extension_Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Chrome_Extension_Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Chrome_Extension_Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Chrome_Extension_Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Chrome_Extension_Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chrome_Extension_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chrome_Extension_Notifications_Bool_Exp;
};

/** columns and relationships of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos = {
  __typename?: 'chrome_extension_suggested_videos';
  chromeExtId: Scalars['String']['output'];
  /** An object relationship */
  chrome_extension_user: Chrome_Extension_Users;
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  published_video?: Maybe<Videos>;
  status: Scalars['String']['output'];
  /** An array relationship */
  suggested_videos_reviews: Array<Chrome_Extension_Suggested_Videos_Reviews>;
  /** An aggregate relationship */
  suggested_videos_reviews_aggregate: Chrome_Extension_Suggested_Videos_Reviews_Aggregate;
  title: Scalars['String']['output'];
};


/** columns and relationships of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_VideosSuggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


/** columns and relationships of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_VideosSuggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};

/** aggregated selection of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Aggregate = {
  __typename?: 'chrome_extension_suggested_videos_aggregate';
  aggregate?: Maybe<Chrome_Extension_Suggested_Videos_Aggregate_Fields>;
  nodes: Array<Chrome_Extension_Suggested_Videos>;
};

export type Chrome_Extension_Suggested_Videos_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chrome_Extension_Suggested_Videos_Aggregate_Bool_Exp_Count>;
};

export type Chrome_Extension_Suggested_Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Aggregate_Fields = {
  __typename?: 'chrome_extension_suggested_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chrome_Extension_Suggested_Videos_Max_Fields>;
  min?: Maybe<Chrome_Extension_Suggested_Videos_Min_Fields>;
};


/** aggregate fields of "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chrome_Extension_Suggested_Videos_Max_Order_By>;
  min?: InputMaybe<Chrome_Extension_Suggested_Videos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Arr_Rel_Insert_Input = {
  data: Array<Chrome_Extension_Suggested_Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chrome_extension_suggested_videos". All fields are combined with a logical 'AND'. */
export type Chrome_Extension_Suggested_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Bool_Exp>>;
  _not?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Bool_Exp>>;
  chromeExtId?: InputMaybe<String_Comparison_Exp>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  publishedVideoId?: InputMaybe<String_Comparison_Exp>;
  published_video?: InputMaybe<Videos_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  suggested_videos_reviews?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
  suggested_videos_reviews_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chrome_extension_suggested_videos" */
export enum Chrome_Extension_Suggested_Videos_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChromeExtensionSuggestedVideosPkey = 'chrome_extension_suggested_videos_pkey'
}

/** input type for inserting data into table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Insert_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  published_video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggested_videos_reviews?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chrome_Extension_Suggested_Videos_Max_Fields = {
  __typename?: 'chrome_extension_suggested_videos_max_fields';
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Max_Order_By = {
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  publishedVideoId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chrome_Extension_Suggested_Videos_Min_Fields = {
  __typename?: 'chrome_extension_suggested_videos_min_fields';
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  publishedVideoId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Min_Order_By = {
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  publishedVideoId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Mutation_Response = {
  __typename?: 'chrome_extension_suggested_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chrome_Extension_Suggested_Videos>;
};

/** input type for inserting object relation for remote table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Obj_Rel_Insert_Input = {
  data: Chrome_Extension_Suggested_Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_On_Conflict>;
};

/** on_conflict condition type for table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_On_Conflict = {
  constraint: Chrome_Extension_Suggested_Videos_Constraint;
  update_columns?: Array<Chrome_Extension_Suggested_Videos_Update_Column>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "chrome_extension_suggested_videos". */
export type Chrome_Extension_Suggested_Videos_Order_By = {
  chromeExtId?: InputMaybe<Order_By>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  publishedVideoId?: InputMaybe<Order_By>;
  published_video?: InputMaybe<Videos_Order_By>;
  status?: InputMaybe<Order_By>;
  suggested_videos_reviews_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chrome_extension_suggested_videos */
export type Chrome_Extension_Suggested_Videos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** columns and relationships of "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews = {
  __typename?: 'chrome_extension_suggested_videos_reviews';
  /** An object relationship */
  admin?: Maybe<Admins>;
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  chrome_extension_user?: Maybe<Chrome_Extension_Users>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  suggestedVideoId: Scalars['String']['output'];
  /** An object relationship */
  suggested_video: Chrome_Extension_Suggested_Videos;
};

/** aggregated selection of "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate = {
  __typename?: 'chrome_extension_suggested_videos_reviews_aggregate';
  aggregate?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Fields>;
  nodes: Array<Chrome_Extension_Suggested_Videos_Reviews>;
};

export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Bool_Exp_Count>;
};

export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Fields = {
  __typename?: 'chrome_extension_suggested_videos_reviews_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Max_Fields>;
  min?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Min_Fields>;
};


/** aggregate fields of "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Max_Order_By>;
  min?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Arr_Rel_Insert_Input = {
  data: Array<Chrome_Extension_Suggested_Videos_Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chrome_extension_suggested_videos_reviews". All fields are combined with a logical 'AND'. */
export type Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>>;
  _not?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>>;
  admin?: InputMaybe<Admins_Bool_Exp>;
  adminId?: InputMaybe<String_Comparison_Exp>;
  chromeExtId?: InputMaybe<String_Comparison_Exp>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  suggestedVideoId?: InputMaybe<String_Comparison_Exp>;
  suggested_video?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};

/** unique or primary key constraints on table "chrome_extension_suggested_videos_reviews" */
export enum Chrome_Extension_Suggested_Videos_Reviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChromeExtensionSuggestedVideosReviewsPkey = 'chrome_extension_suggested_videos_reviews_pkey'
}

/** input type for inserting data into table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Insert_Input = {
  admin?: InputMaybe<Admins_Obj_Rel_Insert_Input>;
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  suggested_video?: InputMaybe<Chrome_Extension_Suggested_Videos_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chrome_Extension_Suggested_Videos_Reviews_Max_Fields = {
  __typename?: 'chrome_extension_suggested_videos_reviews_max_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestedVideoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Max_Order_By = {
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chrome_Extension_Suggested_Videos_Reviews_Min_Fields = {
  __typename?: 'chrome_extension_suggested_videos_reviews_min_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  chromeExtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestedVideoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Min_Order_By = {
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Mutation_Response = {
  __typename?: 'chrome_extension_suggested_videos_reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chrome_Extension_Suggested_Videos_Reviews>;
};

/** on_conflict condition type for table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_On_Conflict = {
  constraint: Chrome_Extension_Suggested_Videos_Reviews_Constraint;
  update_columns?: Array<Chrome_Extension_Suggested_Videos_Reviews_Update_Column>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "chrome_extension_suggested_videos_reviews". */
export type Chrome_Extension_Suggested_Videos_Reviews_Order_By = {
  admin?: InputMaybe<Admins_Order_By>;
  adminId?: InputMaybe<Order_By>;
  chromeExtId?: InputMaybe<Order_By>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestedVideoId?: InputMaybe<Order_By>;
  suggested_video?: InputMaybe<Chrome_Extension_Suggested_Videos_Order_By>;
};

/** primary key columns input for table: chrome_extension_suggested_videos_reviews */
export type Chrome_Extension_Suggested_Videos_Reviews_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "chrome_extension_suggested_videos_reviews" */
export enum Chrome_Extension_Suggested_Videos_Reviews_Select_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestedVideoId = 'suggestedVideoId'
}

/** input type for updating data in table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Set_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chrome_extension_suggested_videos_reviews" */
export type Chrome_Extension_Suggested_Videos_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chrome_Extension_Suggested_Videos_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chrome_Extension_Suggested_Videos_Reviews_Stream_Cursor_Value_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chrome_extension_suggested_videos_reviews" */
export enum Chrome_Extension_Suggested_Videos_Reviews_Update_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestedVideoId = 'suggestedVideoId'
}

export type Chrome_Extension_Suggested_Videos_Reviews_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp;
};

/** select columns of table "chrome_extension_suggested_videos" */
export enum Chrome_Extension_Suggested_Videos_Select_Column {
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedVideoId = 'publishedVideoId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Set_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chrome_extension_suggested_videos" */
export type Chrome_Extension_Suggested_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chrome_Extension_Suggested_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chrome_Extension_Suggested_Videos_Stream_Cursor_Value_Input = {
  chromeExtId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  publishedVideoId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chrome_extension_suggested_videos" */
export enum Chrome_Extension_Suggested_Videos_Update_Column {
  /** column name */
  ChromeExtId = 'chromeExtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedVideoId = 'publishedVideoId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

export type Chrome_Extension_Suggested_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chrome_Extension_Suggested_Videos_Bool_Exp;
};

/** columns and relationships of "chrome_extension_users" */
export type Chrome_Extension_Users = {
  __typename?: 'chrome_extension_users';
  /** An array relationship */
  chrome_extension_suggested_videos: Array<Chrome_Extension_Suggested_Videos>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_aggregate: Chrome_Extension_Suggested_Videos_Aggregate;
  /** An array relationship */
  chrome_extension_suggested_videos_reviews: Array<Chrome_Extension_Suggested_Videos_Reviews>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_reviews_aggregate: Chrome_Extension_Suggested_Videos_Reviews_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "chrome_extension_users" */
export type Chrome_Extension_UsersChrome_Extension_Suggested_VideosArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


/** columns and relationships of "chrome_extension_users" */
export type Chrome_Extension_UsersChrome_Extension_Suggested_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


/** columns and relationships of "chrome_extension_users" */
export type Chrome_Extension_UsersChrome_Extension_Suggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


/** columns and relationships of "chrome_extension_users" */
export type Chrome_Extension_UsersChrome_Extension_Suggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};

/** aggregated selection of "chrome_extension_users" */
export type Chrome_Extension_Users_Aggregate = {
  __typename?: 'chrome_extension_users_aggregate';
  aggregate?: Maybe<Chrome_Extension_Users_Aggregate_Fields>;
  nodes: Array<Chrome_Extension_Users>;
};

/** aggregate fields of "chrome_extension_users" */
export type Chrome_Extension_Users_Aggregate_Fields = {
  __typename?: 'chrome_extension_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chrome_Extension_Users_Max_Fields>;
  min?: Maybe<Chrome_Extension_Users_Min_Fields>;
};


/** aggregate fields of "chrome_extension_users" */
export type Chrome_Extension_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chrome_Extension_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "chrome_extension_users". All fields are combined with a logical 'AND'. */
export type Chrome_Extension_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Chrome_Extension_Users_Bool_Exp>>;
  _not?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Chrome_Extension_Users_Bool_Exp>>;
  chrome_extension_suggested_videos?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
  chrome_extension_suggested_videos_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Aggregate_Bool_Exp>;
  chrome_extension_suggested_videos_reviews?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
  chrome_extension_suggested_videos_reviews_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chrome_extension_users" */
export enum Chrome_Extension_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChromeExtensionUsersPkey = 'chrome_extension_users_pkey',
  /** unique or primary key constraint on columns "userId" */
  ChromeExtensionUsersUserIdKey = 'chrome_extension_users_userId_key'
}

/** input type for inserting data into table "chrome_extension_users" */
export type Chrome_Extension_Users_Insert_Input = {
  chrome_extension_suggested_videos?: InputMaybe<Chrome_Extension_Suggested_Videos_Arr_Rel_Insert_Input>;
  chrome_extension_suggested_videos_reviews?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chrome_Extension_Users_Max_Fields = {
  __typename?: 'chrome_extension_users_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Chrome_Extension_Users_Min_Fields = {
  __typename?: 'chrome_extension_users_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chrome_extension_users" */
export type Chrome_Extension_Users_Mutation_Response = {
  __typename?: 'chrome_extension_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chrome_Extension_Users>;
};

/** input type for inserting object relation for remote table "chrome_extension_users" */
export type Chrome_Extension_Users_Obj_Rel_Insert_Input = {
  data: Chrome_Extension_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chrome_Extension_Users_On_Conflict>;
};

/** on_conflict condition type for table "chrome_extension_users" */
export type Chrome_Extension_Users_On_Conflict = {
  constraint: Chrome_Extension_Users_Constraint;
  update_columns?: Array<Chrome_Extension_Users_Update_Column>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "chrome_extension_users". */
export type Chrome_Extension_Users_Order_By = {
  chrome_extension_suggested_videos_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Aggregate_Order_By>;
  chrome_extension_suggested_videos_reviews_aggregate?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chrome_extension_users */
export type Chrome_Extension_Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "chrome_extension_users" */
export enum Chrome_Extension_Users_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "chrome_extension_users" */
export type Chrome_Extension_Users_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chrome_extension_users" */
export type Chrome_Extension_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chrome_Extension_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chrome_Extension_Users_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chrome_extension_users" */
export enum Chrome_Extension_Users_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

export type Chrome_Extension_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chrome_Extension_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chrome_Extension_Users_Bool_Exp;
};

/** columns and relationships of "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders = {
  __typename?: 'coming_soon_videos_reminders';
  userId: Scalars['String']['output'];
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Aggregate = {
  __typename?: 'coming_soon_videos_reminders_aggregate';
  aggregate?: Maybe<Coming_Soon_Videos_Reminders_Aggregate_Fields>;
  nodes: Array<Coming_Soon_Videos_Reminders>;
};

/** aggregate fields of "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Aggregate_Fields = {
  __typename?: 'coming_soon_videos_reminders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Coming_Soon_Videos_Reminders_Max_Fields>;
  min?: Maybe<Coming_Soon_Videos_Reminders_Min_Fields>;
};


/** aggregate fields of "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "coming_soon_videos_reminders". All fields are combined with a logical 'AND'. */
export type Coming_Soon_Videos_Reminders_Bool_Exp = {
  _and?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Bool_Exp>>;
  _not?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
  _or?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Bool_Exp>>;
  userId?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "coming_soon_videos_reminders" */
export enum Coming_Soon_Videos_Reminders_Constraint {
  /** unique or primary key constraint on columns "userId", "videoId" */
  ComingSoonVideosRemindersPkey = 'coming_soon_videos_reminders_pkey'
}

/** input type for inserting data into table "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Insert_Input = {
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Coming_Soon_Videos_Reminders_Max_Fields = {
  __typename?: 'coming_soon_videos_reminders_max_fields';
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Coming_Soon_Videos_Reminders_Min_Fields = {
  __typename?: 'coming_soon_videos_reminders_min_fields';
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Mutation_Response = {
  __typename?: 'coming_soon_videos_reminders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Coming_Soon_Videos_Reminders>;
};

/** on_conflict condition type for table "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_On_Conflict = {
  constraint: Coming_Soon_Videos_Reminders_Constraint;
  update_columns?: Array<Coming_Soon_Videos_Reminders_Update_Column>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};

/** Ordering options when selecting data from "coming_soon_videos_reminders". */
export type Coming_Soon_Videos_Reminders_Order_By = {
  userId?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coming_soon_videos_reminders */
export type Coming_Soon_Videos_Reminders_Pk_Columns_Input = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "coming_soon_videos_reminders" */
export enum Coming_Soon_Videos_Reminders_Select_Column {
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Set_Input = {
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "coming_soon_videos_reminders" */
export type Coming_Soon_Videos_Reminders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coming_Soon_Videos_Reminders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coming_Soon_Videos_Reminders_Stream_Cursor_Value_Input = {
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "coming_soon_videos_reminders" */
export enum Coming_Soon_Videos_Reminders_Update_Column {
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

export type Coming_Soon_Videos_Reminders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coming_Soon_Videos_Reminders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coming_Soon_Videos_Reminders_Bool_Exp;
};

/** columns and relationships of "contents" */
export type Contents = {
  __typename?: 'contents';
  /** An array relationship */
  categories_contents: Array<Categories_Contents>;
  /** An aggregate relationship */
  categories_contents_aggregate: Categories_Contents_Aggregate;
  /** An array relationship */
  channels_contents: Array<Channels_Contents>;
  /** An aggregate relationship */
  channels_contents_aggregate: Channels_Contents_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  videos_contents: Array<Videos_Contents>;
  /** An aggregate relationship */
  videos_contents_aggregate: Videos_Contents_Aggregate;
};


/** columns and relationships of "contents" */
export type ContentsCategories_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsCategories_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsChannels_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsChannels_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "contents" */
export type ContentsVideos_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsVideos_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};

/** aggregated selection of "contents" */
export type Contents_Aggregate = {
  __typename?: 'contents_aggregate';
  aggregate?: Maybe<Contents_Aggregate_Fields>;
  nodes: Array<Contents>;
};

/** aggregate fields of "contents" */
export type Contents_Aggregate_Fields = {
  __typename?: 'contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contents_Max_Fields>;
  min?: Maybe<Contents_Min_Fields>;
};


/** aggregate fields of "contents" */
export type Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Contents_Append_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Bool_Exp>>;
  _not?: InputMaybe<Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Bool_Exp>>;
  categories_contents?: InputMaybe<Categories_Contents_Bool_Exp>;
  categories_contents_aggregate?: InputMaybe<Categories_Contents_Aggregate_Bool_Exp>;
  channels_contents?: InputMaybe<Channels_Contents_Bool_Exp>;
  channels_contents_aggregate?: InputMaybe<Channels_Contents_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  videos_contents?: InputMaybe<Videos_Contents_Bool_Exp>;
  videos_contents_aggregate?: InputMaybe<Videos_Contents_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contents" */
export enum Contents_Constraint {
  /** unique or primary key constraint on columns "name" */
  ContentsNameKey = 'contents_name_key',
  /** unique or primary key constraint on columns "id" */
  ContentsPkey = 'contents_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Contents_Delete_At_Path_Input = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Contents_Delete_Elem_Input = {
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Contents_Delete_Key_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "contents" */
export type Contents_Insert_Input = {
  categories_contents?: InputMaybe<Categories_Contents_Arr_Rel_Insert_Input>;
  channels_contents?: InputMaybe<Channels_Contents_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videos_contents?: InputMaybe<Videos_Contents_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contents_Max_Fields = {
  __typename?: 'contents_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Contents_Min_Fields = {
  __typename?: 'contents_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "contents" */
export type Contents_Mutation_Response = {
  __typename?: 'contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents>;
};

/** input type for inserting object relation for remote table "contents" */
export type Contents_Obj_Rel_Insert_Input = {
  data: Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};

/** on_conflict condition type for table "contents" */
export type Contents_On_Conflict = {
  constraint: Contents_Constraint;
  update_columns?: Array<Contents_Update_Column>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Contents_Order_By = {
  categories_contents_aggregate?: InputMaybe<Categories_Contents_Aggregate_Order_By>;
  channels_contents_aggregate?: InputMaybe<Channels_Contents_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videos_contents_aggregate?: InputMaybe<Videos_Contents_Aggregate_Order_By>;
};

/** primary key columns input for table: contents */
export type Contents_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Contents_Prepend_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "contents" */
export enum Contents_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contents" */
export type Contents_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "contents" */
export type Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contents_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "contents" */
export enum Contents_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Contents_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Contents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Contents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Contents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Contents_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Contents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contents_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "faq" */
export type Faq = {
  __typename?: 'faq';
  answer: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  question: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "faq" */
export type FaqAnswerArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "faq" */
export type FaqQuestionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "faq" */
export type Faq_Aggregate = {
  __typename?: 'faq_aggregate';
  aggregate?: Maybe<Faq_Aggregate_Fields>;
  nodes: Array<Faq>;
};

/** aggregate fields of "faq" */
export type Faq_Aggregate_Fields = {
  __typename?: 'faq_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Faq_Max_Fields>;
  min?: Maybe<Faq_Min_Fields>;
};


/** aggregate fields of "faq" */
export type Faq_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faq_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Faq_Append_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "faq". All fields are combined with a logical 'AND'. */
export type Faq_Bool_Exp = {
  _and?: InputMaybe<Array<Faq_Bool_Exp>>;
  _not?: InputMaybe<Faq_Bool_Exp>;
  _or?: InputMaybe<Array<Faq_Bool_Exp>>;
  answer?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq" */
export enum Faq_Constraint {
  /** unique or primary key constraint on columns "id" */
  FaqPkey = 'faq_pkey',
  /** unique or primary key constraint on columns "question" */
  FaqQuestionKey = 'faq_question_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Faq_Delete_At_Path_Input = {
  answer?: InputMaybe<Array<Scalars['String']['input']>>;
  question?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Faq_Delete_Elem_Input = {
  answer?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Faq_Delete_Key_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "faq" */
export type Faq_Insert_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Faq_Max_Fields = {
  __typename?: 'faq_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Faq_Min_Fields = {
  __typename?: 'faq_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "faq" */
export type Faq_Mutation_Response = {
  __typename?: 'faq_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Faq>;
};

/** on_conflict condition type for table "faq" */
export type Faq_On_Conflict = {
  constraint: Faq_Constraint;
  update_columns?: Array<Faq_Update_Column>;
  where?: InputMaybe<Faq_Bool_Exp>;
};

/** Ordering options when selecting data from "faq". */
export type Faq_Order_By = {
  answer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faq */
export type Faq_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Faq_Prepend_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "faq" */
export enum Faq_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "faq" */
export type Faq_Set_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "faq" */
export type Faq_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Faq_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faq_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "faq" */
export enum Faq_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Faq_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Faq_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Faq_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Faq_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Faq_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Faq_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Faq_Set_Input>;
  /** filter the rows which have to be updated */
  where: Faq_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "follow_requests" */
export type Follow_Requests = {
  __typename?: 'follow_requests';
  createdAt: Scalars['timestamp']['output'];
  receiverId: Scalars['String']['output'];
  senderId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userBySenderid: Users;
};

/** aggregated selection of "follow_requests" */
export type Follow_Requests_Aggregate = {
  __typename?: 'follow_requests_aggregate';
  aggregate?: Maybe<Follow_Requests_Aggregate_Fields>;
  nodes: Array<Follow_Requests>;
};

export type Follow_Requests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Follow_Requests_Aggregate_Bool_Exp_Count>;
};

export type Follow_Requests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Follow_Requests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "follow_requests" */
export type Follow_Requests_Aggregate_Fields = {
  __typename?: 'follow_requests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Follow_Requests_Max_Fields>;
  min?: Maybe<Follow_Requests_Min_Fields>;
};


/** aggregate fields of "follow_requests" */
export type Follow_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "follow_requests" */
export type Follow_Requests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Follow_Requests_Max_Order_By>;
  min?: InputMaybe<Follow_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "follow_requests" */
export type Follow_Requests_Arr_Rel_Insert_Input = {
  data: Array<Follow_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Follow_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "follow_requests". All fields are combined with a logical 'AND'. */
export type Follow_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Follow_Requests_Bool_Exp>>;
  _not?: InputMaybe<Follow_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Follow_Requests_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  receiverId?: InputMaybe<String_Comparison_Exp>;
  senderId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userBySenderid?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "follow_requests" */
export enum Follow_Requests_Constraint {
  /** unique or primary key constraint on columns "senderId", "receiverId" */
  FollowRequestsPkey = 'follow_requests_pkey'
}

/** input type for inserting data into table "follow_requests" */
export type Follow_Requests_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userBySenderid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Follow_Requests_Max_Fields = {
  __typename?: 'follow_requests_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  receiverId?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "follow_requests" */
export type Follow_Requests_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  senderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Follow_Requests_Min_Fields = {
  __typename?: 'follow_requests_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  receiverId?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "follow_requests" */
export type Follow_Requests_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  senderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "follow_requests" */
export type Follow_Requests_Mutation_Response = {
  __typename?: 'follow_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Follow_Requests>;
};

/** on_conflict condition type for table "follow_requests" */
export type Follow_Requests_On_Conflict = {
  constraint: Follow_Requests_Constraint;
  update_columns?: Array<Follow_Requests_Update_Column>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "follow_requests". */
export type Follow_Requests_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  senderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userBySenderid?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: follow_requests */
export type Follow_Requests_Pk_Columns_Input = {
  receiverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};

/** select columns of table "follow_requests" */
export enum Follow_Requests_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  SenderId = 'senderId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "follow_requests" */
export type Follow_Requests_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "follow_requests" */
export type Follow_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Follow_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follow_Requests_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "follow_requests" */
export enum Follow_Requests_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  SenderId = 'senderId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Follow_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Follow_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Follow_Requests_Bool_Exp;
};

/** columns and relationships of "general_messages" */
export type General_Messages = {
  __typename?: 'general_messages';
  channels?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  privacy: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** aggregated selection of "general_messages" */
export type General_Messages_Aggregate = {
  __typename?: 'general_messages_aggregate';
  aggregate?: Maybe<General_Messages_Aggregate_Fields>;
  nodes: Array<General_Messages>;
};

/** aggregate fields of "general_messages" */
export type General_Messages_Aggregate_Fields = {
  __typename?: 'general_messages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<General_Messages_Max_Fields>;
  min?: Maybe<General_Messages_Min_Fields>;
};


/** aggregate fields of "general_messages" */
export type General_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<General_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "general_messages". All fields are combined with a logical 'AND'. */
export type General_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<General_Messages_Bool_Exp>>;
  _not?: InputMaybe<General_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<General_Messages_Bool_Exp>>;
  channels?: InputMaybe<String_Array_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  privacy?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "general_messages" */
export enum General_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeneralMessagesPkey = 'general_messages_pkey'
}

/** input type for inserting data into table "general_messages" */
export type General_Messages_Insert_Input = {
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type General_Messages_Max_Fields = {
  __typename?: 'general_messages_max_fields';
  channels?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type General_Messages_Min_Fields = {
  __typename?: 'general_messages_min_fields';
  channels?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "general_messages" */
export type General_Messages_Mutation_Response = {
  __typename?: 'general_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<General_Messages>;
};

/** on_conflict condition type for table "general_messages" */
export type General_Messages_On_Conflict = {
  constraint: General_Messages_Constraint;
  update_columns?: Array<General_Messages_Update_Column>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "general_messages". */
export type General_Messages_Order_By = {
  channels?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  privacy?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: general_messages */
export type General_Messages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "general_messages" */
export enum General_Messages_Select_Column {
  /** column name */
  Channels = 'channels',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "general_messages" */
export type General_Messages_Set_Input = {
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "general_messages" */
export type General_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: General_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type General_Messages_Stream_Cursor_Value_Input = {
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "general_messages" */
export enum General_Messages_Update_Column {
  /** column name */
  Channels = 'channels',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  Title = 'title'
}

export type General_Messages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<General_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: General_Messages_Bool_Exp;
};

/** columns and relationships of "genres" */
export type Genres = {
  __typename?: 'genres';
  name: Scalars['String']['output'];
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
};


/** columns and relationships of "genres" */
export type GenresVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


/** columns and relationships of "genres" */
export type GenresVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** aggregated selection of "genres" */
export type Genres_Aggregate = {
  __typename?: 'genres_aggregate';
  aggregate?: Maybe<Genres_Aggregate_Fields>;
  nodes: Array<Genres>;
};

/** aggregate fields of "genres" */
export type Genres_Aggregate_Fields = {
  __typename?: 'genres_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Genres_Max_Fields>;
  min?: Maybe<Genres_Min_Fields>;
};


/** aggregate fields of "genres" */
export type Genres_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Genres_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "genres". All fields are combined with a logical 'AND'. */
export type Genres_Bool_Exp = {
  _and?: InputMaybe<Array<Genres_Bool_Exp>>;
  _not?: InputMaybe<Genres_Bool_Exp>;
  _or?: InputMaybe<Array<Genres_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  videos?: InputMaybe<Videos_Bool_Exp>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "genres" */
export enum Genres_Constraint {
  /** unique or primary key constraint on columns "name" */
  GenresPkey = 'genres_pkey'
}

/** input type for inserting data into table "genres" */
export type Genres_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  videos?: InputMaybe<Videos_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Genres_Max_Fields = {
  __typename?: 'genres_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Genres_Min_Fields = {
  __typename?: 'genres_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "genres" */
export type Genres_Mutation_Response = {
  __typename?: 'genres_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Genres>;
};

/** on_conflict condition type for table "genres" */
export type Genres_On_Conflict = {
  constraint: Genres_Constraint;
  update_columns?: Array<Genres_Update_Column>;
  where?: InputMaybe<Genres_Bool_Exp>;
};

/** Ordering options when selecting data from "genres". */
export type Genres_Order_By = {
  name?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Order_By>;
};

/** primary key columns input for table: genres */
export type Genres_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "genres" */
export enum Genres_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "genres" */
export type Genres_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "genres" */
export type Genres_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Genres_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Genres_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "genres" */
export enum Genres_Update_Column {
  /** column name */
  Name = 'name'
}

export type Genres_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Genres_Set_Input>;
  /** filter the rows which have to be updated */
  where: Genres_Bool_Exp;
};

/** columns and relationships of "heared_methods" */
export type Heared_Methods = {
  __typename?: 'heared_methods';
  count: Scalars['Int']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "heared_methods" */
export type Heared_MethodsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "heared_methods" */
export type Heared_Methods_Aggregate = {
  __typename?: 'heared_methods_aggregate';
  aggregate?: Maybe<Heared_Methods_Aggregate_Fields>;
  nodes: Array<Heared_Methods>;
};

/** aggregate fields of "heared_methods" */
export type Heared_Methods_Aggregate_Fields = {
  __typename?: 'heared_methods_aggregate_fields';
  avg?: Maybe<Heared_Methods_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Heared_Methods_Max_Fields>;
  min?: Maybe<Heared_Methods_Min_Fields>;
  stddev?: Maybe<Heared_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Heared_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Heared_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Heared_Methods_Sum_Fields>;
  var_pop?: Maybe<Heared_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Heared_Methods_Var_Samp_Fields>;
  variance?: Maybe<Heared_Methods_Variance_Fields>;
};


/** aggregate fields of "heared_methods" */
export type Heared_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Heared_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Heared_Methods_Append_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Heared_Methods_Avg_Fields = {
  __typename?: 'heared_methods_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "heared_methods". All fields are combined with a logical 'AND'. */
export type Heared_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Heared_Methods_Bool_Exp>>;
  _not?: InputMaybe<Heared_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Heared_Methods_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "heared_methods" */
export enum Heared_Methods_Constraint {
  /** unique or primary key constraint on columns "name" */
  HearedMethodsNameKey = 'heared_methods_name_key',
  /** unique or primary key constraint on columns "id" */
  HearedMethodsPkey = 'heared_methods_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Heared_Methods_Delete_At_Path_Input = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Heared_Methods_Delete_Elem_Input = {
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Heared_Methods_Delete_Key_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "heared_methods" */
export type Heared_Methods_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "heared_methods" */
export type Heared_Methods_Insert_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Heared_Methods_Max_Fields = {
  __typename?: 'heared_methods_max_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Heared_Methods_Min_Fields = {
  __typename?: 'heared_methods_min_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "heared_methods" */
export type Heared_Methods_Mutation_Response = {
  __typename?: 'heared_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Heared_Methods>;
};

/** on_conflict condition type for table "heared_methods" */
export type Heared_Methods_On_Conflict = {
  constraint: Heared_Methods_Constraint;
  update_columns?: Array<Heared_Methods_Update_Column>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "heared_methods". */
export type Heared_Methods_Order_By = {
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: heared_methods */
export type Heared_Methods_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Heared_Methods_Prepend_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "heared_methods" */
export enum Heared_Methods_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "heared_methods" */
export type Heared_Methods_Set_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Heared_Methods_Stddev_Fields = {
  __typename?: 'heared_methods_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Heared_Methods_Stddev_Pop_Fields = {
  __typename?: 'heared_methods_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Heared_Methods_Stddev_Samp_Fields = {
  __typename?: 'heared_methods_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "heared_methods" */
export type Heared_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Heared_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Heared_Methods_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Heared_Methods_Sum_Fields = {
  __typename?: 'heared_methods_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "heared_methods" */
export enum Heared_Methods_Update_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Heared_Methods_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Heared_Methods_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Heared_Methods_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Heared_Methods_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Heared_Methods_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Heared_Methods_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Heared_Methods_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Heared_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Heared_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Heared_Methods_Var_Pop_Fields = {
  __typename?: 'heared_methods_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Heared_Methods_Var_Samp_Fields = {
  __typename?: 'heared_methods_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Heared_Methods_Variance_Fields = {
  __typename?: 'heared_methods_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _eq?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _gt?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _gte?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['jsonb']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _lte?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _neq?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['jsonb']['input']>>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "kid_user_settings" */
export type Kid_User_Settings = {
  __typename?: 'kid_user_settings';
  audio: Scalars['String']['output'];
  channelsUpdatesNotifications: Scalars['Boolean']['output'];
  displayLanguage: Scalars['String']['output'];
  friendsUpdatesNotifications: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  musicControl: Scalars['String']['output'];
  nextEpisode: Scalars['Boolean']['output'];
  previews: Scalars['Boolean']['output'];
  remindersNotifications: Scalars['Boolean']['output'];
  requestsNotifications: Scalars['Boolean']['output'];
  searchBar: Scalars['Boolean']['output'];
  subtitle: Scalars['String']['output'];
  timerDays?: Maybe<Array<Scalars['Int']['output']>>;
  timerEndTime: Scalars['Int']['output'];
  timerStartingTime: Scalars['Int']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "kid_user_settings" */
export type Kid_User_Settings_Aggregate = {
  __typename?: 'kid_user_settings_aggregate';
  aggregate?: Maybe<Kid_User_Settings_Aggregate_Fields>;
  nodes: Array<Kid_User_Settings>;
};

/** aggregate fields of "kid_user_settings" */
export type Kid_User_Settings_Aggregate_Fields = {
  __typename?: 'kid_user_settings_aggregate_fields';
  avg?: Maybe<Kid_User_Settings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Kid_User_Settings_Max_Fields>;
  min?: Maybe<Kid_User_Settings_Min_Fields>;
  stddev?: Maybe<Kid_User_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Kid_User_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kid_User_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Kid_User_Settings_Sum_Fields>;
  var_pop?: Maybe<Kid_User_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Kid_User_Settings_Var_Samp_Fields>;
  variance?: Maybe<Kid_User_Settings_Variance_Fields>;
};


/** aggregate fields of "kid_user_settings" */
export type Kid_User_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kid_User_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Kid_User_Settings_Avg_Fields = {
  __typename?: 'kid_user_settings_avg_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "kid_user_settings". All fields are combined with a logical 'AND'. */
export type Kid_User_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Kid_User_Settings_Bool_Exp>>;
  _not?: InputMaybe<Kid_User_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Kid_User_Settings_Bool_Exp>>;
  audio?: InputMaybe<String_Comparison_Exp>;
  channelsUpdatesNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  displayLanguage?: InputMaybe<String_Comparison_Exp>;
  friendsUpdatesNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  musicControl?: InputMaybe<String_Comparison_Exp>;
  nextEpisode?: InputMaybe<Boolean_Comparison_Exp>;
  previews?: InputMaybe<Boolean_Comparison_Exp>;
  remindersNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  requestsNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  searchBar?: InputMaybe<Boolean_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  timerDays?: InputMaybe<Int_Array_Comparison_Exp>;
  timerEndTime?: InputMaybe<Int_Comparison_Exp>;
  timerStartingTime?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kid_user_settings" */
export enum Kid_User_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  KidUserSettingsPkey = 'kid_user_settings_pkey',
  /** unique or primary key constraint on columns "userId" */
  KidUserSettingsUserIdKey = 'kid_user_settings_userId_key'
}

/** input type for incrementing numeric columns in table "kid_user_settings" */
export type Kid_User_Settings_Inc_Input = {
  timerEndTime?: InputMaybe<Scalars['Int']['input']>;
  timerStartingTime?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "kid_user_settings" */
export type Kid_User_Settings_Insert_Input = {
  audio?: InputMaybe<Scalars['String']['input']>;
  channelsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  friendsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicControl?: InputMaybe<Scalars['String']['input']>;
  nextEpisode?: InputMaybe<Scalars['Boolean']['input']>;
  previews?: InputMaybe<Scalars['Boolean']['input']>;
  remindersNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  searchBar?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timerDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  timerEndTime?: InputMaybe<Scalars['Int']['input']>;
  timerStartingTime?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Kid_User_Settings_Max_Fields = {
  __typename?: 'kid_user_settings_max_fields';
  audio?: Maybe<Scalars['String']['output']>;
  displayLanguage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  musicControl?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  timerDays?: Maybe<Array<Scalars['Int']['output']>>;
  timerEndTime?: Maybe<Scalars['Int']['output']>;
  timerStartingTime?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Kid_User_Settings_Min_Fields = {
  __typename?: 'kid_user_settings_min_fields';
  audio?: Maybe<Scalars['String']['output']>;
  displayLanguage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  musicControl?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  timerDays?: Maybe<Array<Scalars['Int']['output']>>;
  timerEndTime?: Maybe<Scalars['Int']['output']>;
  timerStartingTime?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "kid_user_settings" */
export type Kid_User_Settings_Mutation_Response = {
  __typename?: 'kid_user_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kid_User_Settings>;
};

/** input type for inserting object relation for remote table "kid_user_settings" */
export type Kid_User_Settings_Obj_Rel_Insert_Input = {
  data: Kid_User_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kid_User_Settings_On_Conflict>;
};

/** on_conflict condition type for table "kid_user_settings" */
export type Kid_User_Settings_On_Conflict = {
  constraint: Kid_User_Settings_Constraint;
  update_columns?: Array<Kid_User_Settings_Update_Column>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "kid_user_settings". */
export type Kid_User_Settings_Order_By = {
  audio?: InputMaybe<Order_By>;
  channelsUpdatesNotifications?: InputMaybe<Order_By>;
  displayLanguage?: InputMaybe<Order_By>;
  friendsUpdatesNotifications?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  musicControl?: InputMaybe<Order_By>;
  nextEpisode?: InputMaybe<Order_By>;
  previews?: InputMaybe<Order_By>;
  remindersNotifications?: InputMaybe<Order_By>;
  requestsNotifications?: InputMaybe<Order_By>;
  searchBar?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  timerDays?: InputMaybe<Order_By>;
  timerEndTime?: InputMaybe<Order_By>;
  timerStartingTime?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kid_user_settings */
export type Kid_User_Settings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "kid_user_settings" */
export enum Kid_User_Settings_Select_Column {
  /** column name */
  Audio = 'audio',
  /** column name */
  ChannelsUpdatesNotifications = 'channelsUpdatesNotifications',
  /** column name */
  DisplayLanguage = 'displayLanguage',
  /** column name */
  FriendsUpdatesNotifications = 'friendsUpdatesNotifications',
  /** column name */
  Id = 'id',
  /** column name */
  MusicControl = 'musicControl',
  /** column name */
  NextEpisode = 'nextEpisode',
  /** column name */
  Previews = 'previews',
  /** column name */
  RemindersNotifications = 'remindersNotifications',
  /** column name */
  RequestsNotifications = 'requestsNotifications',
  /** column name */
  SearchBar = 'searchBar',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  TimerDays = 'timerDays',
  /** column name */
  TimerEndTime = 'timerEndTime',
  /** column name */
  TimerStartingTime = 'timerStartingTime',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "kid_user_settings" */
export type Kid_User_Settings_Set_Input = {
  audio?: InputMaybe<Scalars['String']['input']>;
  channelsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  friendsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicControl?: InputMaybe<Scalars['String']['input']>;
  nextEpisode?: InputMaybe<Scalars['Boolean']['input']>;
  previews?: InputMaybe<Scalars['Boolean']['input']>;
  remindersNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  searchBar?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timerDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  timerEndTime?: InputMaybe<Scalars['Int']['input']>;
  timerStartingTime?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Kid_User_Settings_Stddev_Fields = {
  __typename?: 'kid_user_settings_stddev_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Kid_User_Settings_Stddev_Pop_Fields = {
  __typename?: 'kid_user_settings_stddev_pop_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Kid_User_Settings_Stddev_Samp_Fields = {
  __typename?: 'kid_user_settings_stddev_samp_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "kid_user_settings" */
export type Kid_User_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kid_User_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kid_User_Settings_Stream_Cursor_Value_Input = {
  audio?: InputMaybe<Scalars['String']['input']>;
  channelsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  friendsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicControl?: InputMaybe<Scalars['String']['input']>;
  nextEpisode?: InputMaybe<Scalars['Boolean']['input']>;
  previews?: InputMaybe<Scalars['Boolean']['input']>;
  remindersNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  searchBar?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timerDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  timerEndTime?: InputMaybe<Scalars['Int']['input']>;
  timerStartingTime?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Kid_User_Settings_Sum_Fields = {
  __typename?: 'kid_user_settings_sum_fields';
  timerEndTime?: Maybe<Scalars['Int']['output']>;
  timerStartingTime?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "kid_user_settings" */
export enum Kid_User_Settings_Update_Column {
  /** column name */
  Audio = 'audio',
  /** column name */
  ChannelsUpdatesNotifications = 'channelsUpdatesNotifications',
  /** column name */
  DisplayLanguage = 'displayLanguage',
  /** column name */
  FriendsUpdatesNotifications = 'friendsUpdatesNotifications',
  /** column name */
  Id = 'id',
  /** column name */
  MusicControl = 'musicControl',
  /** column name */
  NextEpisode = 'nextEpisode',
  /** column name */
  Previews = 'previews',
  /** column name */
  RemindersNotifications = 'remindersNotifications',
  /** column name */
  RequestsNotifications = 'requestsNotifications',
  /** column name */
  SearchBar = 'searchBar',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  TimerDays = 'timerDays',
  /** column name */
  TimerEndTime = 'timerEndTime',
  /** column name */
  TimerStartingTime = 'timerStartingTime',
  /** column name */
  UserId = 'userId'
}

export type Kid_User_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Kid_User_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kid_User_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kid_User_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Kid_User_Settings_Var_Pop_Fields = {
  __typename?: 'kid_user_settings_var_pop_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Kid_User_Settings_Var_Samp_Fields = {
  __typename?: 'kid_user_settings_var_samp_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Kid_User_Settings_Variance_Fields = {
  __typename?: 'kid_user_settings_variance_fields';
  timerEndTime?: Maybe<Scalars['Float']['output']>;
  timerStartingTime?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "kids_parents" */
export type Kids_Parents = {
  __typename?: 'kids_parents';
  /** An object relationship */
  kid: Users;
  kidId: Scalars['String']['output'];
  /** An object relationship */
  parent?: Maybe<Users>;
  parentId: Scalars['String']['output'];
};

/** aggregated selection of "kids_parents" */
export type Kids_Parents_Aggregate = {
  __typename?: 'kids_parents_aggregate';
  aggregate?: Maybe<Kids_Parents_Aggregate_Fields>;
  nodes: Array<Kids_Parents>;
};

export type Kids_Parents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kids_Parents_Aggregate_Bool_Exp_Count>;
};

export type Kids_Parents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Kids_Parents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kids_parents" */
export type Kids_Parents_Aggregate_Fields = {
  __typename?: 'kids_parents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Kids_Parents_Max_Fields>;
  min?: Maybe<Kids_Parents_Min_Fields>;
};


/** aggregate fields of "kids_parents" */
export type Kids_Parents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "kids_parents" */
export type Kids_Parents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kids_Parents_Max_Order_By>;
  min?: InputMaybe<Kids_Parents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kids_parents" */
export type Kids_Parents_Arr_Rel_Insert_Input = {
  data: Array<Kids_Parents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kids_Parents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kids_parents". All fields are combined with a logical 'AND'. */
export type Kids_Parents_Bool_Exp = {
  _and?: InputMaybe<Array<Kids_Parents_Bool_Exp>>;
  _not?: InputMaybe<Kids_Parents_Bool_Exp>;
  _or?: InputMaybe<Array<Kids_Parents_Bool_Exp>>;
  kid?: InputMaybe<Users_Bool_Exp>;
  kidId?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Users_Bool_Exp>;
  parentId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kids_parents" */
export enum Kids_Parents_Constraint {
  /** unique or primary key constraint on columns "kidId", "parentId" */
  KidsParentsPkey = 'kids_parents_pkey'
}

/** input type for inserting data into table "kids_parents" */
export type Kids_Parents_Insert_Input = {
  kid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  kidId?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Kids_Parents_Max_Fields = {
  __typename?: 'kids_parents_max_fields';
  kidId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "kids_parents" */
export type Kids_Parents_Max_Order_By = {
  kidId?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kids_Parents_Min_Fields = {
  __typename?: 'kids_parents_min_fields';
  kidId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "kids_parents" */
export type Kids_Parents_Min_Order_By = {
  kidId?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kids_parents" */
export type Kids_Parents_Mutation_Response = {
  __typename?: 'kids_parents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kids_Parents>;
};

/** on_conflict condition type for table "kids_parents" */
export type Kids_Parents_On_Conflict = {
  constraint: Kids_Parents_Constraint;
  update_columns?: Array<Kids_Parents_Update_Column>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};

/** Ordering options when selecting data from "kids_parents". */
export type Kids_Parents_Order_By = {
  kid?: InputMaybe<Users_Order_By>;
  kidId?: InputMaybe<Order_By>;
  parent?: InputMaybe<Users_Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kids_parents */
export type Kids_Parents_Pk_Columns_Input = {
  kidId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};

/** select columns of table "kids_parents" */
export enum Kids_Parents_Select_Column {
  /** column name */
  KidId = 'kidId',
  /** column name */
  ParentId = 'parentId'
}

/** input type for updating data in table "kids_parents" */
export type Kids_Parents_Set_Input = {
  kidId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "kids_parents" */
export type Kids_Parents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kids_Parents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kids_Parents_Stream_Cursor_Value_Input = {
  kidId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "kids_parents" */
export enum Kids_Parents_Update_Column {
  /** column name */
  KidId = 'kidId',
  /** column name */
  ParentId = 'parentId'
}

export type Kids_Parents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kids_Parents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kids_Parents_Bool_Exp;
};

/** columns and relationships of "leaderboard" */
export type Leaderboard = {
  __typename?: 'leaderboard';
  badgesCount: Scalars['Int']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "leaderboard" */
export type Leaderboard_Aggregate = {
  __typename?: 'leaderboard_aggregate';
  aggregate?: Maybe<Leaderboard_Aggregate_Fields>;
  nodes: Array<Leaderboard>;
};

/** aggregate fields of "leaderboard" */
export type Leaderboard_Aggregate_Fields = {
  __typename?: 'leaderboard_aggregate_fields';
  avg?: Maybe<Leaderboard_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Leaderboard_Max_Fields>;
  min?: Maybe<Leaderboard_Min_Fields>;
  stddev?: Maybe<Leaderboard_Stddev_Fields>;
  stddev_pop?: Maybe<Leaderboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leaderboard_Stddev_Samp_Fields>;
  sum?: Maybe<Leaderboard_Sum_Fields>;
  var_pop?: Maybe<Leaderboard_Var_Pop_Fields>;
  var_samp?: Maybe<Leaderboard_Var_Samp_Fields>;
  variance?: Maybe<Leaderboard_Variance_Fields>;
};


/** aggregate fields of "leaderboard" */
export type Leaderboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leaderboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Leaderboard_Avg_Fields = {
  __typename?: 'leaderboard_avg_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "leaderboard". All fields are combined with a logical 'AND'. */
export type Leaderboard_Bool_Exp = {
  _and?: InputMaybe<Array<Leaderboard_Bool_Exp>>;
  _not?: InputMaybe<Leaderboard_Bool_Exp>;
  _or?: InputMaybe<Array<Leaderboard_Bool_Exp>>;
  badgesCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "leaderboard" */
export enum Leaderboard_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeaderboardPkey = 'leaderboard_pkey',
  /** unique or primary key constraint on columns "userId" */
  LeaderboardUserIdKey = 'leaderboard_userId_key'
}

/** input type for incrementing numeric columns in table "leaderboard" */
export type Leaderboard_Inc_Input = {
  badgesCount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "leaderboard" */
export type Leaderboard_Insert_Input = {
  badgesCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Leaderboard_Max_Fields = {
  __typename?: 'leaderboard_max_fields';
  badgesCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Leaderboard_Min_Fields = {
  __typename?: 'leaderboard_min_fields';
  badgesCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "leaderboard" */
export type Leaderboard_Mutation_Response = {
  __typename?: 'leaderboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard>;
};

/** on_conflict condition type for table "leaderboard" */
export type Leaderboard_On_Conflict = {
  constraint: Leaderboard_Constraint;
  update_columns?: Array<Leaderboard_Update_Column>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

/** Ordering options when selecting data from "leaderboard". */
export type Leaderboard_Order_By = {
  badgesCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leaderboard */
export type Leaderboard_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "leaderboard" */
export enum Leaderboard_Select_Column {
  /** column name */
  BadgesCount = 'badgesCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "leaderboard" */
export type Leaderboard_Set_Input = {
  badgesCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Leaderboard_Stddev_Fields = {
  __typename?: 'leaderboard_stddev_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Leaderboard_Stddev_Pop_Fields = {
  __typename?: 'leaderboard_stddev_pop_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Leaderboard_Stddev_Samp_Fields = {
  __typename?: 'leaderboard_stddev_samp_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "leaderboard" */
export type Leaderboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Stream_Cursor_Value_Input = {
  badgesCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Leaderboard_Sum_Fields = {
  __typename?: 'leaderboard_sum_fields';
  badgesCount?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "leaderboard" */
export enum Leaderboard_Update_Column {
  /** column name */
  BadgesCount = 'badgesCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

export type Leaderboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leaderboard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leaderboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leaderboard_Var_Pop_Fields = {
  __typename?: 'leaderboard_var_pop_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Leaderboard_Var_Samp_Fields = {
  __typename?: 'leaderboard_var_samp_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Leaderboard_Variance_Fields = {
  __typename?: 'leaderboard_variance_fields';
  badgesCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "likes" */
export type Likes = {
  __typename?: 'likes';
  createdAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "likes" */
export type Likes_Aggregate = {
  __typename?: 'likes_aggregate';
  aggregate?: Maybe<Likes_Aggregate_Fields>;
  nodes: Array<Likes>;
};

export type Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Likes_Aggregate_Bool_Exp_Count>;
};

export type Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "likes" */
export type Likes_Aggregate_Fields = {
  __typename?: 'likes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Likes_Max_Fields>;
  min?: Maybe<Likes_Min_Fields>;
};


/** aggregate fields of "likes" */
export type Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "likes" */
export type Likes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Likes_Max_Order_By>;
  min?: InputMaybe<Likes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "likes" */
export type Likes_Arr_Rel_Insert_Input = {
  data: Array<Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "likes". All fields are combined with a logical 'AND'. */
export type Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Likes_Bool_Exp>>;
  _not?: InputMaybe<Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Likes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "likes" */
export enum Likes_Constraint {
  /** unique or primary key constraint on columns "userId", "videoId" */
  LikesPkey = 'likes_pkey'
}

/** input type for inserting data into table "likes" */
export type Likes_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Likes_Max_Fields = {
  __typename?: 'likes_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "likes" */
export type Likes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Likes_Min_Fields = {
  __typename?: 'likes_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "likes" */
export type Likes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "likes" */
export type Likes_Mutation_Response = {
  __typename?: 'likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Likes>;
};

/** on_conflict condition type for table "likes" */
export type Likes_On_Conflict = {
  constraint: Likes_Constraint;
  update_columns?: Array<Likes_Update_Column>;
  where?: InputMaybe<Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "likes". */
export type Likes_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: likes */
export type Likes_Pk_Columns_Input = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "likes" */
export enum Likes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "likes" */
export type Likes_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "likes" */
export type Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Likes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "likes" */
export enum Likes_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

export type Likes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addExistingChildAccount?: Maybe<UserAuthResponse>;
  addLike: Like;
  chromeExtGoogleSignin?: Maybe<UserAuthResponse>;
  claimBadge: UserBadge;
  createAISuggestedVideoReviewByAdmin: SuggestedVideoReview;
  createAISuggestedVideoReviewByChromeUser: AiSuggestedVideoReview;
  createAdmin?: Maybe<AdminAuthResponse>;
  createAgePreference: User;
  createCategoriesPreference: User;
  createChildAccount?: Maybe<UserAuthResponse>;
  createChromeExtSuggestedVideo: ChromeExtSuggestedVideo;
  createChromeExtSuggestedVideoReviewByAdmin: SuggestedVideoReview;
  createChromeExtSuggestedVideoReviewByChromeUser: SuggestedVideoReview;
  createFollowRequest: FollowRequest;
  createGeneralMessage: GeneralMessage;
  createGuestUser?: Maybe<UserAuthResponse>;
  createHearedMethodPreference: User;
  createKidCategoriesPreferenceByParent: User;
  createUser?: Maybe<UserAuthResponse>;
  createVideo: Video;
  deActivateAdmin?: Maybe<Admin>;
  deActivateUser?: Maybe<User>;
  deleteFollowRequest: Scalars['Boolean']['output'];
  deleteFollowRequestByParent: Scalars['Boolean']['output'];
  /** delete data from the table: "actions" */
  delete_actions?: Maybe<Actions_Mutation_Response>;
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: Maybe<Actions>;
  /** delete data from the table: "admins" */
  delete_admins?: Maybe<Admins_Mutation_Response>;
  /** delete single row from the table: "admins" */
  delete_admins_by_pk?: Maybe<Admins>;
  /** delete data from the table: "ai_suggested_videos" */
  delete_ai_suggested_videos?: Maybe<Ai_Suggested_Videos_Mutation_Response>;
  /** delete single row from the table: "ai_suggested_videos" */
  delete_ai_suggested_videos_by_pk?: Maybe<Ai_Suggested_Videos>;
  /** delete data from the table: "ai_suggested_videos_reviews" */
  delete_ai_suggested_videos_reviews?: Maybe<Ai_Suggested_Videos_Reviews_Mutation_Response>;
  /** delete single row from the table: "ai_suggested_videos_reviews" */
  delete_ai_suggested_videos_reviews_by_pk?: Maybe<Ai_Suggested_Videos_Reviews>;
  /** delete data from the table: "ai_suggestion_keywords" */
  delete_ai_suggestion_keywords?: Maybe<Ai_Suggestion_Keywords_Mutation_Response>;
  /** delete single row from the table: "ai_suggestion_keywords" */
  delete_ai_suggestion_keywords_by_pk?: Maybe<Ai_Suggestion_Keywords>;
  /** delete data from the table: "badge_videos" */
  delete_badge_videos?: Maybe<Badge_Videos_Mutation_Response>;
  /** delete single row from the table: "badge_videos" */
  delete_badge_videos_by_pk?: Maybe<Badge_Videos>;
  /** delete data from the table: "badges" */
  delete_badges?: Maybe<Badges_Mutation_Response>;
  /** delete single row from the table: "badges" */
  delete_badges_by_pk?: Maybe<Badges>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "categories_contents" */
  delete_categories_contents?: Maybe<Categories_Contents_Mutation_Response>;
  /** delete single row from the table: "categories_contents" */
  delete_categories_contents_by_pk?: Maybe<Categories_Contents>;
  /** delete data from the table: "channels" */
  delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "channels_contents" */
  delete_channels_contents?: Maybe<Channels_Contents_Mutation_Response>;
  /** delete single row from the table: "channels_contents" */
  delete_channels_contents_by_pk?: Maybe<Channels_Contents>;
  /** delete data from the table: "channels_follows" */
  delete_channels_follows?: Maybe<Channels_Follows_Mutation_Response>;
  /** delete single row from the table: "channels_follows" */
  delete_channels_follows_by_pk?: Maybe<Channels_Follows>;
  /** delete data from the table: "chrome_extension_notifications" */
  delete_chrome_extension_notifications?: Maybe<Chrome_Extension_Notifications_Mutation_Response>;
  /** delete single row from the table: "chrome_extension_notifications" */
  delete_chrome_extension_notifications_by_pk?: Maybe<Chrome_Extension_Notifications>;
  /** delete data from the table: "chrome_extension_suggested_videos" */
  delete_chrome_extension_suggested_videos?: Maybe<Chrome_Extension_Suggested_Videos_Mutation_Response>;
  /** delete single row from the table: "chrome_extension_suggested_videos" */
  delete_chrome_extension_suggested_videos_by_pk?: Maybe<Chrome_Extension_Suggested_Videos>;
  /** delete data from the table: "chrome_extension_suggested_videos_reviews" */
  delete_chrome_extension_suggested_videos_reviews?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Mutation_Response>;
  /** delete single row from the table: "chrome_extension_suggested_videos_reviews" */
  delete_chrome_extension_suggested_videos_reviews_by_pk?: Maybe<Chrome_Extension_Suggested_Videos_Reviews>;
  /** delete data from the table: "chrome_extension_users" */
  delete_chrome_extension_users?: Maybe<Chrome_Extension_Users_Mutation_Response>;
  /** delete single row from the table: "chrome_extension_users" */
  delete_chrome_extension_users_by_pk?: Maybe<Chrome_Extension_Users>;
  /** delete data from the table: "coming_soon_videos_reminders" */
  delete_coming_soon_videos_reminders?: Maybe<Coming_Soon_Videos_Reminders_Mutation_Response>;
  /** delete single row from the table: "coming_soon_videos_reminders" */
  delete_coming_soon_videos_reminders_by_pk?: Maybe<Coming_Soon_Videos_Reminders>;
  /** delete data from the table: "contents" */
  delete_contents?: Maybe<Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_contents_by_pk?: Maybe<Contents>;
  /** delete data from the table: "faq" */
  delete_faq?: Maybe<Faq_Mutation_Response>;
  /** delete single row from the table: "faq" */
  delete_faq_by_pk?: Maybe<Faq>;
  /** delete data from the table: "follow_requests" */
  delete_follow_requests?: Maybe<Follow_Requests_Mutation_Response>;
  /** delete single row from the table: "follow_requests" */
  delete_follow_requests_by_pk?: Maybe<Follow_Requests>;
  /** delete data from the table: "general_messages" */
  delete_general_messages?: Maybe<General_Messages_Mutation_Response>;
  /** delete single row from the table: "general_messages" */
  delete_general_messages_by_pk?: Maybe<General_Messages>;
  /** delete data from the table: "genres" */
  delete_genres?: Maybe<Genres_Mutation_Response>;
  /** delete single row from the table: "genres" */
  delete_genres_by_pk?: Maybe<Genres>;
  /** delete data from the table: "heared_methods" */
  delete_heared_methods?: Maybe<Heared_Methods_Mutation_Response>;
  /** delete single row from the table: "heared_methods" */
  delete_heared_methods_by_pk?: Maybe<Heared_Methods>;
  /** delete data from the table: "kid_user_settings" */
  delete_kid_user_settings?: Maybe<Kid_User_Settings_Mutation_Response>;
  /** delete single row from the table: "kid_user_settings" */
  delete_kid_user_settings_by_pk?: Maybe<Kid_User_Settings>;
  /** delete data from the table: "kids_parents" */
  delete_kids_parents?: Maybe<Kids_Parents_Mutation_Response>;
  /** delete single row from the table: "kids_parents" */
  delete_kids_parents_by_pk?: Maybe<Kids_Parents>;
  /** delete data from the table: "leaderboard" */
  delete_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** delete single row from the table: "leaderboard" */
  delete_leaderboard_by_pk?: Maybe<Leaderboard>;
  /** delete data from the table: "likes" */
  delete_likes?: Maybe<Likes_Mutation_Response>;
  /** delete single row from the table: "likes" */
  delete_likes_by_pk?: Maybe<Likes>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "parent_user_settings" */
  delete_parent_user_settings?: Maybe<Parent_User_Settings_Mutation_Response>;
  /** delete single row from the table: "parent_user_settings" */
  delete_parent_user_settings_by_pk?: Maybe<Parent_User_Settings>;
  /** delete data from the table: "privacy_policy" */
  delete_privacy_policy?: Maybe<Privacy_Policy_Mutation_Response>;
  /** delete single row from the table: "privacy_policy" */
  delete_privacy_policy_by_pk?: Maybe<Privacy_Policy>;
  /** delete data from the table: "report_video_methods" */
  delete_report_video_methods?: Maybe<Report_Video_Methods_Mutation_Response>;
  /** delete single row from the table: "report_video_methods" */
  delete_report_video_methods_by_pk?: Maybe<Report_Video_Methods>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete data from the table: "roles_actions" */
  delete_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** delete single row from the table: "roles_actions" */
  delete_roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "seasons" */
  delete_seasons?: Maybe<Seasons_Mutation_Response>;
  /** delete single row from the table: "seasons" */
  delete_seasons_by_pk?: Maybe<Seasons>;
  /** delete data from the table: "series" */
  delete_series?: Maybe<Series_Mutation_Response>;
  /** delete single row from the table: "series" */
  delete_series_by_pk?: Maybe<Series>;
  /** delete data from the table: "suggestion_settings" */
  delete_suggestion_settings?: Maybe<Suggestion_Settings_Mutation_Response>;
  /** delete single row from the table: "suggestion_settings" */
  delete_suggestion_settings_by_pk?: Maybe<Suggestion_Settings>;
  /** delete data from the table: "ticket_replies" */
  delete_ticket_replies?: Maybe<Ticket_Replies_Mutation_Response>;
  /** delete single row from the table: "ticket_replies" */
  delete_ticket_replies_by_pk?: Maybe<Ticket_Replies>;
  /** delete data from the table: "tickets" */
  delete_tickets?: Maybe<Tickets_Mutation_Response>;
  /** delete single row from the table: "tickets" */
  delete_tickets_by_pk?: Maybe<Tickets>;
  /** delete data from the table: "user_badges" */
  delete_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** delete single row from the table: "user_badges" */
  delete_user_badges_by_pk?: Maybe<User_Badges>;
  /** delete data from the table: "user_playlists" */
  delete_user_playlists?: Maybe<User_Playlists_Mutation_Response>;
  /** delete single row from the table: "user_playlists" */
  delete_user_playlists_by_pk?: Maybe<User_Playlists>;
  /** delete data from the table: "user_playlists_videos" */
  delete_user_playlists_videos?: Maybe<User_Playlists_Videos_Mutation_Response>;
  /** delete single row from the table: "user_playlists_videos" */
  delete_user_playlists_videos_by_pk?: Maybe<User_Playlists_Videos>;
  /** delete data from the table: "user_searches" */
  delete_user_searches?: Maybe<User_Searches_Mutation_Response>;
  /** delete single row from the table: "user_searches" */
  delete_user_searches_by_pk?: Maybe<User_Searches>;
  /** delete data from the table: "user_sessions" */
  delete_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** delete single row from the table: "user_sessions" */
  delete_user_sessions_by_pk?: Maybe<User_Sessions>;
  /** delete data from the table: "user_shared_videos" */
  delete_user_shared_videos?: Maybe<User_Shared_Videos_Mutation_Response>;
  /** delete single row from the table: "user_shared_videos" */
  delete_user_shared_videos_by_pk?: Maybe<User_Shared_Videos>;
  /** delete data from the table: "user_watched_badge_videos" */
  delete_user_watched_badge_videos?: Maybe<User_Watched_Badge_Videos_Mutation_Response>;
  /** delete single row from the table: "user_watched_badge_videos" */
  delete_user_watched_badge_videos_by_pk?: Maybe<User_Watched_Badge_Videos>;
  /** delete data from the table: "user_watched_video_sessions" */
  delete_user_watched_video_sessions?: Maybe<User_Watched_Video_Sessions_Mutation_Response>;
  /** delete single row from the table: "user_watched_video_sessions" */
  delete_user_watched_video_sessions_by_pk?: Maybe<User_Watched_Video_Sessions>;
  /** delete data from the table: "user_watched_videos" */
  delete_user_watched_videos?: Maybe<User_Watched_Videos_Mutation_Response>;
  /** delete single row from the table: "user_watched_videos" */
  delete_user_watched_videos_by_pk?: Maybe<User_Watched_Videos>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_categories" */
  delete_users_categories?: Maybe<Users_Categories_Mutation_Response>;
  /** delete single row from the table: "users_categories" */
  delete_users_categories_by_pk?: Maybe<Users_Categories>;
  /** delete data from the table: "users_follows" */
  delete_users_follows?: Maybe<Users_Follows_Mutation_Response>;
  /** delete single row from the table: "users_follows" */
  delete_users_follows_by_pk?: Maybe<Users_Follows>;
  /** delete data from the table: "users_privacy" */
  delete_users_privacy?: Maybe<Users_Privacy_Mutation_Response>;
  /** delete single row from the table: "users_privacy" */
  delete_users_privacy_by_pk?: Maybe<Users_Privacy>;
  /** delete data from the table: "video_reports" */
  delete_video_reports?: Maybe<Video_Reports_Mutation_Response>;
  /** delete single row from the table: "video_reports" */
  delete_video_reports_by_pk?: Maybe<Video_Reports>;
  /** delete data from the table: "videos" */
  delete_videos?: Maybe<Videos_Mutation_Response>;
  /** delete single row from the table: "videos" */
  delete_videos_by_pk?: Maybe<Videos>;
  /** delete data from the table: "videos_contents" */
  delete_videos_contents?: Maybe<Videos_Contents_Mutation_Response>;
  /** delete single row from the table: "videos_contents" */
  delete_videos_contents_by_pk?: Maybe<Videos_Contents>;
  followChannel: ChannelFollow;
  forgetPassword?: Maybe<SignUpResponse>;
  googleSignin?: Maybe<UserAuthResponse>;
  /** insert data into the table: "actions" */
  insert_actions?: Maybe<Actions_Mutation_Response>;
  /** insert a single row into the table: "actions" */
  insert_actions_one?: Maybe<Actions>;
  /** insert data into the table: "admins" */
  insert_admins?: Maybe<Admins_Mutation_Response>;
  /** insert a single row into the table: "admins" */
  insert_admins_one?: Maybe<Admins>;
  /** insert data into the table: "ai_suggested_videos" */
  insert_ai_suggested_videos?: Maybe<Ai_Suggested_Videos_Mutation_Response>;
  /** insert a single row into the table: "ai_suggested_videos" */
  insert_ai_suggested_videos_one?: Maybe<Ai_Suggested_Videos>;
  /** insert data into the table: "ai_suggested_videos_reviews" */
  insert_ai_suggested_videos_reviews?: Maybe<Ai_Suggested_Videos_Reviews_Mutation_Response>;
  /** insert a single row into the table: "ai_suggested_videos_reviews" */
  insert_ai_suggested_videos_reviews_one?: Maybe<Ai_Suggested_Videos_Reviews>;
  /** insert data into the table: "ai_suggestion_keywords" */
  insert_ai_suggestion_keywords?: Maybe<Ai_Suggestion_Keywords_Mutation_Response>;
  /** insert a single row into the table: "ai_suggestion_keywords" */
  insert_ai_suggestion_keywords_one?: Maybe<Ai_Suggestion_Keywords>;
  /** insert data into the table: "badge_videos" */
  insert_badge_videos?: Maybe<Badge_Videos_Mutation_Response>;
  /** insert a single row into the table: "badge_videos" */
  insert_badge_videos_one?: Maybe<Badge_Videos>;
  /** insert data into the table: "badges" */
  insert_badges?: Maybe<Badges_Mutation_Response>;
  /** insert a single row into the table: "badges" */
  insert_badges_one?: Maybe<Badges>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert data into the table: "categories_contents" */
  insert_categories_contents?: Maybe<Categories_Contents_Mutation_Response>;
  /** insert a single row into the table: "categories_contents" */
  insert_categories_contents_one?: Maybe<Categories_Contents>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "channels" */
  insert_channels?: Maybe<Channels_Mutation_Response>;
  /** insert data into the table: "channels_contents" */
  insert_channels_contents?: Maybe<Channels_Contents_Mutation_Response>;
  /** insert a single row into the table: "channels_contents" */
  insert_channels_contents_one?: Maybe<Channels_Contents>;
  /** insert data into the table: "channels_follows" */
  insert_channels_follows?: Maybe<Channels_Follows_Mutation_Response>;
  /** insert a single row into the table: "channels_follows" */
  insert_channels_follows_one?: Maybe<Channels_Follows>;
  /** insert a single row into the table: "channels" */
  insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "chrome_extension_notifications" */
  insert_chrome_extension_notifications?: Maybe<Chrome_Extension_Notifications_Mutation_Response>;
  /** insert a single row into the table: "chrome_extension_notifications" */
  insert_chrome_extension_notifications_one?: Maybe<Chrome_Extension_Notifications>;
  /** insert data into the table: "chrome_extension_suggested_videos" */
  insert_chrome_extension_suggested_videos?: Maybe<Chrome_Extension_Suggested_Videos_Mutation_Response>;
  /** insert a single row into the table: "chrome_extension_suggested_videos" */
  insert_chrome_extension_suggested_videos_one?: Maybe<Chrome_Extension_Suggested_Videos>;
  /** insert data into the table: "chrome_extension_suggested_videos_reviews" */
  insert_chrome_extension_suggested_videos_reviews?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Mutation_Response>;
  /** insert a single row into the table: "chrome_extension_suggested_videos_reviews" */
  insert_chrome_extension_suggested_videos_reviews_one?: Maybe<Chrome_Extension_Suggested_Videos_Reviews>;
  /** insert data into the table: "chrome_extension_users" */
  insert_chrome_extension_users?: Maybe<Chrome_Extension_Users_Mutation_Response>;
  /** insert a single row into the table: "chrome_extension_users" */
  insert_chrome_extension_users_one?: Maybe<Chrome_Extension_Users>;
  /** insert data into the table: "coming_soon_videos_reminders" */
  insert_coming_soon_videos_reminders?: Maybe<Coming_Soon_Videos_Reminders_Mutation_Response>;
  /** insert a single row into the table: "coming_soon_videos_reminders" */
  insert_coming_soon_videos_reminders_one?: Maybe<Coming_Soon_Videos_Reminders>;
  /** insert data into the table: "contents" */
  insert_contents?: Maybe<Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_contents_one?: Maybe<Contents>;
  /** insert data into the table: "faq" */
  insert_faq?: Maybe<Faq_Mutation_Response>;
  /** insert a single row into the table: "faq" */
  insert_faq_one?: Maybe<Faq>;
  /** insert data into the table: "follow_requests" */
  insert_follow_requests?: Maybe<Follow_Requests_Mutation_Response>;
  /** insert a single row into the table: "follow_requests" */
  insert_follow_requests_one?: Maybe<Follow_Requests>;
  /** insert data into the table: "general_messages" */
  insert_general_messages?: Maybe<General_Messages_Mutation_Response>;
  /** insert a single row into the table: "general_messages" */
  insert_general_messages_one?: Maybe<General_Messages>;
  /** insert data into the table: "genres" */
  insert_genres?: Maybe<Genres_Mutation_Response>;
  /** insert a single row into the table: "genres" */
  insert_genres_one?: Maybe<Genres>;
  /** insert data into the table: "heared_methods" */
  insert_heared_methods?: Maybe<Heared_Methods_Mutation_Response>;
  /** insert a single row into the table: "heared_methods" */
  insert_heared_methods_one?: Maybe<Heared_Methods>;
  /** insert data into the table: "kid_user_settings" */
  insert_kid_user_settings?: Maybe<Kid_User_Settings_Mutation_Response>;
  /** insert a single row into the table: "kid_user_settings" */
  insert_kid_user_settings_one?: Maybe<Kid_User_Settings>;
  /** insert data into the table: "kids_parents" */
  insert_kids_parents?: Maybe<Kids_Parents_Mutation_Response>;
  /** insert a single row into the table: "kids_parents" */
  insert_kids_parents_one?: Maybe<Kids_Parents>;
  /** insert data into the table: "leaderboard" */
  insert_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** insert a single row into the table: "leaderboard" */
  insert_leaderboard_one?: Maybe<Leaderboard>;
  /** insert data into the table: "likes" */
  insert_likes?: Maybe<Likes_Mutation_Response>;
  /** insert a single row into the table: "likes" */
  insert_likes_one?: Maybe<Likes>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "parent_user_settings" */
  insert_parent_user_settings?: Maybe<Parent_User_Settings_Mutation_Response>;
  /** insert a single row into the table: "parent_user_settings" */
  insert_parent_user_settings_one?: Maybe<Parent_User_Settings>;
  /** insert data into the table: "privacy_policy" */
  insert_privacy_policy?: Maybe<Privacy_Policy_Mutation_Response>;
  /** insert a single row into the table: "privacy_policy" */
  insert_privacy_policy_one?: Maybe<Privacy_Policy>;
  /** insert data into the table: "report_video_methods" */
  insert_report_video_methods?: Maybe<Report_Video_Methods_Mutation_Response>;
  /** insert a single row into the table: "report_video_methods" */
  insert_report_video_methods_one?: Maybe<Report_Video_Methods>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert data into the table: "roles_actions" */
  insert_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** insert a single row into the table: "roles_actions" */
  insert_roles_actions_one?: Maybe<Roles_Actions>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "seasons" */
  insert_seasons?: Maybe<Seasons_Mutation_Response>;
  /** insert a single row into the table: "seasons" */
  insert_seasons_one?: Maybe<Seasons>;
  /** insert data into the table: "series" */
  insert_series?: Maybe<Series_Mutation_Response>;
  /** insert a single row into the table: "series" */
  insert_series_one?: Maybe<Series>;
  /** insert data into the table: "suggestion_settings" */
  insert_suggestion_settings?: Maybe<Suggestion_Settings_Mutation_Response>;
  /** insert a single row into the table: "suggestion_settings" */
  insert_suggestion_settings_one?: Maybe<Suggestion_Settings>;
  /** insert data into the table: "ticket_replies" */
  insert_ticket_replies?: Maybe<Ticket_Replies_Mutation_Response>;
  /** insert a single row into the table: "ticket_replies" */
  insert_ticket_replies_one?: Maybe<Ticket_Replies>;
  /** insert data into the table: "tickets" */
  insert_tickets?: Maybe<Tickets_Mutation_Response>;
  /** insert a single row into the table: "tickets" */
  insert_tickets_one?: Maybe<Tickets>;
  /** insert data into the table: "user_badges" */
  insert_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** insert a single row into the table: "user_badges" */
  insert_user_badges_one?: Maybe<User_Badges>;
  /** insert data into the table: "user_playlists" */
  insert_user_playlists?: Maybe<User_Playlists_Mutation_Response>;
  /** insert a single row into the table: "user_playlists" */
  insert_user_playlists_one?: Maybe<User_Playlists>;
  /** insert data into the table: "user_playlists_videos" */
  insert_user_playlists_videos?: Maybe<User_Playlists_Videos_Mutation_Response>;
  /** insert a single row into the table: "user_playlists_videos" */
  insert_user_playlists_videos_one?: Maybe<User_Playlists_Videos>;
  /** insert data into the table: "user_searches" */
  insert_user_searches?: Maybe<User_Searches_Mutation_Response>;
  /** insert a single row into the table: "user_searches" */
  insert_user_searches_one?: Maybe<User_Searches>;
  /** insert data into the table: "user_sessions" */
  insert_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** insert a single row into the table: "user_sessions" */
  insert_user_sessions_one?: Maybe<User_Sessions>;
  /** insert data into the table: "user_shared_videos" */
  insert_user_shared_videos?: Maybe<User_Shared_Videos_Mutation_Response>;
  /** insert a single row into the table: "user_shared_videos" */
  insert_user_shared_videos_one?: Maybe<User_Shared_Videos>;
  /** insert data into the table: "user_watched_badge_videos" */
  insert_user_watched_badge_videos?: Maybe<User_Watched_Badge_Videos_Mutation_Response>;
  /** insert a single row into the table: "user_watched_badge_videos" */
  insert_user_watched_badge_videos_one?: Maybe<User_Watched_Badge_Videos>;
  /** insert data into the table: "user_watched_video_sessions" */
  insert_user_watched_video_sessions?: Maybe<User_Watched_Video_Sessions_Mutation_Response>;
  /** insert a single row into the table: "user_watched_video_sessions" */
  insert_user_watched_video_sessions_one?: Maybe<User_Watched_Video_Sessions>;
  /** insert data into the table: "user_watched_videos" */
  insert_user_watched_videos?: Maybe<User_Watched_Videos_Mutation_Response>;
  /** insert a single row into the table: "user_watched_videos" */
  insert_user_watched_videos_one?: Maybe<User_Watched_Videos>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_categories" */
  insert_users_categories?: Maybe<Users_Categories_Mutation_Response>;
  /** insert a single row into the table: "users_categories" */
  insert_users_categories_one?: Maybe<Users_Categories>;
  /** insert data into the table: "users_follows" */
  insert_users_follows?: Maybe<Users_Follows_Mutation_Response>;
  /** insert a single row into the table: "users_follows" */
  insert_users_follows_one?: Maybe<Users_Follows>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_privacy" */
  insert_users_privacy?: Maybe<Users_Privacy_Mutation_Response>;
  /** insert a single row into the table: "users_privacy" */
  insert_users_privacy_one?: Maybe<Users_Privacy>;
  /** insert data into the table: "video_reports" */
  insert_video_reports?: Maybe<Video_Reports_Mutation_Response>;
  /** insert a single row into the table: "video_reports" */
  insert_video_reports_one?: Maybe<Video_Reports>;
  /** insert data into the table: "videos" */
  insert_videos?: Maybe<Videos_Mutation_Response>;
  /** insert data into the table: "videos_contents" */
  insert_videos_contents?: Maybe<Videos_Contents_Mutation_Response>;
  /** insert a single row into the table: "videos_contents" */
  insert_videos_contents_one?: Maybe<Videos_Contents>;
  /** insert a single row into the table: "videos" */
  insert_videos_one?: Maybe<Videos>;
  loginAdmin?: Maybe<AdminAuthResponse>;
  loginByPin?: Maybe<UserAuthResponse>;
  loginChromeExtUser?: Maybe<UserAuthResponse>;
  loginUser?: Maybe<UserAuthResponse>;
  publishAISuggestedVideo: AiSuggestedVideo;
  publishChromeExtSuggestedVideo: ChromeExtSuggestedVideo;
  publishComingSoonVideo: Video;
  reActivateAdmin?: Maybe<Admin>;
  reActivateUser?: Maybe<User>;
  removeAdmin?: Maybe<Admin>;
  removeFollower: Scalars['Boolean']['output'];
  removeFollowerByParent: Scalars['Boolean']['output'];
  removeLike: Like;
  removeUser?: Maybe<User>;
  replyTicket?: Maybe<Ticket>;
  requestPinToken: SignUpResponse;
  resendForgetPasswordCode?: Maybe<SignUpResponse>;
  resendVerificationCode?: Maybe<SignUpResponse>;
  resetUserAvatar?: Maybe<User>;
  resetUserPassword?: Maybe<User>;
  respondFollowRequest: FollowRequest;
  respondFollowRequestByParent: FollowRequest;
  setKidPublicAvatar?: Maybe<User>;
  setPublicAvatar?: Maybe<User>;
  shareVideo: SharedVideo;
  signupChromeExtUser?: Maybe<SignUpResponse>;
  signupUser?: Maybe<SignUpResponse>;
  unfollowChannel: Scalars['Boolean']['output'];
  unfollowUser: Scalars['Boolean']['output'];
  unfollowUserByParent: Scalars['Boolean']['output'];
  updateAISuggestionSettings: SuggestionSetting;
  updateAdmin?: Maybe<Admin>;
  updateChromeExtSuggestionSettings: SuggestionSetting;
  updateKidPrivacy: UserPrivacy;
  updateKidProfile?: Maybe<User>;
  updateParentProfile?: Maybe<User>;
  updateUser?: Maybe<User>;
  updateUserPrivacy: UserPrivacy;
  updateUserProfile?: Maybe<User>;
  updateVideo: Video;
  /** update data of the table: "actions" */
  update_actions?: Maybe<Actions_Mutation_Response>;
  /** update single row of the table: "actions" */
  update_actions_by_pk?: Maybe<Actions>;
  /** update multiples rows of table: "actions" */
  update_actions_many?: Maybe<Array<Maybe<Actions_Mutation_Response>>>;
  /** update data of the table: "admins" */
  update_admins?: Maybe<Admins_Mutation_Response>;
  /** update single row of the table: "admins" */
  update_admins_by_pk?: Maybe<Admins>;
  /** update multiples rows of table: "admins" */
  update_admins_many?: Maybe<Array<Maybe<Admins_Mutation_Response>>>;
  /** update data of the table: "ai_suggested_videos" */
  update_ai_suggested_videos?: Maybe<Ai_Suggested_Videos_Mutation_Response>;
  /** update single row of the table: "ai_suggested_videos" */
  update_ai_suggested_videos_by_pk?: Maybe<Ai_Suggested_Videos>;
  /** update multiples rows of table: "ai_suggested_videos" */
  update_ai_suggested_videos_many?: Maybe<Array<Maybe<Ai_Suggested_Videos_Mutation_Response>>>;
  /** update data of the table: "ai_suggested_videos_reviews" */
  update_ai_suggested_videos_reviews?: Maybe<Ai_Suggested_Videos_Reviews_Mutation_Response>;
  /** update single row of the table: "ai_suggested_videos_reviews" */
  update_ai_suggested_videos_reviews_by_pk?: Maybe<Ai_Suggested_Videos_Reviews>;
  /** update multiples rows of table: "ai_suggested_videos_reviews" */
  update_ai_suggested_videos_reviews_many?: Maybe<Array<Maybe<Ai_Suggested_Videos_Reviews_Mutation_Response>>>;
  /** update data of the table: "ai_suggestion_keywords" */
  update_ai_suggestion_keywords?: Maybe<Ai_Suggestion_Keywords_Mutation_Response>;
  /** update single row of the table: "ai_suggestion_keywords" */
  update_ai_suggestion_keywords_by_pk?: Maybe<Ai_Suggestion_Keywords>;
  /** update multiples rows of table: "ai_suggestion_keywords" */
  update_ai_suggestion_keywords_many?: Maybe<Array<Maybe<Ai_Suggestion_Keywords_Mutation_Response>>>;
  /** update data of the table: "badge_videos" */
  update_badge_videos?: Maybe<Badge_Videos_Mutation_Response>;
  /** update single row of the table: "badge_videos" */
  update_badge_videos_by_pk?: Maybe<Badge_Videos>;
  /** update multiples rows of table: "badge_videos" */
  update_badge_videos_many?: Maybe<Array<Maybe<Badge_Videos_Mutation_Response>>>;
  /** update data of the table: "badges" */
  update_badges?: Maybe<Badges_Mutation_Response>;
  /** update single row of the table: "badges" */
  update_badges_by_pk?: Maybe<Badges>;
  /** update multiples rows of table: "badges" */
  update_badges_many?: Maybe<Array<Maybe<Badges_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update data of the table: "categories_contents" */
  update_categories_contents?: Maybe<Categories_Contents_Mutation_Response>;
  /** update single row of the table: "categories_contents" */
  update_categories_contents_by_pk?: Maybe<Categories_Contents>;
  /** update multiples rows of table: "categories_contents" */
  update_categories_contents_many?: Maybe<Array<Maybe<Categories_Contents_Mutation_Response>>>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "channels" */
  update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  update_channels_by_pk?: Maybe<Channels>;
  /** update data of the table: "channels_contents" */
  update_channels_contents?: Maybe<Channels_Contents_Mutation_Response>;
  /** update single row of the table: "channels_contents" */
  update_channels_contents_by_pk?: Maybe<Channels_Contents>;
  /** update multiples rows of table: "channels_contents" */
  update_channels_contents_many?: Maybe<Array<Maybe<Channels_Contents_Mutation_Response>>>;
  /** update data of the table: "channels_follows" */
  update_channels_follows?: Maybe<Channels_Follows_Mutation_Response>;
  /** update single row of the table: "channels_follows" */
  update_channels_follows_by_pk?: Maybe<Channels_Follows>;
  /** update multiples rows of table: "channels_follows" */
  update_channels_follows_many?: Maybe<Array<Maybe<Channels_Follows_Mutation_Response>>>;
  /** update multiples rows of table: "channels" */
  update_channels_many?: Maybe<Array<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "chrome_extension_notifications" */
  update_chrome_extension_notifications?: Maybe<Chrome_Extension_Notifications_Mutation_Response>;
  /** update single row of the table: "chrome_extension_notifications" */
  update_chrome_extension_notifications_by_pk?: Maybe<Chrome_Extension_Notifications>;
  /** update multiples rows of table: "chrome_extension_notifications" */
  update_chrome_extension_notifications_many?: Maybe<Array<Maybe<Chrome_Extension_Notifications_Mutation_Response>>>;
  /** update data of the table: "chrome_extension_suggested_videos" */
  update_chrome_extension_suggested_videos?: Maybe<Chrome_Extension_Suggested_Videos_Mutation_Response>;
  /** update single row of the table: "chrome_extension_suggested_videos" */
  update_chrome_extension_suggested_videos_by_pk?: Maybe<Chrome_Extension_Suggested_Videos>;
  /** update multiples rows of table: "chrome_extension_suggested_videos" */
  update_chrome_extension_suggested_videos_many?: Maybe<Array<Maybe<Chrome_Extension_Suggested_Videos_Mutation_Response>>>;
  /** update data of the table: "chrome_extension_suggested_videos_reviews" */
  update_chrome_extension_suggested_videos_reviews?: Maybe<Chrome_Extension_Suggested_Videos_Reviews_Mutation_Response>;
  /** update single row of the table: "chrome_extension_suggested_videos_reviews" */
  update_chrome_extension_suggested_videos_reviews_by_pk?: Maybe<Chrome_Extension_Suggested_Videos_Reviews>;
  /** update multiples rows of table: "chrome_extension_suggested_videos_reviews" */
  update_chrome_extension_suggested_videos_reviews_many?: Maybe<Array<Maybe<Chrome_Extension_Suggested_Videos_Reviews_Mutation_Response>>>;
  /** update data of the table: "chrome_extension_users" */
  update_chrome_extension_users?: Maybe<Chrome_Extension_Users_Mutation_Response>;
  /** update single row of the table: "chrome_extension_users" */
  update_chrome_extension_users_by_pk?: Maybe<Chrome_Extension_Users>;
  /** update multiples rows of table: "chrome_extension_users" */
  update_chrome_extension_users_many?: Maybe<Array<Maybe<Chrome_Extension_Users_Mutation_Response>>>;
  /** update data of the table: "coming_soon_videos_reminders" */
  update_coming_soon_videos_reminders?: Maybe<Coming_Soon_Videos_Reminders_Mutation_Response>;
  /** update single row of the table: "coming_soon_videos_reminders" */
  update_coming_soon_videos_reminders_by_pk?: Maybe<Coming_Soon_Videos_Reminders>;
  /** update multiples rows of table: "coming_soon_videos_reminders" */
  update_coming_soon_videos_reminders_many?: Maybe<Array<Maybe<Coming_Soon_Videos_Reminders_Mutation_Response>>>;
  /** update data of the table: "contents" */
  update_contents?: Maybe<Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_contents_by_pk?: Maybe<Contents>;
  /** update multiples rows of table: "contents" */
  update_contents_many?: Maybe<Array<Maybe<Contents_Mutation_Response>>>;
  /** update data of the table: "faq" */
  update_faq?: Maybe<Faq_Mutation_Response>;
  /** update single row of the table: "faq" */
  update_faq_by_pk?: Maybe<Faq>;
  /** update multiples rows of table: "faq" */
  update_faq_many?: Maybe<Array<Maybe<Faq_Mutation_Response>>>;
  /** update data of the table: "follow_requests" */
  update_follow_requests?: Maybe<Follow_Requests_Mutation_Response>;
  /** update single row of the table: "follow_requests" */
  update_follow_requests_by_pk?: Maybe<Follow_Requests>;
  /** update multiples rows of table: "follow_requests" */
  update_follow_requests_many?: Maybe<Array<Maybe<Follow_Requests_Mutation_Response>>>;
  /** update data of the table: "general_messages" */
  update_general_messages?: Maybe<General_Messages_Mutation_Response>;
  /** update single row of the table: "general_messages" */
  update_general_messages_by_pk?: Maybe<General_Messages>;
  /** update multiples rows of table: "general_messages" */
  update_general_messages_many?: Maybe<Array<Maybe<General_Messages_Mutation_Response>>>;
  /** update data of the table: "genres" */
  update_genres?: Maybe<Genres_Mutation_Response>;
  /** update single row of the table: "genres" */
  update_genres_by_pk?: Maybe<Genres>;
  /** update multiples rows of table: "genres" */
  update_genres_many?: Maybe<Array<Maybe<Genres_Mutation_Response>>>;
  /** update data of the table: "heared_methods" */
  update_heared_methods?: Maybe<Heared_Methods_Mutation_Response>;
  /** update single row of the table: "heared_methods" */
  update_heared_methods_by_pk?: Maybe<Heared_Methods>;
  /** update multiples rows of table: "heared_methods" */
  update_heared_methods_many?: Maybe<Array<Maybe<Heared_Methods_Mutation_Response>>>;
  /** update data of the table: "kid_user_settings" */
  update_kid_user_settings?: Maybe<Kid_User_Settings_Mutation_Response>;
  /** update single row of the table: "kid_user_settings" */
  update_kid_user_settings_by_pk?: Maybe<Kid_User_Settings>;
  /** update multiples rows of table: "kid_user_settings" */
  update_kid_user_settings_many?: Maybe<Array<Maybe<Kid_User_Settings_Mutation_Response>>>;
  /** update data of the table: "kids_parents" */
  update_kids_parents?: Maybe<Kids_Parents_Mutation_Response>;
  /** update single row of the table: "kids_parents" */
  update_kids_parents_by_pk?: Maybe<Kids_Parents>;
  /** update multiples rows of table: "kids_parents" */
  update_kids_parents_many?: Maybe<Array<Maybe<Kids_Parents_Mutation_Response>>>;
  /** update data of the table: "leaderboard" */
  update_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** update single row of the table: "leaderboard" */
  update_leaderboard_by_pk?: Maybe<Leaderboard>;
  /** update multiples rows of table: "leaderboard" */
  update_leaderboard_many?: Maybe<Array<Maybe<Leaderboard_Mutation_Response>>>;
  /** update data of the table: "likes" */
  update_likes?: Maybe<Likes_Mutation_Response>;
  /** update single row of the table: "likes" */
  update_likes_by_pk?: Maybe<Likes>;
  /** update multiples rows of table: "likes" */
  update_likes_many?: Maybe<Array<Maybe<Likes_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "parent_user_settings" */
  update_parent_user_settings?: Maybe<Parent_User_Settings_Mutation_Response>;
  /** update single row of the table: "parent_user_settings" */
  update_parent_user_settings_by_pk?: Maybe<Parent_User_Settings>;
  /** update multiples rows of table: "parent_user_settings" */
  update_parent_user_settings_many?: Maybe<Array<Maybe<Parent_User_Settings_Mutation_Response>>>;
  /** update data of the table: "privacy_policy" */
  update_privacy_policy?: Maybe<Privacy_Policy_Mutation_Response>;
  /** update single row of the table: "privacy_policy" */
  update_privacy_policy_by_pk?: Maybe<Privacy_Policy>;
  /** update multiples rows of table: "privacy_policy" */
  update_privacy_policy_many?: Maybe<Array<Maybe<Privacy_Policy_Mutation_Response>>>;
  /** update data of the table: "report_video_methods" */
  update_report_video_methods?: Maybe<Report_Video_Methods_Mutation_Response>;
  /** update single row of the table: "report_video_methods" */
  update_report_video_methods_by_pk?: Maybe<Report_Video_Methods>;
  /** update multiples rows of table: "report_video_methods" */
  update_report_video_methods_many?: Maybe<Array<Maybe<Report_Video_Methods_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update data of the table: "roles_actions" */
  update_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** update single row of the table: "roles_actions" */
  update_roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** update multiples rows of table: "roles_actions" */
  update_roles_actions_many?: Maybe<Array<Maybe<Roles_Actions_Mutation_Response>>>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "seasons" */
  update_seasons?: Maybe<Seasons_Mutation_Response>;
  /** update single row of the table: "seasons" */
  update_seasons_by_pk?: Maybe<Seasons>;
  /** update multiples rows of table: "seasons" */
  update_seasons_many?: Maybe<Array<Maybe<Seasons_Mutation_Response>>>;
  /** update data of the table: "series" */
  update_series?: Maybe<Series_Mutation_Response>;
  /** update single row of the table: "series" */
  update_series_by_pk?: Maybe<Series>;
  /** update multiples rows of table: "series" */
  update_series_many?: Maybe<Array<Maybe<Series_Mutation_Response>>>;
  /** update data of the table: "suggestion_settings" */
  update_suggestion_settings?: Maybe<Suggestion_Settings_Mutation_Response>;
  /** update single row of the table: "suggestion_settings" */
  update_suggestion_settings_by_pk?: Maybe<Suggestion_Settings>;
  /** update multiples rows of table: "suggestion_settings" */
  update_suggestion_settings_many?: Maybe<Array<Maybe<Suggestion_Settings_Mutation_Response>>>;
  /** update data of the table: "ticket_replies" */
  update_ticket_replies?: Maybe<Ticket_Replies_Mutation_Response>;
  /** update single row of the table: "ticket_replies" */
  update_ticket_replies_by_pk?: Maybe<Ticket_Replies>;
  /** update multiples rows of table: "ticket_replies" */
  update_ticket_replies_many?: Maybe<Array<Maybe<Ticket_Replies_Mutation_Response>>>;
  /** update data of the table: "tickets" */
  update_tickets?: Maybe<Tickets_Mutation_Response>;
  /** update single row of the table: "tickets" */
  update_tickets_by_pk?: Maybe<Tickets>;
  /** update multiples rows of table: "tickets" */
  update_tickets_many?: Maybe<Array<Maybe<Tickets_Mutation_Response>>>;
  /** update data of the table: "user_badges" */
  update_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** update single row of the table: "user_badges" */
  update_user_badges_by_pk?: Maybe<User_Badges>;
  /** update multiples rows of table: "user_badges" */
  update_user_badges_many?: Maybe<Array<Maybe<User_Badges_Mutation_Response>>>;
  /** update data of the table: "user_playlists" */
  update_user_playlists?: Maybe<User_Playlists_Mutation_Response>;
  /** update single row of the table: "user_playlists" */
  update_user_playlists_by_pk?: Maybe<User_Playlists>;
  /** update multiples rows of table: "user_playlists" */
  update_user_playlists_many?: Maybe<Array<Maybe<User_Playlists_Mutation_Response>>>;
  /** update data of the table: "user_playlists_videos" */
  update_user_playlists_videos?: Maybe<User_Playlists_Videos_Mutation_Response>;
  /** update single row of the table: "user_playlists_videos" */
  update_user_playlists_videos_by_pk?: Maybe<User_Playlists_Videos>;
  /** update multiples rows of table: "user_playlists_videos" */
  update_user_playlists_videos_many?: Maybe<Array<Maybe<User_Playlists_Videos_Mutation_Response>>>;
  /** update data of the table: "user_searches" */
  update_user_searches?: Maybe<User_Searches_Mutation_Response>;
  /** update single row of the table: "user_searches" */
  update_user_searches_by_pk?: Maybe<User_Searches>;
  /** update multiples rows of table: "user_searches" */
  update_user_searches_many?: Maybe<Array<Maybe<User_Searches_Mutation_Response>>>;
  /** update data of the table: "user_sessions" */
  update_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** update single row of the table: "user_sessions" */
  update_user_sessions_by_pk?: Maybe<User_Sessions>;
  /** update multiples rows of table: "user_sessions" */
  update_user_sessions_many?: Maybe<Array<Maybe<User_Sessions_Mutation_Response>>>;
  /** update data of the table: "user_shared_videos" */
  update_user_shared_videos?: Maybe<User_Shared_Videos_Mutation_Response>;
  /** update single row of the table: "user_shared_videos" */
  update_user_shared_videos_by_pk?: Maybe<User_Shared_Videos>;
  /** update multiples rows of table: "user_shared_videos" */
  update_user_shared_videos_many?: Maybe<Array<Maybe<User_Shared_Videos_Mutation_Response>>>;
  /** update data of the table: "user_watched_badge_videos" */
  update_user_watched_badge_videos?: Maybe<User_Watched_Badge_Videos_Mutation_Response>;
  /** update single row of the table: "user_watched_badge_videos" */
  update_user_watched_badge_videos_by_pk?: Maybe<User_Watched_Badge_Videos>;
  /** update multiples rows of table: "user_watched_badge_videos" */
  update_user_watched_badge_videos_many?: Maybe<Array<Maybe<User_Watched_Badge_Videos_Mutation_Response>>>;
  /** update data of the table: "user_watched_video_sessions" */
  update_user_watched_video_sessions?: Maybe<User_Watched_Video_Sessions_Mutation_Response>;
  /** update single row of the table: "user_watched_video_sessions" */
  update_user_watched_video_sessions_by_pk?: Maybe<User_Watched_Video_Sessions>;
  /** update multiples rows of table: "user_watched_video_sessions" */
  update_user_watched_video_sessions_many?: Maybe<Array<Maybe<User_Watched_Video_Sessions_Mutation_Response>>>;
  /** update data of the table: "user_watched_videos" */
  update_user_watched_videos?: Maybe<User_Watched_Videos_Mutation_Response>;
  /** update single row of the table: "user_watched_videos" */
  update_user_watched_videos_by_pk?: Maybe<User_Watched_Videos>;
  /** update multiples rows of table: "user_watched_videos" */
  update_user_watched_videos_many?: Maybe<Array<Maybe<User_Watched_Videos_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_categories" */
  update_users_categories?: Maybe<Users_Categories_Mutation_Response>;
  /** update single row of the table: "users_categories" */
  update_users_categories_by_pk?: Maybe<Users_Categories>;
  /** update multiples rows of table: "users_categories" */
  update_users_categories_many?: Maybe<Array<Maybe<Users_Categories_Mutation_Response>>>;
  /** update data of the table: "users_follows" */
  update_users_follows?: Maybe<Users_Follows_Mutation_Response>;
  /** update single row of the table: "users_follows" */
  update_users_follows_by_pk?: Maybe<Users_Follows>;
  /** update multiples rows of table: "users_follows" */
  update_users_follows_many?: Maybe<Array<Maybe<Users_Follows_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_privacy" */
  update_users_privacy?: Maybe<Users_Privacy_Mutation_Response>;
  /** update single row of the table: "users_privacy" */
  update_users_privacy_by_pk?: Maybe<Users_Privacy>;
  /** update multiples rows of table: "users_privacy" */
  update_users_privacy_many?: Maybe<Array<Maybe<Users_Privacy_Mutation_Response>>>;
  /** update data of the table: "video_reports" */
  update_video_reports?: Maybe<Video_Reports_Mutation_Response>;
  /** update single row of the table: "video_reports" */
  update_video_reports_by_pk?: Maybe<Video_Reports>;
  /** update multiples rows of table: "video_reports" */
  update_video_reports_many?: Maybe<Array<Maybe<Video_Reports_Mutation_Response>>>;
  /** update data of the table: "videos" */
  update_videos?: Maybe<Videos_Mutation_Response>;
  /** update single row of the table: "videos" */
  update_videos_by_pk?: Maybe<Videos>;
  /** update data of the table: "videos_contents" */
  update_videos_contents?: Maybe<Videos_Contents_Mutation_Response>;
  /** update single row of the table: "videos_contents" */
  update_videos_contents_by_pk?: Maybe<Videos_Contents>;
  /** update multiples rows of table: "videos_contents" */
  update_videos_contents_many?: Maybe<Array<Maybe<Videos_Contents_Mutation_Response>>>;
  /** update multiples rows of table: "videos" */
  update_videos_many?: Maybe<Array<Maybe<Videos_Mutation_Response>>>;
  verifyAccount?: Maybe<UserAuthResponse>;
};


/** mutation root */
export type Mutation_RootAddExistingChildAccountArgs = {
  loginUserInput: LoginUserInput;
};


/** mutation root */
export type Mutation_RootAddLikeArgs = {
  videoId: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootChromeExtGoogleSigninArgs = {
  googleSignInInput: ChromeExtGoogleSignInInput;
};


/** mutation root */
export type Mutation_RootClaimBadgeArgs = {
  badgeId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateAiSuggestedVideoReviewByAdminArgs = {
  createSuggestedVideoReviewInput: CreateSuggestedVideoReviewInput;
};


/** mutation root */
export type Mutation_RootCreateAiSuggestedVideoReviewByChromeUserArgs = {
  createSuggestedVideoReviewInput: CreateSuggestedVideoReviewInput;
};


/** mutation root */
export type Mutation_RootCreateAdminArgs = {
  createAdminInput: CreateAdminInput;
};


/** mutation root */
export type Mutation_RootCreateAgePreferenceArgs = {
  age: Scalars['Float']['input'];
};


/** mutation root */
export type Mutation_RootCreateCategoriesPreferenceArgs = {
  ids: Array<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootCreateChildAccountArgs = {
  createChildAccountInput: CreateChildAccountInput;
};


/** mutation root */
export type Mutation_RootCreateChromeExtSuggestedVideoArgs = {
  createSuggestedVideoInput: CreateSuggestedVideoInput;
};


/** mutation root */
export type Mutation_RootCreateChromeExtSuggestedVideoReviewByAdminArgs = {
  createSuggestedVideoReviewInput: CreateSuggestedVideoReviewInput;
};


/** mutation root */
export type Mutation_RootCreateChromeExtSuggestedVideoReviewByChromeUserArgs = {
  createSuggestedVideoReviewInput: CreateSuggestedVideoReviewInput;
};


/** mutation root */
export type Mutation_RootCreateFollowRequestArgs = {
  receiverId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateGeneralMessageArgs = {
  createGeneralMessageInput: CreateGeneralMessageInput;
};


/** mutation root */
export type Mutation_RootCreateHearedMethodPreferenceArgs = {
  methodId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateKidCategoriesPreferenceByParentArgs = {
  ids: Array<Scalars['String']['input']>;
  kidId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  createUserInput: CreateUserInput;
};


/** mutation root */
export type Mutation_RootCreateVideoArgs = {
  createVideoInput: CreateVideoInput;
};


/** mutation root */
export type Mutation_RootDeActivateAdminArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootDeActivateUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFollowRequestArgs = {
  receiverId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFollowRequestByParentArgs = {
  kidId: Scalars['String']['input'];
  receiverId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ActionsArgs = {
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Actions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AdminsArgs = {
  where: Admins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admins_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggested_VideosArgs = {
  where: Ai_Suggested_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggested_Videos_ReviewsArgs = {
  where: Ai_Suggested_Videos_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggestion_KeywordsArgs = {
  where: Ai_Suggestion_Keywords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ai_Suggestion_Keywords_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Badge_VideosArgs = {
  where: Badge_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BadgesArgs = {
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badges_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Categories_ContentsArgs = {
  where: Categories_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_Contents_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Channels_ContentsArgs = {
  where: Channels_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_Contents_By_PkArgs = {
  channelId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Channels_FollowsArgs = {
  where: Channels_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_Follows_By_PkArgs = {
  channelId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_NotificationsArgs = {
  where: Chrome_Extension_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Suggested_VideosArgs = {
  where: Chrome_Extension_Suggested_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Suggested_Videos_ReviewsArgs = {
  where: Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_UsersArgs = {
  where: Chrome_Extension_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chrome_Extension_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Coming_Soon_Videos_RemindersArgs = {
  where: Coming_Soon_Videos_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coming_Soon_Videos_Reminders_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContentsArgs = {
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FaqArgs = {
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Follow_RequestsArgs = {
  where: Follow_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Follow_Requests_By_PkArgs = {
  receiverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_General_MessagesArgs = {
  where: General_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_General_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GenresArgs = {
  where: Genres_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Genres_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Heared_MethodsArgs = {
  where: Heared_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Heared_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Kid_User_SettingsArgs = {
  where: Kid_User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kid_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Kids_ParentsArgs = {
  where: Kids_Parents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kids_Parents_By_PkArgs = {
  kidId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LeaderboardArgs = {
  where: Leaderboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Leaderboard_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LikesArgs = {
  where: Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Likes_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Parent_User_SettingsArgs = {
  where: Parent_User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parent_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Privacy_PolicyArgs = {
  where: Privacy_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Privacy_Policy_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Report_Video_MethodsArgs = {
  where: Report_Video_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_Video_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_ActionsArgs = {
  where: Roles_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SeasonsArgs = {
  where: Seasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seasons_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SeriesArgs = {
  where: Series_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Series_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Suggestion_SettingsArgs = {
  where: Suggestion_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suggestion_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Ticket_RepliesArgs = {
  where: Ticket_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ticket_Replies_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TicketsArgs = {
  where: Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tickets_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_BadgesArgs = {
  where: User_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Badges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_PlaylistsArgs = {
  where: User_Playlists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Playlists_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Playlists_VideosArgs = {
  where: User_Playlists_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Playlists_Videos_By_PkArgs = {
  playlistId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_SearchesArgs = {
  where: User_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Searches_By_PkArgs = {
  search: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_SessionsArgs = {
  where: User_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Shared_VideosArgs = {
  where: User_Shared_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Shared_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_Badge_VideosArgs = {
  where: User_Watched_Badge_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_Badge_Videos_By_PkArgs = {
  badgeVideoId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_Video_SessionsArgs = {
  where: User_Watched_Video_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_Video_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_VideosArgs = {
  where: User_Watched_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Watched_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_CategoriesArgs = {
  where: Users_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Categories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_FollowsArgs = {
  where: Users_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Follows_By_PkArgs = {
  followeeId: Scalars['String']['input'];
  followerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_PrivacyArgs = {
  where: Users_Privacy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Privacy_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Video_ReportsArgs = {
  where: Video_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Video_Reports_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VideosArgs = {
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Videos_ContentsArgs = {
  where: Videos_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_Contents_By_PkArgs = {
  contentId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootFollowChannelArgs = {
  channelId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootForgetPasswordArgs = {
  forgetPasswordInput: ForgetPasswordInput;
};


/** mutation root */
export type Mutation_RootGoogleSigninArgs = {
  googleSignInInput: GoogleSignInInput;
};


/** mutation root */
export type Mutation_RootInsert_ActionsArgs = {
  objects: Array<Actions_Insert_Input>;
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_OneArgs = {
  object: Actions_Insert_Input;
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AdminsArgs = {
  objects: Array<Admins_Insert_Input>;
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admins_OneArgs = {
  object: Admins_Insert_Input;
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggested_VideosArgs = {
  objects: Array<Ai_Suggested_Videos_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Suggested_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggested_Videos_OneArgs = {
  object: Ai_Suggested_Videos_Insert_Input;
  on_conflict?: InputMaybe<Ai_Suggested_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggested_Videos_ReviewsArgs = {
  objects: Array<Ai_Suggested_Videos_Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Suggested_Videos_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggested_Videos_Reviews_OneArgs = {
  object: Ai_Suggested_Videos_Reviews_Insert_Input;
  on_conflict?: InputMaybe<Ai_Suggested_Videos_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggestion_KeywordsArgs = {
  objects: Array<Ai_Suggestion_Keywords_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Suggestion_Keywords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ai_Suggestion_Keywords_OneArgs = {
  object: Ai_Suggestion_Keywords_Insert_Input;
  on_conflict?: InputMaybe<Ai_Suggestion_Keywords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_VideosArgs = {
  objects: Array<Badge_Videos_Insert_Input>;
  on_conflict?: InputMaybe<Badge_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Videos_OneArgs = {
  object: Badge_Videos_Insert_Input;
  on_conflict?: InputMaybe<Badge_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BadgesArgs = {
  objects: Array<Badges_Insert_Input>;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badges_OneArgs = {
  object: Badges_Insert_Input;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_ContentsArgs = {
  objects: Array<Categories_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Categories_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_Contents_OneArgs = {
  object: Categories_Contents_Insert_Input;
  on_conflict?: InputMaybe<Categories_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChannelsArgs = {
  objects: Array<Channels_Insert_Input>;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_ContentsArgs = {
  objects: Array<Channels_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Channels_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_Contents_OneArgs = {
  object: Channels_Contents_Insert_Input;
  on_conflict?: InputMaybe<Channels_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_FollowsArgs = {
  objects: Array<Channels_Follows_Insert_Input>;
  on_conflict?: InputMaybe<Channels_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_Follows_OneArgs = {
  object: Channels_Follows_Insert_Input;
  on_conflict?: InputMaybe<Channels_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_OneArgs = {
  object: Channels_Insert_Input;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_NotificationsArgs = {
  objects: Array<Chrome_Extension_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Chrome_Extension_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Notifications_OneArgs = {
  object: Chrome_Extension_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Chrome_Extension_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Suggested_VideosArgs = {
  objects: Array<Chrome_Extension_Suggested_Videos_Insert_Input>;
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Suggested_Videos_OneArgs = {
  object: Chrome_Extension_Suggested_Videos_Insert_Input;
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Suggested_Videos_ReviewsArgs = {
  objects: Array<Chrome_Extension_Suggested_Videos_Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Suggested_Videos_Reviews_OneArgs = {
  object: Chrome_Extension_Suggested_Videos_Reviews_Insert_Input;
  on_conflict?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_UsersArgs = {
  objects: Array<Chrome_Extension_Users_Insert_Input>;
  on_conflict?: InputMaybe<Chrome_Extension_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chrome_Extension_Users_OneArgs = {
  object: Chrome_Extension_Users_Insert_Input;
  on_conflict?: InputMaybe<Chrome_Extension_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coming_Soon_Videos_RemindersArgs = {
  objects: Array<Coming_Soon_Videos_Reminders_Insert_Input>;
  on_conflict?: InputMaybe<Coming_Soon_Videos_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coming_Soon_Videos_Reminders_OneArgs = {
  object: Coming_Soon_Videos_Reminders_Insert_Input;
  on_conflict?: InputMaybe<Coming_Soon_Videos_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentsArgs = {
  objects: Array<Contents_Insert_Input>;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_OneArgs = {
  object: Contents_Insert_Input;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaqArgs = {
  objects: Array<Faq_Insert_Input>;
  on_conflict?: InputMaybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_OneArgs = {
  object: Faq_Insert_Input;
  on_conflict?: InputMaybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Follow_RequestsArgs = {
  objects: Array<Follow_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Follow_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Follow_Requests_OneArgs = {
  object: Follow_Requests_Insert_Input;
  on_conflict?: InputMaybe<Follow_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_General_MessagesArgs = {
  objects: Array<General_Messages_Insert_Input>;
  on_conflict?: InputMaybe<General_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_General_Messages_OneArgs = {
  object: General_Messages_Insert_Input;
  on_conflict?: InputMaybe<General_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenresArgs = {
  objects: Array<Genres_Insert_Input>;
  on_conflict?: InputMaybe<Genres_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Genres_OneArgs = {
  object: Genres_Insert_Input;
  on_conflict?: InputMaybe<Genres_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Heared_MethodsArgs = {
  objects: Array<Heared_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Heared_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Heared_Methods_OneArgs = {
  object: Heared_Methods_Insert_Input;
  on_conflict?: InputMaybe<Heared_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kid_User_SettingsArgs = {
  objects: Array<Kid_User_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Kid_User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kid_User_Settings_OneArgs = {
  object: Kid_User_Settings_Insert_Input;
  on_conflict?: InputMaybe<Kid_User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kids_ParentsArgs = {
  objects: Array<Kids_Parents_Insert_Input>;
  on_conflict?: InputMaybe<Kids_Parents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kids_Parents_OneArgs = {
  object: Kids_Parents_Insert_Input;
  on_conflict?: InputMaybe<Kids_Parents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeaderboardArgs = {
  objects: Array<Leaderboard_Insert_Input>;
  on_conflict?: InputMaybe<Leaderboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Leaderboard_OneArgs = {
  object: Leaderboard_Insert_Input;
  on_conflict?: InputMaybe<Leaderboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LikesArgs = {
  objects: Array<Likes_Insert_Input>;
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Likes_OneArgs = {
  object: Likes_Insert_Input;
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_User_SettingsArgs = {
  objects: Array<Parent_User_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Parent_User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_User_Settings_OneArgs = {
  object: Parent_User_Settings_Insert_Input;
  on_conflict?: InputMaybe<Parent_User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Privacy_PolicyArgs = {
  objects: Array<Privacy_Policy_Insert_Input>;
  on_conflict?: InputMaybe<Privacy_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Privacy_Policy_OneArgs = {
  object: Privacy_Policy_Insert_Input;
  on_conflict?: InputMaybe<Privacy_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Video_MethodsArgs = {
  objects: Array<Report_Video_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Report_Video_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Video_Methods_OneArgs = {
  object: Report_Video_Methods_Insert_Input;
  on_conflict?: InputMaybe<Report_Video_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_ActionsArgs = {
  objects: Array<Roles_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_Actions_OneArgs = {
  object: Roles_Actions_Insert_Input;
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SeasonsArgs = {
  objects: Array<Seasons_Insert_Input>;
  on_conflict?: InputMaybe<Seasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seasons_OneArgs = {
  object: Seasons_Insert_Input;
  on_conflict?: InputMaybe<Seasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SeriesArgs = {
  objects: Array<Series_Insert_Input>;
  on_conflict?: InputMaybe<Series_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Series_OneArgs = {
  object: Series_Insert_Input;
  on_conflict?: InputMaybe<Series_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suggestion_SettingsArgs = {
  objects: Array<Suggestion_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Suggestion_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suggestion_Settings_OneArgs = {
  object: Suggestion_Settings_Insert_Input;
  on_conflict?: InputMaybe<Suggestion_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ticket_RepliesArgs = {
  objects: Array<Ticket_Replies_Insert_Input>;
  on_conflict?: InputMaybe<Ticket_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ticket_Replies_OneArgs = {
  object: Ticket_Replies_Insert_Input;
  on_conflict?: InputMaybe<Ticket_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TicketsArgs = {
  objects: Array<Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tickets_OneArgs = {
  object: Tickets_Insert_Input;
  on_conflict?: InputMaybe<Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_BadgesArgs = {
  objects: Array<User_Badges_Insert_Input>;
  on_conflict?: InputMaybe<User_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Badges_OneArgs = {
  object: User_Badges_Insert_Input;
  on_conflict?: InputMaybe<User_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PlaylistsArgs = {
  objects: Array<User_Playlists_Insert_Input>;
  on_conflict?: InputMaybe<User_Playlists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Playlists_OneArgs = {
  object: User_Playlists_Insert_Input;
  on_conflict?: InputMaybe<User_Playlists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Playlists_VideosArgs = {
  objects: Array<User_Playlists_Videos_Insert_Input>;
  on_conflict?: InputMaybe<User_Playlists_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Playlists_Videos_OneArgs = {
  object: User_Playlists_Videos_Insert_Input;
  on_conflict?: InputMaybe<User_Playlists_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_SearchesArgs = {
  objects: Array<User_Searches_Insert_Input>;
  on_conflict?: InputMaybe<User_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Searches_OneArgs = {
  object: User_Searches_Insert_Input;
  on_conflict?: InputMaybe<User_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_SessionsArgs = {
  objects: Array<User_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<User_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Sessions_OneArgs = {
  object: User_Sessions_Insert_Input;
  on_conflict?: InputMaybe<User_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Shared_VideosArgs = {
  objects: Array<User_Shared_Videos_Insert_Input>;
  on_conflict?: InputMaybe<User_Shared_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Shared_Videos_OneArgs = {
  object: User_Shared_Videos_Insert_Input;
  on_conflict?: InputMaybe<User_Shared_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_Badge_VideosArgs = {
  objects: Array<User_Watched_Badge_Videos_Insert_Input>;
  on_conflict?: InputMaybe<User_Watched_Badge_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_Badge_Videos_OneArgs = {
  object: User_Watched_Badge_Videos_Insert_Input;
  on_conflict?: InputMaybe<User_Watched_Badge_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_Video_SessionsArgs = {
  objects: Array<User_Watched_Video_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<User_Watched_Video_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_Video_Sessions_OneArgs = {
  object: User_Watched_Video_Sessions_Insert_Input;
  on_conflict?: InputMaybe<User_Watched_Video_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_VideosArgs = {
  objects: Array<User_Watched_Videos_Insert_Input>;
  on_conflict?: InputMaybe<User_Watched_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Watched_Videos_OneArgs = {
  object: User_Watched_Videos_Insert_Input;
  on_conflict?: InputMaybe<User_Watched_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_CategoriesArgs = {
  objects: Array<Users_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Users_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Categories_OneArgs = {
  object: Users_Categories_Insert_Input;
  on_conflict?: InputMaybe<Users_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_FollowsArgs = {
  objects: Array<Users_Follows_Insert_Input>;
  on_conflict?: InputMaybe<Users_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Follows_OneArgs = {
  object: Users_Follows_Insert_Input;
  on_conflict?: InputMaybe<Users_Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_PrivacyArgs = {
  objects: Array<Users_Privacy_Insert_Input>;
  on_conflict?: InputMaybe<Users_Privacy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Privacy_OneArgs = {
  object: Users_Privacy_Insert_Input;
  on_conflict?: InputMaybe<Users_Privacy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_ReportsArgs = {
  objects: Array<Video_Reports_Insert_Input>;
  on_conflict?: InputMaybe<Video_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Reports_OneArgs = {
  object: Video_Reports_Insert_Input;
  on_conflict?: InputMaybe<Video_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideosArgs = {
  objects: Array<Videos_Insert_Input>;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_ContentsArgs = {
  objects: Array<Videos_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Videos_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_Contents_OneArgs = {
  object: Videos_Contents_Insert_Input;
  on_conflict?: InputMaybe<Videos_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_OneArgs = {
  object: Videos_Insert_Input;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginAdminArgs = {
  loginAdminInput: LoginAdminInput;
};


/** mutation root */
export type Mutation_RootLoginByPinArgs = {
  loginByPinInput: LoginByPinInput;
};


/** mutation root */
export type Mutation_RootLoginChromeExtUserArgs = {
  loginUserInput: LoginUserInput;
};


/** mutation root */
export type Mutation_RootLoginUserArgs = {
  loginUserInput: LoginUserInput;
};


/** mutation root */
export type Mutation_RootPublishAiSuggestedVideoArgs = {
  publishSuggestedVideoInput: PublishSuggestedVideoInput;
};


/** mutation root */
export type Mutation_RootPublishChromeExtSuggestedVideoArgs = {
  publishSuggestedVideoInput: PublishSuggestedVideoInput;
};


/** mutation root */
export type Mutation_RootPublishComingSoonVideoArgs = {
  publishComingSoonVideoInput: PublishComingSoonVideoInput;
};


/** mutation root */
export type Mutation_RootReActivateAdminArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootReActivateUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootRemoveAdminArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveFollowerArgs = {
  followerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveFollowerByParentArgs = {
  followerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveLikeArgs = {
  videoId: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootRemoveUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootReplyTicketArgs = {
  replyTicketInput: ReplyTicketInput;
};


/** mutation root */
export type Mutation_RootRequestPinTokenArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResendForgetPasswordCodeArgs = {
  resendVerificationCodeInput: SendVerificationCodeInput;
};


/** mutation root */
export type Mutation_RootResendVerificationCodeArgs = {
  resendVerificationCodeInput: SendVerificationCodeInput;
};


/** mutation root */
export type Mutation_RootResetUserAvatarArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResetUserPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootRespondFollowRequestArgs = {
  respondFollowRequestInput: RespondFollowRequestInput;
};


/** mutation root */
export type Mutation_RootRespondFollowRequestByParentArgs = {
  kidId: Scalars['String']['input'];
  respondFollowRequestInput: RespondFollowRequestInput;
};


/** mutation root */
export type Mutation_RootSetKidPublicAvatarArgs = {
  url: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSetPublicAvatarArgs = {
  url: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootShareVideoArgs = {
  videoId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSignupChromeExtUserArgs = {
  createChromeExtUserInput: CreateChromeExtUserInput;
};


/** mutation root */
export type Mutation_RootSignupUserArgs = {
  createUserInput: CreateUserInput;
};


/** mutation root */
export type Mutation_RootUnfollowChannelArgs = {
  channelId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnfollowUserArgs = {
  followeeId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnfollowUserByParentArgs = {
  followeeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateAiSuggestionSettingsArgs = {
  updateSuggestionSettingInput: UpdateSuggestionSettingInput;
};


/** mutation root */
export type Mutation_RootUpdateAdminArgs = {
  updateAdminInput: UpdateAdminInput;
};


/** mutation root */
export type Mutation_RootUpdateChromeExtSuggestionSettingsArgs = {
  updateSuggestionSettingInput: UpdateSuggestionSettingInput;
};


/** mutation root */
export type Mutation_RootUpdateKidPrivacyArgs = {
  kidId: Scalars['String']['input'];
  updatePrivacyDto: UpdatePrivacyDto;
};


/** mutation root */
export type Mutation_RootUpdateKidProfileArgs = {
  updateUserProfile: UpdateUserProfileInput;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateParentProfileArgs = {
  updateParentUserProfile: UpdateParentUserProfileInput;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


/** mutation root */
export type Mutation_RootUpdateUserPrivacyArgs = {
  updatePrivacyDto: UpdatePrivacyDto;
};


/** mutation root */
export type Mutation_RootUpdateUserProfileArgs = {
  updateUserProfile: UpdateUserProfileInput;
};


/** mutation root */
export type Mutation_RootUpdateVideoArgs = {
  updateVideoInput: UpdateVideoInput;
};


/** mutation root */
export type Mutation_RootUpdate_ActionsArgs = {
  _set?: InputMaybe<Actions_Set_Input>;
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_By_PkArgs = {
  _set?: InputMaybe<Actions_Set_Input>;
  pk_columns: Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_ManyArgs = {
  updates: Array<Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AdminsArgs = {
  _set?: InputMaybe<Admins_Set_Input>;
  where: Admins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admins_By_PkArgs = {
  _set?: InputMaybe<Admins_Set_Input>;
  pk_columns: Admins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admins_ManyArgs = {
  updates: Array<Admins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_VideosArgs = {
  _set?: InputMaybe<Ai_Suggested_Videos_Set_Input>;
  where: Ai_Suggested_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_Videos_By_PkArgs = {
  _set?: InputMaybe<Ai_Suggested_Videos_Set_Input>;
  pk_columns: Ai_Suggested_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_Videos_ManyArgs = {
  updates: Array<Ai_Suggested_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_Videos_ReviewsArgs = {
  _set?: InputMaybe<Ai_Suggested_Videos_Reviews_Set_Input>;
  where: Ai_Suggested_Videos_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_Videos_Reviews_By_PkArgs = {
  _set?: InputMaybe<Ai_Suggested_Videos_Reviews_Set_Input>;
  pk_columns: Ai_Suggested_Videos_Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggested_Videos_Reviews_ManyArgs = {
  updates: Array<Ai_Suggested_Videos_Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggestion_KeywordsArgs = {
  _append?: InputMaybe<Ai_Suggestion_Keywords_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Suggestion_Keywords_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Suggestion_Keywords_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Suggestion_Keywords_Delete_Key_Input>;
  _prepend?: InputMaybe<Ai_Suggestion_Keywords_Prepend_Input>;
  _set?: InputMaybe<Ai_Suggestion_Keywords_Set_Input>;
  where: Ai_Suggestion_Keywords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggestion_Keywords_By_PkArgs = {
  _append?: InputMaybe<Ai_Suggestion_Keywords_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Suggestion_Keywords_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Suggestion_Keywords_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Suggestion_Keywords_Delete_Key_Input>;
  _prepend?: InputMaybe<Ai_Suggestion_Keywords_Prepend_Input>;
  _set?: InputMaybe<Ai_Suggestion_Keywords_Set_Input>;
  pk_columns: Ai_Suggestion_Keywords_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ai_Suggestion_Keywords_ManyArgs = {
  updates: Array<Ai_Suggestion_Keywords_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_VideosArgs = {
  _set?: InputMaybe<Badge_Videos_Set_Input>;
  where: Badge_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Videos_By_PkArgs = {
  _set?: InputMaybe<Badge_Videos_Set_Input>;
  pk_columns: Badge_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Videos_ManyArgs = {
  updates: Array<Badge_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BadgesArgs = {
  _append?: InputMaybe<Badges_Append_Input>;
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  _inc?: InputMaybe<Badges_Inc_Input>;
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_By_PkArgs = {
  _append?: InputMaybe<Badges_Append_Input>;
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  _inc?: InputMaybe<Badges_Inc_Input>;
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  pk_columns: Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_ManyArgs = {
  updates: Array<Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _append?: InputMaybe<Categories_Append_Input>;
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _append?: InputMaybe<Categories_Append_Input>;
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_ContentsArgs = {
  _set?: InputMaybe<Categories_Contents_Set_Input>;
  where: Categories_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_Contents_By_PkArgs = {
  _set?: InputMaybe<Categories_Contents_Set_Input>;
  pk_columns: Categories_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_Contents_ManyArgs = {
  updates: Array<Categories_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _append?: InputMaybe<Channels_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  _inc?: InputMaybe<Channels_Inc_Input>;
  _prepend?: InputMaybe<Channels_Prepend_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _append?: InputMaybe<Channels_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  _inc?: InputMaybe<Channels_Inc_Input>;
  _prepend?: InputMaybe<Channels_Prepend_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ContentsArgs = {
  _set?: InputMaybe<Channels_Contents_Set_Input>;
  where: Channels_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Contents_By_PkArgs = {
  _set?: InputMaybe<Channels_Contents_Set_Input>;
  pk_columns: Channels_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Contents_ManyArgs = {
  updates: Array<Channels_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_FollowsArgs = {
  _set?: InputMaybe<Channels_Follows_Set_Input>;
  where: Channels_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Follows_By_PkArgs = {
  _set?: InputMaybe<Channels_Follows_Set_Input>;
  pk_columns: Channels_Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Follows_ManyArgs = {
  updates: Array<Channels_Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: Array<Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_NotificationsArgs = {
  _append?: InputMaybe<Chrome_Extension_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Chrome_Extension_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chrome_Extension_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chrome_Extension_Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Chrome_Extension_Notifications_Prepend_Input>;
  _set?: InputMaybe<Chrome_Extension_Notifications_Set_Input>;
  where: Chrome_Extension_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Notifications_By_PkArgs = {
  _append?: InputMaybe<Chrome_Extension_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Chrome_Extension_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chrome_Extension_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chrome_Extension_Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Chrome_Extension_Notifications_Prepend_Input>;
  _set?: InputMaybe<Chrome_Extension_Notifications_Set_Input>;
  pk_columns: Chrome_Extension_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Notifications_ManyArgs = {
  updates: Array<Chrome_Extension_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_VideosArgs = {
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Set_Input>;
  where: Chrome_Extension_Suggested_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_Videos_By_PkArgs = {
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Set_Input>;
  pk_columns: Chrome_Extension_Suggested_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_Videos_ManyArgs = {
  updates: Array<Chrome_Extension_Suggested_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_Videos_ReviewsArgs = {
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Set_Input>;
  where: Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_Videos_Reviews_By_PkArgs = {
  _set?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Set_Input>;
  pk_columns: Chrome_Extension_Suggested_Videos_Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Suggested_Videos_Reviews_ManyArgs = {
  updates: Array<Chrome_Extension_Suggested_Videos_Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_UsersArgs = {
  _set?: InputMaybe<Chrome_Extension_Users_Set_Input>;
  where: Chrome_Extension_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Users_By_PkArgs = {
  _set?: InputMaybe<Chrome_Extension_Users_Set_Input>;
  pk_columns: Chrome_Extension_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chrome_Extension_Users_ManyArgs = {
  updates: Array<Chrome_Extension_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Coming_Soon_Videos_RemindersArgs = {
  _set?: InputMaybe<Coming_Soon_Videos_Reminders_Set_Input>;
  where: Coming_Soon_Videos_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coming_Soon_Videos_Reminders_By_PkArgs = {
  _set?: InputMaybe<Coming_Soon_Videos_Reminders_Set_Input>;
  pk_columns: Coming_Soon_Videos_Reminders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coming_Soon_Videos_Reminders_ManyArgs = {
  updates: Array<Coming_Soon_Videos_Reminders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentsArgs = {
  _append?: InputMaybe<Contents_Append_Input>;
  _delete_at_path?: InputMaybe<Contents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Contents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Contents_Delete_Key_Input>;
  _prepend?: InputMaybe<Contents_Prepend_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_By_PkArgs = {
  _append?: InputMaybe<Contents_Append_Input>;
  _delete_at_path?: InputMaybe<Contents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Contents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Contents_Delete_Key_Input>;
  _prepend?: InputMaybe<Contents_Prepend_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  pk_columns: Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_ManyArgs = {
  updates: Array<Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FaqArgs = {
  _append?: InputMaybe<Faq_Append_Input>;
  _delete_at_path?: InputMaybe<Faq_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Faq_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Faq_Delete_Key_Input>;
  _prepend?: InputMaybe<Faq_Prepend_Input>;
  _set?: InputMaybe<Faq_Set_Input>;
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_By_PkArgs = {
  _append?: InputMaybe<Faq_Append_Input>;
  _delete_at_path?: InputMaybe<Faq_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Faq_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Faq_Delete_Key_Input>;
  _prepend?: InputMaybe<Faq_Prepend_Input>;
  _set?: InputMaybe<Faq_Set_Input>;
  pk_columns: Faq_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_ManyArgs = {
  updates: Array<Faq_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Follow_RequestsArgs = {
  _set?: InputMaybe<Follow_Requests_Set_Input>;
  where: Follow_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Follow_Requests_By_PkArgs = {
  _set?: InputMaybe<Follow_Requests_Set_Input>;
  pk_columns: Follow_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Follow_Requests_ManyArgs = {
  updates: Array<Follow_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_General_MessagesArgs = {
  _set?: InputMaybe<General_Messages_Set_Input>;
  where: General_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_General_Messages_By_PkArgs = {
  _set?: InputMaybe<General_Messages_Set_Input>;
  pk_columns: General_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_General_Messages_ManyArgs = {
  updates: Array<General_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GenresArgs = {
  _set?: InputMaybe<Genres_Set_Input>;
  where: Genres_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Genres_By_PkArgs = {
  _set?: InputMaybe<Genres_Set_Input>;
  pk_columns: Genres_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Genres_ManyArgs = {
  updates: Array<Genres_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Heared_MethodsArgs = {
  _append?: InputMaybe<Heared_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Heared_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Heared_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Heared_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Heared_Methods_Inc_Input>;
  _prepend?: InputMaybe<Heared_Methods_Prepend_Input>;
  _set?: InputMaybe<Heared_Methods_Set_Input>;
  where: Heared_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Heared_Methods_By_PkArgs = {
  _append?: InputMaybe<Heared_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Heared_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Heared_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Heared_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Heared_Methods_Inc_Input>;
  _prepend?: InputMaybe<Heared_Methods_Prepend_Input>;
  _set?: InputMaybe<Heared_Methods_Set_Input>;
  pk_columns: Heared_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Heared_Methods_ManyArgs = {
  updates: Array<Heared_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kid_User_SettingsArgs = {
  _inc?: InputMaybe<Kid_User_Settings_Inc_Input>;
  _set?: InputMaybe<Kid_User_Settings_Set_Input>;
  where: Kid_User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kid_User_Settings_By_PkArgs = {
  _inc?: InputMaybe<Kid_User_Settings_Inc_Input>;
  _set?: InputMaybe<Kid_User_Settings_Set_Input>;
  pk_columns: Kid_User_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kid_User_Settings_ManyArgs = {
  updates: Array<Kid_User_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kids_ParentsArgs = {
  _set?: InputMaybe<Kids_Parents_Set_Input>;
  where: Kids_Parents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kids_Parents_By_PkArgs = {
  _set?: InputMaybe<Kids_Parents_Set_Input>;
  pk_columns: Kids_Parents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kids_Parents_ManyArgs = {
  updates: Array<Kids_Parents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeaderboardArgs = {
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Set_Input>;
  where: Leaderboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Leaderboard_By_PkArgs = {
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Set_Input>;
  pk_columns: Leaderboard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Leaderboard_ManyArgs = {
  updates: Array<Leaderboard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LikesArgs = {
  _set?: InputMaybe<Likes_Set_Input>;
  where: Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Likes_By_PkArgs = {
  _set?: InputMaybe<Likes_Set_Input>;
  pk_columns: Likes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Likes_ManyArgs = {
  updates: Array<Likes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_User_SettingsArgs = {
  _set?: InputMaybe<Parent_User_Settings_Set_Input>;
  where: Parent_User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_User_Settings_By_PkArgs = {
  _set?: InputMaybe<Parent_User_Settings_Set_Input>;
  pk_columns: Parent_User_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_User_Settings_ManyArgs = {
  updates: Array<Parent_User_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_PolicyArgs = {
  _append?: InputMaybe<Privacy_Policy_Append_Input>;
  _delete_at_path?: InputMaybe<Privacy_Policy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Privacy_Policy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Privacy_Policy_Delete_Key_Input>;
  _prepend?: InputMaybe<Privacy_Policy_Prepend_Input>;
  _set?: InputMaybe<Privacy_Policy_Set_Input>;
  where: Privacy_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_Policy_By_PkArgs = {
  _append?: InputMaybe<Privacy_Policy_Append_Input>;
  _delete_at_path?: InputMaybe<Privacy_Policy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Privacy_Policy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Privacy_Policy_Delete_Key_Input>;
  _prepend?: InputMaybe<Privacy_Policy_Prepend_Input>;
  _set?: InputMaybe<Privacy_Policy_Set_Input>;
  pk_columns: Privacy_Policy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_Policy_ManyArgs = {
  updates: Array<Privacy_Policy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Video_MethodsArgs = {
  _append?: InputMaybe<Report_Video_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Video_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Video_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Video_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Report_Video_Methods_Inc_Input>;
  _prepend?: InputMaybe<Report_Video_Methods_Prepend_Input>;
  _set?: InputMaybe<Report_Video_Methods_Set_Input>;
  where: Report_Video_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Video_Methods_By_PkArgs = {
  _append?: InputMaybe<Report_Video_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Video_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Video_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Video_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Report_Video_Methods_Inc_Input>;
  _prepend?: InputMaybe<Report_Video_Methods_Prepend_Input>;
  _set?: InputMaybe<Report_Video_Methods_Set_Input>;
  pk_columns: Report_Video_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Video_Methods_ManyArgs = {
  updates: Array<Report_Video_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ActionsArgs = {
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  where: Roles_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_Actions_By_PkArgs = {
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  pk_columns: Roles_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_Actions_ManyArgs = {
  updates: Array<Roles_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SeasonsArgs = {
  _append?: InputMaybe<Seasons_Append_Input>;
  _delete_at_path?: InputMaybe<Seasons_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Seasons_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Seasons_Delete_Key_Input>;
  _prepend?: InputMaybe<Seasons_Prepend_Input>;
  _set?: InputMaybe<Seasons_Set_Input>;
  where: Seasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seasons_By_PkArgs = {
  _append?: InputMaybe<Seasons_Append_Input>;
  _delete_at_path?: InputMaybe<Seasons_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Seasons_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Seasons_Delete_Key_Input>;
  _prepend?: InputMaybe<Seasons_Prepend_Input>;
  _set?: InputMaybe<Seasons_Set_Input>;
  pk_columns: Seasons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seasons_ManyArgs = {
  updates: Array<Seasons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SeriesArgs = {
  _append?: InputMaybe<Series_Append_Input>;
  _delete_at_path?: InputMaybe<Series_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Series_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Series_Delete_Key_Input>;
  _prepend?: InputMaybe<Series_Prepend_Input>;
  _set?: InputMaybe<Series_Set_Input>;
  where: Series_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Series_By_PkArgs = {
  _append?: InputMaybe<Series_Append_Input>;
  _delete_at_path?: InputMaybe<Series_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Series_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Series_Delete_Key_Input>;
  _prepend?: InputMaybe<Series_Prepend_Input>;
  _set?: InputMaybe<Series_Set_Input>;
  pk_columns: Series_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Series_ManyArgs = {
  updates: Array<Series_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Suggestion_SettingsArgs = {
  _set?: InputMaybe<Suggestion_Settings_Set_Input>;
  where: Suggestion_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Suggestion_Settings_By_PkArgs = {
  _set?: InputMaybe<Suggestion_Settings_Set_Input>;
  pk_columns: Suggestion_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Suggestion_Settings_ManyArgs = {
  updates: Array<Suggestion_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ticket_RepliesArgs = {
  _set?: InputMaybe<Ticket_Replies_Set_Input>;
  where: Ticket_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ticket_Replies_By_PkArgs = {
  _set?: InputMaybe<Ticket_Replies_Set_Input>;
  pk_columns: Ticket_Replies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ticket_Replies_ManyArgs = {
  updates: Array<Ticket_Replies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TicketsArgs = {
  _set?: InputMaybe<Tickets_Set_Input>;
  where: Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_By_PkArgs = {
  _set?: InputMaybe<Tickets_Set_Input>;
  pk_columns: Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_ManyArgs = {
  updates: Array<Tickets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_BadgesArgs = {
  _set?: InputMaybe<User_Badges_Set_Input>;
  where: User_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Badges_By_PkArgs = {
  _set?: InputMaybe<User_Badges_Set_Input>;
  pk_columns: User_Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Badges_ManyArgs = {
  updates: Array<User_Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PlaylistsArgs = {
  _set?: InputMaybe<User_Playlists_Set_Input>;
  where: User_Playlists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Playlists_By_PkArgs = {
  _set?: InputMaybe<User_Playlists_Set_Input>;
  pk_columns: User_Playlists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Playlists_ManyArgs = {
  updates: Array<User_Playlists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Playlists_VideosArgs = {
  _set?: InputMaybe<User_Playlists_Videos_Set_Input>;
  where: User_Playlists_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Playlists_Videos_By_PkArgs = {
  _set?: InputMaybe<User_Playlists_Videos_Set_Input>;
  pk_columns: User_Playlists_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Playlists_Videos_ManyArgs = {
  updates: Array<User_Playlists_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_SearchesArgs = {
  _set?: InputMaybe<User_Searches_Set_Input>;
  where: User_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Searches_By_PkArgs = {
  _set?: InputMaybe<User_Searches_Set_Input>;
  pk_columns: User_Searches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Searches_ManyArgs = {
  updates: Array<User_Searches_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_SessionsArgs = {
  _set?: InputMaybe<User_Sessions_Set_Input>;
  where: User_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sessions_By_PkArgs = {
  _set?: InputMaybe<User_Sessions_Set_Input>;
  pk_columns: User_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sessions_ManyArgs = {
  updates: Array<User_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Shared_VideosArgs = {
  _set?: InputMaybe<User_Shared_Videos_Set_Input>;
  where: User_Shared_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Shared_Videos_By_PkArgs = {
  _set?: InputMaybe<User_Shared_Videos_Set_Input>;
  pk_columns: User_Shared_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Shared_Videos_ManyArgs = {
  updates: Array<User_Shared_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Badge_VideosArgs = {
  _set?: InputMaybe<User_Watched_Badge_Videos_Set_Input>;
  where: User_Watched_Badge_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Badge_Videos_By_PkArgs = {
  _set?: InputMaybe<User_Watched_Badge_Videos_Set_Input>;
  pk_columns: User_Watched_Badge_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Badge_Videos_ManyArgs = {
  updates: Array<User_Watched_Badge_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Video_SessionsArgs = {
  _set?: InputMaybe<User_Watched_Video_Sessions_Set_Input>;
  where: User_Watched_Video_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Video_Sessions_By_PkArgs = {
  _set?: InputMaybe<User_Watched_Video_Sessions_Set_Input>;
  pk_columns: User_Watched_Video_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Video_Sessions_ManyArgs = {
  updates: Array<User_Watched_Video_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_VideosArgs = {
  _inc?: InputMaybe<User_Watched_Videos_Inc_Input>;
  _set?: InputMaybe<User_Watched_Videos_Set_Input>;
  where: User_Watched_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Videos_By_PkArgs = {
  _inc?: InputMaybe<User_Watched_Videos_Inc_Input>;
  _set?: InputMaybe<User_Watched_Videos_Set_Input>;
  pk_columns: User_Watched_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Watched_Videos_ManyArgs = {
  updates: Array<User_Watched_Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_CategoriesArgs = {
  _set?: InputMaybe<Users_Categories_Set_Input>;
  where: Users_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Categories_By_PkArgs = {
  _set?: InputMaybe<Users_Categories_Set_Input>;
  pk_columns: Users_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Categories_ManyArgs = {
  updates: Array<Users_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_FollowsArgs = {
  _set?: InputMaybe<Users_Follows_Set_Input>;
  where: Users_Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Follows_By_PkArgs = {
  _set?: InputMaybe<Users_Follows_Set_Input>;
  pk_columns: Users_Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Follows_ManyArgs = {
  updates: Array<Users_Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_PrivacyArgs = {
  _set?: InputMaybe<Users_Privacy_Set_Input>;
  where: Users_Privacy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Privacy_By_PkArgs = {
  _set?: InputMaybe<Users_Privacy_Set_Input>;
  pk_columns: Users_Privacy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Privacy_ManyArgs = {
  updates: Array<Users_Privacy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Video_ReportsArgs = {
  _set?: InputMaybe<Video_Reports_Set_Input>;
  where: Video_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Reports_By_PkArgs = {
  _set?: InputMaybe<Video_Reports_Set_Input>;
  pk_columns: Video_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Reports_ManyArgs = {
  updates: Array<Video_Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VideosArgs = {
  _append?: InputMaybe<Videos_Append_Input>;
  _delete_at_path?: InputMaybe<Videos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Videos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Videos_Delete_Key_Input>;
  _inc?: InputMaybe<Videos_Inc_Input>;
  _prepend?: InputMaybe<Videos_Prepend_Input>;
  _set?: InputMaybe<Videos_Set_Input>;
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_By_PkArgs = {
  _append?: InputMaybe<Videos_Append_Input>;
  _delete_at_path?: InputMaybe<Videos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Videos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Videos_Delete_Key_Input>;
  _inc?: InputMaybe<Videos_Inc_Input>;
  _prepend?: InputMaybe<Videos_Prepend_Input>;
  _set?: InputMaybe<Videos_Set_Input>;
  pk_columns: Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_ContentsArgs = {
  _set?: InputMaybe<Videos_Contents_Set_Input>;
  where: Videos_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Contents_By_PkArgs = {
  _set?: InputMaybe<Videos_Contents_Set_Input>;
  pk_columns: Videos_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Contents_ManyArgs = {
  updates: Array<Videos_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_ManyArgs = {
  updates: Array<Videos_Updates>;
};


/** mutation root */
export type Mutation_RootVerifyAccountArgs = {
  verifyAccountInput: VerifyAccountInput;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  createdAt: Scalars['timestamp']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "notifications" */
export type NotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "parent_user_settings" */
export type Parent_User_Settings = {
  __typename?: 'parent_user_settings';
  badgesUpdatesNotifications: Scalars['Boolean']['output'];
  childUpdatesNotifications: Scalars['Boolean']['output'];
  displayLanguage: Scalars['String']['output'];
  id: Scalars['String']['output'];
  requestsNotifications: Scalars['Boolean']['output'];
  statisticsUpdatesNotifications: Scalars['Boolean']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "parent_user_settings" */
export type Parent_User_Settings_Aggregate = {
  __typename?: 'parent_user_settings_aggregate';
  aggregate?: Maybe<Parent_User_Settings_Aggregate_Fields>;
  nodes: Array<Parent_User_Settings>;
};

/** aggregate fields of "parent_user_settings" */
export type Parent_User_Settings_Aggregate_Fields = {
  __typename?: 'parent_user_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Parent_User_Settings_Max_Fields>;
  min?: Maybe<Parent_User_Settings_Min_Fields>;
};


/** aggregate fields of "parent_user_settings" */
export type Parent_User_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parent_User_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "parent_user_settings". All fields are combined with a logical 'AND'. */
export type Parent_User_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Parent_User_Settings_Bool_Exp>>;
  _not?: InputMaybe<Parent_User_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Parent_User_Settings_Bool_Exp>>;
  badgesUpdatesNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  childUpdatesNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  displayLanguage?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  requestsNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  statisticsUpdatesNotifications?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parent_user_settings" */
export enum Parent_User_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParentUserSettingsPkey = 'parent_user_settings_pkey',
  /** unique or primary key constraint on columns "userId" */
  ParentUserSettingsUserIdKey = 'parent_user_settings_userId_key'
}

/** input type for inserting data into table "parent_user_settings" */
export type Parent_User_Settings_Insert_Input = {
  badgesUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  childUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  statisticsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Parent_User_Settings_Max_Fields = {
  __typename?: 'parent_user_settings_max_fields';
  displayLanguage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Parent_User_Settings_Min_Fields = {
  __typename?: 'parent_user_settings_min_fields';
  displayLanguage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "parent_user_settings" */
export type Parent_User_Settings_Mutation_Response = {
  __typename?: 'parent_user_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parent_User_Settings>;
};

/** input type for inserting object relation for remote table "parent_user_settings" */
export type Parent_User_Settings_Obj_Rel_Insert_Input = {
  data: Parent_User_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Parent_User_Settings_On_Conflict>;
};

/** on_conflict condition type for table "parent_user_settings" */
export type Parent_User_Settings_On_Conflict = {
  constraint: Parent_User_Settings_Constraint;
  update_columns?: Array<Parent_User_Settings_Update_Column>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "parent_user_settings". */
export type Parent_User_Settings_Order_By = {
  badgesUpdatesNotifications?: InputMaybe<Order_By>;
  childUpdatesNotifications?: InputMaybe<Order_By>;
  displayLanguage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestsNotifications?: InputMaybe<Order_By>;
  statisticsUpdatesNotifications?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parent_user_settings */
export type Parent_User_Settings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "parent_user_settings" */
export enum Parent_User_Settings_Select_Column {
  /** column name */
  BadgesUpdatesNotifications = 'badgesUpdatesNotifications',
  /** column name */
  ChildUpdatesNotifications = 'childUpdatesNotifications',
  /** column name */
  DisplayLanguage = 'displayLanguage',
  /** column name */
  Id = 'id',
  /** column name */
  RequestsNotifications = 'requestsNotifications',
  /** column name */
  StatisticsUpdatesNotifications = 'statisticsUpdatesNotifications',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "parent_user_settings" */
export type Parent_User_Settings_Set_Input = {
  badgesUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  childUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  statisticsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "parent_user_settings" */
export type Parent_User_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parent_User_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parent_User_Settings_Stream_Cursor_Value_Input = {
  badgesUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  childUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  requestsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  statisticsUpdatesNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "parent_user_settings" */
export enum Parent_User_Settings_Update_Column {
  /** column name */
  BadgesUpdatesNotifications = 'badgesUpdatesNotifications',
  /** column name */
  ChildUpdatesNotifications = 'childUpdatesNotifications',
  /** column name */
  DisplayLanguage = 'displayLanguage',
  /** column name */
  Id = 'id',
  /** column name */
  RequestsNotifications = 'requestsNotifications',
  /** column name */
  StatisticsUpdatesNotifications = 'statisticsUpdatesNotifications',
  /** column name */
  UserId = 'userId'
}

export type Parent_User_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parent_User_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parent_User_Settings_Bool_Exp;
};

/** columns and relationships of "privacy_policy" */
export type Privacy_Policy = {
  __typename?: 'privacy_policy';
  content: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "privacy_policy" */
export type Privacy_PolicyContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "privacy_policy" */
export type Privacy_Policy_Aggregate = {
  __typename?: 'privacy_policy_aggregate';
  aggregate?: Maybe<Privacy_Policy_Aggregate_Fields>;
  nodes: Array<Privacy_Policy>;
};

/** aggregate fields of "privacy_policy" */
export type Privacy_Policy_Aggregate_Fields = {
  __typename?: 'privacy_policy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Privacy_Policy_Max_Fields>;
  min?: Maybe<Privacy_Policy_Min_Fields>;
};


/** aggregate fields of "privacy_policy" */
export type Privacy_Policy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Privacy_Policy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Privacy_Policy_Append_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "privacy_policy". All fields are combined with a logical 'AND'. */
export type Privacy_Policy_Bool_Exp = {
  _and?: InputMaybe<Array<Privacy_Policy_Bool_Exp>>;
  _not?: InputMaybe<Privacy_Policy_Bool_Exp>;
  _or?: InputMaybe<Array<Privacy_Policy_Bool_Exp>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "privacy_policy" */
export enum Privacy_Policy_Constraint {
  /** unique or primary key constraint on columns "content" */
  PrivacyPolicyContentKey = 'privacy_policy_content_key',
  /** unique or primary key constraint on columns "id" */
  PrivacyPolicyPkey = 'privacy_policy_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Privacy_Policy_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Privacy_Policy_Delete_Elem_Input = {
  content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Privacy_Policy_Delete_Key_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "privacy_policy" */
export type Privacy_Policy_Insert_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Privacy_Policy_Max_Fields = {
  __typename?: 'privacy_policy_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Privacy_Policy_Min_Fields = {
  __typename?: 'privacy_policy_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "privacy_policy" */
export type Privacy_Policy_Mutation_Response = {
  __typename?: 'privacy_policy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Privacy_Policy>;
};

/** on_conflict condition type for table "privacy_policy" */
export type Privacy_Policy_On_Conflict = {
  constraint: Privacy_Policy_Constraint;
  update_columns?: Array<Privacy_Policy_Update_Column>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};

/** Ordering options when selecting data from "privacy_policy". */
export type Privacy_Policy_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: privacy_policy */
export type Privacy_Policy_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Privacy_Policy_Prepend_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "privacy_policy" */
export enum Privacy_Policy_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "privacy_policy" */
export type Privacy_Policy_Set_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "privacy_policy" */
export type Privacy_Policy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Privacy_Policy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Privacy_Policy_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "privacy_policy" */
export enum Privacy_Policy_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Privacy_Policy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Privacy_Policy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Privacy_Policy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Privacy_Policy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Privacy_Policy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Privacy_Policy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Privacy_Policy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Privacy_Policy_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>;
  /** fetch data from the table: "ai_suggested_videos" */
  ai_suggested_videos: Array<Ai_Suggested_Videos>;
  /** fetch aggregated fields from the table: "ai_suggested_videos" */
  ai_suggested_videos_aggregate: Ai_Suggested_Videos_Aggregate;
  /** fetch data from the table: "ai_suggested_videos" using primary key columns */
  ai_suggested_videos_by_pk?: Maybe<Ai_Suggested_Videos>;
  /** fetch data from the table: "ai_suggested_videos_reviews" */
  ai_suggested_videos_reviews: Array<Ai_Suggested_Videos_Reviews>;
  /** fetch aggregated fields from the table: "ai_suggested_videos_reviews" */
  ai_suggested_videos_reviews_aggregate: Ai_Suggested_Videos_Reviews_Aggregate;
  /** fetch data from the table: "ai_suggested_videos_reviews" using primary key columns */
  ai_suggested_videos_reviews_by_pk?: Maybe<Ai_Suggested_Videos_Reviews>;
  /** fetch data from the table: "ai_suggestion_keywords" */
  ai_suggestion_keywords: Array<Ai_Suggestion_Keywords>;
  /** fetch aggregated fields from the table: "ai_suggestion_keywords" */
  ai_suggestion_keywords_aggregate: Ai_Suggestion_Keywords_Aggregate;
  /** fetch data from the table: "ai_suggestion_keywords" using primary key columns */
  ai_suggestion_keywords_by_pk?: Maybe<Ai_Suggestion_Keywords>;
  /** An array relationship */
  badge_videos: Array<Badge_Videos>;
  /** An aggregate relationship */
  badge_videos_aggregate: Badge_Videos_Aggregate;
  /** fetch data from the table: "badge_videos" using primary key columns */
  badge_videos_by_pk?: Maybe<Badge_Videos>;
  /** fetch data from the table: "badges" */
  badges: Array<Badges>;
  /** fetch aggregated fields from the table: "badges" */
  badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  categories_contents: Array<Categories_Contents>;
  /** An aggregate relationship */
  categories_contents_aggregate: Categories_Contents_Aggregate;
  /** fetch data from the table: "categories_contents" using primary key columns */
  categories_contents_by_pk?: Maybe<Categories_Contents>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** An array relationship */
  channels_contents: Array<Channels_Contents>;
  /** An aggregate relationship */
  channels_contents_aggregate: Channels_Contents_Aggregate;
  /** fetch data from the table: "channels_contents" using primary key columns */
  channels_contents_by_pk?: Maybe<Channels_Contents>;
  /** An array relationship */
  channels_follows: Array<Channels_Follows>;
  /** An aggregate relationship */
  channels_follows_aggregate: Channels_Follows_Aggregate;
  /** fetch data from the table: "channels_follows" using primary key columns */
  channels_follows_by_pk?: Maybe<Channels_Follows>;
  /** fetch data from the table: "chrome_extension_notifications" */
  chrome_extension_notifications: Array<Chrome_Extension_Notifications>;
  /** fetch aggregated fields from the table: "chrome_extension_notifications" */
  chrome_extension_notifications_aggregate: Chrome_Extension_Notifications_Aggregate;
  /** fetch data from the table: "chrome_extension_notifications" using primary key columns */
  chrome_extension_notifications_by_pk?: Maybe<Chrome_Extension_Notifications>;
  /** An array relationship */
  chrome_extension_suggested_videos: Array<Chrome_Extension_Suggested_Videos>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_aggregate: Chrome_Extension_Suggested_Videos_Aggregate;
  /** fetch data from the table: "chrome_extension_suggested_videos" using primary key columns */
  chrome_extension_suggested_videos_by_pk?: Maybe<Chrome_Extension_Suggested_Videos>;
  /** An array relationship */
  chrome_extension_suggested_videos_reviews: Array<Chrome_Extension_Suggested_Videos_Reviews>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_reviews_aggregate: Chrome_Extension_Suggested_Videos_Reviews_Aggregate;
  /** fetch data from the table: "chrome_extension_suggested_videos_reviews" using primary key columns */
  chrome_extension_suggested_videos_reviews_by_pk?: Maybe<Chrome_Extension_Suggested_Videos_Reviews>;
  /** fetch data from the table: "chrome_extension_users" */
  chrome_extension_users: Array<Chrome_Extension_Users>;
  /** fetch aggregated fields from the table: "chrome_extension_users" */
  chrome_extension_users_aggregate: Chrome_Extension_Users_Aggregate;
  /** fetch data from the table: "chrome_extension_users" using primary key columns */
  chrome_extension_users_by_pk?: Maybe<Chrome_Extension_Users>;
  /** fetch data from the table: "coming_soon_videos_reminders" */
  coming_soon_videos_reminders: Array<Coming_Soon_Videos_Reminders>;
  /** fetch aggregated fields from the table: "coming_soon_videos_reminders" */
  coming_soon_videos_reminders_aggregate: Coming_Soon_Videos_Reminders_Aggregate;
  /** fetch data from the table: "coming_soon_videos_reminders" using primary key columns */
  coming_soon_videos_reminders_by_pk?: Maybe<Coming_Soon_Videos_Reminders>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  countUsers: Scalars['Float']['output'];
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  fetchYoutubeVideoGeneralInfo: YoutubeVideoGeneralInfo;
  /** An array relationship */
  follow_requests: Array<Follow_Requests>;
  /** An aggregate relationship */
  follow_requests_aggregate: Follow_Requests_Aggregate;
  /** fetch data from the table: "follow_requests" using primary key columns */
  follow_requests_by_pk?: Maybe<Follow_Requests>;
  /** fetch data from the table: "general_messages" */
  general_messages: Array<General_Messages>;
  /** fetch aggregated fields from the table: "general_messages" */
  general_messages_aggregate: General_Messages_Aggregate;
  /** fetch data from the table: "general_messages" using primary key columns */
  general_messages_by_pk?: Maybe<General_Messages>;
  /** fetch data from the table: "genres" */
  genres: Array<Genres>;
  /** fetch aggregated fields from the table: "genres" */
  genres_aggregate: Genres_Aggregate;
  /** fetch data from the table: "genres" using primary key columns */
  genres_by_pk?: Maybe<Genres>;
  getAllKidsBadgesStatistics: BadgesStatistics;
  getAllKidsSpentTimeStatistics: AllKidsSpentTimeStatistics;
  getAllKidsWatchedCategoriesStatistics: AllKidsWatchedCategoriesStatistics;
  getBadges: BadgesResult;
  getCategoriesUsersStatistics: Array<CategoriesCountStatistics>;
  getCategoriesVideosStatistics: Array<CategoriesCountStatistics>;
  getCategoriesWatchedMinutes: Array<CategoryStatistics>;
  getComingSoonVideos: PaginatedComingSoonVideos;
  getContentStatisticsCount: Array<StatisticsCount>;
  getCreatedUsersMonthsStatistics: Array<MonthStatistics>;
  getEarnedBadges: PaginatedEarnedBadge;
  getEarnedBadgesMonthsStatistics: Array<MonthStatistics>;
  getFollowRequestsCount: FollowRequestsCount;
  getFollowers: PaginatedFollowerResult;
  getFollowingsUpdates: PaginatedFollowingUpdatesVideos;
  getFollows: PaginatedFollowerResult;
  getFollowsCount: FollowsCount;
  getForYouVideos: PaginatedCardVideos;
  getGeneralStatisticsCount: Array<StatisticsCount>;
  getHistory: PaginatedHistoryVideos;
  getKidBadgesStatistics: BadgesStatistics;
  getKidEarnedBadges: PaginatedEarnedBadge;
  getKidFollowRequestsCount: FollowRequestsCount;
  getKidFollowers: PaginatedFollowerResult;
  getKidFollows: PaginatedFollowerResult;
  getKidFollowsCount: FollowsCount;
  getKidHistory: PaginatedHistoryVideos;
  getKidPrivacy: UserPrivacy;
  getKidSharedVideos: PaginatedSharedVideos;
  getKidSpentTimeStatistics: SpentTimeStatistics;
  getKidWatchedCategoriesStatistics: WatchedCategoriesStatistics;
  getLeaderboardRanks: PaginatedLeaderboard;
  getMinutesUsageForAllUsers: Array<DayStatistics>;
  getMoreLikeThisVideos: PaginatedLibraryVideos;
  getNeedsToPublishVideos: NeedsToPublishVideos;
  getNotifications: PaginatedNotifications;
  getPublicAvatars: Array<Scalars['String']['output']>;
  getRandomBadge?: Maybe<BadgeCardItem>;
  getReceivedFollowRequests: PaginatedFollowRequestResult;
  getReceivedFollowRequestsByParent: PaginatedFollowRequestResult;
  getSentFollowRequests: PaginatedFollowRequestResult;
  getSentFollowRequestsByParent: PaginatedFollowRequestResult;
  getSharedVideos: PaginatedSharedVideos;
  getShorts: PaginatedShortVideos;
  getSuggestedVideosReviewsStatistics: SuggestedVideosStatusStatistics;
  getSuggestedVideosReviewsStatisticsByAdmin: SuggestedVideosStatusStatistics;
  getSuggestedVideosStatistics: SuggestedVideosStatusStatistics;
  getSuggestedVideosStatisticsByAdmin: SuggestedVideosStatusStatistics;
  getTopSearches: Array<VideoCardItem>;
  getTrendingNowVideos: PaginatedCardVideos;
  getUserEarnedBadges: PaginatedEarnedBadge;
  getUserFollowers: PaginatedFollowerResult;
  getUserFollowersByParent: PaginatedFollowerResult;
  getUserFollows: PaginatedFollowerResult;
  getUserFollowsByParent: PaginatedFollowerResult;
  getUserFollowsCount: FollowsCount;
  getUserPrivacy: UserPrivacy;
  getUserProfileInfo: UserProfileInfo;
  getUserProfileInfoByParent: UserProfileInfo;
  getUserSharedVideos: PaginatedSharedVideos;
  getUserSharedVideosByParent: PaginatedSharedVideos;
  getUserSpentTimeStatisticsByAdmin: SpentTimeStatistics;
  getUserStatisticsCountByAdmin: Array<StatisticsCount>;
  getUserWatchedCategoriesStatisticsByAdmin: WatchedCategoriesStatistics;
  getUsersAccountStatusStatistics: UsersAccountsStatusStatistics;
  getUsersAgeStatistics: Array<UsersAgeStatistics>;
  getUsersCountriesStatistics: Array<UsersCountriesStatistics>;
  getVideoDetails: VideoDetails;
  getVideoReportingMonthStatistics: Array<MonthReportStatistics>;
  getVideoReportingStatisticsCount: Array<StatisticsCount>;
  /** fetch data from the table: "heared_methods" */
  heared_methods: Array<Heared_Methods>;
  /** fetch aggregated fields from the table: "heared_methods" */
  heared_methods_aggregate: Heared_Methods_Aggregate;
  /** fetch data from the table: "heared_methods" using primary key columns */
  heared_methods_by_pk?: Maybe<Heared_Methods>;
  /** fetch data from the table: "kid_user_settings" */
  kid_user_settings: Array<Kid_User_Settings>;
  /** fetch aggregated fields from the table: "kid_user_settings" */
  kid_user_settings_aggregate: Kid_User_Settings_Aggregate;
  /** fetch data from the table: "kid_user_settings" using primary key columns */
  kid_user_settings_by_pk?: Maybe<Kid_User_Settings>;
  /** fetch data from the table: "kids_parents" */
  kids_parents: Array<Kids_Parents>;
  /** fetch aggregated fields from the table: "kids_parents" */
  kids_parents_aggregate: Kids_Parents_Aggregate;
  /** fetch data from the table: "kids_parents" using primary key columns */
  kids_parents_by_pk?: Maybe<Kids_Parents>;
  /** fetch data from the table: "leaderboard" */
  leaderboard: Array<Leaderboard>;
  /** fetch aggregated fields from the table: "leaderboard" */
  leaderboard_aggregate: Leaderboard_Aggregate;
  /** fetch data from the table: "leaderboard" using primary key columns */
  leaderboard_by_pk?: Maybe<Leaderboard>;
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  likes_by_pk?: Maybe<Likes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "parent_user_settings" */
  parent_user_settings: Array<Parent_User_Settings>;
  /** fetch aggregated fields from the table: "parent_user_settings" */
  parent_user_settings_aggregate: Parent_User_Settings_Aggregate;
  /** fetch data from the table: "parent_user_settings" using primary key columns */
  parent_user_settings_by_pk?: Maybe<Parent_User_Settings>;
  /** fetch data from the table: "privacy_policy" */
  privacy_policy: Array<Privacy_Policy>;
  /** fetch aggregated fields from the table: "privacy_policy" */
  privacy_policy_aggregate: Privacy_Policy_Aggregate;
  /** fetch data from the table: "privacy_policy" using primary key columns */
  privacy_policy_by_pk?: Maybe<Privacy_Policy>;
  /** fetch data from the table: "report_video_methods" */
  report_video_methods: Array<Report_Video_Methods>;
  /** fetch aggregated fields from the table: "report_video_methods" */
  report_video_methods_aggregate: Report_Video_Methods_Aggregate;
  /** fetch data from the table: "report_video_methods" using primary key columns */
  report_video_methods_by_pk?: Maybe<Report_Video_Methods>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles_actions" */
  roles_actions: Array<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles_actions" */
  roles_actions_aggregate: Roles_Actions_Aggregate;
  /** fetch data from the table: "roles_actions" using primary key columns */
  roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  search: PaginatedSearchResult;
  searchAll: SearchAllResult;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** fetch data from the table: "series" */
  series: Array<Series>;
  /** fetch aggregated fields from the table: "series" */
  series_aggregate: Series_Aggregate;
  /** fetch data from the table: "series" using primary key columns */
  series_by_pk?: Maybe<Series>;
  /** fetch data from the table: "suggestion_settings" */
  suggestion_settings: Array<Suggestion_Settings>;
  /** fetch aggregated fields from the table: "suggestion_settings" */
  suggestion_settings_aggregate: Suggestion_Settings_Aggregate;
  /** fetch data from the table: "suggestion_settings" using primary key columns */
  suggestion_settings_by_pk?: Maybe<Suggestion_Settings>;
  /** An array relationship */
  ticket_replies: Array<Ticket_Replies>;
  /** An aggregate relationship */
  ticket_replies_aggregate: Ticket_Replies_Aggregate;
  /** fetch data from the table: "ticket_replies" using primary key columns */
  ticket_replies_by_pk?: Maybe<Ticket_Replies>;
  /** fetch data from the table: "tickets" */
  tickets: Array<Tickets>;
  /** fetch aggregated fields from the table: "tickets" */
  tickets_aggregate: Tickets_Aggregate;
  /** fetch data from the table: "tickets" using primary key columns */
  tickets_by_pk?: Maybe<Tickets>;
  /** fetch data from the table: "user_badges" */
  user_badges: Array<User_Badges>;
  /** fetch aggregated fields from the table: "user_badges" */
  user_badges_aggregate: User_Badges_Aggregate;
  /** fetch data from the table: "user_badges" using primary key columns */
  user_badges_by_pk?: Maybe<User_Badges>;
  /** An array relationship */
  user_playlists: Array<User_Playlists>;
  /** An aggregate relationship */
  user_playlists_aggregate: User_Playlists_Aggregate;
  /** fetch data from the table: "user_playlists" using primary key columns */
  user_playlists_by_pk?: Maybe<User_Playlists>;
  /** An array relationship */
  user_playlists_videos: Array<User_Playlists_Videos>;
  /** An aggregate relationship */
  user_playlists_videos_aggregate: User_Playlists_Videos_Aggregate;
  /** fetch data from the table: "user_playlists_videos" using primary key columns */
  user_playlists_videos_by_pk?: Maybe<User_Playlists_Videos>;
  /** fetch data from the table: "user_searches" */
  user_searches: Array<User_Searches>;
  /** fetch aggregated fields from the table: "user_searches" */
  user_searches_aggregate: User_Searches_Aggregate;
  /** fetch data from the table: "user_searches" using primary key columns */
  user_searches_by_pk?: Maybe<User_Searches>;
  /** fetch data from the table: "user_sessions" */
  user_sessions: Array<User_Sessions>;
  /** fetch aggregated fields from the table: "user_sessions" */
  user_sessions_aggregate: User_Sessions_Aggregate;
  /** fetch data from the table: "user_sessions" using primary key columns */
  user_sessions_by_pk?: Maybe<User_Sessions>;
  /** fetch data from the table: "user_shared_videos" */
  user_shared_videos: Array<User_Shared_Videos>;
  /** fetch aggregated fields from the table: "user_shared_videos" */
  user_shared_videos_aggregate: User_Shared_Videos_Aggregate;
  /** fetch data from the table: "user_shared_videos" using primary key columns */
  user_shared_videos_by_pk?: Maybe<User_Shared_Videos>;
  /** fetch data from the table: "user_watched_badge_videos" */
  user_watched_badge_videos: Array<User_Watched_Badge_Videos>;
  /** fetch aggregated fields from the table: "user_watched_badge_videos" */
  user_watched_badge_videos_aggregate: User_Watched_Badge_Videos_Aggregate;
  /** fetch data from the table: "user_watched_badge_videos" using primary key columns */
  user_watched_badge_videos_by_pk?: Maybe<User_Watched_Badge_Videos>;
  /** fetch data from the table: "user_watched_video_sessions" */
  user_watched_video_sessions: Array<User_Watched_Video_Sessions>;
  /** fetch aggregated fields from the table: "user_watched_video_sessions" */
  user_watched_video_sessions_aggregate: User_Watched_Video_Sessions_Aggregate;
  /** fetch data from the table: "user_watched_video_sessions" using primary key columns */
  user_watched_video_sessions_by_pk?: Maybe<User_Watched_Video_Sessions>;
  /** An array relationship */
  user_watched_videos: Array<User_Watched_Videos>;
  /** An aggregate relationship */
  user_watched_videos_aggregate: User_Watched_Videos_Aggregate;
  /** fetch data from the table: "user_watched_videos" using primary key columns */
  user_watched_videos_by_pk?: Maybe<User_Watched_Videos>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  users_categories: Array<Users_Categories>;
  /** An aggregate relationship */
  users_categories_aggregate: Users_Categories_Aggregate;
  /** fetch data from the table: "users_categories" using primary key columns */
  users_categories_by_pk?: Maybe<Users_Categories>;
  /** An array relationship */
  users_follows: Array<Users_Follows>;
  /** An aggregate relationship */
  users_follows_aggregate: Users_Follows_Aggregate;
  /** fetch data from the table: "users_follows" using primary key columns */
  users_follows_by_pk?: Maybe<Users_Follows>;
  /** fetch data from the table: "users_privacy" */
  users_privacy: Array<Users_Privacy>;
  /** fetch aggregated fields from the table: "users_privacy" */
  users_privacy_aggregate: Users_Privacy_Aggregate;
  /** fetch data from the table: "users_privacy" using primary key columns */
  users_privacy_by_pk?: Maybe<Users_Privacy>;
  /** An array relationship */
  video_reports: Array<Video_Reports>;
  /** An aggregate relationship */
  video_reports_aggregate: Video_Reports_Aggregate;
  /** fetch data from the table: "video_reports" using primary key columns */
  video_reports_by_pk?: Maybe<Video_Reports>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** An array relationship */
  videos_contents: Array<Videos_Contents>;
  /** An aggregate relationship */
  videos_contents_aggregate: Videos_Contents_Aggregate;
  /** fetch data from the table: "videos_contents" using primary key columns */
  videos_contents_by_pk?: Maybe<Videos_Contents>;
};


export type Query_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Query_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Query_RootActions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Query_RootAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Query_RootAdmins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAi_Suggested_VideosArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};


export type Query_RootAi_Suggested_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};


export type Query_RootAi_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAi_Suggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Query_RootAi_Suggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Query_RootAi_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAi_Suggestion_KeywordsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggestion_Keywords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggestion_Keywords_Order_By>>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};


export type Query_RootAi_Suggestion_Keywords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggestion_Keywords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggestion_Keywords_Order_By>>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};


export type Query_RootAi_Suggestion_Keywords_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBadge_VideosArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


export type Query_RootBadge_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


export type Query_RootBadge_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCategories_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


export type Query_RootCategories_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


export type Query_RootCategories_Contents_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChannels_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


export type Query_RootChannels_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


export type Query_RootChannels_Contents_By_PkArgs = {
  channelId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


export type Query_RootChannels_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


export type Query_RootChannels_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


export type Query_RootChannels_Follows_By_PkArgs = {
  channelId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootChrome_Extension_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Notifications_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};


export type Query_RootChrome_Extension_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Notifications_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};


export type Query_RootChrome_Extension_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChrome_Extension_Suggested_VideosArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


export type Query_RootChrome_Extension_Suggested_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


export type Query_RootChrome_Extension_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChrome_Extension_Suggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Query_RootChrome_Extension_Suggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Query_RootChrome_Extension_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChrome_Extension_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Users_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};


export type Query_RootChrome_Extension_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Users_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};


export type Query_RootChrome_Extension_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootComing_Soon_Videos_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Order_By>>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};


export type Query_RootComing_Soon_Videos_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Order_By>>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};


export type Query_RootComing_Soon_Videos_Reminders_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Query_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCountUsersArgs = {
  fetchUsersArgs?: InputMaybe<FetchUsersArgs>;
};


export type Query_RootFaqArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFetchYoutubeVideoGeneralInfoArgs = {
  link: Scalars['String']['input'];
};


export type Query_RootFollow_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


export type Query_RootFollow_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


export type Query_RootFollow_Requests_By_PkArgs = {
  receiverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};


export type Query_RootGeneral_MessagesArgs = {
  distinct_on?: InputMaybe<Array<General_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<General_Messages_Order_By>>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};


export type Query_RootGeneral_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<General_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<General_Messages_Order_By>>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};


export type Query_RootGeneral_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootGenresArgs = {
  distinct_on?: InputMaybe<Array<Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genres_Order_By>>;
  where?: InputMaybe<Genres_Bool_Exp>;
};


export type Query_RootGenres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genres_Order_By>>;
  where?: InputMaybe<Genres_Bool_Exp>;
};


export type Query_RootGenres_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootGetAllKidsSpentTimeStatisticsArgs = {
  datesDto: DatesDto;
};


export type Query_RootGetAllKidsWatchedCategoriesStatisticsArgs = {
  datesDto: DatesDto;
};


export type Query_RootGetCategoriesWatchedMinutesArgs = {
  datesDto: DatesDto;
};


export type Query_RootGetComingSoonVideosArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pagingArgs: PagingArgs;
};


export type Query_RootGetEarnedBadgesArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetFollowersArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetFollowingsUpdatesArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetFollowsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetForYouVideosArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pagingArgs: PagingArgs;
};


export type Query_RootGetHistoryArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidBadgesStatisticsArgs = {
  kidId: Scalars['String']['input'];
};


export type Query_RootGetKidEarnedBadgesArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidFollowRequestsCountArgs = {
  kidId: Scalars['String']['input'];
};


export type Query_RootGetKidFollowersArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidFollowsArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidFollowsCountArgs = {
  kidId: Scalars['String']['input'];
};


export type Query_RootGetKidHistoryArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidPrivacyArgs = {
  kidId: Scalars['String']['input'];
};


export type Query_RootGetKidSharedVideosArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
};


export type Query_RootGetKidSpentTimeStatisticsArgs = {
  getUserStatisticsDto: GetUserStatisticsDto;
};


export type Query_RootGetKidWatchedCategoriesStatisticsArgs = {
  getUserStatisticsDto: GetUserStatisticsDto;
};


export type Query_RootGetLeaderboardRanksArgs = {
  datesDto?: InputMaybe<GetLeaderboardDatesDto>;
  pagingArgs: PagingArgs;
};


export type Query_RootGetMinutesUsageForAllUsersArgs = {
  datesDto: DatesDto;
};


export type Query_RootGetMoreLikeThisVideosArgs = {
  pagingArgs: PagingArgs;
  videoId: Scalars['String']['input'];
};


export type Query_RootGetNotificationsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetReceivedFollowRequestsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetReceivedFollowRequestsByParentArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetSentFollowRequestsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetSentFollowRequestsByParentArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetSharedVideosArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetShortsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetSuggestedVideosReviewsStatisticsByAdminArgs = {
  datesDto: DatesDto;
};


export type Query_RootGetTrendingNowVideosArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pagingArgs: PagingArgs;
};


export type Query_RootGetUserEarnedBadgesArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserFollowersArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserFollowersByParentArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserFollowsArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserFollowsByParentArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserFollowsCountArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserProfileInfoArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserProfileInfoByParentArgs = {
  kidId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserSharedVideosArgs = {
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserSharedVideosByParentArgs = {
  kidId: Scalars['String']['input'];
  pagingArgs: PagingArgs;
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserSpentTimeStatisticsByAdminArgs = {
  getUserStatisticsDto: GetUserStatisticsDto;
};


export type Query_RootGetUserStatisticsCountByAdminArgs = {
  getUserCountStatisticsDto: GetUserCountStatisticsDto;
};


export type Query_RootGetUserWatchedCategoriesStatisticsByAdminArgs = {
  getUserStatisticsDto: GetUserStatisticsDto;
};


export type Query_RootGetVideoDetailsArgs = {
  videoId: Scalars['String']['input'];
};


export type Query_RootGetVideoReportingStatisticsCountArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type Query_RootHeared_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Heared_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Heared_Methods_Order_By>>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};


export type Query_RootHeared_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Heared_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Heared_Methods_Order_By>>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};


export type Query_RootHeared_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootKid_User_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Kid_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kid_User_Settings_Order_By>>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};


export type Query_RootKid_User_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kid_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kid_User_Settings_Order_By>>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};


export type Query_RootKid_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootKids_ParentsArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


export type Query_RootKids_Parents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


export type Query_RootKids_Parents_By_PkArgs = {
  kidId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};


export type Query_RootLeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};


export type Query_RootLeaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};


export type Query_RootLeaderboard_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Query_RootLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Query_RootLikes_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootParent_User_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Parent_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_User_Settings_Order_By>>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};


export type Query_RootParent_User_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_User_Settings_Order_By>>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};


export type Query_RootParent_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPrivacy_PolicyArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Policy_Order_By>>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};


export type Query_RootPrivacy_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Policy_Order_By>>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};


export type Query_RootPrivacy_Policy_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootReport_Video_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Report_Video_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Video_Methods_Order_By>>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};


export type Query_RootReport_Video_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Video_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Video_Methods_Order_By>>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};


export type Query_RootReport_Video_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Query_RootRoles_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Query_RootRoles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSearchArgs = {
  pagingArgs: PagingArgs;
  searchDto: SearchDto;
};


export type Query_RootSearchAllArgs = {
  searchTerm: Scalars['String']['input'];
};


export type Query_RootSeasonsArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


export type Query_RootSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


export type Query_RootSeries_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSuggestion_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Suggestion_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Suggestion_Settings_Order_By>>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};


export type Query_RootSuggestion_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suggestion_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Suggestion_Settings_Order_By>>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};


export type Query_RootSuggestion_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTicket_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


export type Query_RootTicket_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


export type Query_RootTicket_Replies_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTicketsArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tickets_Order_By>>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};


export type Query_RootTickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tickets_Order_By>>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};


export type Query_RootTickets_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Query_RootUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Query_RootUser_Badges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootUser_PlaylistsArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


export type Query_RootUser_Playlists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


export type Query_RootUser_Playlists_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_Playlists_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


export type Query_RootUser_Playlists_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


export type Query_RootUser_Playlists_Videos_By_PkArgs = {
  playlistId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Query_RootUser_SearchesArgs = {
  distinct_on?: InputMaybe<Array<User_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Searches_Order_By>>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};


export type Query_RootUser_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Searches_Order_By>>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};


export type Query_RootUser_Searches_By_PkArgs = {
  search: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Query_RootUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Query_RootUser_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_Shared_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Shared_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Shared_Videos_Order_By>>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};


export type Query_RootUser_Shared_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Shared_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Shared_Videos_Order_By>>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};


export type Query_RootUser_Shared_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Query_RootUser_Watched_Badge_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Badge_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};


export type Query_RootUser_Watched_Badge_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Badge_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};


export type Query_RootUser_Watched_Badge_Videos_By_PkArgs = {
  badgeVideoId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootUser_Watched_Video_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Video_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Video_Sessions_Order_By>>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};


export type Query_RootUser_Watched_Video_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Video_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Video_Sessions_Order_By>>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};


export type Query_RootUser_Watched_Video_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_Watched_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


export type Query_RootUser_Watched_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


export type Query_RootUser_Watched_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUsers_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


export type Query_RootUsers_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


export type Query_RootUsers_Categories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootUsers_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


export type Query_RootUsers_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


export type Query_RootUsers_Follows_By_PkArgs = {
  followeeId: Scalars['String']['input'];
  followerId: Scalars['String']['input'];
};


export type Query_RootUsers_PrivacyArgs = {
  distinct_on?: InputMaybe<Array<Users_Privacy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Privacy_Order_By>>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};


export type Query_RootUsers_Privacy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Privacy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Privacy_Order_By>>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};


export type Query_RootUsers_Privacy_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVideo_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


export type Query_RootVideo_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


export type Query_RootVideo_Reports_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVideos_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


export type Query_RootVideos_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


export type Query_RootVideos_Contents_By_PkArgs = {
  contentId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** columns and relationships of "report_video_methods" */
export type Report_Video_Methods = {
  __typename?: 'report_video_methods';
  count: Scalars['Int']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "report_video_methods" */
export type Report_Video_MethodsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "report_video_methods" */
export type Report_Video_Methods_Aggregate = {
  __typename?: 'report_video_methods_aggregate';
  aggregate?: Maybe<Report_Video_Methods_Aggregate_Fields>;
  nodes: Array<Report_Video_Methods>;
};

/** aggregate fields of "report_video_methods" */
export type Report_Video_Methods_Aggregate_Fields = {
  __typename?: 'report_video_methods_aggregate_fields';
  avg?: Maybe<Report_Video_Methods_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Report_Video_Methods_Max_Fields>;
  min?: Maybe<Report_Video_Methods_Min_Fields>;
  stddev?: Maybe<Report_Video_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Video_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Video_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Video_Methods_Sum_Fields>;
  var_pop?: Maybe<Report_Video_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Video_Methods_Var_Samp_Fields>;
  variance?: Maybe<Report_Video_Methods_Variance_Fields>;
};


/** aggregate fields of "report_video_methods" */
export type Report_Video_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Video_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Report_Video_Methods_Append_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Report_Video_Methods_Avg_Fields = {
  __typename?: 'report_video_methods_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "report_video_methods". All fields are combined with a logical 'AND'. */
export type Report_Video_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Video_Methods_Bool_Exp>>;
  _not?: InputMaybe<Report_Video_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Video_Methods_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "report_video_methods" */
export enum Report_Video_Methods_Constraint {
  /** unique or primary key constraint on columns "name" */
  ReportVideoMethodsNameKey = 'report_video_methods_name_key',
  /** unique or primary key constraint on columns "id" */
  ReportVideoMethodsPkey = 'report_video_methods_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Report_Video_Methods_Delete_At_Path_Input = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Report_Video_Methods_Delete_Elem_Input = {
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Report_Video_Methods_Delete_Key_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "report_video_methods" */
export type Report_Video_Methods_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "report_video_methods" */
export type Report_Video_Methods_Insert_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Report_Video_Methods_Max_Fields = {
  __typename?: 'report_video_methods_max_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Report_Video_Methods_Min_Fields = {
  __typename?: 'report_video_methods_min_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "report_video_methods" */
export type Report_Video_Methods_Mutation_Response = {
  __typename?: 'report_video_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Video_Methods>;
};

/** on_conflict condition type for table "report_video_methods" */
export type Report_Video_Methods_On_Conflict = {
  constraint: Report_Video_Methods_Constraint;
  update_columns?: Array<Report_Video_Methods_Update_Column>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "report_video_methods". */
export type Report_Video_Methods_Order_By = {
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: report_video_methods */
export type Report_Video_Methods_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Report_Video_Methods_Prepend_Input = {
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "report_video_methods" */
export enum Report_Video_Methods_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "report_video_methods" */
export type Report_Video_Methods_Set_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Report_Video_Methods_Stddev_Fields = {
  __typename?: 'report_video_methods_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Report_Video_Methods_Stddev_Pop_Fields = {
  __typename?: 'report_video_methods_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Report_Video_Methods_Stddev_Samp_Fields = {
  __typename?: 'report_video_methods_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "report_video_methods" */
export type Report_Video_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Video_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Video_Methods_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Report_Video_Methods_Sum_Fields = {
  __typename?: 'report_video_methods_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "report_video_methods" */
export enum Report_Video_Methods_Update_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Report_Video_Methods_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Report_Video_Methods_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Report_Video_Methods_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Report_Video_Methods_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Report_Video_Methods_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Report_Video_Methods_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Report_Video_Methods_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Video_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Video_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Report_Video_Methods_Var_Pop_Fields = {
  __typename?: 'report_video_methods_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Report_Video_Methods_Var_Samp_Fields = {
  __typename?: 'report_video_methods_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Report_Video_Methods_Variance_Fields = {
  __typename?: 'report_video_methods_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  role_actions: Array<Roles_Actions>;
  /** An aggregate relationship */
  role_actions_aggregate: Roles_Actions_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "roles" */
export type RolesAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRole_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRole_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};

/** columns and relationships of "roles_actions" */
export type Roles_Actions = {
  __typename?: 'roles_actions';
  /** An object relationship */
  action: Actions;
  actionId: Scalars['String']['output'];
  /** An object relationship */
  role: Roles;
  roleId: Scalars['String']['output'];
};

/** aggregated selection of "roles_actions" */
export type Roles_Actions_Aggregate = {
  __typename?: 'roles_actions_aggregate';
  aggregate?: Maybe<Roles_Actions_Aggregate_Fields>;
  nodes: Array<Roles_Actions>;
};

export type Roles_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Roles_Actions_Aggregate_Bool_Exp_Count>;
};

export type Roles_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Roles_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "roles_actions" */
export type Roles_Actions_Aggregate_Fields = {
  __typename?: 'roles_actions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Actions_Max_Fields>;
  min?: Maybe<Roles_Actions_Min_Fields>;
};


/** aggregate fields of "roles_actions" */
export type Roles_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "roles_actions" */
export type Roles_Actions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Actions_Max_Order_By>;
  min?: InputMaybe<Roles_Actions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles_actions" */
export type Roles_Actions_Arr_Rel_Insert_Input = {
  data: Array<Roles_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles_actions". All fields are combined with a logical 'AND'. */
export type Roles_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Actions_Bool_Exp>>;
  _not?: InputMaybe<Roles_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Actions_Bool_Exp>>;
  action?: InputMaybe<Actions_Bool_Exp>;
  actionId?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  roleId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles_actions" */
export enum Roles_Actions_Constraint {
  /** unique or primary key constraint on columns "roleId", "actionId" */
  RolesActionsPkey = 'roles_actions_pkey'
}

/** input type for inserting data into table "roles_actions" */
export type Roles_Actions_Insert_Input = {
  action?: InputMaybe<Actions_Obj_Rel_Insert_Input>;
  actionId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Roles_Actions_Max_Fields = {
  __typename?: 'roles_actions_max_fields';
  actionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "roles_actions" */
export type Roles_Actions_Max_Order_By = {
  actionId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Actions_Min_Fields = {
  __typename?: 'roles_actions_min_fields';
  actionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "roles_actions" */
export type Roles_Actions_Min_Order_By = {
  actionId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "roles_actions" */
export type Roles_Actions_Mutation_Response = {
  __typename?: 'roles_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles_Actions>;
};

/** on_conflict condition type for table "roles_actions" */
export type Roles_Actions_On_Conflict = {
  constraint: Roles_Actions_Constraint;
  update_columns?: Array<Roles_Actions_Update_Column>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "roles_actions". */
export type Roles_Actions_Order_By = {
  action?: InputMaybe<Actions_Order_By>;
  actionId?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles_actions */
export type Roles_Actions_Pk_Columns_Input = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

/** select columns of table "roles_actions" */
export enum Roles_Actions_Select_Column {
  /** column name */
  ActionId = 'actionId',
  /** column name */
  RoleId = 'roleId'
}

/** input type for updating data in table "roles_actions" */
export type Roles_Actions_Set_Input = {
  actionId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "roles_actions" */
export type Roles_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Actions_Stream_Cursor_Value_Input = {
  actionId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "roles_actions" */
export enum Roles_Actions_Update_Column {
  /** column name */
  ActionId = 'actionId',
  /** column name */
  RoleId = 'roleId'
}

export type Roles_Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Actions_Bool_Exp;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  admins?: InputMaybe<Admins_Bool_Exp>;
  admins_aggregate?: InputMaybe<Admins_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_actions?: InputMaybe<Roles_Actions_Bool_Exp>;
  role_actions_aggregate?: InputMaybe<Roles_Actions_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolesNameKey = 'roles_name_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  admins?: InputMaybe<Admins_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_actions?: InputMaybe<Roles_Actions_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  admins_aggregate?: InputMaybe<Admins_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_actions_aggregate?: InputMaybe<Roles_Actions_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "seasons" */
export type Seasons = {
  __typename?: 'seasons';
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  serieId: Scalars['String']['output'];
  /** An object relationship */
  series: Series;
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
};


/** columns and relationships of "seasons" */
export type SeasonsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "seasons" */
export type SeasonsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "seasons" */
export type SeasonsVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


/** columns and relationships of "seasons" */
export type SeasonsVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** aggregated selection of "seasons" */
export type Seasons_Aggregate = {
  __typename?: 'seasons_aggregate';
  aggregate?: Maybe<Seasons_Aggregate_Fields>;
  nodes: Array<Seasons>;
};

export type Seasons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Seasons_Aggregate_Bool_Exp_Count>;
};

export type Seasons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Seasons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Seasons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "seasons" */
export type Seasons_Aggregate_Fields = {
  __typename?: 'seasons_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Seasons_Max_Fields>;
  min?: Maybe<Seasons_Min_Fields>;
};


/** aggregate fields of "seasons" */
export type Seasons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seasons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "seasons" */
export type Seasons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seasons_Max_Order_By>;
  min?: InputMaybe<Seasons_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Seasons_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "seasons" */
export type Seasons_Arr_Rel_Insert_Input = {
  data: Array<Seasons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Seasons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "seasons". All fields are combined with a logical 'AND'. */
export type Seasons_Bool_Exp = {
  _and?: InputMaybe<Array<Seasons_Bool_Exp>>;
  _not?: InputMaybe<Seasons_Bool_Exp>;
  _or?: InputMaybe<Array<Seasons_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  serieId?: InputMaybe<String_Comparison_Exp>;
  series?: InputMaybe<Series_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  videos?: InputMaybe<Videos_Bool_Exp>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "seasons" */
export enum Seasons_Constraint {
  /** unique or primary key constraint on columns "serieId", "name" */
  SeasonsNameSerieIdKey = 'seasons_name_serieId_key',
  /** unique or primary key constraint on columns "id" */
  SeasonsPkey = 'seasons_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Seasons_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Seasons_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Seasons_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "seasons" */
export type Seasons_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  serieId?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Series_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videos?: InputMaybe<Videos_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Seasons_Max_Fields = {
  __typename?: 'seasons_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  serieId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "seasons" */
export type Seasons_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serieId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seasons_Min_Fields = {
  __typename?: 'seasons_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  serieId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "seasons" */
export type Seasons_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serieId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "seasons" */
export type Seasons_Mutation_Response = {
  __typename?: 'seasons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Seasons>;
};

/** input type for inserting object relation for remote table "seasons" */
export type Seasons_Obj_Rel_Insert_Input = {
  data: Seasons_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Seasons_On_Conflict>;
};

/** on_conflict condition type for table "seasons" */
export type Seasons_On_Conflict = {
  constraint: Seasons_Constraint;
  update_columns?: Array<Seasons_Update_Column>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};

/** Ordering options when selecting data from "seasons". */
export type Seasons_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serieId?: InputMaybe<Order_By>;
  series?: InputMaybe<Series_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Order_By>;
};

/** primary key columns input for table: seasons */
export type Seasons_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Seasons_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "seasons" */
export enum Seasons_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SerieId = 'serieId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "seasons" */
export type Seasons_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  serieId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "seasons" */
export type Seasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Seasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Seasons_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  serieId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "seasons" */
export enum Seasons_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SerieId = 'serieId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Seasons_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Seasons_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Seasons_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Seasons_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Seasons_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Seasons_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Seasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Seasons_Bool_Exp;
};

/** columns and relationships of "series" */
export type Series = {
  __typename?: 'series';
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "series" */
export type SeriesDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "series" */
export type SeriesNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "series" */
export type SeriesSeasonsArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


/** columns and relationships of "series" */
export type SeriesSeasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};

/** aggregated selection of "series" */
export type Series_Aggregate = {
  __typename?: 'series_aggregate';
  aggregate?: Maybe<Series_Aggregate_Fields>;
  nodes: Array<Series>;
};

/** aggregate fields of "series" */
export type Series_Aggregate_Fields = {
  __typename?: 'series_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Series_Max_Fields>;
  min?: Maybe<Series_Min_Fields>;
};


/** aggregate fields of "series" */
export type Series_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Series_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Series_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "series". All fields are combined with a logical 'AND'. */
export type Series_Bool_Exp = {
  _and?: InputMaybe<Array<Series_Bool_Exp>>;
  _not?: InputMaybe<Series_Bool_Exp>;
  _or?: InputMaybe<Array<Series_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  seasons?: InputMaybe<Seasons_Bool_Exp>;
  seasons_aggregate?: InputMaybe<Seasons_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "series" */
export enum Series_Constraint {
  /** unique or primary key constraint on columns "name" */
  SeriesNameKey = 'series_name_key',
  /** unique or primary key constraint on columns "id" */
  SeriesPkey = 'series_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Series_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Series_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Series_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "series" */
export type Series_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  seasons?: InputMaybe<Seasons_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Series_Max_Fields = {
  __typename?: 'series_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Series_Min_Fields = {
  __typename?: 'series_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "series" */
export type Series_Mutation_Response = {
  __typename?: 'series_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Series>;
};

/** input type for inserting object relation for remote table "series" */
export type Series_Obj_Rel_Insert_Input = {
  data: Series_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Series_On_Conflict>;
};

/** on_conflict condition type for table "series" */
export type Series_On_Conflict = {
  constraint: Series_Constraint;
  update_columns?: Array<Series_Update_Column>;
  where?: InputMaybe<Series_Bool_Exp>;
};

/** Ordering options when selecting data from "series". */
export type Series_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  seasons_aggregate?: InputMaybe<Seasons_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: series */
export type Series_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Series_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "series" */
export enum Series_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "series" */
export type Series_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "series" */
export type Series_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Series_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Series_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "series" */
export enum Series_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Series_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Series_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Series_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Series_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Series_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Series_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Series_Set_Input>;
  /** filter the rows which have to be updated */
  where: Series_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream: Array<Actions>;
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>;
  /** fetch data from the table in a streaming manner: "admins" */
  admins_stream: Array<Admins>;
  /** fetch data from the table: "ai_suggested_videos" */
  ai_suggested_videos: Array<Ai_Suggested_Videos>;
  /** fetch aggregated fields from the table: "ai_suggested_videos" */
  ai_suggested_videos_aggregate: Ai_Suggested_Videos_Aggregate;
  /** fetch data from the table: "ai_suggested_videos" using primary key columns */
  ai_suggested_videos_by_pk?: Maybe<Ai_Suggested_Videos>;
  /** fetch data from the table: "ai_suggested_videos_reviews" */
  ai_suggested_videos_reviews: Array<Ai_Suggested_Videos_Reviews>;
  /** fetch aggregated fields from the table: "ai_suggested_videos_reviews" */
  ai_suggested_videos_reviews_aggregate: Ai_Suggested_Videos_Reviews_Aggregate;
  /** fetch data from the table: "ai_suggested_videos_reviews" using primary key columns */
  ai_suggested_videos_reviews_by_pk?: Maybe<Ai_Suggested_Videos_Reviews>;
  /** fetch data from the table in a streaming manner: "ai_suggested_videos_reviews" */
  ai_suggested_videos_reviews_stream: Array<Ai_Suggested_Videos_Reviews>;
  /** fetch data from the table in a streaming manner: "ai_suggested_videos" */
  ai_suggested_videos_stream: Array<Ai_Suggested_Videos>;
  /** fetch data from the table: "ai_suggestion_keywords" */
  ai_suggestion_keywords: Array<Ai_Suggestion_Keywords>;
  /** fetch aggregated fields from the table: "ai_suggestion_keywords" */
  ai_suggestion_keywords_aggregate: Ai_Suggestion_Keywords_Aggregate;
  /** fetch data from the table: "ai_suggestion_keywords" using primary key columns */
  ai_suggestion_keywords_by_pk?: Maybe<Ai_Suggestion_Keywords>;
  /** fetch data from the table in a streaming manner: "ai_suggestion_keywords" */
  ai_suggestion_keywords_stream: Array<Ai_Suggestion_Keywords>;
  /** An array relationship */
  badge_videos: Array<Badge_Videos>;
  /** An aggregate relationship */
  badge_videos_aggregate: Badge_Videos_Aggregate;
  /** fetch data from the table: "badge_videos" using primary key columns */
  badge_videos_by_pk?: Maybe<Badge_Videos>;
  /** fetch data from the table in a streaming manner: "badge_videos" */
  badge_videos_stream: Array<Badge_Videos>;
  /** fetch data from the table: "badges" */
  badges: Array<Badges>;
  /** fetch aggregated fields from the table: "badges" */
  badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table in a streaming manner: "badges" */
  badges_stream: Array<Badges>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  categories_contents: Array<Categories_Contents>;
  /** An aggregate relationship */
  categories_contents_aggregate: Categories_Contents_Aggregate;
  /** fetch data from the table: "categories_contents" using primary key columns */
  categories_contents_by_pk?: Maybe<Categories_Contents>;
  /** fetch data from the table in a streaming manner: "categories_contents" */
  categories_contents_stream: Array<Categories_Contents>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** An array relationship */
  channels_contents: Array<Channels_Contents>;
  /** An aggregate relationship */
  channels_contents_aggregate: Channels_Contents_Aggregate;
  /** fetch data from the table: "channels_contents" using primary key columns */
  channels_contents_by_pk?: Maybe<Channels_Contents>;
  /** fetch data from the table in a streaming manner: "channels_contents" */
  channels_contents_stream: Array<Channels_Contents>;
  /** An array relationship */
  channels_follows: Array<Channels_Follows>;
  /** An aggregate relationship */
  channels_follows_aggregate: Channels_Follows_Aggregate;
  /** fetch data from the table: "channels_follows" using primary key columns */
  channels_follows_by_pk?: Maybe<Channels_Follows>;
  /** fetch data from the table in a streaming manner: "channels_follows" */
  channels_follows_stream: Array<Channels_Follows>;
  /** fetch data from the table in a streaming manner: "channels" */
  channels_stream: Array<Channels>;
  /** fetch data from the table: "chrome_extension_notifications" */
  chrome_extension_notifications: Array<Chrome_Extension_Notifications>;
  /** fetch aggregated fields from the table: "chrome_extension_notifications" */
  chrome_extension_notifications_aggregate: Chrome_Extension_Notifications_Aggregate;
  /** fetch data from the table: "chrome_extension_notifications" using primary key columns */
  chrome_extension_notifications_by_pk?: Maybe<Chrome_Extension_Notifications>;
  /** fetch data from the table in a streaming manner: "chrome_extension_notifications" */
  chrome_extension_notifications_stream: Array<Chrome_Extension_Notifications>;
  /** An array relationship */
  chrome_extension_suggested_videos: Array<Chrome_Extension_Suggested_Videos>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_aggregate: Chrome_Extension_Suggested_Videos_Aggregate;
  /** fetch data from the table: "chrome_extension_suggested_videos" using primary key columns */
  chrome_extension_suggested_videos_by_pk?: Maybe<Chrome_Extension_Suggested_Videos>;
  /** An array relationship */
  chrome_extension_suggested_videos_reviews: Array<Chrome_Extension_Suggested_Videos_Reviews>;
  /** An aggregate relationship */
  chrome_extension_suggested_videos_reviews_aggregate: Chrome_Extension_Suggested_Videos_Reviews_Aggregate;
  /** fetch data from the table: "chrome_extension_suggested_videos_reviews" using primary key columns */
  chrome_extension_suggested_videos_reviews_by_pk?: Maybe<Chrome_Extension_Suggested_Videos_Reviews>;
  /** fetch data from the table in a streaming manner: "chrome_extension_suggested_videos_reviews" */
  chrome_extension_suggested_videos_reviews_stream: Array<Chrome_Extension_Suggested_Videos_Reviews>;
  /** fetch data from the table in a streaming manner: "chrome_extension_suggested_videos" */
  chrome_extension_suggested_videos_stream: Array<Chrome_Extension_Suggested_Videos>;
  /** fetch data from the table: "chrome_extension_users" */
  chrome_extension_users: Array<Chrome_Extension_Users>;
  /** fetch aggregated fields from the table: "chrome_extension_users" */
  chrome_extension_users_aggregate: Chrome_Extension_Users_Aggregate;
  /** fetch data from the table: "chrome_extension_users" using primary key columns */
  chrome_extension_users_by_pk?: Maybe<Chrome_Extension_Users>;
  /** fetch data from the table in a streaming manner: "chrome_extension_users" */
  chrome_extension_users_stream: Array<Chrome_Extension_Users>;
  /** fetch data from the table: "coming_soon_videos_reminders" */
  coming_soon_videos_reminders: Array<Coming_Soon_Videos_Reminders>;
  /** fetch aggregated fields from the table: "coming_soon_videos_reminders" */
  coming_soon_videos_reminders_aggregate: Coming_Soon_Videos_Reminders_Aggregate;
  /** fetch data from the table: "coming_soon_videos_reminders" using primary key columns */
  coming_soon_videos_reminders_by_pk?: Maybe<Coming_Soon_Videos_Reminders>;
  /** fetch data from the table in a streaming manner: "coming_soon_videos_reminders" */
  coming_soon_videos_reminders_stream: Array<Coming_Soon_Videos_Reminders>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** fetch data from the table in a streaming manner: "contents" */
  contents_stream: Array<Contents>;
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  /** fetch data from the table in a streaming manner: "faq" */
  faq_stream: Array<Faq>;
  /** An array relationship */
  follow_requests: Array<Follow_Requests>;
  /** An aggregate relationship */
  follow_requests_aggregate: Follow_Requests_Aggregate;
  /** fetch data from the table: "follow_requests" using primary key columns */
  follow_requests_by_pk?: Maybe<Follow_Requests>;
  /** fetch data from the table in a streaming manner: "follow_requests" */
  follow_requests_stream: Array<Follow_Requests>;
  /** fetch data from the table: "general_messages" */
  general_messages: Array<General_Messages>;
  /** fetch aggregated fields from the table: "general_messages" */
  general_messages_aggregate: General_Messages_Aggregate;
  /** fetch data from the table: "general_messages" using primary key columns */
  general_messages_by_pk?: Maybe<General_Messages>;
  /** fetch data from the table in a streaming manner: "general_messages" */
  general_messages_stream: Array<General_Messages>;
  /** fetch data from the table: "genres" */
  genres: Array<Genres>;
  /** fetch aggregated fields from the table: "genres" */
  genres_aggregate: Genres_Aggregate;
  /** fetch data from the table: "genres" using primary key columns */
  genres_by_pk?: Maybe<Genres>;
  /** fetch data from the table in a streaming manner: "genres" */
  genres_stream: Array<Genres>;
  /** fetch data from the table: "heared_methods" */
  heared_methods: Array<Heared_Methods>;
  /** fetch aggregated fields from the table: "heared_methods" */
  heared_methods_aggregate: Heared_Methods_Aggregate;
  /** fetch data from the table: "heared_methods" using primary key columns */
  heared_methods_by_pk?: Maybe<Heared_Methods>;
  /** fetch data from the table in a streaming manner: "heared_methods" */
  heared_methods_stream: Array<Heared_Methods>;
  /** fetch data from the table: "kid_user_settings" */
  kid_user_settings: Array<Kid_User_Settings>;
  /** fetch aggregated fields from the table: "kid_user_settings" */
  kid_user_settings_aggregate: Kid_User_Settings_Aggregate;
  /** fetch data from the table: "kid_user_settings" using primary key columns */
  kid_user_settings_by_pk?: Maybe<Kid_User_Settings>;
  /** fetch data from the table in a streaming manner: "kid_user_settings" */
  kid_user_settings_stream: Array<Kid_User_Settings>;
  /** fetch data from the table: "kids_parents" */
  kids_parents: Array<Kids_Parents>;
  /** fetch aggregated fields from the table: "kids_parents" */
  kids_parents_aggregate: Kids_Parents_Aggregate;
  /** fetch data from the table: "kids_parents" using primary key columns */
  kids_parents_by_pk?: Maybe<Kids_Parents>;
  /** fetch data from the table in a streaming manner: "kids_parents" */
  kids_parents_stream: Array<Kids_Parents>;
  /** fetch data from the table: "leaderboard" */
  leaderboard: Array<Leaderboard>;
  /** fetch aggregated fields from the table: "leaderboard" */
  leaderboard_aggregate: Leaderboard_Aggregate;
  /** fetch data from the table: "leaderboard" using primary key columns */
  leaderboard_by_pk?: Maybe<Leaderboard>;
  /** fetch data from the table in a streaming manner: "leaderboard" */
  leaderboard_stream: Array<Leaderboard>;
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  likes_by_pk?: Maybe<Likes>;
  /** fetch data from the table in a streaming manner: "likes" */
  likes_stream: Array<Likes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "parent_user_settings" */
  parent_user_settings: Array<Parent_User_Settings>;
  /** fetch aggregated fields from the table: "parent_user_settings" */
  parent_user_settings_aggregate: Parent_User_Settings_Aggregate;
  /** fetch data from the table: "parent_user_settings" using primary key columns */
  parent_user_settings_by_pk?: Maybe<Parent_User_Settings>;
  /** fetch data from the table in a streaming manner: "parent_user_settings" */
  parent_user_settings_stream: Array<Parent_User_Settings>;
  /** fetch data from the table: "privacy_policy" */
  privacy_policy: Array<Privacy_Policy>;
  /** fetch aggregated fields from the table: "privacy_policy" */
  privacy_policy_aggregate: Privacy_Policy_Aggregate;
  /** fetch data from the table: "privacy_policy" using primary key columns */
  privacy_policy_by_pk?: Maybe<Privacy_Policy>;
  /** fetch data from the table in a streaming manner: "privacy_policy" */
  privacy_policy_stream: Array<Privacy_Policy>;
  /** fetch data from the table: "report_video_methods" */
  report_video_methods: Array<Report_Video_Methods>;
  /** fetch aggregated fields from the table: "report_video_methods" */
  report_video_methods_aggregate: Report_Video_Methods_Aggregate;
  /** fetch data from the table: "report_video_methods" using primary key columns */
  report_video_methods_by_pk?: Maybe<Report_Video_Methods>;
  /** fetch data from the table in a streaming manner: "report_video_methods" */
  report_video_methods_stream: Array<Report_Video_Methods>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles_actions" */
  roles_actions: Array<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles_actions" */
  roles_actions_aggregate: Roles_Actions_Aggregate;
  /** fetch data from the table: "roles_actions" using primary key columns */
  roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** fetch data from the table in a streaming manner: "roles_actions" */
  roles_actions_stream: Array<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** fetch data from the table in a streaming manner: "seasons" */
  seasons_stream: Array<Seasons>;
  /** fetch data from the table: "series" */
  series: Array<Series>;
  /** fetch aggregated fields from the table: "series" */
  series_aggregate: Series_Aggregate;
  /** fetch data from the table: "series" using primary key columns */
  series_by_pk?: Maybe<Series>;
  /** fetch data from the table in a streaming manner: "series" */
  series_stream: Array<Series>;
  /** fetch data from the table: "suggestion_settings" */
  suggestion_settings: Array<Suggestion_Settings>;
  /** fetch aggregated fields from the table: "suggestion_settings" */
  suggestion_settings_aggregate: Suggestion_Settings_Aggregate;
  /** fetch data from the table: "suggestion_settings" using primary key columns */
  suggestion_settings_by_pk?: Maybe<Suggestion_Settings>;
  /** fetch data from the table in a streaming manner: "suggestion_settings" */
  suggestion_settings_stream: Array<Suggestion_Settings>;
  /** An array relationship */
  ticket_replies: Array<Ticket_Replies>;
  /** An aggregate relationship */
  ticket_replies_aggregate: Ticket_Replies_Aggregate;
  /** fetch data from the table: "ticket_replies" using primary key columns */
  ticket_replies_by_pk?: Maybe<Ticket_Replies>;
  /** fetch data from the table in a streaming manner: "ticket_replies" */
  ticket_replies_stream: Array<Ticket_Replies>;
  /** fetch data from the table: "tickets" */
  tickets: Array<Tickets>;
  /** fetch aggregated fields from the table: "tickets" */
  tickets_aggregate: Tickets_Aggregate;
  /** fetch data from the table: "tickets" using primary key columns */
  tickets_by_pk?: Maybe<Tickets>;
  /** fetch data from the table in a streaming manner: "tickets" */
  tickets_stream: Array<Tickets>;
  /** fetch data from the table: "user_badges" */
  user_badges: Array<User_Badges>;
  /** fetch aggregated fields from the table: "user_badges" */
  user_badges_aggregate: User_Badges_Aggregate;
  /** fetch data from the table: "user_badges" using primary key columns */
  user_badges_by_pk?: Maybe<User_Badges>;
  /** fetch data from the table in a streaming manner: "user_badges" */
  user_badges_stream: Array<User_Badges>;
  /** An array relationship */
  user_playlists: Array<User_Playlists>;
  /** An aggregate relationship */
  user_playlists_aggregate: User_Playlists_Aggregate;
  /** fetch data from the table: "user_playlists" using primary key columns */
  user_playlists_by_pk?: Maybe<User_Playlists>;
  /** fetch data from the table in a streaming manner: "user_playlists" */
  user_playlists_stream: Array<User_Playlists>;
  /** An array relationship */
  user_playlists_videos: Array<User_Playlists_Videos>;
  /** An aggregate relationship */
  user_playlists_videos_aggregate: User_Playlists_Videos_Aggregate;
  /** fetch data from the table: "user_playlists_videos" using primary key columns */
  user_playlists_videos_by_pk?: Maybe<User_Playlists_Videos>;
  /** fetch data from the table in a streaming manner: "user_playlists_videos" */
  user_playlists_videos_stream: Array<User_Playlists_Videos>;
  /** fetch data from the table: "user_searches" */
  user_searches: Array<User_Searches>;
  /** fetch aggregated fields from the table: "user_searches" */
  user_searches_aggregate: User_Searches_Aggregate;
  /** fetch data from the table: "user_searches" using primary key columns */
  user_searches_by_pk?: Maybe<User_Searches>;
  /** fetch data from the table in a streaming manner: "user_searches" */
  user_searches_stream: Array<User_Searches>;
  /** fetch data from the table: "user_sessions" */
  user_sessions: Array<User_Sessions>;
  /** fetch aggregated fields from the table: "user_sessions" */
  user_sessions_aggregate: User_Sessions_Aggregate;
  /** fetch data from the table: "user_sessions" using primary key columns */
  user_sessions_by_pk?: Maybe<User_Sessions>;
  /** fetch data from the table in a streaming manner: "user_sessions" */
  user_sessions_stream: Array<User_Sessions>;
  /** fetch data from the table: "user_shared_videos" */
  user_shared_videos: Array<User_Shared_Videos>;
  /** fetch aggregated fields from the table: "user_shared_videos" */
  user_shared_videos_aggregate: User_Shared_Videos_Aggregate;
  /** fetch data from the table: "user_shared_videos" using primary key columns */
  user_shared_videos_by_pk?: Maybe<User_Shared_Videos>;
  /** fetch data from the table in a streaming manner: "user_shared_videos" */
  user_shared_videos_stream: Array<User_Shared_Videos>;
  /** fetch data from the table: "user_watched_badge_videos" */
  user_watched_badge_videos: Array<User_Watched_Badge_Videos>;
  /** fetch aggregated fields from the table: "user_watched_badge_videos" */
  user_watched_badge_videos_aggregate: User_Watched_Badge_Videos_Aggregate;
  /** fetch data from the table: "user_watched_badge_videos" using primary key columns */
  user_watched_badge_videos_by_pk?: Maybe<User_Watched_Badge_Videos>;
  /** fetch data from the table in a streaming manner: "user_watched_badge_videos" */
  user_watched_badge_videos_stream: Array<User_Watched_Badge_Videos>;
  /** fetch data from the table: "user_watched_video_sessions" */
  user_watched_video_sessions: Array<User_Watched_Video_Sessions>;
  /** fetch aggregated fields from the table: "user_watched_video_sessions" */
  user_watched_video_sessions_aggregate: User_Watched_Video_Sessions_Aggregate;
  /** fetch data from the table: "user_watched_video_sessions" using primary key columns */
  user_watched_video_sessions_by_pk?: Maybe<User_Watched_Video_Sessions>;
  /** fetch data from the table in a streaming manner: "user_watched_video_sessions" */
  user_watched_video_sessions_stream: Array<User_Watched_Video_Sessions>;
  /** An array relationship */
  user_watched_videos: Array<User_Watched_Videos>;
  /** An aggregate relationship */
  user_watched_videos_aggregate: User_Watched_Videos_Aggregate;
  /** fetch data from the table: "user_watched_videos" using primary key columns */
  user_watched_videos_by_pk?: Maybe<User_Watched_Videos>;
  /** fetch data from the table in a streaming manner: "user_watched_videos" */
  user_watched_videos_stream: Array<User_Watched_Videos>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  users_categories: Array<Users_Categories>;
  /** An aggregate relationship */
  users_categories_aggregate: Users_Categories_Aggregate;
  /** fetch data from the table: "users_categories" using primary key columns */
  users_categories_by_pk?: Maybe<Users_Categories>;
  /** fetch data from the table in a streaming manner: "users_categories" */
  users_categories_stream: Array<Users_Categories>;
  /** An array relationship */
  users_follows: Array<Users_Follows>;
  /** An aggregate relationship */
  users_follows_aggregate: Users_Follows_Aggregate;
  /** fetch data from the table: "users_follows" using primary key columns */
  users_follows_by_pk?: Maybe<Users_Follows>;
  /** fetch data from the table in a streaming manner: "users_follows" */
  users_follows_stream: Array<Users_Follows>;
  /** fetch data from the table: "users_privacy" */
  users_privacy: Array<Users_Privacy>;
  /** fetch aggregated fields from the table: "users_privacy" */
  users_privacy_aggregate: Users_Privacy_Aggregate;
  /** fetch data from the table: "users_privacy" using primary key columns */
  users_privacy_by_pk?: Maybe<Users_Privacy>;
  /** fetch data from the table in a streaming manner: "users_privacy" */
  users_privacy_stream: Array<Users_Privacy>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** An array relationship */
  video_reports: Array<Video_Reports>;
  /** An aggregate relationship */
  video_reports_aggregate: Video_Reports_Aggregate;
  /** fetch data from the table: "video_reports" using primary key columns */
  video_reports_by_pk?: Maybe<Video_Reports>;
  /** fetch data from the table in a streaming manner: "video_reports" */
  video_reports_stream: Array<Video_Reports>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** An array relationship */
  videos_contents: Array<Videos_Contents>;
  /** An aggregate relationship */
  videos_contents_aggregate: Videos_Contents_Aggregate;
  /** fetch data from the table: "videos_contents" using primary key columns */
  videos_contents_by_pk?: Maybe<Videos_Contents>;
  /** fetch data from the table in a streaming manner: "videos_contents" */
  videos_contents_stream: Array<Videos_Contents>;
  /** fetch data from the table in a streaming manner: "videos" */
  videos_stream: Array<Videos>;
};


export type Subscription_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootActions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAdmins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAdmins_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Admins_Stream_Cursor_Input>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_VideosArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAi_Suggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAi_Suggested_Videos_Reviews_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Suggested_Videos_Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootAi_Suggested_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Suggested_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootAi_Suggestion_KeywordsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggestion_Keywords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggestion_Keywords_Order_By>>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};


export type Subscription_RootAi_Suggestion_Keywords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Suggestion_Keywords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Suggestion_Keywords_Order_By>>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};


export type Subscription_RootAi_Suggestion_Keywords_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAi_Suggestion_Keywords_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Suggestion_Keywords_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Suggestion_Keywords_Bool_Exp>;
};


export type Subscription_RootBadge_VideosArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


export type Subscription_RootBadge_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badge_Videos_Order_By>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


export type Subscription_RootBadge_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBadge_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Badge_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Badge_Videos_Bool_Exp>;
};


export type Subscription_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBadges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCategories_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


export type Subscription_RootCategories_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Contents_Order_By>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


export type Subscription_RootCategories_Contents_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


export type Subscription_RootCategories_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Contents_Bool_Exp>;
};


export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChannels_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


export type Subscription_RootChannels_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Contents_Order_By>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


export type Subscription_RootChannels_Contents_By_PkArgs = {
  channelId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};


export type Subscription_RootChannels_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Channels_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Contents_Bool_Exp>;
};


export type Subscription_RootChannels_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


export type Subscription_RootChannels_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


export type Subscription_RootChannels_Follows_By_PkArgs = {
  channelId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Subscription_RootChannels_Follows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Channels_Follows_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Notifications_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Notifications_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChrome_Extension_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chrome_Extension_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Chrome_Extension_Notifications_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_VideosArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_Videos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChrome_Extension_Suggested_Videos_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_Videos_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Suggested_Videos_Reviews_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_Videos_Reviews_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChrome_Extension_Suggested_Videos_Reviews_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Reviews_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Suggested_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chrome_Extension_Suggested_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Chrome_Extension_Suggested_Videos_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_UsersArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Users_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chrome_Extension_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chrome_Extension_Users_Order_By>>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};


export type Subscription_RootChrome_Extension_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChrome_Extension_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chrome_Extension_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
};


export type Subscription_RootComing_Soon_Videos_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Order_By>>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};


export type Subscription_RootComing_Soon_Videos_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coming_Soon_Videos_Reminders_Order_By>>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};


export type Subscription_RootComing_Soon_Videos_Reminders_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootComing_Soon_Videos_Reminders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Coming_Soon_Videos_Reminders_Stream_Cursor_Input>>;
  where?: InputMaybe<Coming_Soon_Videos_Reminders_Bool_Exp>;
};


export type Subscription_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootContents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootFaqArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootFaq_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Faq_Stream_Cursor_Input>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Subscription_RootFollow_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


export type Subscription_RootFollow_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


export type Subscription_RootFollow_Requests_By_PkArgs = {
  receiverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};


export type Subscription_RootFollow_Requests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Follow_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


export type Subscription_RootGeneral_MessagesArgs = {
  distinct_on?: InputMaybe<Array<General_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<General_Messages_Order_By>>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};


export type Subscription_RootGeneral_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<General_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<General_Messages_Order_By>>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};


export type Subscription_RootGeneral_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootGeneral_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<General_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<General_Messages_Bool_Exp>;
};


export type Subscription_RootGenresArgs = {
  distinct_on?: InputMaybe<Array<Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genres_Order_By>>;
  where?: InputMaybe<Genres_Bool_Exp>;
};


export type Subscription_RootGenres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genres_Order_By>>;
  where?: InputMaybe<Genres_Bool_Exp>;
};


export type Subscription_RootGenres_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootGenres_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Genres_Stream_Cursor_Input>>;
  where?: InputMaybe<Genres_Bool_Exp>;
};


export type Subscription_RootHeared_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Heared_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Heared_Methods_Order_By>>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};


export type Subscription_RootHeared_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Heared_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Heared_Methods_Order_By>>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};


export type Subscription_RootHeared_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootHeared_Methods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Heared_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Heared_Methods_Bool_Exp>;
};


export type Subscription_RootKid_User_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Kid_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kid_User_Settings_Order_By>>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};


export type Subscription_RootKid_User_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kid_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kid_User_Settings_Order_By>>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};


export type Subscription_RootKid_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootKid_User_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kid_User_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Kid_User_Settings_Bool_Exp>;
};


export type Subscription_RootKids_ParentsArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


export type Subscription_RootKids_Parents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


export type Subscription_RootKids_Parents_By_PkArgs = {
  kidId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};


export type Subscription_RootKids_Parents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kids_Parents_Stream_Cursor_Input>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


export type Subscription_RootLeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};


export type Subscription_RootLeaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};


export type Subscription_RootLeaderboard_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootLeaderboard_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Leaderboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};


export type Subscription_RootLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootLikes_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootLikes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootParent_User_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Parent_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_User_Settings_Order_By>>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};


export type Subscription_RootParent_User_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_User_Settings_Order_By>>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};


export type Subscription_RootParent_User_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootParent_User_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parent_User_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Parent_User_Settings_Bool_Exp>;
};


export type Subscription_RootPrivacy_PolicyArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Policy_Order_By>>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};


export type Subscription_RootPrivacy_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Policy_Order_By>>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};


export type Subscription_RootPrivacy_Policy_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPrivacy_Policy_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Privacy_Policy_Stream_Cursor_Input>>;
  where?: InputMaybe<Privacy_Policy_Bool_Exp>;
};


export type Subscription_RootReport_Video_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Report_Video_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Video_Methods_Order_By>>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};


export type Subscription_RootReport_Video_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Video_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Video_Methods_Order_By>>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};


export type Subscription_RootReport_Video_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootReport_Video_Methods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Report_Video_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Video_Methods_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


export type Subscription_RootRoles_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootSeasonsArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Seasons_Order_By>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSeasons_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Seasons_Stream_Cursor_Input>>;
  where?: InputMaybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


export type Subscription_RootSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


export type Subscription_RootSeries_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSeries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Series_Stream_Cursor_Input>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


export type Subscription_RootSuggestion_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Suggestion_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Suggestion_Settings_Order_By>>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};


export type Subscription_RootSuggestion_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suggestion_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Suggestion_Settings_Order_By>>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};


export type Subscription_RootSuggestion_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSuggestion_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Suggestion_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};


export type Subscription_RootTicket_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


export type Subscription_RootTicket_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


export type Subscription_RootTicket_Replies_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTicket_Replies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ticket_Replies_Stream_Cursor_Input>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


export type Subscription_RootTicketsArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tickets_Order_By>>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};


export type Subscription_RootTickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tickets_Order_By>>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};


export type Subscription_RootTickets_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTickets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};


export type Subscription_RootUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_Badges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Subscription_RootUser_Badges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_PlaylistsArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


export type Subscription_RootUser_Playlists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


export type Subscription_RootUser_Playlists_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Playlists_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Playlists_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


export type Subscription_RootUser_Playlists_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Playlists_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Playlists_Videos_By_PkArgs = {
  playlistId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootUser_Playlists_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Playlists_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


export type Subscription_RootUser_SearchesArgs = {
  distinct_on?: InputMaybe<Array<User_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Searches_Order_By>>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};


export type Subscription_RootUser_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Searches_Order_By>>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};


export type Subscription_RootUser_Searches_By_PkArgs = {
  search: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Subscription_RootUser_Searches_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Searches_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};


export type Subscription_RootUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Shared_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Shared_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Shared_Videos_Order_By>>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Shared_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Shared_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Shared_Videos_Order_By>>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Shared_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootUser_Shared_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Shared_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Badge_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Badge_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Badge_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Badge_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Badge_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Badge_Videos_By_PkArgs = {
  badgeVideoId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Subscription_RootUser_Watched_Badge_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Watched_Badge_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Video_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Video_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Video_Sessions_Order_By>>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Video_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Video_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Video_Sessions_Order_By>>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Video_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Watched_Video_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Watched_Video_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Watched_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


export type Subscription_RootUser_Watched_Videos_By_PkArgs = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootUser_Watched_Videos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Watched_Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


export type Subscription_RootUsers_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


export type Subscription_RootUsers_Categories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Subscription_RootUsers_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


export type Subscription_RootUsers_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


export type Subscription_RootUsers_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


export type Subscription_RootUsers_Follows_By_PkArgs = {
  followeeId: Scalars['String']['input'];
  followerId: Scalars['String']['input'];
};


export type Subscription_RootUsers_Follows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Follows_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


export type Subscription_RootUsers_PrivacyArgs = {
  distinct_on?: InputMaybe<Array<Users_Privacy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Privacy_Order_By>>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};


export type Subscription_RootUsers_Privacy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Privacy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Privacy_Order_By>>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};


export type Subscription_RootUsers_Privacy_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Privacy_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Privacy_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVideo_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


export type Subscription_RootVideo_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


export type Subscription_RootVideo_Reports_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootVideo_Reports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Video_Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


export type Subscription_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootVideos_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


export type Subscription_RootVideos_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


export type Subscription_RootVideos_Contents_By_PkArgs = {
  contentId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};


export type Subscription_RootVideos_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Videos_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


export type Subscription_RootVideos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** columns and relationships of "suggestion_settings" */
export type Suggestion_Settings = {
  __typename?: 'suggestion_settings';
  approvalRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  rejectionRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "suggestion_settings" */
export type Suggestion_Settings_Aggregate = {
  __typename?: 'suggestion_settings_aggregate';
  aggregate?: Maybe<Suggestion_Settings_Aggregate_Fields>;
  nodes: Array<Suggestion_Settings>;
};

/** aggregate fields of "suggestion_settings" */
export type Suggestion_Settings_Aggregate_Fields = {
  __typename?: 'suggestion_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Suggestion_Settings_Max_Fields>;
  min?: Maybe<Suggestion_Settings_Min_Fields>;
};


/** aggregate fields of "suggestion_settings" */
export type Suggestion_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Suggestion_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "suggestion_settings". All fields are combined with a logical 'AND'. */
export type Suggestion_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Suggestion_Settings_Bool_Exp>>;
  _not?: InputMaybe<Suggestion_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Suggestion_Settings_Bool_Exp>>;
  approvalRules?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  rejectionRules?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "suggestion_settings" */
export enum Suggestion_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  SuggestionSettingsPkey = 'suggestion_settings_pkey',
  /** unique or primary key constraint on columns "type" */
  SuggestionSettingsTypeKey = 'suggestion_settings_type_key'
}

/** input type for inserting data into table "suggestion_settings" */
export type Suggestion_Settings_Insert_Input = {
  approvalRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rejectionRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Suggestion_Settings_Max_Fields = {
  __typename?: 'suggestion_settings_max_fields';
  approvalRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  rejectionRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Suggestion_Settings_Min_Fields = {
  __typename?: 'suggestion_settings_min_fields';
  approvalRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  rejectionRules?: Maybe<Array<Scalars['jsonb']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "suggestion_settings" */
export type Suggestion_Settings_Mutation_Response = {
  __typename?: 'suggestion_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Suggestion_Settings>;
};

/** on_conflict condition type for table "suggestion_settings" */
export type Suggestion_Settings_On_Conflict = {
  constraint: Suggestion_Settings_Constraint;
  update_columns?: Array<Suggestion_Settings_Update_Column>;
  where?: InputMaybe<Suggestion_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "suggestion_settings". */
export type Suggestion_Settings_Order_By = {
  approvalRules?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rejectionRules?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: suggestion_settings */
export type Suggestion_Settings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "suggestion_settings" */
export enum Suggestion_Settings_Select_Column {
  /** column name */
  ApprovalRules = 'approvalRules',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RejectionRules = 'rejectionRules',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "suggestion_settings" */
export type Suggestion_Settings_Set_Input = {
  approvalRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rejectionRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "suggestion_settings" */
export type Suggestion_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Suggestion_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Suggestion_Settings_Stream_Cursor_Value_Input = {
  approvalRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rejectionRules?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "suggestion_settings" */
export enum Suggestion_Settings_Update_Column {
  /** column name */
  ApprovalRules = 'approvalRules',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RejectionRules = 'rejectionRules',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Suggestion_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Suggestion_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Suggestion_Settings_Bool_Exp;
};

/** columns and relationships of "ticket_replies" */
export type Ticket_Replies = {
  __typename?: 'ticket_replies';
  content: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  ticket: Tickets;
  ticketId: Scalars['String']['output'];
};

/** aggregated selection of "ticket_replies" */
export type Ticket_Replies_Aggregate = {
  __typename?: 'ticket_replies_aggregate';
  aggregate?: Maybe<Ticket_Replies_Aggregate_Fields>;
  nodes: Array<Ticket_Replies>;
};

export type Ticket_Replies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ticket_Replies_Aggregate_Bool_Exp_Count>;
};

export type Ticket_Replies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ticket_Replies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ticket_replies" */
export type Ticket_Replies_Aggregate_Fields = {
  __typename?: 'ticket_replies_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Ticket_Replies_Max_Fields>;
  min?: Maybe<Ticket_Replies_Min_Fields>;
};


/** aggregate fields of "ticket_replies" */
export type Ticket_Replies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ticket_replies" */
export type Ticket_Replies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ticket_Replies_Max_Order_By>;
  min?: InputMaybe<Ticket_Replies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ticket_replies" */
export type Ticket_Replies_Arr_Rel_Insert_Input = {
  data: Array<Ticket_Replies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ticket_Replies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ticket_replies". All fields are combined with a logical 'AND'. */
export type Ticket_Replies_Bool_Exp = {
  _and?: InputMaybe<Array<Ticket_Replies_Bool_Exp>>;
  _not?: InputMaybe<Ticket_Replies_Bool_Exp>;
  _or?: InputMaybe<Array<Ticket_Replies_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ticket?: InputMaybe<Tickets_Bool_Exp>;
  ticketId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ticket_replies" */
export enum Ticket_Replies_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketRepliesPkey = 'ticket_replies_pkey'
}

/** input type for inserting data into table "ticket_replies" */
export type Ticket_Replies_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ticket?: InputMaybe<Tickets_Obj_Rel_Insert_Input>;
  ticketId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ticket_Replies_Max_Fields = {
  __typename?: 'ticket_replies_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ticket_replies" */
export type Ticket_Replies_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ticketId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ticket_Replies_Min_Fields = {
  __typename?: 'ticket_replies_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ticket_replies" */
export type Ticket_Replies_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ticketId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ticket_replies" */
export type Ticket_Replies_Mutation_Response = {
  __typename?: 'ticket_replies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Replies>;
};

/** on_conflict condition type for table "ticket_replies" */
export type Ticket_Replies_On_Conflict = {
  constraint: Ticket_Replies_Constraint;
  update_columns?: Array<Ticket_Replies_Update_Column>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};

/** Ordering options when selecting data from "ticket_replies". */
export type Ticket_Replies_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Tickets_Order_By>;
  ticketId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ticket_replies */
export type Ticket_Replies_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ticket_replies" */
export enum Ticket_Replies_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TicketId = 'ticketId'
}

/** input type for updating data in table "ticket_replies" */
export type Ticket_Replies_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ticket_replies" */
export type Ticket_Replies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ticket_Replies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ticket_Replies_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ticket_replies" */
export enum Ticket_Replies_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TicketId = 'ticketId'
}

export type Ticket_Replies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ticket_Replies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Replies_Bool_Exp;
};

/** columns and relationships of "tickets" */
export type Tickets = {
  __typename?: 'tickets';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  /** An array relationship */
  ticket_replies: Array<Ticket_Replies>;
  /** An aggregate relationship */
  ticket_replies_aggregate: Ticket_Replies_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "tickets" */
export type TicketsTicket_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};


/** columns and relationships of "tickets" */
export type TicketsTicket_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ticket_Replies_Order_By>>;
  where?: InputMaybe<Ticket_Replies_Bool_Exp>;
};

/** aggregated selection of "tickets" */
export type Tickets_Aggregate = {
  __typename?: 'tickets_aggregate';
  aggregate?: Maybe<Tickets_Aggregate_Fields>;
  nodes: Array<Tickets>;
};

/** aggregate fields of "tickets" */
export type Tickets_Aggregate_Fields = {
  __typename?: 'tickets_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tickets_Max_Fields>;
  min?: Maybe<Tickets_Min_Fields>;
};


/** aggregate fields of "tickets" */
export type Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'. */
export type Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Tickets_Bool_Exp>>;
  _not?: InputMaybe<Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Tickets_Bool_Exp>>;
  attachmentUrl?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  platform?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  ticket_replies?: InputMaybe<Ticket_Replies_Bool_Exp>;
  ticket_replies_aggregate?: InputMaybe<Ticket_Replies_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tickets" */
export enum Tickets_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketsPkey = 'tickets_pkey'
}

/** input type for inserting data into table "tickets" */
export type Tickets_Insert_Input = {
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  ticket_replies?: InputMaybe<Ticket_Replies_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tickets_Max_Fields = {
  __typename?: 'tickets_max_fields';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tickets_Min_Fields = {
  __typename?: 'tickets_min_fields';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tickets" */
export type Tickets_Mutation_Response = {
  __typename?: 'tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tickets>;
};

/** input type for inserting object relation for remote table "tickets" */
export type Tickets_Obj_Rel_Insert_Input = {
  data: Tickets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tickets_On_Conflict>;
};

/** on_conflict condition type for table "tickets" */
export type Tickets_On_Conflict = {
  constraint: Tickets_Constraint;
  update_columns?: Array<Tickets_Update_Column>;
  where?: InputMaybe<Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "tickets". */
export type Tickets_Order_By = {
  attachmentUrl?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  ticket_replies_aggregate?: InputMaybe<Ticket_Replies_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tickets */
export type Tickets_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tickets" */
export enum Tickets_Select_Column {
  /** column name */
  AttachmentUrl = 'attachmentUrl',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "tickets" */
export type Tickets_Set_Input = {
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tickets" */
export type Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tickets_Stream_Cursor_Value_Input = {
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tickets" */
export enum Tickets_Update_Column {
  /** column name */
  AttachmentUrl = 'attachmentUrl',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Tickets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tickets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tickets_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** columns and relationships of "user_badges" */
export type User_Badges = {
  __typename?: 'user_badges';
  /** An object relationship */
  badge: Badges;
  badgeId: Scalars['String']['output'];
  claimedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt: Scalars['timestamp']['output'];
  isClaimed: Scalars['Boolean']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_badges" */
export type User_Badges_Aggregate = {
  __typename?: 'user_badges_aggregate';
  aggregate?: Maybe<User_Badges_Aggregate_Fields>;
  nodes: Array<User_Badges>;
};

/** aggregate fields of "user_badges" */
export type User_Badges_Aggregate_Fields = {
  __typename?: 'user_badges_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Badges_Max_Fields>;
  min?: Maybe<User_Badges_Min_Fields>;
};


/** aggregate fields of "user_badges" */
export type User_Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_badges". All fields are combined with a logical 'AND'. */
export type User_Badges_Bool_Exp = {
  _and?: InputMaybe<Array<User_Badges_Bool_Exp>>;
  _not?: InputMaybe<User_Badges_Bool_Exp>;
  _or?: InputMaybe<Array<User_Badges_Bool_Exp>>;
  badge?: InputMaybe<Badges_Bool_Exp>;
  badgeId?: InputMaybe<String_Comparison_Exp>;
  claimedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  isClaimed?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_badges" */
export enum User_Badges_Constraint {
  /** unique or primary key constraint on columns "userId", "badgeId" */
  UserBadgesPkey = 'user_badges_pkey'
}

/** input type for inserting data into table "user_badges" */
export type User_Badges_Insert_Input = {
  badge?: InputMaybe<Badges_Obj_Rel_Insert_Input>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  claimedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Badges_Max_Fields = {
  __typename?: 'user_badges_max_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  claimedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Badges_Min_Fields = {
  __typename?: 'user_badges_min_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  claimedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_badges" */
export type User_Badges_Mutation_Response = {
  __typename?: 'user_badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Badges>;
};

/** on_conflict condition type for table "user_badges" */
export type User_Badges_On_Conflict = {
  constraint: User_Badges_Constraint;
  update_columns?: Array<User_Badges_Update_Column>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "user_badges". */
export type User_Badges_Order_By = {
  badge?: InputMaybe<Badges_Order_By>;
  badgeId?: InputMaybe<Order_By>;
  claimedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isClaimed?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_badges */
export type User_Badges_Pk_Columns_Input = {
  badgeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "user_badges" */
export enum User_Badges_Select_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  ClaimedAt = 'claimedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_badges" */
export type User_Badges_Set_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  claimedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_badges" */
export type User_Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Badges_Stream_Cursor_Value_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  claimedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_badges" */
export enum User_Badges_Update_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  ClaimedAt = 'claimedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  UserId = 'userId'
}

export type User_Badges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Badges_Bool_Exp;
};

/** columns and relationships of "user_playlists" */
export type User_Playlists = {
  __typename?: 'user_playlists';
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  /** An array relationship */
  user_playlists_videos: Array<User_Playlists_Videos>;
  /** An aggregate relationship */
  user_playlists_videos_aggregate: User_Playlists_Videos_Aggregate;
};


/** columns and relationships of "user_playlists" */
export type User_PlaylistsUser_Playlists_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};


/** columns and relationships of "user_playlists" */
export type User_PlaylistsUser_Playlists_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Videos_Order_By>>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};

/** aggregated selection of "user_playlists" */
export type User_Playlists_Aggregate = {
  __typename?: 'user_playlists_aggregate';
  aggregate?: Maybe<User_Playlists_Aggregate_Fields>;
  nodes: Array<User_Playlists>;
};

export type User_Playlists_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Playlists_Aggregate_Bool_Exp_Count>;
};

export type User_Playlists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Playlists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Playlists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_playlists" */
export type User_Playlists_Aggregate_Fields = {
  __typename?: 'user_playlists_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Playlists_Max_Fields>;
  min?: Maybe<User_Playlists_Min_Fields>;
};


/** aggregate fields of "user_playlists" */
export type User_Playlists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Playlists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_playlists" */
export type User_Playlists_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Playlists_Max_Order_By>;
  min?: InputMaybe<User_Playlists_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_playlists" */
export type User_Playlists_Arr_Rel_Insert_Input = {
  data: Array<User_Playlists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Playlists_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_playlists". All fields are combined with a logical 'AND'. */
export type User_Playlists_Bool_Exp = {
  _and?: InputMaybe<Array<User_Playlists_Bool_Exp>>;
  _not?: InputMaybe<User_Playlists_Bool_Exp>;
  _or?: InputMaybe<Array<User_Playlists_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_playlists_videos?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
  user_playlists_videos_aggregate?: InputMaybe<User_Playlists_Videos_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_playlists" */
export enum User_Playlists_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPlaylistsPkey = 'user_playlists_pkey',
  /** unique or primary key constraint on columns "userId", "name" */
  UserPlaylistsUserIdNameKey = 'user_playlists_userId_name_key'
}

/** input type for inserting data into table "user_playlists" */
export type User_Playlists_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_playlists_videos?: InputMaybe<User_Playlists_Videos_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Playlists_Max_Fields = {
  __typename?: 'user_playlists_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_playlists" */
export type User_Playlists_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Playlists_Min_Fields = {
  __typename?: 'user_playlists_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_playlists" */
export type User_Playlists_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_playlists" */
export type User_Playlists_Mutation_Response = {
  __typename?: 'user_playlists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Playlists>;
};

/** input type for inserting object relation for remote table "user_playlists" */
export type User_Playlists_Obj_Rel_Insert_Input = {
  data: User_Playlists_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Playlists_On_Conflict>;
};

/** on_conflict condition type for table "user_playlists" */
export type User_Playlists_On_Conflict = {
  constraint: User_Playlists_Constraint;
  update_columns?: Array<User_Playlists_Update_Column>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};

/** Ordering options when selecting data from "user_playlists". */
export type User_Playlists_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  user_playlists_videos_aggregate?: InputMaybe<User_Playlists_Videos_Aggregate_Order_By>;
};

/** primary key columns input for table: user_playlists */
export type User_Playlists_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_playlists" */
export enum User_Playlists_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_playlists" */
export type User_Playlists_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_playlists" */
export type User_Playlists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Playlists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Playlists_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_playlists" */
export enum User_Playlists_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Playlists_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Playlists_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Playlists_Bool_Exp;
};

/** columns and relationships of "user_playlists_videos" */
export type User_Playlists_Videos = {
  __typename?: 'user_playlists_videos';
  playlistId: Scalars['String']['output'];
  /** An object relationship */
  user_playlist: User_Playlists;
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "user_playlists_videos" */
export type User_Playlists_Videos_Aggregate = {
  __typename?: 'user_playlists_videos_aggregate';
  aggregate?: Maybe<User_Playlists_Videos_Aggregate_Fields>;
  nodes: Array<User_Playlists_Videos>;
};

export type User_Playlists_Videos_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Playlists_Videos_Aggregate_Bool_Exp_Count>;
};

export type User_Playlists_Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_playlists_videos" */
export type User_Playlists_Videos_Aggregate_Fields = {
  __typename?: 'user_playlists_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Playlists_Videos_Max_Fields>;
  min?: Maybe<User_Playlists_Videos_Min_Fields>;
};


/** aggregate fields of "user_playlists_videos" */
export type User_Playlists_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Playlists_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_playlists_videos" */
export type User_Playlists_Videos_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Playlists_Videos_Max_Order_By>;
  min?: InputMaybe<User_Playlists_Videos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_playlists_videos" */
export type User_Playlists_Videos_Arr_Rel_Insert_Input = {
  data: Array<User_Playlists_Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Playlists_Videos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_playlists_videos". All fields are combined with a logical 'AND'. */
export type User_Playlists_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<User_Playlists_Videos_Bool_Exp>>;
  _not?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<User_Playlists_Videos_Bool_Exp>>;
  playlistId?: InputMaybe<String_Comparison_Exp>;
  user_playlist?: InputMaybe<User_Playlists_Bool_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_playlists_videos" */
export enum User_Playlists_Videos_Constraint {
  /** unique or primary key constraint on columns "videoId", "playlistId" */
  UserPlaylistsVideosPkey = 'user_playlists_videos_pkey'
}

/** input type for inserting data into table "user_playlists_videos" */
export type User_Playlists_Videos_Insert_Input = {
  playlistId?: InputMaybe<Scalars['String']['input']>;
  user_playlist?: InputMaybe<User_Playlists_Obj_Rel_Insert_Input>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Playlists_Videos_Max_Fields = {
  __typename?: 'user_playlists_videos_max_fields';
  playlistId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_playlists_videos" */
export type User_Playlists_Videos_Max_Order_By = {
  playlistId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Playlists_Videos_Min_Fields = {
  __typename?: 'user_playlists_videos_min_fields';
  playlistId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_playlists_videos" */
export type User_Playlists_Videos_Min_Order_By = {
  playlistId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_playlists_videos" */
export type User_Playlists_Videos_Mutation_Response = {
  __typename?: 'user_playlists_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Playlists_Videos>;
};

/** on_conflict condition type for table "user_playlists_videos" */
export type User_Playlists_Videos_On_Conflict = {
  constraint: User_Playlists_Videos_Constraint;
  update_columns?: Array<User_Playlists_Videos_Update_Column>;
  where?: InputMaybe<User_Playlists_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "user_playlists_videos". */
export type User_Playlists_Videos_Order_By = {
  playlistId?: InputMaybe<Order_By>;
  user_playlist?: InputMaybe<User_Playlists_Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_playlists_videos */
export type User_Playlists_Videos_Pk_Columns_Input = {
  playlistId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "user_playlists_videos" */
export enum User_Playlists_Videos_Select_Column {
  /** column name */
  PlaylistId = 'playlistId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "user_playlists_videos" */
export type User_Playlists_Videos_Set_Input = {
  playlistId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_playlists_videos" */
export type User_Playlists_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Playlists_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Playlists_Videos_Stream_Cursor_Value_Input = {
  playlistId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_playlists_videos" */
export enum User_Playlists_Videos_Update_Column {
  /** column name */
  PlaylistId = 'playlistId',
  /** column name */
  VideoId = 'videoId'
}

export type User_Playlists_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Playlists_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Playlists_Videos_Bool_Exp;
};

/** columns and relationships of "user_searches" */
export type User_Searches = {
  __typename?: 'user_searches';
  createdAt: Scalars['timestamp']['output'];
  search: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_searches" */
export type User_Searches_Aggregate = {
  __typename?: 'user_searches_aggregate';
  aggregate?: Maybe<User_Searches_Aggregate_Fields>;
  nodes: Array<User_Searches>;
};

/** aggregate fields of "user_searches" */
export type User_Searches_Aggregate_Fields = {
  __typename?: 'user_searches_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Searches_Max_Fields>;
  min?: Maybe<User_Searches_Min_Fields>;
};


/** aggregate fields of "user_searches" */
export type User_Searches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Searches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_searches". All fields are combined with a logical 'AND'. */
export type User_Searches_Bool_Exp = {
  _and?: InputMaybe<Array<User_Searches_Bool_Exp>>;
  _not?: InputMaybe<User_Searches_Bool_Exp>;
  _or?: InputMaybe<Array<User_Searches_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  search?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_searches" */
export enum User_Searches_Constraint {
  /** unique or primary key constraint on columns "userId", "search" */
  UserSearchesPkey = 'user_searches_pkey'
}

/** input type for inserting data into table "user_searches" */
export type User_Searches_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Searches_Max_Fields = {
  __typename?: 'user_searches_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Searches_Min_Fields = {
  __typename?: 'user_searches_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_searches" */
export type User_Searches_Mutation_Response = {
  __typename?: 'user_searches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Searches>;
};

/** on_conflict condition type for table "user_searches" */
export type User_Searches_On_Conflict = {
  constraint: User_Searches_Constraint;
  update_columns?: Array<User_Searches_Update_Column>;
  where?: InputMaybe<User_Searches_Bool_Exp>;
};

/** Ordering options when selecting data from "user_searches". */
export type User_Searches_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  search?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_searches */
export type User_Searches_Pk_Columns_Input = {
  search: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "user_searches" */
export enum User_Searches_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Search = 'search',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_searches" */
export type User_Searches_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_searches" */
export type User_Searches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Searches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Searches_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_searches" */
export enum User_Searches_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Search = 'search',
  /** column name */
  UserId = 'userId'
}

export type User_Searches_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Searches_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Searches_Bool_Exp;
};

/** columns and relationships of "user_sessions" */
export type User_Sessions = {
  __typename?: 'user_sessions';
  endAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  startedAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_sessions" */
export type User_Sessions_Aggregate = {
  __typename?: 'user_sessions_aggregate';
  aggregate?: Maybe<User_Sessions_Aggregate_Fields>;
  nodes: Array<User_Sessions>;
};

/** aggregate fields of "user_sessions" */
export type User_Sessions_Aggregate_Fields = {
  __typename?: 'user_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Sessions_Max_Fields>;
  min?: Maybe<User_Sessions_Min_Fields>;
};


/** aggregate fields of "user_sessions" */
export type User_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_sessions". All fields are combined with a logical 'AND'. */
export type User_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Sessions_Bool_Exp>>;
  _not?: InputMaybe<User_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Sessions_Bool_Exp>>;
  endAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  startedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_sessions" */
export enum User_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSessionsPkey = 'user_sessions_pkey'
}

/** input type for inserting data into table "user_sessions" */
export type User_Sessions_Insert_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Sessions_Max_Fields = {
  __typename?: 'user_sessions_max_fields';
  endAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Sessions_Min_Fields = {
  __typename?: 'user_sessions_min_fields';
  endAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_sessions" */
export type User_Sessions_Mutation_Response = {
  __typename?: 'user_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Sessions>;
};

/** on_conflict condition type for table "user_sessions" */
export type User_Sessions_On_Conflict = {
  constraint: User_Sessions_Constraint;
  update_columns?: Array<User_Sessions_Update_Column>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_sessions". */
export type User_Sessions_Order_By = {
  endAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_sessions */
export type User_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_sessions" */
export enum User_Sessions_Select_Column {
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_sessions" */
export type User_Sessions_Set_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_sessions" */
export type User_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Sessions_Stream_Cursor_Value_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_sessions" */
export enum User_Sessions_Update_Column {
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Sessions_Bool_Exp;
};

/** columns and relationships of "user_shared_videos" */
export type User_Shared_Videos = {
  __typename?: 'user_shared_videos';
  createdAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "user_shared_videos" */
export type User_Shared_Videos_Aggregate = {
  __typename?: 'user_shared_videos_aggregate';
  aggregate?: Maybe<User_Shared_Videos_Aggregate_Fields>;
  nodes: Array<User_Shared_Videos>;
};

/** aggregate fields of "user_shared_videos" */
export type User_Shared_Videos_Aggregate_Fields = {
  __typename?: 'user_shared_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Shared_Videos_Max_Fields>;
  min?: Maybe<User_Shared_Videos_Min_Fields>;
};


/** aggregate fields of "user_shared_videos" */
export type User_Shared_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Shared_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_shared_videos". All fields are combined with a logical 'AND'. */
export type User_Shared_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<User_Shared_Videos_Bool_Exp>>;
  _not?: InputMaybe<User_Shared_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<User_Shared_Videos_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_shared_videos" */
export enum User_Shared_Videos_Constraint {
  /** unique or primary key constraint on columns "userId", "videoId" */
  UserSharedVideosPkey = 'user_shared_videos_pkey'
}

/** input type for inserting data into table "user_shared_videos" */
export type User_Shared_Videos_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Shared_Videos_Max_Fields = {
  __typename?: 'user_shared_videos_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Shared_Videos_Min_Fields = {
  __typename?: 'user_shared_videos_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_shared_videos" */
export type User_Shared_Videos_Mutation_Response = {
  __typename?: 'user_shared_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Shared_Videos>;
};

/** on_conflict condition type for table "user_shared_videos" */
export type User_Shared_Videos_On_Conflict = {
  constraint: User_Shared_Videos_Constraint;
  update_columns?: Array<User_Shared_Videos_Update_Column>;
  where?: InputMaybe<User_Shared_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "user_shared_videos". */
export type User_Shared_Videos_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_shared_videos */
export type User_Shared_Videos_Pk_Columns_Input = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "user_shared_videos" */
export enum User_Shared_Videos_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "user_shared_videos" */
export type User_Shared_Videos_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_shared_videos" */
export type User_Shared_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Shared_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Shared_Videos_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_shared_videos" */
export enum User_Shared_Videos_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

export type User_Shared_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Shared_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Shared_Videos_Bool_Exp;
};

/** columns and relationships of "user_watched_badge_videos" */
export type User_Watched_Badge_Videos = {
  __typename?: 'user_watched_badge_videos';
  badgeVideoId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Aggregate = {
  __typename?: 'user_watched_badge_videos_aggregate';
  aggregate?: Maybe<User_Watched_Badge_Videos_Aggregate_Fields>;
  nodes: Array<User_Watched_Badge_Videos>;
};

/** aggregate fields of "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Aggregate_Fields = {
  __typename?: 'user_watched_badge_videos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Watched_Badge_Videos_Max_Fields>;
  min?: Maybe<User_Watched_Badge_Videos_Min_Fields>;
};


/** aggregate fields of "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Watched_Badge_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_watched_badge_videos". All fields are combined with a logical 'AND'. */
export type User_Watched_Badge_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<User_Watched_Badge_Videos_Bool_Exp>>;
  _not?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<User_Watched_Badge_Videos_Bool_Exp>>;
  badgeVideoId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_watched_badge_videos" */
export enum User_Watched_Badge_Videos_Constraint {
  /** unique or primary key constraint on columns "userId", "badgeVideoId" */
  UserWatchedBadgeVideosPkey = 'user_watched_badge_videos_pkey'
}

/** input type for inserting data into table "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Insert_Input = {
  badgeVideoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Watched_Badge_Videos_Max_Fields = {
  __typename?: 'user_watched_badge_videos_max_fields';
  badgeVideoId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Watched_Badge_Videos_Min_Fields = {
  __typename?: 'user_watched_badge_videos_min_fields';
  badgeVideoId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Mutation_Response = {
  __typename?: 'user_watched_badge_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Watched_Badge_Videos>;
};

/** on_conflict condition type for table "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_On_Conflict = {
  constraint: User_Watched_Badge_Videos_Constraint;
  update_columns?: Array<User_Watched_Badge_Videos_Update_Column>;
  where?: InputMaybe<User_Watched_Badge_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "user_watched_badge_videos". */
export type User_Watched_Badge_Videos_Order_By = {
  badgeVideoId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_watched_badge_videos */
export type User_Watched_Badge_Videos_Pk_Columns_Input = {
  badgeVideoId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "user_watched_badge_videos" */
export enum User_Watched_Badge_Videos_Select_Column {
  /** column name */
  BadgeVideoId = 'badgeVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Set_Input = {
  badgeVideoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_watched_badge_videos" */
export type User_Watched_Badge_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Watched_Badge_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Watched_Badge_Videos_Stream_Cursor_Value_Input = {
  badgeVideoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_watched_badge_videos" */
export enum User_Watched_Badge_Videos_Update_Column {
  /** column name */
  BadgeVideoId = 'badgeVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UserId = 'userId'
}

export type User_Watched_Badge_Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Watched_Badge_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Watched_Badge_Videos_Bool_Exp;
};

/** columns and relationships of "user_watched_video_sessions" */
export type User_Watched_Video_Sessions = {
  __typename?: 'user_watched_video_sessions';
  endAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  startedAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Aggregate = {
  __typename?: 'user_watched_video_sessions_aggregate';
  aggregate?: Maybe<User_Watched_Video_Sessions_Aggregate_Fields>;
  nodes: Array<User_Watched_Video_Sessions>;
};

/** aggregate fields of "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Aggregate_Fields = {
  __typename?: 'user_watched_video_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Watched_Video_Sessions_Max_Fields>;
  min?: Maybe<User_Watched_Video_Sessions_Min_Fields>;
};


/** aggregate fields of "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Watched_Video_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_watched_video_sessions". All fields are combined with a logical 'AND'. */
export type User_Watched_Video_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Watched_Video_Sessions_Bool_Exp>>;
  _not?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Watched_Video_Sessions_Bool_Exp>>;
  endAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  startedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_watched_video_sessions" */
export enum User_Watched_Video_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserWatchedVideoSessionsPkey = 'user_watched_video_sessions_pkey'
}

/** input type for inserting data into table "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Insert_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Watched_Video_Sessions_Max_Fields = {
  __typename?: 'user_watched_video_sessions_max_fields';
  endAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Watched_Video_Sessions_Min_Fields = {
  __typename?: 'user_watched_video_sessions_min_fields';
  endAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Mutation_Response = {
  __typename?: 'user_watched_video_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Watched_Video_Sessions>;
};

/** on_conflict condition type for table "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_On_Conflict = {
  constraint: User_Watched_Video_Sessions_Constraint;
  update_columns?: Array<User_Watched_Video_Sessions_Update_Column>;
  where?: InputMaybe<User_Watched_Video_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_watched_video_sessions". */
export type User_Watched_Video_Sessions_Order_By = {
  endAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_watched_video_sessions */
export type User_Watched_Video_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_watched_video_sessions" */
export enum User_Watched_Video_Sessions_Select_Column {
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Set_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_watched_video_sessions" */
export type User_Watched_Video_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Watched_Video_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Watched_Video_Sessions_Stream_Cursor_Value_Input = {
  endAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_watched_video_sessions" */
export enum User_Watched_Video_Sessions_Update_Column {
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

export type User_Watched_Video_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Watched_Video_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Watched_Video_Sessions_Bool_Exp;
};

/** columns and relationships of "user_watched_videos" */
export type User_Watched_Videos = {
  __typename?: 'user_watched_videos';
  createdAt: Scalars['timestamp']['output'];
  currentSecond: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isFinished: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "user_watched_videos" */
export type User_Watched_Videos_Aggregate = {
  __typename?: 'user_watched_videos_aggregate';
  aggregate?: Maybe<User_Watched_Videos_Aggregate_Fields>;
  nodes: Array<User_Watched_Videos>;
};

export type User_Watched_Videos_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Watched_Videos_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Watched_Videos_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Watched_Videos_Aggregate_Bool_Exp_Count>;
};

export type User_Watched_Videos_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Watched_Videos_Select_Column_User_Watched_Videos_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Watched_Videos_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Watched_Videos_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Watched_Videos_Select_Column_User_Watched_Videos_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Watched_Videos_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Watched_Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Watched_Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_watched_videos" */
export type User_Watched_Videos_Aggregate_Fields = {
  __typename?: 'user_watched_videos_aggregate_fields';
  avg?: Maybe<User_Watched_Videos_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Watched_Videos_Max_Fields>;
  min?: Maybe<User_Watched_Videos_Min_Fields>;
  stddev?: Maybe<User_Watched_Videos_Stddev_Fields>;
  stddev_pop?: Maybe<User_Watched_Videos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Watched_Videos_Stddev_Samp_Fields>;
  sum?: Maybe<User_Watched_Videos_Sum_Fields>;
  var_pop?: Maybe<User_Watched_Videos_Var_Pop_Fields>;
  var_samp?: Maybe<User_Watched_Videos_Var_Samp_Fields>;
  variance?: Maybe<User_Watched_Videos_Variance_Fields>;
};


/** aggregate fields of "user_watched_videos" */
export type User_Watched_Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_watched_videos" */
export type User_Watched_Videos_Aggregate_Order_By = {
  avg?: InputMaybe<User_Watched_Videos_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Watched_Videos_Max_Order_By>;
  min?: InputMaybe<User_Watched_Videos_Min_Order_By>;
  stddev?: InputMaybe<User_Watched_Videos_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Watched_Videos_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Watched_Videos_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Watched_Videos_Sum_Order_By>;
  var_pop?: InputMaybe<User_Watched_Videos_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Watched_Videos_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Watched_Videos_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_watched_videos" */
export type User_Watched_Videos_Arr_Rel_Insert_Input = {
  data: Array<User_Watched_Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Watched_Videos_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Watched_Videos_Avg_Fields = {
  __typename?: 'user_watched_videos_avg_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Avg_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_watched_videos". All fields are combined with a logical 'AND'. */
export type User_Watched_Videos_Bool_Exp = {
  _and?: InputMaybe<Array<User_Watched_Videos_Bool_Exp>>;
  _not?: InputMaybe<User_Watched_Videos_Bool_Exp>;
  _or?: InputMaybe<Array<User_Watched_Videos_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  currentSecond?: InputMaybe<Int_Comparison_Exp>;
  isDeleted?: InputMaybe<Boolean_Comparison_Exp>;
  isFinished?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_watched_videos" */
export enum User_Watched_Videos_Constraint {
  /** unique or primary key constraint on columns "userId", "videoId" */
  UserWatchedVideosPkey = 'user_watched_videos_pkey'
}

/** input type for incrementing numeric columns in table "user_watched_videos" */
export type User_Watched_Videos_Inc_Input = {
  currentSecond?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_watched_videos" */
export type User_Watched_Videos_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  currentSecond?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Watched_Videos_Max_Fields = {
  __typename?: 'user_watched_videos_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  currentSecond?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currentSecond?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Watched_Videos_Min_Fields = {
  __typename?: 'user_watched_videos_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  currentSecond?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currentSecond?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_watched_videos" */
export type User_Watched_Videos_Mutation_Response = {
  __typename?: 'user_watched_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Watched_Videos>;
};

/** on_conflict condition type for table "user_watched_videos" */
export type User_Watched_Videos_On_Conflict = {
  constraint: User_Watched_Videos_Constraint;
  update_columns?: Array<User_Watched_Videos_Update_Column>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "user_watched_videos". */
export type User_Watched_Videos_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currentSecond?: InputMaybe<Order_By>;
  isDeleted?: InputMaybe<Order_By>;
  isFinished?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_watched_videos */
export type User_Watched_Videos_Pk_Columns_Input = {
  userId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "user_watched_videos" */
export enum User_Watched_Videos_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentSecond = 'currentSecond',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsFinished = 'isFinished',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

/** select "user_watched_videos_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_watched_videos" */
export enum User_Watched_Videos_Select_Column_User_Watched_Videos_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsFinished = 'isFinished'
}

/** select "user_watched_videos_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_watched_videos" */
export enum User_Watched_Videos_Select_Column_User_Watched_Videos_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsFinished = 'isFinished'
}

/** input type for updating data in table "user_watched_videos" */
export type User_Watched_Videos_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  currentSecond?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Watched_Videos_Stddev_Fields = {
  __typename?: 'user_watched_videos_stddev_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Stddev_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Watched_Videos_Stddev_Pop_Fields = {
  __typename?: 'user_watched_videos_stddev_pop_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Stddev_Pop_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Watched_Videos_Stddev_Samp_Fields = {
  __typename?: 'user_watched_videos_stddev_samp_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Stddev_Samp_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_watched_videos" */
export type User_Watched_Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Watched_Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Watched_Videos_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  currentSecond?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Watched_Videos_Sum_Fields = {
  __typename?: 'user_watched_videos_sum_fields';
  currentSecond?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Sum_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** update columns of table "user_watched_videos" */
export enum User_Watched_Videos_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentSecond = 'currentSecond',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsFinished = 'isFinished',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoId = 'videoId'
}

export type User_Watched_Videos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Watched_Videos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Watched_Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Watched_Videos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Watched_Videos_Var_Pop_Fields = {
  __typename?: 'user_watched_videos_var_pop_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Var_Pop_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Watched_Videos_Var_Samp_Fields = {
  __typename?: 'user_watched_videos_var_samp_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Var_Samp_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Watched_Videos_Variance_Fields = {
  __typename?: 'user_watched_videos_variance_fields';
  currentSecond?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_watched_videos" */
export type User_Watched_Videos_Variance_Order_By = {
  currentSecond?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  channels_follows: Array<Channels_Follows>;
  /** An aggregate relationship */
  channels_follows_aggregate: Channels_Follows_Aggregate;
  /** An object relationship */
  chrome_extension_user?: Maybe<Chrome_Extension_Users>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  /** An array relationship */
  followRequestsBySenderid: Array<Follow_Requests>;
  /** An aggregate relationship */
  followRequestsBySenderid_aggregate: Follow_Requests_Aggregate;
  /** An array relationship */
  follow_requests: Array<Follow_Requests>;
  /** An aggregate relationship */
  follow_requests_aggregate: Follow_Requests_Aggregate;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPreferencesChoosen: Scalars['Boolean']['output'];
  /** An object relationship */
  kid_user_setting?: Maybe<Kid_User_Settings>;
  /** An array relationship */
  kids: Array<Kids_Parents>;
  /** An aggregate relationship */
  kids_aggregate: Kids_Parents_Aggregate;
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  /** An object relationship */
  parent_user_setting?: Maybe<Parent_User_Settings>;
  /** An array relationship */
  parents: Array<Kids_Parents>;
  /** An aggregate relationship */
  parents_aggregate: Kids_Parents_Aggregate;
  pin?: Maybe<Scalars['String']['output']>;
  signinMethod: Scalars['String']['output'];
  status: Scalars['String']['output'];
  theme?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  user_playlists: Array<User_Playlists>;
  /** An aggregate relationship */
  user_playlists_aggregate: User_Playlists_Aggregate;
  /** An array relationship */
  user_watched_videos: Array<User_Watched_Videos>;
  /** An aggregate relationship */
  user_watched_videos_aggregate: User_Watched_Videos_Aggregate;
  /** An array relationship */
  usersFollowsByFollowerid: Array<Users_Follows>;
  /** An aggregate relationship */
  usersFollowsByFollowerid_aggregate: Users_Follows_Aggregate;
  /** An array relationship */
  users_categories: Array<Users_Categories>;
  /** An aggregate relationship */
  users_categories_aggregate: Users_Categories_Aggregate;
  /** An array relationship */
  users_follows: Array<Users_Follows>;
  /** An aggregate relationship */
  users_follows_aggregate: Users_Follows_Aggregate;
  verified: Scalars['Boolean']['output'];
  /** An array relationship */
  video_reports: Array<Video_Reports>;
  /** An aggregate relationship */
  video_reports_aggregate: Video_Reports_Aggregate;
};


/** columns and relationships of "users" */
export type UsersChannels_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannels_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Follows_Order_By>>;
  where?: InputMaybe<Channels_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowRequestsBySenderidArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowRequestsBySenderid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollow_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollow_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follow_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follow_Requests_Order_By>>;
  where?: InputMaybe<Follow_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersKidsArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersKids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersParentsArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kids_Parents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kids_Parents_Order_By>>;
  where?: InputMaybe<Kids_Parents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_PlaylistsArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Playlists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Playlists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Playlists_Order_By>>;
  where?: InputMaybe<User_Playlists_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Watched_VideosArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Watched_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Watched_Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Watched_Videos_Order_By>>;
  where?: InputMaybe<User_Watched_Videos_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsersFollowsByFolloweridArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsersFollowsByFollowerid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Categories_Order_By>>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_FollowsArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Follows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Follows_Order_By>>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVideo_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVideo_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  birthDate?: InputMaybe<Date_Comparison_Exp>;
  channels_follows?: InputMaybe<Channels_Follows_Bool_Exp>;
  channels_follows_aggregate?: InputMaybe<Channels_Follows_Aggregate_Bool_Exp>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Bool_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fcmTokens?: InputMaybe<String_Array_Comparison_Exp>;
  followRequestsBySenderid?: InputMaybe<Follow_Requests_Bool_Exp>;
  followRequestsBySenderid_aggregate?: InputMaybe<Follow_Requests_Aggregate_Bool_Exp>;
  follow_requests?: InputMaybe<Follow_Requests_Bool_Exp>;
  follow_requests_aggregate?: InputMaybe<Follow_Requests_Aggregate_Bool_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isPreferencesChoosen?: InputMaybe<Boolean_Comparison_Exp>;
  kid_user_setting?: InputMaybe<Kid_User_Settings_Bool_Exp>;
  kids?: InputMaybe<Kids_Parents_Bool_Exp>;
  kids_aggregate?: InputMaybe<Kids_Parents_Aggregate_Bool_Exp>;
  likes?: InputMaybe<Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Likes_Aggregate_Bool_Exp>;
  parent_user_setting?: InputMaybe<Parent_User_Settings_Bool_Exp>;
  parents?: InputMaybe<Kids_Parents_Bool_Exp>;
  parents_aggregate?: InputMaybe<Kids_Parents_Aggregate_Bool_Exp>;
  pin?: InputMaybe<String_Comparison_Exp>;
  signinMethod?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  theme?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user_playlists?: InputMaybe<User_Playlists_Bool_Exp>;
  user_playlists_aggregate?: InputMaybe<User_Playlists_Aggregate_Bool_Exp>;
  user_watched_videos?: InputMaybe<User_Watched_Videos_Bool_Exp>;
  user_watched_videos_aggregate?: InputMaybe<User_Watched_Videos_Aggregate_Bool_Exp>;
  usersFollowsByFollowerid?: InputMaybe<Users_Follows_Bool_Exp>;
  usersFollowsByFollowerid_aggregate?: InputMaybe<Users_Follows_Aggregate_Bool_Exp>;
  users_categories?: InputMaybe<Users_Categories_Bool_Exp>;
  users_categories_aggregate?: InputMaybe<Users_Categories_Aggregate_Bool_Exp>;
  users_follows?: InputMaybe<Users_Follows_Bool_Exp>;
  users_follows_aggregate?: InputMaybe<Users_Follows_Aggregate_Bool_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
  video_reports?: InputMaybe<Video_Reports_Bool_Exp>;
  video_reports_aggregate?: InputMaybe<Video_Reports_Aggregate_Bool_Exp>;
};

/** columns and relationships of "users_categories" */
export type Users_Categories = {
  __typename?: 'users_categories';
  /** An object relationship */
  category: Categories;
  categoryId: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "users_categories" */
export type Users_Categories_Aggregate = {
  __typename?: 'users_categories_aggregate';
  aggregate?: Maybe<Users_Categories_Aggregate_Fields>;
  nodes: Array<Users_Categories>;
};

export type Users_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Categories_Aggregate_Bool_Exp_Count>;
};

export type Users_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_categories" */
export type Users_Categories_Aggregate_Fields = {
  __typename?: 'users_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Categories_Max_Fields>;
  min?: Maybe<Users_Categories_Min_Fields>;
};


/** aggregate fields of "users_categories" */
export type Users_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_categories" */
export type Users_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Categories_Max_Order_By>;
  min?: InputMaybe<Users_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_categories" */
export type Users_Categories_Arr_Rel_Insert_Input = {
  data: Array<Users_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_categories". All fields are combined with a logical 'AND'. */
export type Users_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Categories_Bool_Exp>>;
  _not?: InputMaybe<Users_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Categories_Bool_Exp>>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_categories" */
export enum Users_Categories_Constraint {
  /** unique or primary key constraint on columns "userId", "categoryId" */
  UsersCategoriesPkey = 'users_categories_pkey'
}

/** input type for inserting data into table "users_categories" */
export type Users_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Categories_Max_Fields = {
  __typename?: 'users_categories_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_categories" */
export type Users_Categories_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Categories_Min_Fields = {
  __typename?: 'users_categories_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_categories" */
export type Users_Categories_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_categories" */
export type Users_Categories_Mutation_Response = {
  __typename?: 'users_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Categories>;
};

/** on_conflict condition type for table "users_categories" */
export type Users_Categories_On_Conflict = {
  constraint: Users_Categories_Constraint;
  update_columns?: Array<Users_Categories_Update_Column>;
  where?: InputMaybe<Users_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "users_categories". */
export type Users_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_categories */
export type Users_Categories_Pk_Columns_Input = {
  categoryId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "users_categories" */
export enum Users_Categories_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "users_categories" */
export type Users_Categories_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_categories" */
export type Users_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Categories_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_categories" */
export enum Users_Categories_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  UserId = 'userId'
}

export type Users_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Categories_Bool_Exp;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** columns and relationships of "users_follows" */
export type Users_Follows = {
  __typename?: 'users_follows';
  createdAt: Scalars['timestamp']['output'];
  /** An object relationship */
  followee: Users;
  followeeId: Scalars['String']['output'];
  /** An object relationship */
  follower: Users;
  followerId: Scalars['String']['output'];
};

/** aggregated selection of "users_follows" */
export type Users_Follows_Aggregate = {
  __typename?: 'users_follows_aggregate';
  aggregate?: Maybe<Users_Follows_Aggregate_Fields>;
  nodes: Array<Users_Follows>;
};

export type Users_Follows_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Follows_Aggregate_Bool_Exp_Count>;
};

export type Users_Follows_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Follows_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_follows" */
export type Users_Follows_Aggregate_Fields = {
  __typename?: 'users_follows_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Follows_Max_Fields>;
  min?: Maybe<Users_Follows_Min_Fields>;
};


/** aggregate fields of "users_follows" */
export type Users_Follows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_follows" */
export type Users_Follows_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Follows_Max_Order_By>;
  min?: InputMaybe<Users_Follows_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_follows" */
export type Users_Follows_Arr_Rel_Insert_Input = {
  data: Array<Users_Follows_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Follows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_follows". All fields are combined with a logical 'AND'. */
export type Users_Follows_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Follows_Bool_Exp>>;
  _not?: InputMaybe<Users_Follows_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Follows_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  followee?: InputMaybe<Users_Bool_Exp>;
  followeeId?: InputMaybe<String_Comparison_Exp>;
  follower?: InputMaybe<Users_Bool_Exp>;
  followerId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_follows" */
export enum Users_Follows_Constraint {
  /** unique or primary key constraint on columns "followerId", "followeeId" */
  UsersFollowsPkey = 'users_follows_pkey'
}

/** input type for inserting data into table "users_follows" */
export type Users_Follows_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  followee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  followeeId?: InputMaybe<Scalars['String']['input']>;
  follower?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  followerId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Follows_Max_Fields = {
  __typename?: 'users_follows_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  followeeId?: Maybe<Scalars['String']['output']>;
  followerId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_follows" */
export type Users_Follows_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followeeId?: InputMaybe<Order_By>;
  followerId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Follows_Min_Fields = {
  __typename?: 'users_follows_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  followeeId?: Maybe<Scalars['String']['output']>;
  followerId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_follows" */
export type Users_Follows_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followeeId?: InputMaybe<Order_By>;
  followerId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_follows" */
export type Users_Follows_Mutation_Response = {
  __typename?: 'users_follows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Follows>;
};

/** on_conflict condition type for table "users_follows" */
export type Users_Follows_On_Conflict = {
  constraint: Users_Follows_Constraint;
  update_columns?: Array<Users_Follows_Update_Column>;
  where?: InputMaybe<Users_Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "users_follows". */
export type Users_Follows_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  followee?: InputMaybe<Users_Order_By>;
  followeeId?: InputMaybe<Order_By>;
  follower?: InputMaybe<Users_Order_By>;
  followerId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_follows */
export type Users_Follows_Pk_Columns_Input = {
  followeeId: Scalars['String']['input'];
  followerId: Scalars['String']['input'];
};

/** select columns of table "users_follows" */
export enum Users_Follows_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FolloweeId = 'followeeId',
  /** column name */
  FollowerId = 'followerId'
}

/** input type for updating data in table "users_follows" */
export type Users_Follows_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  followeeId?: InputMaybe<Scalars['String']['input']>;
  followerId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_follows" */
export type Users_Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Follows_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  followeeId?: InputMaybe<Scalars['String']['input']>;
  followerId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_follows" */
export enum Users_Follows_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FolloweeId = 'followeeId',
  /** column name */
  FollowerId = 'followerId'
}

export type Users_Follows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Follows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Follows_Bool_Exp;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  channels_follows?: InputMaybe<Channels_Follows_Arr_Rel_Insert_Input>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Obj_Rel_Insert_Input>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  followRequestsBySenderid?: InputMaybe<Follow_Requests_Arr_Rel_Insert_Input>;
  follow_requests?: InputMaybe<Follow_Requests_Arr_Rel_Insert_Input>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  kid_user_setting?: InputMaybe<Kid_User_Settings_Obj_Rel_Insert_Input>;
  kids?: InputMaybe<Kids_Parents_Arr_Rel_Insert_Input>;
  likes?: InputMaybe<Likes_Arr_Rel_Insert_Input>;
  parent_user_setting?: InputMaybe<Parent_User_Settings_Obj_Rel_Insert_Input>;
  parents?: InputMaybe<Kids_Parents_Arr_Rel_Insert_Input>;
  pin?: InputMaybe<Scalars['String']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user_playlists?: InputMaybe<User_Playlists_Arr_Rel_Insert_Input>;
  user_watched_videos?: InputMaybe<User_Watched_Videos_Arr_Rel_Insert_Input>;
  usersFollowsByFollowerid?: InputMaybe<Users_Follows_Arr_Rel_Insert_Input>;
  users_categories?: InputMaybe<Users_Categories_Arr_Rel_Insert_Input>;
  users_follows?: InputMaybe<Users_Follows_Arr_Rel_Insert_Input>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  video_reports?: InputMaybe<Video_Reports_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['date']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
  signinMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['date']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
  signinMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  avatar?: InputMaybe<Order_By>;
  birthDate?: InputMaybe<Order_By>;
  channels_follows_aggregate?: InputMaybe<Channels_Follows_Aggregate_Order_By>;
  chrome_extension_user?: InputMaybe<Chrome_Extension_Users_Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fcmTokens?: InputMaybe<Order_By>;
  followRequestsBySenderid_aggregate?: InputMaybe<Follow_Requests_Aggregate_Order_By>;
  follow_requests_aggregate?: InputMaybe<Follow_Requests_Aggregate_Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPreferencesChoosen?: InputMaybe<Order_By>;
  kid_user_setting?: InputMaybe<Kid_User_Settings_Order_By>;
  kids_aggregate?: InputMaybe<Kids_Parents_Aggregate_Order_By>;
  likes_aggregate?: InputMaybe<Likes_Aggregate_Order_By>;
  parent_user_setting?: InputMaybe<Parent_User_Settings_Order_By>;
  parents_aggregate?: InputMaybe<Kids_Parents_Aggregate_Order_By>;
  pin?: InputMaybe<Order_By>;
  signinMethod?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_playlists_aggregate?: InputMaybe<User_Playlists_Aggregate_Order_By>;
  user_watched_videos_aggregate?: InputMaybe<User_Watched_Videos_Aggregate_Order_By>;
  usersFollowsByFollowerid_aggregate?: InputMaybe<Users_Follows_Aggregate_Order_By>;
  users_categories_aggregate?: InputMaybe<Users_Categories_Aggregate_Order_By>;
  users_follows_aggregate?: InputMaybe<Users_Follows_Aggregate_Order_By>;
  verified?: InputMaybe<Order_By>;
  video_reports_aggregate?: InputMaybe<Video_Reports_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** columns and relationships of "users_privacy" */
export type Users_Privacy = {
  __typename?: 'users_privacy';
  badges: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  follows: Scalars['String']['output'];
  id: Scalars['String']['output'];
  posts: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "users_privacy" */
export type Users_Privacy_Aggregate = {
  __typename?: 'users_privacy_aggregate';
  aggregate?: Maybe<Users_Privacy_Aggregate_Fields>;
  nodes: Array<Users_Privacy>;
};

/** aggregate fields of "users_privacy" */
export type Users_Privacy_Aggregate_Fields = {
  __typename?: 'users_privacy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Privacy_Max_Fields>;
  min?: Maybe<Users_Privacy_Min_Fields>;
};


/** aggregate fields of "users_privacy" */
export type Users_Privacy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Privacy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users_privacy". All fields are combined with a logical 'AND'. */
export type Users_Privacy_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Privacy_Bool_Exp>>;
  _not?: InputMaybe<Users_Privacy_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Privacy_Bool_Exp>>;
  badges?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  follows?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  posts?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_privacy" */
export enum Users_Privacy_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPrivacyPkey = 'users_privacy_pkey',
  /** unique or primary key constraint on columns "userId" */
  UsersPrivacyUserIdKey = 'users_privacy_userId_key'
}

/** input type for inserting data into table "users_privacy" */
export type Users_Privacy_Insert_Input = {
  badges?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  follows?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  posts?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Privacy_Max_Fields = {
  __typename?: 'users_privacy_max_fields';
  badges?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  follows?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Privacy_Min_Fields = {
  __typename?: 'users_privacy_min_fields';
  badges?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  follows?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_privacy" */
export type Users_Privacy_Mutation_Response = {
  __typename?: 'users_privacy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Privacy>;
};

/** on_conflict condition type for table "users_privacy" */
export type Users_Privacy_On_Conflict = {
  constraint: Users_Privacy_Constraint;
  update_columns?: Array<Users_Privacy_Update_Column>;
  where?: InputMaybe<Users_Privacy_Bool_Exp>;
};

/** Ordering options when selecting data from "users_privacy". */
export type Users_Privacy_Order_By = {
  badges?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  follows?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  posts?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_privacy */
export type Users_Privacy_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "users_privacy" */
export enum Users_Privacy_Select_Column {
  /** column name */
  Badges = 'badges',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Follows = 'follows',
  /** column name */
  Id = 'id',
  /** column name */
  Posts = 'posts',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "users_privacy" */
export type Users_Privacy_Set_Input = {
  badges?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  follows?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  posts?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_privacy" */
export type Users_Privacy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Privacy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Privacy_Stream_Cursor_Value_Input = {
  badges?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  follows?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  posts?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_privacy" */
export enum Users_Privacy_Update_Column {
  /** column name */
  Badges = 'badges',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Follows = 'follows',
  /** column name */
  Id = 'id',
  /** column name */
  Posts = 'posts',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Users_Privacy_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Privacy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Privacy_Bool_Exp;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreferencesChoosen = 'isPreferencesChoosen',
  /** column name */
  Pin = 'pin',
  /** column name */
  SigninMethod = 'signinMethod',
  /** column name */
  Status = 'status',
  /** column name */
  Theme = 'theme',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreferencesChoosen = 'isPreferencesChoosen',
  /** column name */
  Pin = 'pin',
  /** column name */
  SigninMethod = 'signinMethod',
  /** column name */
  Status = 'status',
  /** column name */
  Theme = 'theme',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Verified = 'verified'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** columns and relationships of "video_reports" */
export type Video_Reports = {
  __typename?: 'video_reports';
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  status: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  video?: Maybe<Videos>;
  videoDeletedAt?: Maybe<Scalars['timestamp']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoLink?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "video_reports" */
export type Video_Reports_Aggregate = {
  __typename?: 'video_reports_aggregate';
  aggregate?: Maybe<Video_Reports_Aggregate_Fields>;
  nodes: Array<Video_Reports>;
};

export type Video_Reports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Reports_Aggregate_Bool_Exp_Count>;
};

export type Video_Reports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Video_Reports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_reports" */
export type Video_Reports_Aggregate_Fields = {
  __typename?: 'video_reports_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Video_Reports_Max_Fields>;
  min?: Maybe<Video_Reports_Min_Fields>;
};


/** aggregate fields of "video_reports" */
export type Video_Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "video_reports" */
export type Video_Reports_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Reports_Max_Order_By>;
  min?: InputMaybe<Video_Reports_Min_Order_By>;
};

/** input type for inserting array relation for remote table "video_reports" */
export type Video_Reports_Arr_Rel_Insert_Input = {
  data: Array<Video_Reports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Reports_On_Conflict>;
};

/** Boolean expression to filter rows from the table "video_reports". All fields are combined with a logical 'AND'. */
export type Video_Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Reports_Bool_Exp>>;
  _not?: InputMaybe<Video_Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Reports_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoDeletedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
  videoLink?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_reports" */
export enum Video_Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideoReportsPkey = 'video_reports_pkey',
  /** unique or primary key constraint on columns "userId", "videoId" */
  VideoReportsUserIdVideoIdKey = 'video_reports_userId_videoId_key'
}

/** input type for inserting data into table "video_reports" */
export type Video_Reports_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoDeletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
  videoLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Video_Reports_Max_Fields = {
  __typename?: 'video_reports_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoDeletedAt?: Maybe<Scalars['timestamp']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoLink?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "video_reports" */
export type Video_Reports_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoDeletedAt?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
  videoLink?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Reports_Min_Fields = {
  __typename?: 'video_reports_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  videoDeletedAt?: Maybe<Scalars['timestamp']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoLink?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "video_reports" */
export type Video_Reports_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  videoDeletedAt?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
  videoLink?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_reports" */
export type Video_Reports_Mutation_Response = {
  __typename?: 'video_reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Reports>;
};

/** on_conflict condition type for table "video_reports" */
export type Video_Reports_On_Conflict = {
  constraint: Video_Reports_Constraint;
  update_columns?: Array<Video_Reports_Update_Column>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "video_reports". */
export type Video_Reports_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoDeletedAt?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
  videoLink?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_reports */
export type Video_Reports_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "video_reports" */
export enum Video_Reports_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoDeletedAt = 'videoDeletedAt',
  /** column name */
  VideoId = 'videoId',
  /** column name */
  VideoLink = 'videoLink'
}

/** input type for updating data in table "video_reports" */
export type Video_Reports_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoDeletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
  videoLink?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "video_reports" */
export type Video_Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Reports_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  videoDeletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
  videoLink?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "video_reports" */
export enum Video_Reports_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoDeletedAt = 'videoDeletedAt',
  /** column name */
  VideoId = 'videoId',
  /** column name */
  VideoLink = 'videoLink'
}

export type Video_Reports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Reports_Bool_Exp;
};

/** columns and relationships of "videos" */
export type Videos = {
  __typename?: 'videos';
  aspectRatio: Scalars['float8']['output'];
  /** An object relationship */
  badge_video?: Maybe<Badge_Videos>;
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['String']['output'];
  classification: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['jsonb']['output'];
  duration: Scalars['Int']['output'];
  episode?: Maybe<Scalars['Int']['output']>;
  genre: Scalars['String']['output'];
  id: Scalars['String']['output'];
  likeCount: Scalars['Int']['output'];
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  link: Scalars['String']['output'];
  musicType: Scalars['String']['output'];
  searchCount: Scalars['Int']['output'];
  /** An object relationship */
  season?: Maybe<Seasons>;
  seasonId?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  thumbnailUrlHigh?: Maybe<Scalars['String']['output']>;
  title: Scalars['jsonb']['output'];
  trendingFromDate?: Maybe<Scalars['date']['output']>;
  trendingToDate?: Maybe<Scalars['date']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  uploadedDate: Scalars['timestamp']['output'];
  uploadingDate?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  video_reports: Array<Video_Reports>;
  /** An aggregate relationship */
  video_reports_aggregate: Video_Reports_Aggregate;
  /** An array relationship */
  videos_contents: Array<Videos_Contents>;
  /** An aggregate relationship */
  videos_contents_aggregate: Videos_Contents_Aggregate;
  viewCount: Scalars['Int']['output'];
};


/** columns and relationships of "videos" */
export type VideosDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "videos" */
export type VideosLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "videos" */
export type VideosVideo_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosVideo_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Video_Reports_Order_By>>;
  where?: InputMaybe<Video_Reports_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosVideos_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosVideos_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Videos_Contents_Order_By>>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};

/** aggregated selection of "videos" */
export type Videos_Aggregate = {
  __typename?: 'videos_aggregate';
  aggregate?: Maybe<Videos_Aggregate_Fields>;
  nodes: Array<Videos>;
};

export type Videos_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Videos_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Videos_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Videos_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Videos_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Videos_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Videos_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Videos_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Videos_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Videos_Aggregate_Bool_Exp_Var_Samp>;
};

export type Videos_Aggregate_Bool_Exp_Avg = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Corr = {
  arguments: Videos_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Videos_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Videos_Aggregate_Bool_Exp_Max = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Min = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Sum = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Videos_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Videos_Select_Column_Videos_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "videos" */
export type Videos_Aggregate_Fields = {
  __typename?: 'videos_aggregate_fields';
  avg?: Maybe<Videos_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Videos_Max_Fields>;
  min?: Maybe<Videos_Min_Fields>;
  stddev?: Maybe<Videos_Stddev_Fields>;
  stddev_pop?: Maybe<Videos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Videos_Stddev_Samp_Fields>;
  sum?: Maybe<Videos_Sum_Fields>;
  var_pop?: Maybe<Videos_Var_Pop_Fields>;
  var_samp?: Maybe<Videos_Var_Samp_Fields>;
  variance?: Maybe<Videos_Variance_Fields>;
};


/** aggregate fields of "videos" */
export type Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "videos" */
export type Videos_Aggregate_Order_By = {
  avg?: InputMaybe<Videos_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Max_Order_By>;
  min?: InputMaybe<Videos_Min_Order_By>;
  stddev?: InputMaybe<Videos_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Videos_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Videos_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Videos_Sum_Order_By>;
  var_pop?: InputMaybe<Videos_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Videos_Var_Samp_Order_By>;
  variance?: InputMaybe<Videos_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Videos_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "videos" */
export type Videos_Arr_Rel_Insert_Input = {
  data: Array<Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** aggregate avg on columns */
export type Videos_Avg_Fields = {
  __typename?: 'videos_avg_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "videos" */
export type Videos_Avg_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "videos". All fields are combined with a logical 'AND'. */
export type Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Bool_Exp>>;
  _not?: InputMaybe<Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Bool_Exp>>;
  aspectRatio?: InputMaybe<Float8_Comparison_Exp>;
  badge_video?: InputMaybe<Badge_Videos_Bool_Exp>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channelId?: InputMaybe<String_Comparison_Exp>;
  classification?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  episode?: InputMaybe<Int_Comparison_Exp>;
  genre?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  likeCount?: InputMaybe<Int_Comparison_Exp>;
  likes?: InputMaybe<Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Likes_Aggregate_Bool_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  musicType?: InputMaybe<String_Comparison_Exp>;
  searchCount?: InputMaybe<Int_Comparison_Exp>;
  season?: InputMaybe<Seasons_Bool_Exp>;
  seasonId?: InputMaybe<String_Comparison_Exp>;
  thumbnailUrl?: InputMaybe<String_Comparison_Exp>;
  thumbnailUrlHigh?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  trendingFromDate?: InputMaybe<Date_Comparison_Exp>;
  trendingToDate?: InputMaybe<Date_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  uploadedDate?: InputMaybe<Timestamp_Comparison_Exp>;
  uploadingDate?: InputMaybe<Date_Comparison_Exp>;
  video_reports?: InputMaybe<Video_Reports_Bool_Exp>;
  video_reports_aggregate?: InputMaybe<Video_Reports_Aggregate_Bool_Exp>;
  videos_contents?: InputMaybe<Videos_Contents_Bool_Exp>;
  videos_contents_aggregate?: InputMaybe<Videos_Contents_Aggregate_Bool_Exp>;
  viewCount?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "videos" */
export enum Videos_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideosPkey = 'videos_pkey',
  /** unique or primary key constraint on columns "seasonId", "episode" */
  VideosSeasonIdEpisodeKey = 'videos_seasonId_episode_key',
  /** unique or primary key constraint on columns "title" */
  VideosTitleKey = 'videos_title_key'
}

/** columns and relationships of "videos_contents" */
export type Videos_Contents = {
  __typename?: 'videos_contents';
  /** An object relationship */
  content: Contents;
  contentId: Scalars['String']['output'];
  /** An object relationship */
  video: Videos;
  videoId: Scalars['String']['output'];
};

/** aggregated selection of "videos_contents" */
export type Videos_Contents_Aggregate = {
  __typename?: 'videos_contents_aggregate';
  aggregate?: Maybe<Videos_Contents_Aggregate_Fields>;
  nodes: Array<Videos_Contents>;
};

export type Videos_Contents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Videos_Contents_Aggregate_Bool_Exp_Count>;
};

export type Videos_Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Videos_Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "videos_contents" */
export type Videos_Contents_Aggregate_Fields = {
  __typename?: 'videos_contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Videos_Contents_Max_Fields>;
  min?: Maybe<Videos_Contents_Min_Fields>;
};


/** aggregate fields of "videos_contents" */
export type Videos_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "videos_contents" */
export type Videos_Contents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Contents_Max_Order_By>;
  min?: InputMaybe<Videos_Contents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "videos_contents" */
export type Videos_Contents_Arr_Rel_Insert_Input = {
  data: Array<Videos_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_Contents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "videos_contents". All fields are combined with a logical 'AND'. */
export type Videos_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Contents_Bool_Exp>>;
  _not?: InputMaybe<Videos_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Contents_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  contentId?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  videoId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "videos_contents" */
export enum Videos_Contents_Constraint {
  /** unique or primary key constraint on columns "contentId", "videoId" */
  VideosContentsPkey = 'videos_contents_pkey'
}

/** input type for inserting data into table "videos_contents" */
export type Videos_Contents_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  contentId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Videos_Contents_Max_Fields = {
  __typename?: 'videos_contents_max_fields';
  contentId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "videos_contents" */
export type Videos_Contents_Max_Order_By = {
  contentId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Contents_Min_Fields = {
  __typename?: 'videos_contents_min_fields';
  contentId?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "videos_contents" */
export type Videos_Contents_Min_Order_By = {
  contentId?: InputMaybe<Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos_contents" */
export type Videos_Contents_Mutation_Response = {
  __typename?: 'videos_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos_Contents>;
};

/** on_conflict condition type for table "videos_contents" */
export type Videos_Contents_On_Conflict = {
  constraint: Videos_Contents_Constraint;
  update_columns?: Array<Videos_Contents_Update_Column>;
  where?: InputMaybe<Videos_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "videos_contents". */
export type Videos_Contents_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  contentId?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  videoId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videos_contents */
export type Videos_Contents_Pk_Columns_Input = {
  contentId: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

/** select columns of table "videos_contents" */
export enum Videos_Contents_Select_Column {
  /** column name */
  ContentId = 'contentId',
  /** column name */
  VideoId = 'videoId'
}

/** input type for updating data in table "videos_contents" */
export type Videos_Contents_Set_Input = {
  contentId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "videos_contents" */
export type Videos_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Videos_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Videos_Contents_Stream_Cursor_Value_Input = {
  contentId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "videos_contents" */
export enum Videos_Contents_Update_Column {
  /** column name */
  ContentId = 'contentId',
  /** column name */
  VideoId = 'videoId'
}

export type Videos_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Videos_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Videos_Contents_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Videos_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Videos_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Videos_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "videos" */
export type Videos_Inc_Input = {
  aspectRatio?: InputMaybe<Scalars['float8']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  searchCount?: InputMaybe<Scalars['Int']['input']>;
  viewCount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "videos" */
export type Videos_Insert_Input = {
  aspectRatio?: InputMaybe<Scalars['float8']['input']>;
  badge_video?: InputMaybe<Badge_Videos_Obj_Rel_Insert_Input>;
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  likes?: InputMaybe<Likes_Arr_Rel_Insert_Input>;
  link?: InputMaybe<Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
  searchCount?: InputMaybe<Scalars['Int']['input']>;
  season?: InputMaybe<Seasons_Obj_Rel_Insert_Input>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrlHigh?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  trendingFromDate?: InputMaybe<Scalars['date']['input']>;
  trendingToDate?: InputMaybe<Scalars['date']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  uploadedDate?: InputMaybe<Scalars['timestamp']['input']>;
  uploadingDate?: InputMaybe<Scalars['date']['input']>;
  video_reports?: InputMaybe<Video_Reports_Arr_Rel_Insert_Input>;
  videos_contents?: InputMaybe<Videos_Contents_Arr_Rel_Insert_Input>;
  viewCount?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Videos_Max_Fields = {
  __typename?: 'videos_max_fields';
  aspectRatio?: Maybe<Scalars['float8']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  episode?: Maybe<Scalars['Int']['output']>;
  genre?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  musicType?: Maybe<Scalars['String']['output']>;
  searchCount?: Maybe<Scalars['Int']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrlHigh?: Maybe<Scalars['String']['output']>;
  trendingFromDate?: Maybe<Scalars['date']['output']>;
  trendingToDate?: Maybe<Scalars['date']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  uploadedDate?: Maybe<Scalars['timestamp']['output']>;
  uploadingDate?: Maybe<Scalars['date']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "videos" */
export type Videos_Max_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  channelId?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  musicType?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  seasonId?: InputMaybe<Order_By>;
  thumbnailUrl?: InputMaybe<Order_By>;
  thumbnailUrlHigh?: InputMaybe<Order_By>;
  trendingFromDate?: InputMaybe<Order_By>;
  trendingToDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedDate?: InputMaybe<Order_By>;
  uploadingDate?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Min_Fields = {
  __typename?: 'videos_min_fields';
  aspectRatio?: Maybe<Scalars['float8']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  episode?: Maybe<Scalars['Int']['output']>;
  genre?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  musicType?: Maybe<Scalars['String']['output']>;
  searchCount?: Maybe<Scalars['Int']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrlHigh?: Maybe<Scalars['String']['output']>;
  trendingFromDate?: Maybe<Scalars['date']['output']>;
  trendingToDate?: Maybe<Scalars['date']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  uploadedDate?: Maybe<Scalars['timestamp']['output']>;
  uploadingDate?: Maybe<Scalars['date']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "videos" */
export type Videos_Min_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  channelId?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  musicType?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  seasonId?: InputMaybe<Order_By>;
  thumbnailUrl?: InputMaybe<Order_By>;
  thumbnailUrlHigh?: InputMaybe<Order_By>;
  trendingFromDate?: InputMaybe<Order_By>;
  trendingToDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedDate?: InputMaybe<Order_By>;
  uploadingDate?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos" */
export type Videos_Mutation_Response = {
  __typename?: 'videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos>;
};

/** input type for inserting object relation for remote table "videos" */
export type Videos_Obj_Rel_Insert_Input = {
  data: Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** on_conflict condition type for table "videos" */
export type Videos_On_Conflict = {
  constraint: Videos_Constraint;
  update_columns?: Array<Videos_Update_Column>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "videos". */
export type Videos_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  badge_video?: InputMaybe<Badge_Videos_Order_By>;
  channel?: InputMaybe<Channels_Order_By>;
  channelId?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Likes_Aggregate_Order_By>;
  link?: InputMaybe<Order_By>;
  musicType?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  season?: InputMaybe<Seasons_Order_By>;
  seasonId?: InputMaybe<Order_By>;
  thumbnailUrl?: InputMaybe<Order_By>;
  thumbnailUrlHigh?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  trendingFromDate?: InputMaybe<Order_By>;
  trendingToDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedDate?: InputMaybe<Order_By>;
  uploadingDate?: InputMaybe<Order_By>;
  video_reports_aggregate?: InputMaybe<Video_Reports_Aggregate_Order_By>;
  videos_contents_aggregate?: InputMaybe<Videos_Contents_Aggregate_Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videos */
export type Videos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Videos_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "videos" */
export enum Videos_Select_Column {
  /** column name */
  AspectRatio = 'aspectRatio',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Classification = 'classification',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Episode = 'episode',
  /** column name */
  Genre = 'genre',
  /** column name */
  Id = 'id',
  /** column name */
  LikeCount = 'likeCount',
  /** column name */
  Link = 'link',
  /** column name */
  MusicType = 'musicType',
  /** column name */
  SearchCount = 'searchCount',
  /** column name */
  SeasonId = 'seasonId',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  ThumbnailUrlHigh = 'thumbnailUrlHigh',
  /** column name */
  Title = 'title',
  /** column name */
  TrendingFromDate = 'trendingFromDate',
  /** column name */
  TrendingToDate = 'trendingToDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedDate = 'uploadedDate',
  /** column name */
  UploadingDate = 'uploadingDate',
  /** column name */
  ViewCount = 'viewCount'
}

/** select "videos_aggregate_bool_exp_avg_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_corr_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_max_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_min_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_sum_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** select "videos_aggregate_bool_exp_var_samp_arguments_columns" columns of table "videos" */
export enum Videos_Select_Column_Videos_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AspectRatio = 'aspectRatio'
}

/** input type for updating data in table "videos" */
export type Videos_Set_Input = {
  aspectRatio?: InputMaybe<Scalars['float8']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
  searchCount?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrlHigh?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  trendingFromDate?: InputMaybe<Scalars['date']['input']>;
  trendingToDate?: InputMaybe<Scalars['date']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  uploadedDate?: InputMaybe<Scalars['timestamp']['input']>;
  uploadingDate?: InputMaybe<Scalars['date']['input']>;
  viewCount?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Videos_Stddev_Fields = {
  __typename?: 'videos_stddev_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "videos" */
export type Videos_Stddev_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Videos_Stddev_Pop_Fields = {
  __typename?: 'videos_stddev_pop_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "videos" */
export type Videos_Stddev_Pop_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Videos_Stddev_Samp_Fields = {
  __typename?: 'videos_stddev_samp_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "videos" */
export type Videos_Stddev_Samp_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "videos" */
export type Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Videos_Stream_Cursor_Value_Input = {
  aspectRatio?: InputMaybe<Scalars['float8']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
  searchCount?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrlHigh?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  trendingFromDate?: InputMaybe<Scalars['date']['input']>;
  trendingToDate?: InputMaybe<Scalars['date']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  uploadedDate?: InputMaybe<Scalars['timestamp']['input']>;
  uploadingDate?: InputMaybe<Scalars['date']['input']>;
  viewCount?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Videos_Sum_Fields = {
  __typename?: 'videos_sum_fields';
  aspectRatio?: Maybe<Scalars['float8']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  episode?: Maybe<Scalars['Int']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  searchCount?: Maybe<Scalars['Int']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "videos" */
export type Videos_Sum_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** update columns of table "videos" */
export enum Videos_Update_Column {
  /** column name */
  AspectRatio = 'aspectRatio',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Classification = 'classification',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Episode = 'episode',
  /** column name */
  Genre = 'genre',
  /** column name */
  Id = 'id',
  /** column name */
  LikeCount = 'likeCount',
  /** column name */
  Link = 'link',
  /** column name */
  MusicType = 'musicType',
  /** column name */
  SearchCount = 'searchCount',
  /** column name */
  SeasonId = 'seasonId',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  ThumbnailUrlHigh = 'thumbnailUrlHigh',
  /** column name */
  Title = 'title',
  /** column name */
  TrendingFromDate = 'trendingFromDate',
  /** column name */
  TrendingToDate = 'trendingToDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedDate = 'uploadedDate',
  /** column name */
  UploadingDate = 'uploadingDate',
  /** column name */
  ViewCount = 'viewCount'
}

export type Videos_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Videos_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Videos_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Videos_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Videos_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Videos_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Videos_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Videos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Videos_Var_Pop_Fields = {
  __typename?: 'videos_var_pop_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "videos" */
export type Videos_Var_Pop_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Videos_Var_Samp_Fields = {
  __typename?: 'videos_var_samp_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "videos" */
export type Videos_Var_Samp_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Videos_Variance_Fields = {
  __typename?: 'videos_variance_fields';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  episode?: Maybe<Scalars['Float']['output']>;
  likeCount?: Maybe<Scalars['Float']['output']>;
  searchCount?: Maybe<Scalars['Float']['output']>;
  viewCount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "videos" */
export type Videos_Variance_Order_By = {
  aspectRatio?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  episode?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  searchCount?: InputMaybe<Order_By>;
  viewCount?: InputMaybe<Order_By>;
};

export type ChangeAiSuggestionStatusMutationVariables = Exact<{
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChangeAiSuggestionStatusMutation = { __typename?: 'mutation_root', createAISuggestedVideoReviewByAdmin: { __typename?: 'SuggestedVideoReview', id: string } };

export type EditAiSuggestionSettingsMutationVariables = Exact<{
  updateSuggestionSettingInput?: InputMaybe<UpdateSuggestionSettingInput>;
}>;


export type EditAiSuggestionSettingsMutation = { __typename?: 'mutation_root', updateAISuggestionSettings: { __typename?: 'SuggestionSetting', id: string } };

export type PublishAiSuggestionVideoMutationVariables = Exact<{
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslateInput>;
  description?: InputMaybe<TranslateInput>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
}>;


export type PublishAiSuggestionVideoMutation = { __typename?: 'mutation_root', publishAISuggestedVideo: { __typename?: 'AISuggestedVideo', id: string } };

export type CreateAiSuggestionKeywordMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateAiSuggestionKeywordMutation = { __typename?: 'mutation_root', insert_ai_suggestion_keywords_one?: { __typename?: 'ai_suggestion_keywords', id: string } | null };

export type DeleteAiSuggestionKeywordMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteAiSuggestionKeywordMutation = { __typename?: 'mutation_root', delete_ai_suggestion_keywords_by_pk?: { __typename?: 'ai_suggestion_keywords', id: string } | null };

export type CreateRoleActionMutationVariables = Exact<{
  roleId?: InputMaybe<Scalars['String']['input']>;
  actionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateRoleActionMutation = { __typename?: 'mutation_root', insert_roles_actions_one?: { __typename?: 'roles_actions', actionId: string } | null };

export type DeleteRoleActionMutationVariables = Exact<{
  roleId?: InputMaybe<Scalars['String']['input']>;
  actionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteRoleActionMutation = { __typename?: 'mutation_root', delete_roles_actions_by_pk?: { __typename?: 'roles_actions', actionId: string } | null };

export type CreateAdminMutationVariables = Exact<{
  username?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAdminMutation = { __typename?: 'mutation_root', createAdmin?: { __typename?: 'AdminAuthResponse', user?: { __typename?: 'Admin', id: string } | null } | null };

export type EditAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditAdminMutation = { __typename?: 'mutation_root', updateAdmin?: { __typename?: 'Admin', id: string } | null };

export type DeleteAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteAdminMutation = { __typename?: 'mutation_root', removeAdmin?: { __typename?: 'Admin', id: string } | null };

export type DeActivateAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DeActivateAdminMutation = { __typename?: 'mutation_root', deActivateAdmin?: { __typename?: 'Admin', id: string } | null };

export type ReActivateAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReActivateAdminMutation = { __typename?: 'mutation_root', reActivateAdmin?: { __typename?: 'Admin', id: string } | null };

export type CreateAppDiscoveryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateAppDiscoveryMutation = { __typename?: 'mutation_root', insert_heared_methods_one?: { __typename?: 'heared_methods', id: string } | null };

export type EditAppDiscoveryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditAppDiscoveryMutation = { __typename?: 'mutation_root', update_heared_methods_by_pk?: { __typename?: 'heared_methods', id: string } | null };

export type DeleteAppDiscoveryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteAppDiscoveryMutation = { __typename?: 'mutation_root', delete_heared_methods_by_pk?: { __typename?: 'heared_methods', id: string } | null };

export type ChangeAppDiscoveryStatusMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChangeAppDiscoveryStatusMutation = { __typename?: 'mutation_root', update_heared_methods_by_pk?: { __typename?: 'heared_methods', id: string } | null };

export type CreateBadgeMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateBadgeMutation = { __typename?: 'mutation_root', insert_badges_one?: { __typename?: 'badges', id: string } | null };

export type EditBadgeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditBadgeMutation = { __typename?: 'mutation_root', update_badges_by_pk?: { __typename?: 'badges', id: string } | null };

export type DeleteBadgeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteBadgeMutation = { __typename?: 'mutation_root', delete_badges_by_pk?: { __typename?: 'badges', id: string } | null };

export type PublishBadgeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PublishBadgeMutation = { __typename?: 'mutation_root', update_badges_by_pk?: { __typename?: 'badges', id: string } | null };

export type CreateBadgeVideoMutationVariables = Exact<{
  badgeId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateBadgeVideoMutation = { __typename?: 'mutation_root', insert_badge_videos_one?: { __typename?: 'badge_videos', id: string } | null };

export type DeleteBadgeVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteBadgeVideoMutation = { __typename?: 'mutation_root', delete_badge_videos_by_pk?: { __typename?: 'badge_videos', id: string } | null };

export type CreateCategoryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  categories_contents?: InputMaybe<Categories_Contents_Arr_Rel_Insert_Input>;
}>;


export type CreateCategoryMutation = { __typename?: 'mutation_root', insert_categories_one?: { __typename?: 'categories', id: string } | null };

export type EditCategoryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditCategoryMutation = { __typename?: 'mutation_root', update_categories_by_pk?: { __typename?: 'categories', id: string } | null };

export type DeleteCategoryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteCategoryMutation = { __typename?: 'mutation_root', delete_categories_by_pk?: { __typename?: 'categories', id: string } | null };

export type CreateChannelMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  maxTargetAge?: InputMaybe<Scalars['Int']['input']>;
  minTargetAge?: InputMaybe<Scalars['Int']['input']>;
  channels_contents?: InputMaybe<Channels_Contents_Arr_Rel_Insert_Input>;
}>;


export type CreateChannelMutation = { __typename?: 'mutation_root', insert_channels_one?: { __typename?: 'channels', id: string } | null };

export type EditChannelMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditChannelMutation = { __typename?: 'mutation_root', update_channels_by_pk?: { __typename?: 'channels', id: string } | null };

export type DeleteChannelMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteChannelMutation = { __typename?: 'mutation_root', delete_channels_by_pk?: { __typename?: 'channels', id: string } | null };

export type ChangeSuggestionStatusMutationVariables = Exact<{
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChangeSuggestionStatusMutation = { __typename?: 'mutation_root', createChromeExtSuggestedVideoReviewByAdmin: { __typename?: 'SuggestedVideoReview', id: string } };

export type PublishSuggestionVideoMutationVariables = Exact<{
  suggestedVideoId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslateInput>;
  description?: InputMaybe<TranslateInput>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
}>;


export type PublishSuggestionVideoMutation = { __typename?: 'mutation_root', publishChromeExtSuggestedVideo: { __typename?: 'ChromeExtSuggestedVideo', id: string } };

export type EditSuggestionSettingsMutationVariables = Exact<{
  updateSuggestionSettingInput?: InputMaybe<UpdateSuggestionSettingInput>;
}>;


export type EditSuggestionSettingsMutation = { __typename?: 'mutation_root', updateChromeExtSuggestionSettings: { __typename?: 'SuggestionSetting', id: string } };

export type PublishComingSoonVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
}>;


export type PublishComingSoonVideoMutation = { __typename?: 'mutation_root', publishComingSoonVideo: { __typename?: 'Video', id: string } };

export type CreateFaqMutationVariables = Exact<{
  question?: InputMaybe<Scalars['jsonb']['input']>;
  answer?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateFaqMutation = { __typename?: 'mutation_root', insert_faq_one?: { __typename?: 'faq', id: string } | null };

export type EditFaqMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  answer?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditFaqMutation = { __typename?: 'mutation_root', update_faq_by_pk?: { __typename?: 'faq', id: string } | null };

export type DeleteFaqMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteFaqMutation = { __typename?: 'mutation_root', delete_faq_by_pk?: { __typename?: 'faq', id: string } | null };

export type CreateNotificationMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateNotificationMutation = { __typename?: 'mutation_root', createGeneralMessage: { __typename?: 'GeneralMessage', title: string } };

export type DeleteNotificationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteNotificationMutation = { __typename?: 'mutation_root', delete_general_messages_by_pk?: { __typename?: 'general_messages', id: string } | null };

export type CreatePrivacyPolicyMutationVariables = Exact<{
  content?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreatePrivacyPolicyMutation = { __typename?: 'mutation_root', insert_privacy_policy_one?: { __typename?: 'privacy_policy', id: string } | null };

export type EditPrivacyPolicyMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditPrivacyPolicyMutation = { __typename?: 'mutation_root', update_privacy_policy_by_pk?: { __typename?: 'privacy_policy', id: string } | null };

export type AllowReportedVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllowReportedVideoMutation = { __typename?: 'mutation_root', update_video_reports_by_pk?: { __typename?: 'video_reports', id: string } | null };

export type CreateRoleMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  role_actions?: InputMaybe<Roles_Actions_Arr_Rel_Insert_Input>;
}>;


export type CreateRoleMutation = { __typename?: 'mutation_root', insert_roles_one?: { __typename?: 'roles', id: string } | null };

export type EditRoleMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditRoleMutation = { __typename?: 'mutation_root', update_roles_by_pk?: { __typename?: 'roles', id: string } | null };

export type DeleteRoleMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteRoleMutation = { __typename?: 'mutation_root', delete_roles_by_pk?: { __typename?: 'roles', id: string } | null };

export type CreateSeasonMutationVariables = Exact<{
  serieId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateSeasonMutation = { __typename?: 'mutation_root', insert_seasons_one?: { __typename?: 'seasons', id: string } | null };

export type EditSeasonMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditSeasonMutation = { __typename?: 'mutation_root', update_seasons_by_pk?: { __typename?: 'seasons', id: string } | null };

export type DeleteSeasonMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteSeasonMutation = { __typename?: 'mutation_root', delete_seasons_by_pk?: { __typename?: 'seasons', id: string } | null };

export type CreateSeriesMutationVariables = Exact<{
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateSeriesMutation = { __typename?: 'mutation_root', insert_series_one?: { __typename?: 'series', id: string } | null };

export type EditSeriesMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditSeriesMutation = { __typename?: 'mutation_root', update_series_by_pk?: { __typename?: 'series', id: string } | null };

export type DeleteSerieMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteSerieMutation = { __typename?: 'mutation_root', delete_series_by_pk?: { __typename?: 'series', id: string } | null };

export type ReplyTicketMutationVariables = Exact<{
  ticketId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReplyTicketMutation = { __typename?: 'mutation_root', replyTicket?: { __typename?: 'Ticket', id: string } | null };

export type ChangeTicketStatusMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChangeTicketStatusMutation = { __typename?: 'mutation_root', update_tickets_by_pk?: { __typename?: 'tickets', id: string } | null };

export type CreateUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', createUser?: { __typename?: 'UserAuthResponse', user?: { __typename?: 'User', id: string } | null } | null };

export type EditUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type EditUserMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'User', id: string } | null };

export type DeleteUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', removeUser?: { __typename?: 'User', id: string } | null };

export type DeActivateUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DeActivateUserMutation = { __typename?: 'mutation_root', deActivateUser?: { __typename?: 'User', id: string } | null };

export type ReActivateUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReActivateUserMutation = { __typename?: 'mutation_root', reActivateUser?: { __typename?: 'User', id: string } | null };

export type ResetUserAvatarMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResetUserAvatarMutation = { __typename?: 'mutation_root', resetUserAvatar?: { __typename?: 'User', id: string } | null };

export type CreateVideoMutationVariables = Exact<{
  channelId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TranslateInput>;
  description?: InputMaybe<TranslateInput>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  uploadingDate?: InputMaybe<Scalars['DateTime']['input']>;
  trendingFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  trendingToDate?: InputMaybe<Scalars['DateTime']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
  musicType?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateVideoMutation = { __typename?: 'mutation_root', createVideo: { __typename?: 'Video', id: string } };

export type EditVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<TranslateInput>;
  link?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<TranslateInput>;
  musicType?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditVideoMutation = { __typename?: 'mutation_root', updateVideo: { __typename?: 'Video', id: string } };

export type DeleteVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteVideoMutation = { __typename?: 'mutation_root', delete_videos_by_pk?: { __typename?: 'videos', id: string } | null };

export type CreateEpisodeVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  episode?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateEpisodeVideoMutation = { __typename?: 'mutation_root', updateVideo: { __typename?: 'Video', id: string } };

export type GetAiSuggestionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  keywords?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetAiSuggestionsQuery = { __typename?: 'query_root', ai_suggested_videos: Array<{ __typename?: 'ai_suggested_videos', id: string, title: string, keywords: string, status: string, link: string, suggested_videos_reviews: Array<{ __typename?: 'ai_suggested_videos_reviews', adminId?: string | null, chromeExtId?: string | null }> }>, ai_suggested_videos_aggregate: { __typename?: 'ai_suggested_videos_aggregate', aggregate?: { __typename?: 'ai_suggested_videos_aggregate_fields', count: number } | null } };

export type GetAiSuggestionByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAiSuggestionByIdQuery = { __typename?: 'query_root', ai_suggested_videos_by_pk?: { __typename?: 'ai_suggested_videos', id: string, keywords: string, title: string, description: string, status: string, link: string, createdAt: any } | null };

export type GetAiSuggestionReviewsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  suggestedVideoId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetAiSuggestionReviewsQuery = { __typename?: 'query_root', ai_suggested_videos_reviews: Array<{ __typename?: 'ai_suggested_videos_reviews', id: string, status: string, chrome_extension_user?: { __typename?: 'chrome_extension_users', user: { __typename?: 'users', fullName: string, type: string } } | null, admin?: { __typename?: 'admins', username: string } | null }>, ai_suggested_videos_reviews_aggregate: { __typename?: 'ai_suggested_videos_reviews_aggregate', aggregate?: { __typename?: 'ai_suggested_videos_reviews_aggregate_fields', count: number } | null } };

export type GetAiSuggestionSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAiSuggestionSettingsQuery = { __typename?: 'query_root', suggestion_settings: Array<{ __typename?: 'suggestion_settings', id: string, approvalRules?: Array<any> | null, rejectionRules?: Array<any> | null }> };

export type GetAiSuggestionKeywordsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetAiSuggestionKeywordsQuery = { __typename?: 'query_root', ai_suggestion_keywords: Array<{ __typename?: 'ai_suggestion_keywords', id: string, name: any }>, ai_suggestion_keywords_aggregate: { __typename?: 'ai_suggestion_keywords_aggregate', aggregate?: { __typename?: 'ai_suggestion_keywords_aggregate_fields', count: number } | null } };

export type GetActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActionsQuery = { __typename?: 'query_root', actions: Array<{ __typename?: 'actions', id: string, systemCriteria: string, name: string }> };

export type GetAdminsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roleId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetAdminsQuery = { __typename?: 'query_root', admins: Array<{ __typename?: 'admins', id: string, email: string, username: string, status: string, role: { __typename?: 'roles', id: string, name: string } }>, admins_aggregate: { __typename?: 'admins_aggregate', aggregate?: { __typename?: 'admins_aggregate_fields', count: number } | null } };

export type GetAdminByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAdminByIdQuery = { __typename?: 'query_root', admins_by_pk?: { __typename?: 'admins', id: string, email: string, roleId: string, status: string, username: string, role: { __typename?: 'roles', id: string, name: string } } | null };

export type GetAppDiscoveryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetAppDiscoveryQuery = { __typename?: 'query_root', heared_methods: Array<{ __typename?: 'heared_methods', id: string, name: any, status: string }>, heared_methods_aggregate: { __typename?: 'heared_methods_aggregate', aggregate?: { __typename?: 'heared_methods_aggregate_fields', count: number } | null } };

export type GetAppDiscoveryByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAppDiscoveryByIdQuery = { __typename?: 'query_root', heared_methods_by_pk?: { __typename?: 'heared_methods', id: string, name: any } | null };

export type LoginAdminMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginAdminMutation = { __typename?: 'mutation_root', loginAdmin?: { __typename?: 'AdminAuthResponse', token?: string | null, user?: { __typename?: 'Admin', email: string, username: string, id: string, avatar?: string | null, role?: { __typename?: 'AdminRole', permissions: Array<{ __typename?: 'Permission', id: string, name: string, systemCriteria: string }> } | null } | null } | null };

export type GetBadgesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetBadgesQuery = { __typename?: 'query_root', badges: Array<{ __typename?: 'badges', id: string, avatar?: string | null, name: any, type: string, isPublished: boolean, category?: { __typename?: 'categories', id: string, name: any } | null, badge_videos: Array<{ __typename?: 'badge_videos', videoId: string }> }>, badges_aggregate: { __typename?: 'badges_aggregate', aggregate?: { __typename?: 'badges_aggregate_fields', count: number } | null } };

export type GetBadgeByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBadgeByIdQuery = { __typename?: 'query_root', badges_by_pk?: { __typename?: 'badges', id: string, avatar?: string | null, name: any, description: any, isPublished: boolean, category?: { __typename?: 'categories', id: string, name: any } | null } | null };

export type GetBadgeVideosQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  badgeId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetBadgeVideosQuery = { __typename?: 'query_root', badge_videos: Array<{ __typename?: 'badge_videos', id: string, videoId: string, video: { __typename?: 'videos', id: string, thumbnailUrl: string, title: any, description: any, classification: string, genre: string, link: string } }>, badge_videos_aggregate: { __typename?: 'badge_videos_aggregate', aggregate?: { __typename?: 'badge_videos_aggregate_fields', count: number } | null } };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', id: string, name: any }> };

export type GetCategoriesWithPaginationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetCategoriesWithPaginationQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', id: string, avatar?: string | null, name: any, categories_contents: Array<{ __typename?: 'categories_contents', content: { __typename?: 'contents', id: string, name: any } }> }>, categories_aggregate: { __typename?: 'categories_aggregate', aggregate?: { __typename?: 'categories_aggregate_fields', count: number } | null } };

export type GetCategoryByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCategoryByIdQuery = { __typename?: 'query_root', categories_by_pk?: { __typename?: 'categories', id: string, name: any, avatar?: string | null, color: string } | null };

export type GetChannelsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetChannelsQuery = { __typename?: 'query_root', channels: Array<{ __typename?: 'channels', id: string, avatar?: string | null, name: any, category: { __typename?: 'categories', name: any } }>, channels_aggregate: { __typename?: 'channels_aggregate', aggregate?: { __typename?: 'channels_aggregate_fields', count: number } | null } };

export type GetChannelByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChannelByIdQuery = { __typename?: 'query_root', channels_by_pk?: { __typename?: 'channels', id: string, avatar?: string | null, name: any, description: any, minTargetAge: number, maxTargetAge: number, category: { __typename?: 'categories', name: any }, channels_contents: Array<{ __typename?: 'channels_contents', contentId: string, content: { __typename?: 'contents', name: any } }> } | null };

export type GetChannelsByCategoryQueryVariables = Exact<{
  categoryId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetChannelsByCategoryQuery = { __typename?: 'query_root', channels: Array<{ __typename?: 'channels', id: string, name: any }> };

export type GetChannelVideoByBadgeQueryVariables = Exact<{
  channelId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetChannelVideoByBadgeQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', id: string, title: any }> };

export type GetAllChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllChannelsQuery = { __typename?: 'query_root', channels: Array<{ __typename?: 'channels', id: string, name: any }> };

export type GetSuggestedVideosStatisticsByAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuggestedVideosStatisticsByAdminQuery = { __typename?: 'query_root', getSuggestedVideosStatisticsByAdmin: { __typename?: 'SuggestedVideosStatusStatistics', approved: number, rejected: number, pending: number } };

export type GetChromeExtUsersStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChromeExtUsersStatisticsQuery = { __typename?: 'query_root', users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null }, chrome_extension_users_aggregate: { __typename?: 'chrome_extension_users_aggregate', aggregate?: { __typename?: 'chrome_extension_users_aggregate_fields', count: number } | null } };

export type GetVideosReviewsStatisticsByAdminQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type GetVideosReviewsStatisticsByAdminQuery = { __typename?: 'query_root', getSuggestedVideosReviewsStatisticsByAdmin: { __typename?: 'SuggestedVideosStatusStatistics', approved: number, rejected: number, pending: number } };

export type GetChromeExtensionSuggestionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  chromeExtId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetChromeExtensionSuggestionsQuery = { __typename?: 'query_root', chrome_extension_suggested_videos: Array<{ __typename?: 'chrome_extension_suggested_videos', id: string, title: string, link: string, status: string, suggested_videos_reviews: Array<{ __typename?: 'chrome_extension_suggested_videos_reviews', adminId?: string | null, chromeExtId?: string | null }> }>, chrome_extension_suggested_videos_aggregate: { __typename?: 'chrome_extension_suggested_videos_aggregate', aggregate?: { __typename?: 'chrome_extension_suggested_videos_aggregate_fields', count: number } | null } };

export type GetChromeExtensionSuggestionByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChromeExtensionSuggestionByIdQuery = { __typename?: 'query_root', chrome_extension_suggested_videos_by_pk?: { __typename?: 'chrome_extension_suggested_videos', id: string, title: string, link: string, status: string, description: string, createdAt: any, chrome_extension_user: { __typename?: 'chrome_extension_users', user: { __typename?: 'users', fullName: string } } } | null };

export type GetChromeExtensionSuggestionSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChromeExtensionSuggestionSettingsQuery = { __typename?: 'query_root', suggestion_settings: Array<{ __typename?: 'suggestion_settings', id: string, approvalRules?: Array<any> | null, rejectionRules?: Array<any> | null }> };

export type GetChromeExtensionUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Users_Bool_Exp>;
}>;


export type GetChromeExtensionUsersQuery = { __typename?: 'query_root', chrome_extension_users: Array<{ __typename?: 'chrome_extension_users', id: string, user: { __typename?: 'users', id: string, fullName: string, status: string }, chrome_extension_suggested_videos_aggregate: { __typename?: 'chrome_extension_suggested_videos_aggregate', aggregate?: { __typename?: 'chrome_extension_suggested_videos_aggregate_fields', count: number } | null } }>, chrome_extension_users_aggregate: { __typename?: 'chrome_extension_users_aggregate', aggregate?: { __typename?: 'chrome_extension_users_aggregate_fields', count: number } | null } };

export type GetChromeExtensionVideoVotesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  suggestedVideoId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetChromeExtensionVideoVotesQuery = { __typename?: 'query_root', chrome_extension_suggested_videos_reviews: Array<{ __typename?: 'chrome_extension_suggested_videos_reviews', id: string, status: string, chrome_extension_user?: { __typename?: 'chrome_extension_users', user: { __typename?: 'users', fullName: string, type: string } } | null, admin?: { __typename?: 'admins', username: string } | null }>, chrome_extension_suggested_videos_reviews_aggregate: { __typename?: 'chrome_extension_suggested_videos_reviews_aggregate', aggregate?: { __typename?: 'chrome_extension_suggested_videos_reviews_aggregate_fields', count: number } | null } };

export type GetComingSoonVideosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetComingSoonVideosQuery = { __typename?: 'query_root', getNeedsToPublishVideos: { __typename?: 'NeedsToPublishVideos', today: Array<{ __typename?: 'Video', id: string, title: any, genre: string, thumbnailUrl: string, classification: string }> } };

export type GetNotificationsForComingSoonVideosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsForComingSoonVideosQuery = { __typename?: 'query_root', getNeedsToPublishVideos: { __typename?: 'NeedsToPublishVideos', today: Array<{ __typename?: 'Video', id: string, createdAt: any }>, tomorrow: Array<{ __typename?: 'Video', id: string, createdAt: any }> } };

export type GetContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContentQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', id: string, name: any }> };

export type GetCategoriesContentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesContentsQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', id: string, name: any, categories_contents: Array<{ __typename?: 'categories_contents', content: { __typename?: 'contents', id: string, name: any } }> }> };

export type GetChannelContentQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChannelContentQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', id: string, name: any }> };

export type GetFaqQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetFaqQuery = { __typename?: 'query_root', faq: Array<{ __typename?: 'faq', id: string, question: any, answer: any }>, faq_aggregate: { __typename?: 'faq_aggregate', aggregate?: { __typename?: 'faq_aggregate_fields', count: number } | null } };

export type GetFaqByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetFaqByIdQuery = { __typename?: 'query_root', faq_by_pk?: { __typename?: 'faq', id: string, question: any, answer: any } | null };

export type GetNotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetNotificationsQuery = { __typename?: 'query_root', general_messages: Array<{ __typename?: 'general_messages', id: string, title: string, message: string, privacy: string, channels?: Array<string> | null }>, general_messages_aggregate: { __typename?: 'general_messages_aggregate', aggregate?: { __typename?: 'general_messages_aggregate_fields', count: number } | null } };

export type GetPrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrivacyPolicyQuery = { __typename?: 'query_root', privacy_policy: Array<{ __typename?: 'privacy_policy', id: string, content: any }> };

export type GetVideoReportingStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoReportingStatisticsQuery = { __typename?: 'query_root', getVideoReportingStatisticsCount: Array<{ __typename?: 'StatisticsCount', label: string, count: number }> };

export type GetVideoReportingMonthStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoReportingMonthStatisticsQuery = { __typename?: 'query_root', getVideoReportingMonthStatistics: Array<{ __typename?: 'MonthReportStatistics', month: number, totalReports: number }> };

export type GetReportedVideosQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
}>;


export type GetReportedVideosQuery = { __typename?: 'query_root', video_reports: Array<{ __typename?: 'video_reports', id: string, videoId?: string | null, reason: string, status: string, video?: { __typename?: 'videos', channel: { __typename?: 'channels', category: { __typename?: 'categories', name: any } } } | null }>, video_reports_aggregate: { __typename?: 'video_reports_aggregate', aggregate?: { __typename?: 'video_reports_aggregate_fields', count: number } | null } };

export type GetReportedVideoByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetReportedVideoByIdQuery = { __typename?: 'query_root', video_reports_by_pk?: { __typename?: 'video_reports', id: string, videoId?: string | null, status: string, reason: string, createdAt: any, video?: { __typename?: 'videos', id: string, title: any, link: string, channel: { __typename?: 'channels', category: { __typename?: 'categories', name: any } } } | null } | null };

export type GetRolesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetRolesQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'roles', id: string, name: string, admins: Array<{ __typename?: 'admins', username: string }> }>, roles_aggregate: { __typename?: 'roles_aggregate', aggregate?: { __typename?: 'roles_aggregate_fields', count: number } | null } };

export type GetAllRolesForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRolesForSelectQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'roles', id: string, name: string }> };

export type GetRoleByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRoleByIdQuery = { __typename?: 'query_root', roles_by_pk?: { __typename?: 'roles', id: string, name: string } | null };

export type GetRoleActionsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRoleActionsQuery = { __typename?: 'query_root', roles_actions: Array<{ __typename?: 'roles_actions', action: { __typename?: 'actions', id: string, systemCriteria: string, name: string } }> };

export type GetSeriesSeasonsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serieId?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetSeriesSeasonsQuery = { __typename?: 'query_root', seasons: Array<{ __typename?: 'seasons', id: string, name: any, series: { __typename?: 'series', id: string, name: any } }>, seasons_aggregate: { __typename?: 'seasons_aggregate', aggregate?: { __typename?: 'seasons_aggregate_fields', count: number } | null } };

export type GetSeasonByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSeasonByIdQuery = { __typename?: 'query_root', seasons_by_pk?: { __typename?: 'seasons', id: string, name: any, description: any } | null };

export type GetSeriesWithSeasonsAndEpisodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSeriesWithSeasonsAndEpisodeQuery = { __typename?: 'query_root', series: Array<{ __typename?: 'series', id: string, name: any, seasons: Array<{ __typename?: 'seasons', id: string, name: any, videos: Array<{ __typename?: 'videos', id: string, episode?: number | null }> }> }> };

export type GetSeriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetSeriesQuery = { __typename?: 'query_root', series: Array<{ __typename?: 'series', id: string, name: any }>, series_aggregate: { __typename?: 'series_aggregate', aggregate?: { __typename?: 'series_aggregate_fields', count: number } | null } };

export type GetSeriesByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSeriesByIdQuery = { __typename?: 'query_root', series_by_pk?: { __typename?: 'series', id: string, name: any, description: any } | null };

export type GetDashboardGeneralStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardGeneralStatisticsQuery = { __typename?: 'query_root', getGeneralStatisticsCount: Array<{ __typename?: 'StatisticsCount', label: string, count: number }> };

export type GetDashboardUsersPerMonthsStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardUsersPerMonthsStatisticsQuery = { __typename?: 'query_root', getCreatedUsersMonthsStatistics: Array<{ __typename?: 'MonthStatistics', month: number, count: number }> };

export type GetDashboardEarnedBadgesMonthsStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardEarnedBadgesMonthsStatisticsQuery = { __typename?: 'query_root', getEarnedBadgesMonthsStatistics: Array<{ __typename?: 'MonthStatistics', month: number, count: number }> };

export type GetDashboardTotalSpentTimeStatisticsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type GetDashboardTotalSpentTimeStatisticsQuery = { __typename?: 'query_root', getMinutesUsageForAllUsers: Array<{ __typename?: 'DayStatistics', day: any, totalMinutes: number }> };

export type GetDashboardUsersAgeStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardUsersAgeStatisticsQuery = { __typename?: 'query_root', getUsersAgeStatistics: Array<{ __typename?: 'UsersAgeStatistics', age: number, count: number }> };

export type GetDashboardUserStatusAndTypesStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardUserStatusAndTypesStatisticsQuery = { __typename?: 'query_root', getUsersAccountStatusStatistics: { __typename?: 'UsersAccountsStatusStatistics', accountStatus: Array<{ __typename?: 'StatisticsCount', label: string, count: number }>, accountType: Array<{ __typename?: 'StatisticsCount', label: string, count: number }> } };

export type GetDashboardTopCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardTopCountriesQuery = { __typename?: 'query_root', getUsersCountriesStatistics: Array<{ __typename?: 'UsersCountriesStatistics', country: string, count: number }> };

export type GetDashboardContentStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardContentStatisticsQuery = { __typename?: 'query_root', getContentStatisticsCount: Array<{ __typename?: 'StatisticsCount', label: string, count: number }> };

export type GetDashboardCategoriesWatchedMinutesStatisticsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type GetDashboardCategoriesWatchedMinutesStatisticsQuery = { __typename?: 'query_root', getCategoriesWatchedMinutes: Array<{ __typename?: 'CategoryStatistics', categoryName: any, color: string, totalMinutes: number }> };

export type GetDashboardVideosCategoriesStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardVideosCategoriesStatisticsQuery = { __typename?: 'query_root', getCategoriesVideosStatistics: Array<{ __typename?: 'CategoriesCountStatistics', category: any, count: number }> };

export type GetDashboardUsersCategoriesStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardUsersCategoriesStatisticsQuery = { __typename?: 'query_root', getCategoriesUsersStatistics: Array<{ __typename?: 'CategoriesCountStatistics', category: any, count: number }> };

export type GetTicketsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetTicketsQuery = { __typename?: 'query_root', tickets: Array<{ __typename?: 'tickets', id: string, subject: string, content: string, attachmentUrl?: string | null, status: string }>, tickets_aggregate: { __typename?: 'tickets_aggregate', aggregate?: { __typename?: 'tickets_aggregate_fields', count: number } | null } };

export type GetTicketDetailsAndRepliesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTicketDetailsAndRepliesQuery = { __typename?: 'query_root', tickets_by_pk?: { __typename?: 'tickets', id: string, subject: string, content: string, platform: string, status: string, createdAt: any, attachmentUrl?: string | null, user?: { __typename?: 'users', fullName: string } | null, ticket_replies: Array<{ __typename?: 'ticket_replies', id: string, content: string, createdAt: any }> } | null };

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetUsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, avatar?: string | null, fullName: string, email: string, type: string, status: string, verified: boolean }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } };

export type GetUserByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: string, avatar?: string | null, email: string, fullName: string, status: string, verified: boolean, type: string } | null };

export type GetUserSpentTimeStatisticsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type GetUserSpentTimeStatisticsQuery = { __typename?: 'query_root', getUserSpentTimeStatisticsByAdmin: { __typename?: 'SpentTimeStatistics', daysStatistics: Array<{ __typename?: 'DayStatistics', day: any, totalMinutes: number }> } };

export type GetUserStatisticsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserStatisticsQuery = { __typename?: 'query_root', getUserStatisticsCountByAdmin: Array<{ __typename?: 'StatisticsCount', label: string, count: number }> };

export type GetUserCategoriesStatisticsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type GetUserCategoriesStatisticsQuery = { __typename?: 'query_root', getUserWatchedCategoriesStatisticsByAdmin: { __typename?: 'WatchedCategoriesStatistics', categoriesDetails: Array<{ __typename?: 'CategoryStatistics', categoryName: any, color: string, totalMinutes: number }> } };

export type GetChannelVideosQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  channelId?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetChannelVideosQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', id: string, thumbnailUrl: string, title: any, description: any, classification: string, genre: string, link: string }>, videos_aggregate: { __typename?: 'videos_aggregate', aggregate?: { __typename?: 'videos_aggregate_fields', count: number } | null } };

export type GetVideoByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetVideoByIdQuery = { __typename?: 'query_root', videos_by_pk?: { __typename?: 'videos', id: string, title: any, link: string, description: any, classification: string, uploadingDate?: any | null, musicType: string } | null };

export type GetGenresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenresQuery = { __typename?: 'query_root', genres: Array<{ __typename?: 'genres', name: string }> };

export type GetSeasonVideosQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
}>;


export type GetSeasonVideosQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', id: string, thumbnailUrl: string, title: any, episode?: number | null, link: string }>, videos_aggregate: { __typename?: 'videos_aggregate', aggregate?: { __typename?: 'videos_aggregate_fields', count: number } | null } };

export type GetVideosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideosQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', id: string, title: any }> };


export const ChangeAiSuggestionStatusDocument = `
    mutation ChangeAISuggestionStatus($suggestedVideoId: String = "", $status: String = "") {
  createAISuggestedVideoReviewByAdmin(
    createSuggestedVideoReviewInput: {status: $status, suggestedVideoId: $suggestedVideoId}
  ) {
    id
  }
}
    `;
export const useChangeAiSuggestionStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeAiSuggestionStatusMutation, TError, ChangeAiSuggestionStatusMutationVariables, TContext>) =>
    useMutation<ChangeAiSuggestionStatusMutation, TError, ChangeAiSuggestionStatusMutationVariables, TContext>(
      ['ChangeAISuggestionStatus'],
      (variables?: ChangeAiSuggestionStatusMutationVariables) => fetchData<ChangeAiSuggestionStatusMutation, ChangeAiSuggestionStatusMutationVariables>(ChangeAiSuggestionStatusDocument, variables)(),
      options
    );
export const EditAiSuggestionSettingsDocument = `
    mutation EditAISuggestionSettings($updateSuggestionSettingInput: UpdateSuggestionSettingInput = {approvalRules: {adminsRequiredCount: 0, parentsRequiredCount: 0}, rejectionRules: {adminsRequiredCount: 0, parentsRequiredCount: 0}}) {
  updateAISuggestionSettings(
    updateSuggestionSettingInput: $updateSuggestionSettingInput
  ) {
    id
  }
}
    `;
export const useEditAiSuggestionSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditAiSuggestionSettingsMutation, TError, EditAiSuggestionSettingsMutationVariables, TContext>) =>
    useMutation<EditAiSuggestionSettingsMutation, TError, EditAiSuggestionSettingsMutationVariables, TContext>(
      ['EditAISuggestionSettings'],
      (variables?: EditAiSuggestionSettingsMutationVariables) => fetchData<EditAiSuggestionSettingsMutation, EditAiSuggestionSettingsMutationVariables>(EditAiSuggestionSettingsDocument, variables)(),
      options
    );
export const PublishAiSuggestionVideoDocument = `
    mutation PublishAISuggestionVideo($suggestedVideoId: String = "", $channelId: String = "", $classification: String = "", $title: TranslateInput = {ar: "", en: ""}, $description: TranslateInput = {ar: "", en: ""}, $contentIds: [String!] = "", $musicType: String = "") {
  publishAISuggestedVideo(
    publishSuggestedVideoInput: {channelId: $channelId, classification: $classification, contentIds: $contentIds, description: $description, suggestedVideoId: $suggestedVideoId, title: $title, musicType: $musicType}
  ) {
    id
  }
}
    `;
export const usePublishAiSuggestionVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishAiSuggestionVideoMutation, TError, PublishAiSuggestionVideoMutationVariables, TContext>) =>
    useMutation<PublishAiSuggestionVideoMutation, TError, PublishAiSuggestionVideoMutationVariables, TContext>(
      ['PublishAISuggestionVideo'],
      (variables?: PublishAiSuggestionVideoMutationVariables) => fetchData<PublishAiSuggestionVideoMutation, PublishAiSuggestionVideoMutationVariables>(PublishAiSuggestionVideoDocument, variables)(),
      options
    );
export const CreateAiSuggestionKeywordDocument = `
    mutation CreateAiSuggestionKeyword($name: jsonb = "") {
  insert_ai_suggestion_keywords_one(object: {name: $name}) {
    id
  }
}
    `;
export const useCreateAiSuggestionKeywordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAiSuggestionKeywordMutation, TError, CreateAiSuggestionKeywordMutationVariables, TContext>) =>
    useMutation<CreateAiSuggestionKeywordMutation, TError, CreateAiSuggestionKeywordMutationVariables, TContext>(
      ['CreateAiSuggestionKeyword'],
      (variables?: CreateAiSuggestionKeywordMutationVariables) => fetchData<CreateAiSuggestionKeywordMutation, CreateAiSuggestionKeywordMutationVariables>(CreateAiSuggestionKeywordDocument, variables)(),
      options
    );
export const DeleteAiSuggestionKeywordDocument = `
    mutation DeleteAiSuggestionKeyword($id: String = "") {
  delete_ai_suggestion_keywords_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteAiSuggestionKeywordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAiSuggestionKeywordMutation, TError, DeleteAiSuggestionKeywordMutationVariables, TContext>) =>
    useMutation<DeleteAiSuggestionKeywordMutation, TError, DeleteAiSuggestionKeywordMutationVariables, TContext>(
      ['DeleteAiSuggestionKeyword'],
      (variables?: DeleteAiSuggestionKeywordMutationVariables) => fetchData<DeleteAiSuggestionKeywordMutation, DeleteAiSuggestionKeywordMutationVariables>(DeleteAiSuggestionKeywordDocument, variables)(),
      options
    );
export const CreateRoleActionDocument = `
    mutation CreateRoleAction($roleId: String = "", $actionId: String = "") {
  insert_roles_actions_one(object: {roleId: $roleId, actionId: $actionId}) {
    actionId
  }
}
    `;
export const useCreateRoleActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRoleActionMutation, TError, CreateRoleActionMutationVariables, TContext>) =>
    useMutation<CreateRoleActionMutation, TError, CreateRoleActionMutationVariables, TContext>(
      ['CreateRoleAction'],
      (variables?: CreateRoleActionMutationVariables) => fetchData<CreateRoleActionMutation, CreateRoleActionMutationVariables>(CreateRoleActionDocument, variables)(),
      options
    );
export const DeleteRoleActionDocument = `
    mutation DeleteRoleAction($roleId: String = "", $actionId: String = "") {
  delete_roles_actions_by_pk(actionId: $actionId, roleId: $roleId) {
    actionId
  }
}
    `;
export const useDeleteRoleActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRoleActionMutation, TError, DeleteRoleActionMutationVariables, TContext>) =>
    useMutation<DeleteRoleActionMutation, TError, DeleteRoleActionMutationVariables, TContext>(
      ['DeleteRoleAction'],
      (variables?: DeleteRoleActionMutationVariables) => fetchData<DeleteRoleActionMutation, DeleteRoleActionMutationVariables>(DeleteRoleActionDocument, variables)(),
      options
    );
export const CreateAdminDocument = `
    mutation CreateAdmin($username: String = "", $roleId: String = "", $email: String = "", $password: String = "") {
  createAdmin(
    createAdminInput: {email: $email, password: $password, roleId: $roleId, username: $username}
  ) {
    user {
      id
    }
  }
}
    `;
export const useCreateAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAdminMutation, TError, CreateAdminMutationVariables, TContext>) =>
    useMutation<CreateAdminMutation, TError, CreateAdminMutationVariables, TContext>(
      ['CreateAdmin'],
      (variables?: CreateAdminMutationVariables) => fetchData<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, variables)(),
      options
    );
export const EditAdminDocument = `
    mutation EditAdmin($id: ID = "", $email: String, $password: String, $roleId: String, $username: String) {
  updateAdmin(
    updateAdminInput: {id: $id, email: $email, password: $password, roleId: $roleId, username: $username}
  ) {
    id
  }
}
    `;
export const useEditAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditAdminMutation, TError, EditAdminMutationVariables, TContext>) =>
    useMutation<EditAdminMutation, TError, EditAdminMutationVariables, TContext>(
      ['EditAdmin'],
      (variables?: EditAdminMutationVariables) => fetchData<EditAdminMutation, EditAdminMutationVariables>(EditAdminDocument, variables)(),
      options
    );
export const DeleteAdminDocument = `
    mutation DeleteAdmin($id: String = "") {
  removeAdmin(id: $id) {
    id
  }
}
    `;
export const useDeleteAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAdminMutation, TError, DeleteAdminMutationVariables, TContext>) =>
    useMutation<DeleteAdminMutation, TError, DeleteAdminMutationVariables, TContext>(
      ['DeleteAdmin'],
      (variables?: DeleteAdminMutationVariables) => fetchData<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, variables)(),
      options
    );
export const DeActivateAdminDocument = `
    mutation DeActivateAdmin($id: ID = "") {
  deActivateAdmin(id: $id) {
    id
  }
}
    `;
export const useDeActivateAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeActivateAdminMutation, TError, DeActivateAdminMutationVariables, TContext>) =>
    useMutation<DeActivateAdminMutation, TError, DeActivateAdminMutationVariables, TContext>(
      ['DeActivateAdmin'],
      (variables?: DeActivateAdminMutationVariables) => fetchData<DeActivateAdminMutation, DeActivateAdminMutationVariables>(DeActivateAdminDocument, variables)(),
      options
    );
export const ReActivateAdminDocument = `
    mutation ReActivateAdmin($id: ID = "") {
  reActivateAdmin(id: $id) {
    id
  }
}
    `;
export const useReActivateAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReActivateAdminMutation, TError, ReActivateAdminMutationVariables, TContext>) =>
    useMutation<ReActivateAdminMutation, TError, ReActivateAdminMutationVariables, TContext>(
      ['ReActivateAdmin'],
      (variables?: ReActivateAdminMutationVariables) => fetchData<ReActivateAdminMutation, ReActivateAdminMutationVariables>(ReActivateAdminDocument, variables)(),
      options
    );
export const CreateAppDiscoveryDocument = `
    mutation CreateAppDiscovery($name: jsonb) {
  insert_heared_methods_one(object: {name: $name, status: "Active"}) {
    id
  }
}
    `;
export const useCreateAppDiscoveryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAppDiscoveryMutation, TError, CreateAppDiscoveryMutationVariables, TContext>) =>
    useMutation<CreateAppDiscoveryMutation, TError, CreateAppDiscoveryMutationVariables, TContext>(
      ['CreateAppDiscovery'],
      (variables?: CreateAppDiscoveryMutationVariables) => fetchData<CreateAppDiscoveryMutation, CreateAppDiscoveryMutationVariables>(CreateAppDiscoveryDocument, variables)(),
      options
    );
export const EditAppDiscoveryDocument = `
    mutation EditAppDiscovery($id: String = "", $name: jsonb) {
  update_heared_methods_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
  }
}
    `;
export const useEditAppDiscoveryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditAppDiscoveryMutation, TError, EditAppDiscoveryMutationVariables, TContext>) =>
    useMutation<EditAppDiscoveryMutation, TError, EditAppDiscoveryMutationVariables, TContext>(
      ['EditAppDiscovery'],
      (variables?: EditAppDiscoveryMutationVariables) => fetchData<EditAppDiscoveryMutation, EditAppDiscoveryMutationVariables>(EditAppDiscoveryDocument, variables)(),
      options
    );
export const DeleteAppDiscoveryDocument = `
    mutation DeleteAppDiscovery($id: String = "") {
  delete_heared_methods_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteAppDiscoveryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAppDiscoveryMutation, TError, DeleteAppDiscoveryMutationVariables, TContext>) =>
    useMutation<DeleteAppDiscoveryMutation, TError, DeleteAppDiscoveryMutationVariables, TContext>(
      ['DeleteAppDiscovery'],
      (variables?: DeleteAppDiscoveryMutationVariables) => fetchData<DeleteAppDiscoveryMutation, DeleteAppDiscoveryMutationVariables>(DeleteAppDiscoveryDocument, variables)(),
      options
    );
export const ChangeAppDiscoveryStatusDocument = `
    mutation ChangeAppDiscoveryStatus($id: String = "", $status: String = "") {
  update_heared_methods_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
  }
}
    `;
export const useChangeAppDiscoveryStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeAppDiscoveryStatusMutation, TError, ChangeAppDiscoveryStatusMutationVariables, TContext>) =>
    useMutation<ChangeAppDiscoveryStatusMutation, TError, ChangeAppDiscoveryStatusMutationVariables, TContext>(
      ['ChangeAppDiscoveryStatus'],
      (variables?: ChangeAppDiscoveryStatusMutationVariables) => fetchData<ChangeAppDiscoveryStatusMutation, ChangeAppDiscoveryStatusMutationVariables>(ChangeAppDiscoveryStatusDocument, variables)(),
      options
    );
export const CreateBadgeDocument = `
    mutation CreateBadge($name: jsonb, $description: jsonb, $categoryId: String) {
  insert_badges_one(
    object: {name: $name, description: $description, categoryId: $categoryId}
  ) {
    id
  }
}
    `;
export const useCreateBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBadgeMutation, TError, CreateBadgeMutationVariables, TContext>) =>
    useMutation<CreateBadgeMutation, TError, CreateBadgeMutationVariables, TContext>(
      ['CreateBadge'],
      (variables?: CreateBadgeMutationVariables) => fetchData<CreateBadgeMutation, CreateBadgeMutationVariables>(CreateBadgeDocument, variables)(),
      options
    );
export const EditBadgeDocument = `
    mutation EditBadge($id: String = "", $name: jsonb, $description: jsonb) {
  update_badges_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    id
  }
}
    `;
export const useEditBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditBadgeMutation, TError, EditBadgeMutationVariables, TContext>) =>
    useMutation<EditBadgeMutation, TError, EditBadgeMutationVariables, TContext>(
      ['EditBadge'],
      (variables?: EditBadgeMutationVariables) => fetchData<EditBadgeMutation, EditBadgeMutationVariables>(EditBadgeDocument, variables)(),
      options
    );
export const DeleteBadgeDocument = `
    mutation DeleteBadge($id: String = "") {
  delete_badges_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBadgeMutation, TError, DeleteBadgeMutationVariables, TContext>) =>
    useMutation<DeleteBadgeMutation, TError, DeleteBadgeMutationVariables, TContext>(
      ['DeleteBadge'],
      (variables?: DeleteBadgeMutationVariables) => fetchData<DeleteBadgeMutation, DeleteBadgeMutationVariables>(DeleteBadgeDocument, variables)(),
      options
    );
export const PublishBadgeDocument = `
    mutation PublishBadge($id: String = "", $isPublished: Boolean) {
  update_badges_by_pk(pk_columns: {id: $id}, _set: {isPublished: $isPublished}) {
    id
  }
}
    `;
export const usePublishBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishBadgeMutation, TError, PublishBadgeMutationVariables, TContext>) =>
    useMutation<PublishBadgeMutation, TError, PublishBadgeMutationVariables, TContext>(
      ['PublishBadge'],
      (variables?: PublishBadgeMutationVariables) => fetchData<PublishBadgeMutation, PublishBadgeMutationVariables>(PublishBadgeDocument, variables)(),
      options
    );
export const CreateBadgeVideoDocument = `
    mutation CreateBadgeVideo($badgeId: String, $videoId: String) {
  insert_badge_videos_one(object: {badgeId: $badgeId, videoId: $videoId}) {
    id
  }
}
    `;
export const useCreateBadgeVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBadgeVideoMutation, TError, CreateBadgeVideoMutationVariables, TContext>) =>
    useMutation<CreateBadgeVideoMutation, TError, CreateBadgeVideoMutationVariables, TContext>(
      ['CreateBadgeVideo'],
      (variables?: CreateBadgeVideoMutationVariables) => fetchData<CreateBadgeVideoMutation, CreateBadgeVideoMutationVariables>(CreateBadgeVideoDocument, variables)(),
      options
    );
export const DeleteBadgeVideoDocument = `
    mutation DeleteBadgeVideo($id: String = "") {
  delete_badge_videos_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteBadgeVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBadgeVideoMutation, TError, DeleteBadgeVideoMutationVariables, TContext>) =>
    useMutation<DeleteBadgeVideoMutation, TError, DeleteBadgeVideoMutationVariables, TContext>(
      ['DeleteBadgeVideo'],
      (variables?: DeleteBadgeVideoMutationVariables) => fetchData<DeleteBadgeVideoMutation, DeleteBadgeVideoMutationVariables>(DeleteBadgeVideoDocument, variables)(),
      options
    );
export const CreateCategoryDocument = `
    mutation CreateCategory($name: jsonb, $color: String, $categories_contents: categories_contents_arr_rel_insert_input = {data: {contentId: ""}}) {
  insert_categories_one(
    object: {name: $name, color: $color, categories_contents: $categories_contents}
  ) {
    id
  }
}
    `;
export const useCreateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>) =>
    useMutation<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>(
      ['CreateCategory'],
      (variables?: CreateCategoryMutationVariables) => fetchData<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, variables)(),
      options
    );
export const EditCategoryDocument = `
    mutation EditCategory($id: String = "", $name: jsonb, $color: String) {
  update_categories_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, color: $color}
  ) {
    id
  }
}
    `;
export const useEditCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditCategoryMutation, TError, EditCategoryMutationVariables, TContext>) =>
    useMutation<EditCategoryMutation, TError, EditCategoryMutationVariables, TContext>(
      ['EditCategory'],
      (variables?: EditCategoryMutationVariables) => fetchData<EditCategoryMutation, EditCategoryMutationVariables>(EditCategoryDocument, variables)(),
      options
    );
export const DeleteCategoryDocument = `
    mutation DeleteCategory($id: String = "") {
  delete_categories_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>) =>
    useMutation<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>(
      ['DeleteCategory'],
      (variables?: DeleteCategoryMutationVariables) => fetchData<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, variables)(),
      options
    );
export const CreateChannelDocument = `
    mutation CreateChannel($name: jsonb, $description: jsonb, $categoryId: String, $maxTargetAge: Int, $minTargetAge: Int, $channels_contents: channels_contents_arr_rel_insert_input = {data: {contentId: ""}}) {
  insert_channels_one(
    object: {name: $name, description: $description, categoryId: $categoryId, maxTargetAge: $maxTargetAge, minTargetAge: $minTargetAge, channels_contents: $channels_contents}
  ) {
    id
  }
}
    `;
export const useCreateChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateChannelMutation, TError, CreateChannelMutationVariables, TContext>) =>
    useMutation<CreateChannelMutation, TError, CreateChannelMutationVariables, TContext>(
      ['CreateChannel'],
      (variables?: CreateChannelMutationVariables) => fetchData<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, variables)(),
      options
    );
export const EditChannelDocument = `
    mutation EditChannel($id: String = "", $name: jsonb, $description: jsonb) {
  update_channels_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    id
  }
}
    `;
export const useEditChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditChannelMutation, TError, EditChannelMutationVariables, TContext>) =>
    useMutation<EditChannelMutation, TError, EditChannelMutationVariables, TContext>(
      ['EditChannel'],
      (variables?: EditChannelMutationVariables) => fetchData<EditChannelMutation, EditChannelMutationVariables>(EditChannelDocument, variables)(),
      options
    );
export const DeleteChannelDocument = `
    mutation DeleteChannel($id: String = "") {
  delete_channels_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteChannelMutation, TError, DeleteChannelMutationVariables, TContext>) =>
    useMutation<DeleteChannelMutation, TError, DeleteChannelMutationVariables, TContext>(
      ['DeleteChannel'],
      (variables?: DeleteChannelMutationVariables) => fetchData<DeleteChannelMutation, DeleteChannelMutationVariables>(DeleteChannelDocument, variables)(),
      options
    );
export const ChangeSuggestionStatusDocument = `
    mutation ChangeSuggestionStatus($suggestedVideoId: String = "", $status: String = "") {
  createChromeExtSuggestedVideoReviewByAdmin(
    createSuggestedVideoReviewInput: {suggestedVideoId: $suggestedVideoId, status: $status}
  ) {
    id
  }
}
    `;
export const useChangeSuggestionStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeSuggestionStatusMutation, TError, ChangeSuggestionStatusMutationVariables, TContext>) =>
    useMutation<ChangeSuggestionStatusMutation, TError, ChangeSuggestionStatusMutationVariables, TContext>(
      ['ChangeSuggestionStatus'],
      (variables?: ChangeSuggestionStatusMutationVariables) => fetchData<ChangeSuggestionStatusMutation, ChangeSuggestionStatusMutationVariables>(ChangeSuggestionStatusDocument, variables)(),
      options
    );
export const PublishSuggestionVideoDocument = `
    mutation PublishSuggestionVideo($suggestedVideoId: String = "", $channelId: String = "", $classification: String = "", $title: TranslateInput = {ar: "", en: ""}, $description: TranslateInput = {ar: "", en: ""}, $contentIds: [String!] = "", $musicType: String = "") {
  publishChromeExtSuggestedVideo(
    publishSuggestedVideoInput: {channelId: $channelId, classification: $classification, contentIds: $contentIds, description: $description, suggestedVideoId: $suggestedVideoId, title: $title, musicType: $musicType}
  ) {
    id
  }
}
    `;
export const usePublishSuggestionVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishSuggestionVideoMutation, TError, PublishSuggestionVideoMutationVariables, TContext>) =>
    useMutation<PublishSuggestionVideoMutation, TError, PublishSuggestionVideoMutationVariables, TContext>(
      ['PublishSuggestionVideo'],
      (variables?: PublishSuggestionVideoMutationVariables) => fetchData<PublishSuggestionVideoMutation, PublishSuggestionVideoMutationVariables>(PublishSuggestionVideoDocument, variables)(),
      options
    );
export const EditSuggestionSettingsDocument = `
    mutation EditSuggestionSettings($updateSuggestionSettingInput: UpdateSuggestionSettingInput = {approvalRules: {adminsRequiredCount: 0, parentsRequiredCount: 0}, rejectionRules: {adminsRequiredCount: 0, parentsRequiredCount: 0}}) {
  updateChromeExtSuggestionSettings(
    updateSuggestionSettingInput: $updateSuggestionSettingInput
  ) {
    id
  }
}
    `;
export const useEditSuggestionSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditSuggestionSettingsMutation, TError, EditSuggestionSettingsMutationVariables, TContext>) =>
    useMutation<EditSuggestionSettingsMutation, TError, EditSuggestionSettingsMutationVariables, TContext>(
      ['EditSuggestionSettings'],
      (variables?: EditSuggestionSettingsMutationVariables) => fetchData<EditSuggestionSettingsMutation, EditSuggestionSettingsMutationVariables>(EditSuggestionSettingsDocument, variables)(),
      options
    );
export const PublishComingSoonVideoDocument = `
    mutation PublishComingSoonVideo($id: ID = "", $link: String = "") {
  publishComingSoonVideo(publishComingSoonVideoInput: {id: $id, link: $link}) {
    id
  }
}
    `;
export const usePublishComingSoonVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishComingSoonVideoMutation, TError, PublishComingSoonVideoMutationVariables, TContext>) =>
    useMutation<PublishComingSoonVideoMutation, TError, PublishComingSoonVideoMutationVariables, TContext>(
      ['PublishComingSoonVideo'],
      (variables?: PublishComingSoonVideoMutationVariables) => fetchData<PublishComingSoonVideoMutation, PublishComingSoonVideoMutationVariables>(PublishComingSoonVideoDocument, variables)(),
      options
    );
export const CreateFaqDocument = `
    mutation CreateFAQ($question: jsonb, $answer: jsonb) {
  insert_faq_one(object: {question: $question, answer: $answer}) {
    id
  }
}
    `;
export const useCreateFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateFaqMutation, TError, CreateFaqMutationVariables, TContext>) =>
    useMutation<CreateFaqMutation, TError, CreateFaqMutationVariables, TContext>(
      ['CreateFAQ'],
      (variables?: CreateFaqMutationVariables) => fetchData<CreateFaqMutation, CreateFaqMutationVariables>(CreateFaqDocument, variables)(),
      options
    );
export const EditFaqDocument = `
    mutation EditFAQ($id: String = "", $question: jsonb, $answer: jsonb) {
  update_faq_by_pk(
    pk_columns: {id: $id}
    _set: {question: $question, answer: $answer}
  ) {
    id
  }
}
    `;
export const useEditFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditFaqMutation, TError, EditFaqMutationVariables, TContext>) =>
    useMutation<EditFaqMutation, TError, EditFaqMutationVariables, TContext>(
      ['EditFAQ'],
      (variables?: EditFaqMutationVariables) => fetchData<EditFaqMutation, EditFaqMutationVariables>(EditFaqDocument, variables)(),
      options
    );
export const DeleteFaqDocument = `
    mutation DeleteFAQ($id: String = "") {
  delete_faq_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteFaqMutation, TError, DeleteFaqMutationVariables, TContext>) =>
    useMutation<DeleteFaqMutation, TError, DeleteFaqMutationVariables, TContext>(
      ['DeleteFAQ'],
      (variables?: DeleteFaqMutationVariables) => fetchData<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, variables)(),
      options
    );
export const CreateNotificationDocument = `
    mutation CreateNotification($title: String = "", $message: String = "", $privacy: String = "", $channels: [String!] = "") {
  createGeneralMessage(
    createGeneralMessageInput: {channels: $channels, message: $message, privacy: $privacy, title: $title}
  ) {
    title
  }
}
    `;
export const useCreateNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateNotificationMutation, TError, CreateNotificationMutationVariables, TContext>) =>
    useMutation<CreateNotificationMutation, TError, CreateNotificationMutationVariables, TContext>(
      ['CreateNotification'],
      (variables?: CreateNotificationMutationVariables) => fetchData<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, variables)(),
      options
    );
export const DeleteNotificationDocument = `
    mutation DeleteNotification($id: String = "") {
  delete_general_messages_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteNotificationMutation, TError, DeleteNotificationMutationVariables, TContext>) =>
    useMutation<DeleteNotificationMutation, TError, DeleteNotificationMutationVariables, TContext>(
      ['DeleteNotification'],
      (variables?: DeleteNotificationMutationVariables) => fetchData<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, variables)(),
      options
    );
export const CreatePrivacyPolicyDocument = `
    mutation CreatePrivacyPolicy($content: jsonb) {
  insert_privacy_policy_one(object: {content: $content}) {
    id
  }
}
    `;
export const useCreatePrivacyPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePrivacyPolicyMutation, TError, CreatePrivacyPolicyMutationVariables, TContext>) =>
    useMutation<CreatePrivacyPolicyMutation, TError, CreatePrivacyPolicyMutationVariables, TContext>(
      ['CreatePrivacyPolicy'],
      (variables?: CreatePrivacyPolicyMutationVariables) => fetchData<CreatePrivacyPolicyMutation, CreatePrivacyPolicyMutationVariables>(CreatePrivacyPolicyDocument, variables)(),
      options
    );
export const EditPrivacyPolicyDocument = `
    mutation EditPrivacyPolicy($id: String = "", $content: jsonb) {
  update_privacy_policy_by_pk(pk_columns: {id: $id}, _set: {content: $content}) {
    id
  }
}
    `;
export const useEditPrivacyPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditPrivacyPolicyMutation, TError, EditPrivacyPolicyMutationVariables, TContext>) =>
    useMutation<EditPrivacyPolicyMutation, TError, EditPrivacyPolicyMutationVariables, TContext>(
      ['EditPrivacyPolicy'],
      (variables?: EditPrivacyPolicyMutationVariables) => fetchData<EditPrivacyPolicyMutation, EditPrivacyPolicyMutationVariables>(EditPrivacyPolicyDocument, variables)(),
      options
    );
export const AllowReportedVideoDocument = `
    mutation AllowReportedVideo($id: String = "") {
  update_video_reports_by_pk(pk_columns: {id: $id}, _set: {status: "Closed"}) {
    id
  }
}
    `;
export const useAllowReportedVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AllowReportedVideoMutation, TError, AllowReportedVideoMutationVariables, TContext>) =>
    useMutation<AllowReportedVideoMutation, TError, AllowReportedVideoMutationVariables, TContext>(
      ['AllowReportedVideo'],
      (variables?: AllowReportedVideoMutationVariables) => fetchData<AllowReportedVideoMutation, AllowReportedVideoMutationVariables>(AllowReportedVideoDocument, variables)(),
      options
    );
export const CreateRoleDocument = `
    mutation CreateRole($name: String, $role_actions: roles_actions_arr_rel_insert_input = {data: {actionId: ""}}) {
  insert_roles_one(object: {name: $name, role_actions: $role_actions}) {
    id
  }
}
    `;
export const useCreateRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRoleMutation, TError, CreateRoleMutationVariables, TContext>) =>
    useMutation<CreateRoleMutation, TError, CreateRoleMutationVariables, TContext>(
      ['CreateRole'],
      (variables?: CreateRoleMutationVariables) => fetchData<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, variables)(),
      options
    );
export const EditRoleDocument = `
    mutation EditRole($id: String = "", $name: String) {
  update_roles_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
  }
}
    `;
export const useEditRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditRoleMutation, TError, EditRoleMutationVariables, TContext>) =>
    useMutation<EditRoleMutation, TError, EditRoleMutationVariables, TContext>(
      ['EditRole'],
      (variables?: EditRoleMutationVariables) => fetchData<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, variables)(),
      options
    );
export const DeleteRoleDocument = `
    mutation DeleteRole($id: String = "") {
  delete_roles_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>) =>
    useMutation<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>(
      ['DeleteRole'],
      (variables?: DeleteRoleMutationVariables) => fetchData<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, variables)(),
      options
    );
export const CreateSeasonDocument = `
    mutation CreateSeason($serieId: String = "", $name: jsonb, $description: jsonb) {
  insert_seasons_one(
    object: {serieId: $serieId, name: $name, description: $description}
  ) {
    id
  }
}
    `;
export const useCreateSeasonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSeasonMutation, TError, CreateSeasonMutationVariables, TContext>) =>
    useMutation<CreateSeasonMutation, TError, CreateSeasonMutationVariables, TContext>(
      ['CreateSeason'],
      (variables?: CreateSeasonMutationVariables) => fetchData<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument, variables)(),
      options
    );
export const EditSeasonDocument = `
    mutation EditSeason($id: String = "", $name: jsonb, $description: jsonb) {
  update_seasons_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    id
  }
}
    `;
export const useEditSeasonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditSeasonMutation, TError, EditSeasonMutationVariables, TContext>) =>
    useMutation<EditSeasonMutation, TError, EditSeasonMutationVariables, TContext>(
      ['EditSeason'],
      (variables?: EditSeasonMutationVariables) => fetchData<EditSeasonMutation, EditSeasonMutationVariables>(EditSeasonDocument, variables)(),
      options
    );
export const DeleteSeasonDocument = `
    mutation DeleteSeason($id: String = "") {
  delete_seasons_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteSeasonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSeasonMutation, TError, DeleteSeasonMutationVariables, TContext>) =>
    useMutation<DeleteSeasonMutation, TError, DeleteSeasonMutationVariables, TContext>(
      ['DeleteSeason'],
      (variables?: DeleteSeasonMutationVariables) => fetchData<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument, variables)(),
      options
    );
export const CreateSeriesDocument = `
    mutation CreateSeries($name: jsonb, $description: jsonb) {
  insert_series_one(object: {name: $name, description: $description}) {
    id
  }
}
    `;
export const useCreateSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>) =>
    useMutation<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>(
      ['CreateSeries'],
      (variables?: CreateSeriesMutationVariables) => fetchData<CreateSeriesMutation, CreateSeriesMutationVariables>(CreateSeriesDocument, variables)(),
      options
    );
export const EditSeriesDocument = `
    mutation EditSeries($id: String = "", $name: jsonb, $description: jsonb) {
  update_series_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    id
  }
}
    `;
export const useEditSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditSeriesMutation, TError, EditSeriesMutationVariables, TContext>) =>
    useMutation<EditSeriesMutation, TError, EditSeriesMutationVariables, TContext>(
      ['EditSeries'],
      (variables?: EditSeriesMutationVariables) => fetchData<EditSeriesMutation, EditSeriesMutationVariables>(EditSeriesDocument, variables)(),
      options
    );
export const DeleteSerieDocument = `
    mutation DeleteSerie($id: String = "") {
  delete_series_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteSerieMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSerieMutation, TError, DeleteSerieMutationVariables, TContext>) =>
    useMutation<DeleteSerieMutation, TError, DeleteSerieMutationVariables, TContext>(
      ['DeleteSerie'],
      (variables?: DeleteSerieMutationVariables) => fetchData<DeleteSerieMutation, DeleteSerieMutationVariables>(DeleteSerieDocument, variables)(),
      options
    );
export const ReplyTicketDocument = `
    mutation ReplyTicket($ticketId: String = "", $content: String = "") {
  replyTicket(replyTicketInput: {ticketId: $ticketId, content: $content}) {
    id
  }
}
    `;
export const useReplyTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReplyTicketMutation, TError, ReplyTicketMutationVariables, TContext>) =>
    useMutation<ReplyTicketMutation, TError, ReplyTicketMutationVariables, TContext>(
      ['ReplyTicket'],
      (variables?: ReplyTicketMutationVariables) => fetchData<ReplyTicketMutation, ReplyTicketMutationVariables>(ReplyTicketDocument, variables)(),
      options
    );
export const ChangeTicketStatusDocument = `
    mutation ChangeTicketStatus($id: String = "") {
  update_tickets_by_pk(pk_columns: {id: $id}, _set: {status: "Closed"}) {
    id
  }
}
    `;
export const useChangeTicketStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeTicketStatusMutation, TError, ChangeTicketStatusMutationVariables, TContext>) =>
    useMutation<ChangeTicketStatusMutation, TError, ChangeTicketStatusMutationVariables, TContext>(
      ['ChangeTicketStatus'],
      (variables?: ChangeTicketStatusMutationVariables) => fetchData<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>(ChangeTicketStatusDocument, variables)(),
      options
    );
export const CreateUserDocument = `
    mutation createUser($email: String = "", $fullName: String = "", $password: String = "", $type: String = "", $pin: String) {
  createUser(
    createUserInput: {email: $email, fullName: $fullName, password: $password, type: $type, pin: $pin}
  ) {
    user {
      id
    }
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['createUser'],
      (variables?: CreateUserMutationVariables) => fetchData<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
      options
    );
export const EditUserDocument = `
    mutation EditUser($id: ID = "", $email: String, $fullName: String, $password: String, $verified: Boolean = false) {
  updateUser(
    updateUserInput: {id: $id, verified: $verified, email: $email, fullName: $fullName, password: $password}
  ) {
    id
  }
}
    `;
export const useEditUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditUserMutation, TError, EditUserMutationVariables, TContext>) =>
    useMutation<EditUserMutation, TError, EditUserMutationVariables, TContext>(
      ['EditUser'],
      (variables?: EditUserMutationVariables) => fetchData<EditUserMutation, EditUserMutationVariables>(EditUserDocument, variables)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($id: ID = "") {
  removeUser(id: $id) {
    id
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => fetchData<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
      options
    );
export const DeActivateUserDocument = `
    mutation DeActivateUser($id: ID = "") {
  deActivateUser(id: $id) {
    id
  }
}
    `;
export const useDeActivateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeActivateUserMutation, TError, DeActivateUserMutationVariables, TContext>) =>
    useMutation<DeActivateUserMutation, TError, DeActivateUserMutationVariables, TContext>(
      ['DeActivateUser'],
      (variables?: DeActivateUserMutationVariables) => fetchData<DeActivateUserMutation, DeActivateUserMutationVariables>(DeActivateUserDocument, variables)(),
      options
    );
export const ReActivateUserDocument = `
    mutation ReActivateUser($id: ID = "") {
  reActivateUser(id: $id) {
    id
  }
}
    `;
export const useReActivateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReActivateUserMutation, TError, ReActivateUserMutationVariables, TContext>) =>
    useMutation<ReActivateUserMutation, TError, ReActivateUserMutationVariables, TContext>(
      ['ReActivateUser'],
      (variables?: ReActivateUserMutationVariables) => fetchData<ReActivateUserMutation, ReActivateUserMutationVariables>(ReActivateUserDocument, variables)(),
      options
    );
export const ResetUserAvatarDocument = `
    mutation ResetUserAvatar($userId: String = "") {
  resetUserAvatar(userId: $userId) {
    id
  }
}
    `;
export const useResetUserAvatarMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResetUserAvatarMutation, TError, ResetUserAvatarMutationVariables, TContext>) =>
    useMutation<ResetUserAvatarMutation, TError, ResetUserAvatarMutationVariables, TContext>(
      ['ResetUserAvatar'],
      (variables?: ResetUserAvatarMutationVariables) => fetchData<ResetUserAvatarMutation, ResetUserAvatarMutationVariables>(ResetUserAvatarDocument, variables)(),
      options
    );
export const CreateVideoDocument = `
    mutation CreateVideo($channelId: String = "", $classification: String = "", $genre: String = "", $link: String = "", $title: TranslateInput = {ar: "", en: ""}, $description: TranslateInput = {ar: "", en: ""}, $contentIds: [String!] = "", $uploadingDate: DateTime, $trendingFromDate: DateTime, $trendingToDate: DateTime, $seasonId: String, $episode: Int, $musicType: String = "") {
  createVideo(
    createVideoInput: {channelId: $channelId, classification: $classification, genre: $genre, link: $link, title: $title, description: $description, contentIds: $contentIds, uploadingDate: $uploadingDate, trendingFromDate: $trendingFromDate, trendingToDate: $trendingToDate, seasonId: $seasonId, episode: $episode, musicType: $musicType}
  ) {
    id
  }
}
    `;
export const useCreateVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVideoMutation, TError, CreateVideoMutationVariables, TContext>) =>
    useMutation<CreateVideoMutation, TError, CreateVideoMutationVariables, TContext>(
      ['CreateVideo'],
      (variables?: CreateVideoMutationVariables) => fetchData<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, variables)(),
      options
    );
export const EditVideoDocument = `
    mutation EditVideo($id: ID = "", $title: TranslateInput = {ar: "", en: ""}, $link: String, $description: TranslateInput = {ar: "", en: ""}, $musicType: String = "") {
  updateVideo(
    updateVideoInput: {id: $id, title: $title, link: $link, description: $description, musicType: $musicType}
  ) {
    id
  }
}
    `;
export const useEditVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditVideoMutation, TError, EditVideoMutationVariables, TContext>) =>
    useMutation<EditVideoMutation, TError, EditVideoMutationVariables, TContext>(
      ['EditVideo'],
      (variables?: EditVideoMutationVariables) => fetchData<EditVideoMutation, EditVideoMutationVariables>(EditVideoDocument, variables)(),
      options
    );
export const DeleteVideoDocument = `
    mutation DeleteVideo($id: String = "") {
  delete_videos_by_pk(id: $id) {
    id
  }
}
    `;
export const useDeleteVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteVideoMutation, TError, DeleteVideoMutationVariables, TContext>) =>
    useMutation<DeleteVideoMutation, TError, DeleteVideoMutationVariables, TContext>(
      ['DeleteVideo'],
      (variables?: DeleteVideoMutationVariables) => fetchData<DeleteVideoMutation, DeleteVideoMutationVariables>(DeleteVideoDocument, variables)(),
      options
    );
export const CreateEpisodeVideoDocument = `
    mutation CreateEpisodeVideo($id: ID = "", $seasonId: String, $episode: Int) {
  updateVideo(updateVideoInput: {id: $id, seasonId: $seasonId, episode: $episode}) {
    id
  }
}
    `;
export const useCreateEpisodeVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEpisodeVideoMutation, TError, CreateEpisodeVideoMutationVariables, TContext>) =>
    useMutation<CreateEpisodeVideoMutation, TError, CreateEpisodeVideoMutationVariables, TContext>(
      ['CreateEpisodeVideo'],
      (variables?: CreateEpisodeVideoMutationVariables) => fetchData<CreateEpisodeVideoMutation, CreateEpisodeVideoMutationVariables>(CreateEpisodeVideoDocument, variables)(),
      options
    );
export const GetAiSuggestionsDocument = `
    query GetAISuggestions($limit: Int, $offset: Int, $keywords: String_comparison_exp = {}, $status: String_comparison_exp = {}) {
  ai_suggested_videos(
    limit: $limit
    offset: $offset
    where: {keywords: $keywords, status: $status}
  ) {
    id
    title
    keywords
    status
    link
    suggested_videos_reviews {
      adminId
      chromeExtId
    }
  }
  ai_suggested_videos_aggregate(where: {keywords: $keywords, status: $status}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetAiSuggestionsQuery = <
      TData = GetAiSuggestionsQuery,
      TError = unknown
    >(
      variables?: GetAiSuggestionsQueryVariables,
      options?: UseQueryOptions<GetAiSuggestionsQuery, TError, TData>
    ) =>
    useQuery<GetAiSuggestionsQuery, TError, TData>(
      variables === undefined ? ['GetAISuggestions'] : ['GetAISuggestions', variables],
      fetchData<GetAiSuggestionsQuery, GetAiSuggestionsQueryVariables>(GetAiSuggestionsDocument, variables),
      options
    );
export const GetAiSuggestionByIdDocument = `
    query GetAISuggestionById($id: String = "") {
  ai_suggested_videos_by_pk(id: $id) {
    id
    keywords
    title
    description
    status
    link
    createdAt
  }
}
    `;
export const useGetAiSuggestionByIdQuery = <
      TData = GetAiSuggestionByIdQuery,
      TError = unknown
    >(
      variables?: GetAiSuggestionByIdQueryVariables,
      options?: UseQueryOptions<GetAiSuggestionByIdQuery, TError, TData>
    ) =>
    useQuery<GetAiSuggestionByIdQuery, TError, TData>(
      variables === undefined ? ['GetAISuggestionById'] : ['GetAISuggestionById', variables],
      fetchData<GetAiSuggestionByIdQuery, GetAiSuggestionByIdQueryVariables>(GetAiSuggestionByIdDocument, variables),
      options
    );
export const GetAiSuggestionReviewsDocument = `
    query GetAISuggestionReviews($limit: Int, $offset: Int, $suggestedVideoId: String_comparison_exp = {}) {
  ai_suggested_videos_reviews(
    limit: $limit
    offset: $offset
    where: {suggestedVideoId: $suggestedVideoId}
  ) {
    id
    status
    chrome_extension_user {
      user {
        fullName
        type
      }
    }
    admin {
      username
    }
  }
  ai_suggested_videos_reviews_aggregate(
    where: {suggestedVideoId: $suggestedVideoId}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetAiSuggestionReviewsQuery = <
      TData = GetAiSuggestionReviewsQuery,
      TError = unknown
    >(
      variables?: GetAiSuggestionReviewsQueryVariables,
      options?: UseQueryOptions<GetAiSuggestionReviewsQuery, TError, TData>
    ) =>
    useQuery<GetAiSuggestionReviewsQuery, TError, TData>(
      variables === undefined ? ['GetAISuggestionReviews'] : ['GetAISuggestionReviews', variables],
      fetchData<GetAiSuggestionReviewsQuery, GetAiSuggestionReviewsQueryVariables>(GetAiSuggestionReviewsDocument, variables),
      options
    );
export const GetAiSuggestionSettingsDocument = `
    query GetAISuggestionSettings {
  suggestion_settings(where: {type: {_eq: "AI Suggestions"}}) {
    id
    approvalRules
    rejectionRules
  }
}
    `;
export const useGetAiSuggestionSettingsQuery = <
      TData = GetAiSuggestionSettingsQuery,
      TError = unknown
    >(
      variables?: GetAiSuggestionSettingsQueryVariables,
      options?: UseQueryOptions<GetAiSuggestionSettingsQuery, TError, TData>
    ) =>
    useQuery<GetAiSuggestionSettingsQuery, TError, TData>(
      variables === undefined ? ['GetAISuggestionSettings'] : ['GetAISuggestionSettings', variables],
      fetchData<GetAiSuggestionSettingsQuery, GetAiSuggestionSettingsQueryVariables>(GetAiSuggestionSettingsDocument, variables),
      options
    );
export const GetAiSuggestionKeywordsDocument = `
    query GetAISuggestionKeywords($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}) {
  ai_suggestion_keywords(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    name
  }
  ai_suggestion_keywords_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetAiSuggestionKeywordsQuery = <
      TData = GetAiSuggestionKeywordsQuery,
      TError = unknown
    >(
      variables?: GetAiSuggestionKeywordsQueryVariables,
      options?: UseQueryOptions<GetAiSuggestionKeywordsQuery, TError, TData>
    ) =>
    useQuery<GetAiSuggestionKeywordsQuery, TError, TData>(
      variables === undefined ? ['GetAISuggestionKeywords'] : ['GetAISuggestionKeywords', variables],
      fetchData<GetAiSuggestionKeywordsQuery, GetAiSuggestionKeywordsQueryVariables>(GetAiSuggestionKeywordsDocument, variables),
      options
    );
export const GetActionsDocument = `
    query GetActions {
  actions {
    id
    systemCriteria
    name
  }
}
    `;
export const useGetActionsQuery = <
      TData = GetActionsQuery,
      TError = unknown
    >(
      variables?: GetActionsQueryVariables,
      options?: UseQueryOptions<GetActionsQuery, TError, TData>
    ) =>
    useQuery<GetActionsQuery, TError, TData>(
      variables === undefined ? ['GetActions'] : ['GetActions', variables],
      fetchData<GetActionsQuery, GetActionsQueryVariables>(GetActionsDocument, variables),
      options
    );
export const GetAdminsDocument = `
    query GetAdmins($limit: Int, $offset: Int, $roleId: String_comparison_exp = {}, $status: String_comparison_exp = {}, $username: String_comparison_exp = {}) {
  admins(
    limit: $limit
    offset: $offset
    where: {roleId: $roleId, status: $status, username: $username}
  ) {
    id
    email
    username
    status
    role {
      id
      name
    }
  }
  admins_aggregate(where: {roleId: $roleId, status: $status, username: $username}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetAdminsQuery = <
      TData = GetAdminsQuery,
      TError = unknown
    >(
      variables?: GetAdminsQueryVariables,
      options?: UseQueryOptions<GetAdminsQuery, TError, TData>
    ) =>
    useQuery<GetAdminsQuery, TError, TData>(
      variables === undefined ? ['GetAdmins'] : ['GetAdmins', variables],
      fetchData<GetAdminsQuery, GetAdminsQueryVariables>(GetAdminsDocument, variables),
      options
    );
export const GetAdminByIdDocument = `
    query GetAdminById($id: String = "") {
  admins_by_pk(id: $id) {
    id
    email
    roleId
    status
    username
    role {
      id
      name
    }
  }
}
    `;
export const useGetAdminByIdQuery = <
      TData = GetAdminByIdQuery,
      TError = unknown
    >(
      variables?: GetAdminByIdQueryVariables,
      options?: UseQueryOptions<GetAdminByIdQuery, TError, TData>
    ) =>
    useQuery<GetAdminByIdQuery, TError, TData>(
      variables === undefined ? ['GetAdminById'] : ['GetAdminById', variables],
      fetchData<GetAdminByIdQuery, GetAdminByIdQueryVariables>(GetAdminByIdDocument, variables),
      options
    );
export const GetAppDiscoveryDocument = `
    query GetAppDiscovery($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}, $status: String_comparison_exp = {}) {
  heared_methods(
    limit: $limit
    offset: $offset
    where: {name: $name, status: $status}
  ) {
    id
    name
    status
  }
  heared_methods_aggregate(where: {name: $name, status: $status}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetAppDiscoveryQuery = <
      TData = GetAppDiscoveryQuery,
      TError = unknown
    >(
      variables?: GetAppDiscoveryQueryVariables,
      options?: UseQueryOptions<GetAppDiscoveryQuery, TError, TData>
    ) =>
    useQuery<GetAppDiscoveryQuery, TError, TData>(
      variables === undefined ? ['GetAppDiscovery'] : ['GetAppDiscovery', variables],
      fetchData<GetAppDiscoveryQuery, GetAppDiscoveryQueryVariables>(GetAppDiscoveryDocument, variables),
      options
    );
export const GetAppDiscoveryByIdDocument = `
    query GetAppDiscoveryById($id: String = "") {
  heared_methods_by_pk(id: $id) {
    id
    name
  }
}
    `;
export const useGetAppDiscoveryByIdQuery = <
      TData = GetAppDiscoveryByIdQuery,
      TError = unknown
    >(
      variables?: GetAppDiscoveryByIdQueryVariables,
      options?: UseQueryOptions<GetAppDiscoveryByIdQuery, TError, TData>
    ) =>
    useQuery<GetAppDiscoveryByIdQuery, TError, TData>(
      variables === undefined ? ['GetAppDiscoveryById'] : ['GetAppDiscoveryById', variables],
      fetchData<GetAppDiscoveryByIdQuery, GetAppDiscoveryByIdQueryVariables>(GetAppDiscoveryByIdDocument, variables),
      options
    );
export const LoginAdminDocument = `
    mutation LoginAdmin($email: String!, $password: String!) {
  loginAdmin(loginAdminInput: {email: $email, password: $password}) {
    token
    user {
      email
      username
      id
      avatar
      role {
        permissions {
          id
          name
          systemCriteria
        }
      }
    }
  }
}
    `;
export const useLoginAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginAdminMutation, TError, LoginAdminMutationVariables, TContext>) =>
    useMutation<LoginAdminMutation, TError, LoginAdminMutationVariables, TContext>(
      ['LoginAdmin'],
      (variables?: LoginAdminMutationVariables) => fetchData<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, variables)(),
      options
    );
export const GetBadgesDocument = `
    query GetBadges($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}) {
  badges(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    avatar
    name
    type
    isPublished
    category {
      id
      name
    }
    badge_videos {
      videoId
    }
  }
  badges_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetBadgesQuery = <
      TData = GetBadgesQuery,
      TError = unknown
    >(
      variables?: GetBadgesQueryVariables,
      options?: UseQueryOptions<GetBadgesQuery, TError, TData>
    ) =>
    useQuery<GetBadgesQuery, TError, TData>(
      variables === undefined ? ['GetBadges'] : ['GetBadges', variables],
      fetchData<GetBadgesQuery, GetBadgesQueryVariables>(GetBadgesDocument, variables),
      options
    );
export const GetBadgeByIdDocument = `
    query GetBadgeById($id: String = "") {
  badges_by_pk(id: $id) {
    id
    avatar
    name
    description
    isPublished
    category {
      id
      name
    }
  }
}
    `;
export const useGetBadgeByIdQuery = <
      TData = GetBadgeByIdQuery,
      TError = unknown
    >(
      variables?: GetBadgeByIdQueryVariables,
      options?: UseQueryOptions<GetBadgeByIdQuery, TError, TData>
    ) =>
    useQuery<GetBadgeByIdQuery, TError, TData>(
      variables === undefined ? ['GetBadgeById'] : ['GetBadgeById', variables],
      fetchData<GetBadgeByIdQuery, GetBadgeByIdQueryVariables>(GetBadgeByIdDocument, variables),
      options
    );
export const GetBadgeVideosDocument = `
    query GetBadgeVideos($limit: Int, $offset: Int, $badgeId: String_comparison_exp = {_eq: ""}) {
  badge_videos(limit: $limit, offset: $offset, where: {badgeId: $badgeId}) {
    id
    videoId
    video {
      id
      thumbnailUrl
      title
      description
      classification
      genre
      link
    }
  }
  badge_videos_aggregate(where: {badgeId: $badgeId}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetBadgeVideosQuery = <
      TData = GetBadgeVideosQuery,
      TError = unknown
    >(
      variables?: GetBadgeVideosQueryVariables,
      options?: UseQueryOptions<GetBadgeVideosQuery, TError, TData>
    ) =>
    useQuery<GetBadgeVideosQuery, TError, TData>(
      variables === undefined ? ['GetBadgeVideos'] : ['GetBadgeVideos', variables],
      fetchData<GetBadgeVideosQuery, GetBadgeVideosQueryVariables>(GetBadgeVideosDocument, variables),
      options
    );
export const GetCategoriesDocument = `
    query GetCategories {
  categories {
    id
    name
  }
}
    `;
export const useGetCategoriesQuery = <
      TData = GetCategoriesQuery,
      TError = unknown
    >(
      variables?: GetCategoriesQueryVariables,
      options?: UseQueryOptions<GetCategoriesQuery, TError, TData>
    ) =>
    useQuery<GetCategoriesQuery, TError, TData>(
      variables === undefined ? ['GetCategories'] : ['GetCategories', variables],
      fetchData<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, variables),
      options
    );
export const GetCategoriesWithPaginationDocument = `
    query GetCategoriesWithPagination($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}) {
  categories(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    avatar
    name
    categories_contents {
      content {
        id
        name
      }
    }
  }
  categories_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetCategoriesWithPaginationQuery = <
      TData = GetCategoriesWithPaginationQuery,
      TError = unknown
    >(
      variables?: GetCategoriesWithPaginationQueryVariables,
      options?: UseQueryOptions<GetCategoriesWithPaginationQuery, TError, TData>
    ) =>
    useQuery<GetCategoriesWithPaginationQuery, TError, TData>(
      variables === undefined ? ['GetCategoriesWithPagination'] : ['GetCategoriesWithPagination', variables],
      fetchData<GetCategoriesWithPaginationQuery, GetCategoriesWithPaginationQueryVariables>(GetCategoriesWithPaginationDocument, variables),
      options
    );
export const GetCategoryByIdDocument = `
    query GetCategoryById($id: String = "") {
  categories_by_pk(id: $id) {
    id
    name
    avatar
    color
  }
}
    `;
export const useGetCategoryByIdQuery = <
      TData = GetCategoryByIdQuery,
      TError = unknown
    >(
      variables?: GetCategoryByIdQueryVariables,
      options?: UseQueryOptions<GetCategoryByIdQuery, TError, TData>
    ) =>
    useQuery<GetCategoryByIdQuery, TError, TData>(
      variables === undefined ? ['GetCategoryById'] : ['GetCategoryById', variables],
      fetchData<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(GetCategoryByIdDocument, variables),
      options
    );
export const GetChannelsDocument = `
    query GetChannels($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}) {
  channels(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    avatar
    name
    category {
      name
    }
  }
  channels_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChannelsQuery = <
      TData = GetChannelsQuery,
      TError = unknown
    >(
      variables?: GetChannelsQueryVariables,
      options?: UseQueryOptions<GetChannelsQuery, TError, TData>
    ) =>
    useQuery<GetChannelsQuery, TError, TData>(
      variables === undefined ? ['GetChannels'] : ['GetChannels', variables],
      fetchData<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, variables),
      options
    );
export const GetChannelByIdDocument = `
    query GetChannelById($id: String = "") {
  channels_by_pk(id: $id) {
    id
    avatar
    name
    description
    minTargetAge
    maxTargetAge
    category {
      name
    }
    channels_contents {
      contentId
      content {
        name
      }
    }
  }
}
    `;
export const useGetChannelByIdQuery = <
      TData = GetChannelByIdQuery,
      TError = unknown
    >(
      variables?: GetChannelByIdQueryVariables,
      options?: UseQueryOptions<GetChannelByIdQuery, TError, TData>
    ) =>
    useQuery<GetChannelByIdQuery, TError, TData>(
      variables === undefined ? ['GetChannelById'] : ['GetChannelById', variables],
      fetchData<GetChannelByIdQuery, GetChannelByIdQueryVariables>(GetChannelByIdDocument, variables),
      options
    );
export const GetChannelsByCategoryDocument = `
    query GetChannelsByCategory($categoryId: String_comparison_exp = {_eq: ""}) {
  channels(where: {categoryId: $categoryId}) {
    id
    name
  }
}
    `;
export const useGetChannelsByCategoryQuery = <
      TData = GetChannelsByCategoryQuery,
      TError = unknown
    >(
      variables?: GetChannelsByCategoryQueryVariables,
      options?: UseQueryOptions<GetChannelsByCategoryQuery, TError, TData>
    ) =>
    useQuery<GetChannelsByCategoryQuery, TError, TData>(
      variables === undefined ? ['GetChannelsByCategory'] : ['GetChannelsByCategory', variables],
      fetchData<GetChannelsByCategoryQuery, GetChannelsByCategoryQueryVariables>(GetChannelsByCategoryDocument, variables),
      options
    );
export const GetChannelVideoByBadgeDocument = `
    query GetChannelVideoByBadge($channelId: String_comparison_exp = {_eq: ""}) {
  videos(where: {_and: {channelId: $channelId, _not: {badge_video: {}}}}) {
    id
    title
  }
}
    `;
export const useGetChannelVideoByBadgeQuery = <
      TData = GetChannelVideoByBadgeQuery,
      TError = unknown
    >(
      variables?: GetChannelVideoByBadgeQueryVariables,
      options?: UseQueryOptions<GetChannelVideoByBadgeQuery, TError, TData>
    ) =>
    useQuery<GetChannelVideoByBadgeQuery, TError, TData>(
      variables === undefined ? ['GetChannelVideoByBadge'] : ['GetChannelVideoByBadge', variables],
      fetchData<GetChannelVideoByBadgeQuery, GetChannelVideoByBadgeQueryVariables>(GetChannelVideoByBadgeDocument, variables),
      options
    );
export const GetAllChannelsDocument = `
    query GetAllChannels {
  channels {
    id
    name
  }
}
    `;
export const useGetAllChannelsQuery = <
      TData = GetAllChannelsQuery,
      TError = unknown
    >(
      variables?: GetAllChannelsQueryVariables,
      options?: UseQueryOptions<GetAllChannelsQuery, TError, TData>
    ) =>
    useQuery<GetAllChannelsQuery, TError, TData>(
      variables === undefined ? ['GetAllChannels'] : ['GetAllChannels', variables],
      fetchData<GetAllChannelsQuery, GetAllChannelsQueryVariables>(GetAllChannelsDocument, variables),
      options
    );
export const GetSuggestedVideosStatisticsByAdminDocument = `
    query GetSuggestedVideosStatisticsByAdmin {
  getSuggestedVideosStatisticsByAdmin {
    approved
    rejected
    pending
  }
}
    `;
export const useGetSuggestedVideosStatisticsByAdminQuery = <
      TData = GetSuggestedVideosStatisticsByAdminQuery,
      TError = unknown
    >(
      variables?: GetSuggestedVideosStatisticsByAdminQueryVariables,
      options?: UseQueryOptions<GetSuggestedVideosStatisticsByAdminQuery, TError, TData>
    ) =>
    useQuery<GetSuggestedVideosStatisticsByAdminQuery, TError, TData>(
      variables === undefined ? ['GetSuggestedVideosStatisticsByAdmin'] : ['GetSuggestedVideosStatisticsByAdmin', variables],
      fetchData<GetSuggestedVideosStatisticsByAdminQuery, GetSuggestedVideosStatisticsByAdminQueryVariables>(GetSuggestedVideosStatisticsByAdminDocument, variables),
      options
    );
export const GetChromeExtUsersStatisticsDocument = `
    query getChromeExtUsersStatistics {
  users_aggregate(where: {type: {_eq: "Parent"}}) {
    aggregate {
      count
    }
  }
  chrome_extension_users_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChromeExtUsersStatisticsQuery = <
      TData = GetChromeExtUsersStatisticsQuery,
      TError = unknown
    >(
      variables?: GetChromeExtUsersStatisticsQueryVariables,
      options?: UseQueryOptions<GetChromeExtUsersStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtUsersStatisticsQuery, TError, TData>(
      variables === undefined ? ['getChromeExtUsersStatistics'] : ['getChromeExtUsersStatistics', variables],
      fetchData<GetChromeExtUsersStatisticsQuery, GetChromeExtUsersStatisticsQueryVariables>(GetChromeExtUsersStatisticsDocument, variables),
      options
    );
export const GetVideosReviewsStatisticsByAdminDocument = `
    query GetVideosReviewsStatisticsByAdmin($startDate: DateTime = "", $endDate: DateTime = "") {
  getSuggestedVideosReviewsStatisticsByAdmin(
    datesDto: {startDate: $startDate, endDate: $endDate}
  ) {
    approved
    rejected
    pending
  }
}
    `;
export const useGetVideosReviewsStatisticsByAdminQuery = <
      TData = GetVideosReviewsStatisticsByAdminQuery,
      TError = unknown
    >(
      variables?: GetVideosReviewsStatisticsByAdminQueryVariables,
      options?: UseQueryOptions<GetVideosReviewsStatisticsByAdminQuery, TError, TData>
    ) =>
    useQuery<GetVideosReviewsStatisticsByAdminQuery, TError, TData>(
      variables === undefined ? ['GetVideosReviewsStatisticsByAdmin'] : ['GetVideosReviewsStatisticsByAdmin', variables],
      fetchData<GetVideosReviewsStatisticsByAdminQuery, GetVideosReviewsStatisticsByAdminQueryVariables>(GetVideosReviewsStatisticsByAdminDocument, variables),
      options
    );
export const GetChromeExtensionSuggestionsDocument = `
    query GetChromeExtensionSuggestions($limit: Int, $offset: Int, $title: String_comparison_exp = {}, $status: String_comparison_exp = {}, $chromeExtId: String_comparison_exp = {}) {
  chrome_extension_suggested_videos(
    limit: $limit
    offset: $offset
    where: {title: $title, status: $status, chromeExtId: $chromeExtId}
  ) {
    id
    title
    link
    status
    suggested_videos_reviews {
      adminId
      chromeExtId
    }
  }
  chrome_extension_suggested_videos_aggregate(
    where: {title: $title, status: $status, chromeExtId: $chromeExtId}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChromeExtensionSuggestionsQuery = <
      TData = GetChromeExtensionSuggestionsQuery,
      TError = unknown
    >(
      variables?: GetChromeExtensionSuggestionsQueryVariables,
      options?: UseQueryOptions<GetChromeExtensionSuggestionsQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtensionSuggestionsQuery, TError, TData>(
      variables === undefined ? ['GetChromeExtensionSuggestions'] : ['GetChromeExtensionSuggestions', variables],
      fetchData<GetChromeExtensionSuggestionsQuery, GetChromeExtensionSuggestionsQueryVariables>(GetChromeExtensionSuggestionsDocument, variables),
      options
    );
export const GetChromeExtensionSuggestionByIdDocument = `
    query GetChromeExtensionSuggestionById($id: String = "") {
  chrome_extension_suggested_videos_by_pk(id: $id) {
    id
    title
    link
    status
    description
    createdAt
    chrome_extension_user {
      user {
        fullName
      }
    }
  }
}
    `;
export const useGetChromeExtensionSuggestionByIdQuery = <
      TData = GetChromeExtensionSuggestionByIdQuery,
      TError = unknown
    >(
      variables?: GetChromeExtensionSuggestionByIdQueryVariables,
      options?: UseQueryOptions<GetChromeExtensionSuggestionByIdQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtensionSuggestionByIdQuery, TError, TData>(
      variables === undefined ? ['GetChromeExtensionSuggestionById'] : ['GetChromeExtensionSuggestionById', variables],
      fetchData<GetChromeExtensionSuggestionByIdQuery, GetChromeExtensionSuggestionByIdQueryVariables>(GetChromeExtensionSuggestionByIdDocument, variables),
      options
    );
export const GetChromeExtensionSuggestionSettingsDocument = `
    query GetChromeExtensionSuggestionSettings {
  suggestion_settings(where: {type: {_eq: "Chrome Ext Suggestions"}}) {
    id
    approvalRules
    rejectionRules
  }
}
    `;
export const useGetChromeExtensionSuggestionSettingsQuery = <
      TData = GetChromeExtensionSuggestionSettingsQuery,
      TError = unknown
    >(
      variables?: GetChromeExtensionSuggestionSettingsQueryVariables,
      options?: UseQueryOptions<GetChromeExtensionSuggestionSettingsQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtensionSuggestionSettingsQuery, TError, TData>(
      variables === undefined ? ['GetChromeExtensionSuggestionSettings'] : ['GetChromeExtensionSuggestionSettings', variables],
      fetchData<GetChromeExtensionSuggestionSettingsQuery, GetChromeExtensionSuggestionSettingsQueryVariables>(GetChromeExtensionSuggestionSettingsDocument, variables),
      options
    );
export const GetChromeExtensionUsersDocument = `
    query GetChromeExtensionUsers($limit: Int, $offset: Int, $user: users_bool_exp = {}) {
  chrome_extension_users(limit: $limit, offset: $offset, where: {user: $user}) {
    id
    user {
      id
      fullName
      status
    }
    chrome_extension_suggested_videos_aggregate {
      aggregate {
        count
      }
    }
  }
  chrome_extension_users_aggregate(where: {user: $user}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChromeExtensionUsersQuery = <
      TData = GetChromeExtensionUsersQuery,
      TError = unknown
    >(
      variables?: GetChromeExtensionUsersQueryVariables,
      options?: UseQueryOptions<GetChromeExtensionUsersQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtensionUsersQuery, TError, TData>(
      variables === undefined ? ['GetChromeExtensionUsers'] : ['GetChromeExtensionUsers', variables],
      fetchData<GetChromeExtensionUsersQuery, GetChromeExtensionUsersQueryVariables>(GetChromeExtensionUsersDocument, variables),
      options
    );
export const GetChromeExtensionVideoVotesDocument = `
    query GetChromeExtensionVideoVotes($limit: Int, $offset: Int, $suggestedVideoId: String_comparison_exp = {}) {
  chrome_extension_suggested_videos_reviews(
    limit: $limit
    offset: $offset
    where: {suggestedVideoId: $suggestedVideoId}
  ) {
    id
    status
    chrome_extension_user {
      user {
        fullName
        type
      }
    }
    admin {
      username
    }
  }
  chrome_extension_suggested_videos_reviews_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChromeExtensionVideoVotesQuery = <
      TData = GetChromeExtensionVideoVotesQuery,
      TError = unknown
    >(
      variables?: GetChromeExtensionVideoVotesQueryVariables,
      options?: UseQueryOptions<GetChromeExtensionVideoVotesQuery, TError, TData>
    ) =>
    useQuery<GetChromeExtensionVideoVotesQuery, TError, TData>(
      variables === undefined ? ['GetChromeExtensionVideoVotes'] : ['GetChromeExtensionVideoVotes', variables],
      fetchData<GetChromeExtensionVideoVotesQuery, GetChromeExtensionVideoVotesQueryVariables>(GetChromeExtensionVideoVotesDocument, variables),
      options
    );
export const GetComingSoonVideosDocument = `
    query GetComingSoonVideos {
  getNeedsToPublishVideos {
    today {
      id
      title
      genre
      thumbnailUrl
      classification
    }
  }
}
    `;
export const useGetComingSoonVideosQuery = <
      TData = GetComingSoonVideosQuery,
      TError = unknown
    >(
      variables?: GetComingSoonVideosQueryVariables,
      options?: UseQueryOptions<GetComingSoonVideosQuery, TError, TData>
    ) =>
    useQuery<GetComingSoonVideosQuery, TError, TData>(
      variables === undefined ? ['GetComingSoonVideos'] : ['GetComingSoonVideos', variables],
      fetchData<GetComingSoonVideosQuery, GetComingSoonVideosQueryVariables>(GetComingSoonVideosDocument, variables),
      options
    );
export const GetNotificationsForComingSoonVideosDocument = `
    query GetNotificationsForComingSoonVideos {
  getNeedsToPublishVideos {
    today {
      id
      createdAt
    }
    tomorrow {
      id
      createdAt
    }
  }
}
    `;
export const useGetNotificationsForComingSoonVideosQuery = <
      TData = GetNotificationsForComingSoonVideosQuery,
      TError = unknown
    >(
      variables?: GetNotificationsForComingSoonVideosQueryVariables,
      options?: UseQueryOptions<GetNotificationsForComingSoonVideosQuery, TError, TData>
    ) =>
    useQuery<GetNotificationsForComingSoonVideosQuery, TError, TData>(
      variables === undefined ? ['GetNotificationsForComingSoonVideos'] : ['GetNotificationsForComingSoonVideos', variables],
      fetchData<GetNotificationsForComingSoonVideosQuery, GetNotificationsForComingSoonVideosQueryVariables>(GetNotificationsForComingSoonVideosDocument, variables),
      options
    );
export const GetContentDocument = `
    query GetContent {
  contents {
    id
    name
  }
}
    `;
export const useGetContentQuery = <
      TData = GetContentQuery,
      TError = unknown
    >(
      variables?: GetContentQueryVariables,
      options?: UseQueryOptions<GetContentQuery, TError, TData>
    ) =>
    useQuery<GetContentQuery, TError, TData>(
      variables === undefined ? ['GetContent'] : ['GetContent', variables],
      fetchData<GetContentQuery, GetContentQueryVariables>(GetContentDocument, variables),
      options
    );
export const GetCategoriesContentsDocument = `
    query GetCategoriesContents {
  categories {
    id
    name
    categories_contents {
      content {
        id
        name
      }
    }
  }
}
    `;
export const useGetCategoriesContentsQuery = <
      TData = GetCategoriesContentsQuery,
      TError = unknown
    >(
      variables?: GetCategoriesContentsQueryVariables,
      options?: UseQueryOptions<GetCategoriesContentsQuery, TError, TData>
    ) =>
    useQuery<GetCategoriesContentsQuery, TError, TData>(
      variables === undefined ? ['GetCategoriesContents'] : ['GetCategoriesContents', variables],
      fetchData<GetCategoriesContentsQuery, GetCategoriesContentsQueryVariables>(GetCategoriesContentsDocument, variables),
      options
    );
export const GetChannelContentDocument = `
    query GetChannelContent($_eq: String = "") {
  contents(where: {channels_contents: {channelId: {_eq: $_eq}}}) {
    id
    name
  }
}
    `;
export const useGetChannelContentQuery = <
      TData = GetChannelContentQuery,
      TError = unknown
    >(
      variables?: GetChannelContentQueryVariables,
      options?: UseQueryOptions<GetChannelContentQuery, TError, TData>
    ) =>
    useQuery<GetChannelContentQuery, TError, TData>(
      variables === undefined ? ['GetChannelContent'] : ['GetChannelContent', variables],
      fetchData<GetChannelContentQuery, GetChannelContentQueryVariables>(GetChannelContentDocument, variables),
      options
    );
export const GetFaqDocument = `
    query GetFAQ($limit: Int, $offset: Int, $question: jsonb_comparison_exp = {}) {
  faq(limit: $limit, offset: $offset, where: {question: $question}) {
    id
    question
    answer
  }
  faq_aggregate(where: {question: $question}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetFaqQuery = <
      TData = GetFaqQuery,
      TError = unknown
    >(
      variables?: GetFaqQueryVariables,
      options?: UseQueryOptions<GetFaqQuery, TError, TData>
    ) =>
    useQuery<GetFaqQuery, TError, TData>(
      variables === undefined ? ['GetFAQ'] : ['GetFAQ', variables],
      fetchData<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, variables),
      options
    );
export const GetFaqByIdDocument = `
    query GetFAQById($id: String = "") {
  faq_by_pk(id: $id) {
    id
    question
    answer
  }
}
    `;
export const useGetFaqByIdQuery = <
      TData = GetFaqByIdQuery,
      TError = unknown
    >(
      variables?: GetFaqByIdQueryVariables,
      options?: UseQueryOptions<GetFaqByIdQuery, TError, TData>
    ) =>
    useQuery<GetFaqByIdQuery, TError, TData>(
      variables === undefined ? ['GetFAQById'] : ['GetFAQById', variables],
      fetchData<GetFaqByIdQuery, GetFaqByIdQueryVariables>(GetFaqByIdDocument, variables),
      options
    );
export const GetNotificationsDocument = `
    query GetNotifications($limit: Int, $offset: Int, $title: String_comparison_exp = {}) {
  general_messages(limit: $limit, offset: $offset, where: {title: $title}) {
    id
    title
    message
    privacy
    channels
  }
  general_messages_aggregate(where: {title: $title}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetNotificationsQuery = <
      TData = GetNotificationsQuery,
      TError = unknown
    >(
      variables?: GetNotificationsQueryVariables,
      options?: UseQueryOptions<GetNotificationsQuery, TError, TData>
    ) =>
    useQuery<GetNotificationsQuery, TError, TData>(
      variables === undefined ? ['GetNotifications'] : ['GetNotifications', variables],
      fetchData<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, variables),
      options
    );
export const GetPrivacyPolicyDocument = `
    query GetPrivacyPolicy {
  privacy_policy(order_by: {createdAt: desc}) {
    id
    content
  }
}
    `;
export const useGetPrivacyPolicyQuery = <
      TData = GetPrivacyPolicyQuery,
      TError = unknown
    >(
      variables?: GetPrivacyPolicyQueryVariables,
      options?: UseQueryOptions<GetPrivacyPolicyQuery, TError, TData>
    ) =>
    useQuery<GetPrivacyPolicyQuery, TError, TData>(
      variables === undefined ? ['GetPrivacyPolicy'] : ['GetPrivacyPolicy', variables],
      fetchData<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>(GetPrivacyPolicyDocument, variables),
      options
    );
export const GetVideoReportingStatisticsDocument = `
    query GetVideoReportingStatistics {
  getVideoReportingStatisticsCount {
    label
    count
  }
}
    `;
export const useGetVideoReportingStatisticsQuery = <
      TData = GetVideoReportingStatisticsQuery,
      TError = unknown
    >(
      variables?: GetVideoReportingStatisticsQueryVariables,
      options?: UseQueryOptions<GetVideoReportingStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetVideoReportingStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetVideoReportingStatistics'] : ['GetVideoReportingStatistics', variables],
      fetchData<GetVideoReportingStatisticsQuery, GetVideoReportingStatisticsQueryVariables>(GetVideoReportingStatisticsDocument, variables),
      options
    );
export const GetVideoReportingMonthStatisticsDocument = `
    query GetVideoReportingMonthStatistics {
  getVideoReportingMonthStatistics {
    month
    totalReports
  }
}
    `;
export const useGetVideoReportingMonthStatisticsQuery = <
      TData = GetVideoReportingMonthStatisticsQuery,
      TError = unknown
    >(
      variables?: GetVideoReportingMonthStatisticsQueryVariables,
      options?: UseQueryOptions<GetVideoReportingMonthStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetVideoReportingMonthStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetVideoReportingMonthStatistics'] : ['GetVideoReportingMonthStatistics', variables],
      fetchData<GetVideoReportingMonthStatisticsQuery, GetVideoReportingMonthStatisticsQueryVariables>(GetVideoReportingMonthStatisticsDocument, variables),
      options
    );
export const GetReportedVideosDocument = `
    query GetReportedVideos($limit: Int, $offset: Int, $status: String_comparison_exp = {}, $video: videos_bool_exp = {}) {
  video_reports(
    limit: $limit
    offset: $offset
    where: {status: $status, video: $video}
  ) {
    id
    videoId
    reason
    status
    video {
      channel {
        category {
          name
        }
      }
    }
  }
  video_reports_aggregate(where: {status: $status, video: $video}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetReportedVideosQuery = <
      TData = GetReportedVideosQuery,
      TError = unknown
    >(
      variables?: GetReportedVideosQueryVariables,
      options?: UseQueryOptions<GetReportedVideosQuery, TError, TData>
    ) =>
    useQuery<GetReportedVideosQuery, TError, TData>(
      variables === undefined ? ['GetReportedVideos'] : ['GetReportedVideos', variables],
      fetchData<GetReportedVideosQuery, GetReportedVideosQueryVariables>(GetReportedVideosDocument, variables),
      options
    );
export const GetReportedVideoByIdDocument = `
    query GetReportedVideoById($id: String = "") {
  video_reports_by_pk(id: $id) {
    id
    videoId
    status
    reason
    createdAt
    video {
      id
      title
      link
      channel {
        category {
          name
        }
      }
    }
  }
}
    `;
export const useGetReportedVideoByIdQuery = <
      TData = GetReportedVideoByIdQuery,
      TError = unknown
    >(
      variables?: GetReportedVideoByIdQueryVariables,
      options?: UseQueryOptions<GetReportedVideoByIdQuery, TError, TData>
    ) =>
    useQuery<GetReportedVideoByIdQuery, TError, TData>(
      variables === undefined ? ['GetReportedVideoById'] : ['GetReportedVideoById', variables],
      fetchData<GetReportedVideoByIdQuery, GetReportedVideoByIdQueryVariables>(GetReportedVideoByIdDocument, variables),
      options
    );
export const GetRolesDocument = `
    query GetRoles($limit: Int, $offset: Int, $name: String_comparison_exp = {}) {
  roles(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    name
    admins {
      username
    }
  }
  roles_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetRolesQuery = <
      TData = GetRolesQuery,
      TError = unknown
    >(
      variables?: GetRolesQueryVariables,
      options?: UseQueryOptions<GetRolesQuery, TError, TData>
    ) =>
    useQuery<GetRolesQuery, TError, TData>(
      variables === undefined ? ['GetRoles'] : ['GetRoles', variables],
      fetchData<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, variables),
      options
    );
export const GetAllRolesForSelectDocument = `
    query GetAllRolesForSelect {
  roles {
    id
    name
  }
}
    `;
export const useGetAllRolesForSelectQuery = <
      TData = GetAllRolesForSelectQuery,
      TError = unknown
    >(
      variables?: GetAllRolesForSelectQueryVariables,
      options?: UseQueryOptions<GetAllRolesForSelectQuery, TError, TData>
    ) =>
    useQuery<GetAllRolesForSelectQuery, TError, TData>(
      variables === undefined ? ['GetAllRolesForSelect'] : ['GetAllRolesForSelect', variables],
      fetchData<GetAllRolesForSelectQuery, GetAllRolesForSelectQueryVariables>(GetAllRolesForSelectDocument, variables),
      options
    );
export const GetRoleByIdDocument = `
    query GetRoleById($id: String = "") {
  roles_by_pk(id: $id) {
    id
    name
  }
}
    `;
export const useGetRoleByIdQuery = <
      TData = GetRoleByIdQuery,
      TError = unknown
    >(
      variables?: GetRoleByIdQueryVariables,
      options?: UseQueryOptions<GetRoleByIdQuery, TError, TData>
    ) =>
    useQuery<GetRoleByIdQuery, TError, TData>(
      variables === undefined ? ['GetRoleById'] : ['GetRoleById', variables],
      fetchData<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, variables),
      options
    );
export const GetRoleActionsDocument = `
    query GetRoleActions($_eq: String) {
  roles_actions(where: {roleId: {_eq: $_eq}}) {
    action {
      id
      systemCriteria
      name
    }
  }
}
    `;
export const useGetRoleActionsQuery = <
      TData = GetRoleActionsQuery,
      TError = unknown
    >(
      variables?: GetRoleActionsQueryVariables,
      options?: UseQueryOptions<GetRoleActionsQuery, TError, TData>
    ) =>
    useQuery<GetRoleActionsQuery, TError, TData>(
      variables === undefined ? ['GetRoleActions'] : ['GetRoleActions', variables],
      fetchData<GetRoleActionsQuery, GetRoleActionsQueryVariables>(GetRoleActionsDocument, variables),
      options
    );
export const GetSeriesSeasonsDocument = `
    query GetSeriesSeasons($limit: Int, $offset: Int, $serieId: String_comparison_exp = {_eq: ""}, $name: jsonb_comparison_exp = {}) {
  seasons(limit: $limit, offset: $offset, where: {serieId: $serieId, name: $name}) {
    id
    name
    series {
      id
      name
    }
  }
  seasons_aggregate(where: {serieId: $serieId, name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetSeriesSeasonsQuery = <
      TData = GetSeriesSeasonsQuery,
      TError = unknown
    >(
      variables?: GetSeriesSeasonsQueryVariables,
      options?: UseQueryOptions<GetSeriesSeasonsQuery, TError, TData>
    ) =>
    useQuery<GetSeriesSeasonsQuery, TError, TData>(
      variables === undefined ? ['GetSeriesSeasons'] : ['GetSeriesSeasons', variables],
      fetchData<GetSeriesSeasonsQuery, GetSeriesSeasonsQueryVariables>(GetSeriesSeasonsDocument, variables),
      options
    );
export const GetSeasonByIdDocument = `
    query GetSeasonById($id: String = "") {
  seasons_by_pk(id: $id) {
    id
    name
    description
  }
}
    `;
export const useGetSeasonByIdQuery = <
      TData = GetSeasonByIdQuery,
      TError = unknown
    >(
      variables?: GetSeasonByIdQueryVariables,
      options?: UseQueryOptions<GetSeasonByIdQuery, TError, TData>
    ) =>
    useQuery<GetSeasonByIdQuery, TError, TData>(
      variables === undefined ? ['GetSeasonById'] : ['GetSeasonById', variables],
      fetchData<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>(GetSeasonByIdDocument, variables),
      options
    );
export const GetSeriesWithSeasonsAndEpisodeDocument = `
    query GetSeriesWithSeasonsAndEpisode {
  series {
    id
    name
    seasons {
      id
      name
      videos {
        id
        episode
      }
    }
  }
}
    `;
export const useGetSeriesWithSeasonsAndEpisodeQuery = <
      TData = GetSeriesWithSeasonsAndEpisodeQuery,
      TError = unknown
    >(
      variables?: GetSeriesWithSeasonsAndEpisodeQueryVariables,
      options?: UseQueryOptions<GetSeriesWithSeasonsAndEpisodeQuery, TError, TData>
    ) =>
    useQuery<GetSeriesWithSeasonsAndEpisodeQuery, TError, TData>(
      variables === undefined ? ['GetSeriesWithSeasonsAndEpisode'] : ['GetSeriesWithSeasonsAndEpisode', variables],
      fetchData<GetSeriesWithSeasonsAndEpisodeQuery, GetSeriesWithSeasonsAndEpisodeQueryVariables>(GetSeriesWithSeasonsAndEpisodeDocument, variables),
      options
    );
export const GetSeriesDocument = `
    query GetSeries($limit: Int, $offset: Int, $name: jsonb_comparison_exp = {}) {
  series(limit: $limit, offset: $offset, where: {name: $name}) {
    id
    name
  }
  series_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetSeriesQuery = <
      TData = GetSeriesQuery,
      TError = unknown
    >(
      variables?: GetSeriesQueryVariables,
      options?: UseQueryOptions<GetSeriesQuery, TError, TData>
    ) =>
    useQuery<GetSeriesQuery, TError, TData>(
      variables === undefined ? ['GetSeries'] : ['GetSeries', variables],
      fetchData<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, variables),
      options
    );
export const GetSeriesByIdDocument = `
    query GetSeriesById($id: String = "") {
  series_by_pk(id: $id) {
    id
    name
    description
  }
}
    `;
export const useGetSeriesByIdQuery = <
      TData = GetSeriesByIdQuery,
      TError = unknown
    >(
      variables?: GetSeriesByIdQueryVariables,
      options?: UseQueryOptions<GetSeriesByIdQuery, TError, TData>
    ) =>
    useQuery<GetSeriesByIdQuery, TError, TData>(
      variables === undefined ? ['GetSeriesById'] : ['GetSeriesById', variables],
      fetchData<GetSeriesByIdQuery, GetSeriesByIdQueryVariables>(GetSeriesByIdDocument, variables),
      options
    );
export const GetDashboardGeneralStatisticsDocument = `
    query GetDashboardGeneralStatistics {
  getGeneralStatisticsCount {
    label
    count
  }
}
    `;
export const useGetDashboardGeneralStatisticsQuery = <
      TData = GetDashboardGeneralStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardGeneralStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardGeneralStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardGeneralStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardGeneralStatistics'] : ['GetDashboardGeneralStatistics', variables],
      fetchData<GetDashboardGeneralStatisticsQuery, GetDashboardGeneralStatisticsQueryVariables>(GetDashboardGeneralStatisticsDocument, variables),
      options
    );
export const GetDashboardUsersPerMonthsStatisticsDocument = `
    query GetDashboardUsersPerMonthsStatistics {
  getCreatedUsersMonthsStatistics {
    month
    count
  }
}
    `;
export const useGetDashboardUsersPerMonthsStatisticsQuery = <
      TData = GetDashboardUsersPerMonthsStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardUsersPerMonthsStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardUsersPerMonthsStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardUsersPerMonthsStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardUsersPerMonthsStatistics'] : ['GetDashboardUsersPerMonthsStatistics', variables],
      fetchData<GetDashboardUsersPerMonthsStatisticsQuery, GetDashboardUsersPerMonthsStatisticsQueryVariables>(GetDashboardUsersPerMonthsStatisticsDocument, variables),
      options
    );
export const GetDashboardEarnedBadgesMonthsStatisticsDocument = `
    query GetDashboardEarnedBadgesMonthsStatistics {
  getEarnedBadgesMonthsStatistics {
    month
    count
  }
}
    `;
export const useGetDashboardEarnedBadgesMonthsStatisticsQuery = <
      TData = GetDashboardEarnedBadgesMonthsStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardEarnedBadgesMonthsStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardEarnedBadgesMonthsStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardEarnedBadgesMonthsStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardEarnedBadgesMonthsStatistics'] : ['GetDashboardEarnedBadgesMonthsStatistics', variables],
      fetchData<GetDashboardEarnedBadgesMonthsStatisticsQuery, GetDashboardEarnedBadgesMonthsStatisticsQueryVariables>(GetDashboardEarnedBadgesMonthsStatisticsDocument, variables),
      options
    );
export const GetDashboardTotalSpentTimeStatisticsDocument = `
    query GetDashboardTotalSpentTimeStatistics($startDate: DateTime = "", $endDate: DateTime = "") {
  getMinutesUsageForAllUsers(datesDto: {endDate: $endDate, startDate: $startDate}) {
    day
    totalMinutes
  }
}
    `;
export const useGetDashboardTotalSpentTimeStatisticsQuery = <
      TData = GetDashboardTotalSpentTimeStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardTotalSpentTimeStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardTotalSpentTimeStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardTotalSpentTimeStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardTotalSpentTimeStatistics'] : ['GetDashboardTotalSpentTimeStatistics', variables],
      fetchData<GetDashboardTotalSpentTimeStatisticsQuery, GetDashboardTotalSpentTimeStatisticsQueryVariables>(GetDashboardTotalSpentTimeStatisticsDocument, variables),
      options
    );
export const GetDashboardUsersAgeStatisticsDocument = `
    query GetDashboardUsersAgeStatistics {
  getUsersAgeStatistics {
    age
    count
  }
}
    `;
export const useGetDashboardUsersAgeStatisticsQuery = <
      TData = GetDashboardUsersAgeStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardUsersAgeStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardUsersAgeStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardUsersAgeStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardUsersAgeStatistics'] : ['GetDashboardUsersAgeStatistics', variables],
      fetchData<GetDashboardUsersAgeStatisticsQuery, GetDashboardUsersAgeStatisticsQueryVariables>(GetDashboardUsersAgeStatisticsDocument, variables),
      options
    );
export const GetDashboardUserStatusAndTypesStatisticsDocument = `
    query GetDashboardUserStatusAndTypesStatistics {
  getUsersAccountStatusStatistics {
    accountStatus {
      label
      count
    }
    accountType {
      label
      count
    }
  }
}
    `;
export const useGetDashboardUserStatusAndTypesStatisticsQuery = <
      TData = GetDashboardUserStatusAndTypesStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardUserStatusAndTypesStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardUserStatusAndTypesStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardUserStatusAndTypesStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardUserStatusAndTypesStatistics'] : ['GetDashboardUserStatusAndTypesStatistics', variables],
      fetchData<GetDashboardUserStatusAndTypesStatisticsQuery, GetDashboardUserStatusAndTypesStatisticsQueryVariables>(GetDashboardUserStatusAndTypesStatisticsDocument, variables),
      options
    );
export const GetDashboardTopCountriesDocument = `
    query GetDashboardTopCountries {
  getUsersCountriesStatistics {
    country
    count
  }
}
    `;
export const useGetDashboardTopCountriesQuery = <
      TData = GetDashboardTopCountriesQuery,
      TError = unknown
    >(
      variables?: GetDashboardTopCountriesQueryVariables,
      options?: UseQueryOptions<GetDashboardTopCountriesQuery, TError, TData>
    ) =>
    useQuery<GetDashboardTopCountriesQuery, TError, TData>(
      variables === undefined ? ['GetDashboardTopCountries'] : ['GetDashboardTopCountries', variables],
      fetchData<GetDashboardTopCountriesQuery, GetDashboardTopCountriesQueryVariables>(GetDashboardTopCountriesDocument, variables),
      options
    );
export const GetDashboardContentStatisticsDocument = `
    query GetDashboardContentStatistics {
  getContentStatisticsCount {
    label
    count
  }
}
    `;
export const useGetDashboardContentStatisticsQuery = <
      TData = GetDashboardContentStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardContentStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardContentStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardContentStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardContentStatistics'] : ['GetDashboardContentStatistics', variables],
      fetchData<GetDashboardContentStatisticsQuery, GetDashboardContentStatisticsQueryVariables>(GetDashboardContentStatisticsDocument, variables),
      options
    );
export const GetDashboardCategoriesWatchedMinutesStatisticsDocument = `
    query GetDashboardCategoriesWatchedMinutesStatistics($startDate: DateTime = "", $endDate: DateTime = "") {
  getCategoriesWatchedMinutes(
    datesDto: {startDate: $startDate, endDate: $endDate}
  ) {
    categoryName
    color
    totalMinutes
  }
}
    `;
export const useGetDashboardCategoriesWatchedMinutesStatisticsQuery = <
      TData = GetDashboardCategoriesWatchedMinutesStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardCategoriesWatchedMinutesStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardCategoriesWatchedMinutesStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardCategoriesWatchedMinutesStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardCategoriesWatchedMinutesStatistics'] : ['GetDashboardCategoriesWatchedMinutesStatistics', variables],
      fetchData<GetDashboardCategoriesWatchedMinutesStatisticsQuery, GetDashboardCategoriesWatchedMinutesStatisticsQueryVariables>(GetDashboardCategoriesWatchedMinutesStatisticsDocument, variables),
      options
    );
export const GetDashboardVideosCategoriesStatisticsDocument = `
    query GetDashboardVideosCategoriesStatistics {
  getCategoriesVideosStatistics {
    category
    count
  }
}
    `;
export const useGetDashboardVideosCategoriesStatisticsQuery = <
      TData = GetDashboardVideosCategoriesStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardVideosCategoriesStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardVideosCategoriesStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardVideosCategoriesStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardVideosCategoriesStatistics'] : ['GetDashboardVideosCategoriesStatistics', variables],
      fetchData<GetDashboardVideosCategoriesStatisticsQuery, GetDashboardVideosCategoriesStatisticsQueryVariables>(GetDashboardVideosCategoriesStatisticsDocument, variables),
      options
    );
export const GetDashboardUsersCategoriesStatisticsDocument = `
    query GetDashboardUsersCategoriesStatistics {
  getCategoriesUsersStatistics {
    category
    count
  }
}
    `;
export const useGetDashboardUsersCategoriesStatisticsQuery = <
      TData = GetDashboardUsersCategoriesStatisticsQuery,
      TError = unknown
    >(
      variables?: GetDashboardUsersCategoriesStatisticsQueryVariables,
      options?: UseQueryOptions<GetDashboardUsersCategoriesStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetDashboardUsersCategoriesStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetDashboardUsersCategoriesStatistics'] : ['GetDashboardUsersCategoriesStatistics', variables],
      fetchData<GetDashboardUsersCategoriesStatisticsQuery, GetDashboardUsersCategoriesStatisticsQueryVariables>(GetDashboardUsersCategoriesStatisticsDocument, variables),
      options
    );
export const GetTicketsDocument = `
    query GetTickets($limit: Int, $offset: Int, $subject: String_comparison_exp = {}, $status: String_comparison_exp = {}) {
  tickets(
    limit: $limit
    offset: $offset
    where: {subject: $subject, status: $status}
  ) {
    id
    subject
    content
    attachmentUrl
    status
  }
  tickets_aggregate(where: {subject: $subject, status: $status}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetTicketsQuery = <
      TData = GetTicketsQuery,
      TError = unknown
    >(
      variables?: GetTicketsQueryVariables,
      options?: UseQueryOptions<GetTicketsQuery, TError, TData>
    ) =>
    useQuery<GetTicketsQuery, TError, TData>(
      variables === undefined ? ['GetTickets'] : ['GetTickets', variables],
      fetchData<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, variables),
      options
    );
export const GetTicketDetailsAndRepliesDocument = `
    query GetTicketDetailsAndReplies($id: String = "") {
  tickets_by_pk(id: $id) {
    id
    subject
    content
    platform
    status
    createdAt
    attachmentUrl
    user {
      fullName
    }
    ticket_replies {
      id
      content
      createdAt
    }
  }
}
    `;
export const useGetTicketDetailsAndRepliesQuery = <
      TData = GetTicketDetailsAndRepliesQuery,
      TError = unknown
    >(
      variables?: GetTicketDetailsAndRepliesQueryVariables,
      options?: UseQueryOptions<GetTicketDetailsAndRepliesQuery, TError, TData>
    ) =>
    useQuery<GetTicketDetailsAndRepliesQuery, TError, TData>(
      variables === undefined ? ['GetTicketDetailsAndReplies'] : ['GetTicketDetailsAndReplies', variables],
      fetchData<GetTicketDetailsAndRepliesQuery, GetTicketDetailsAndRepliesQueryVariables>(GetTicketDetailsAndRepliesDocument, variables),
      options
    );
export const GetUsersDocument = `
    query GetUsers($limit: Int, $offset: Int, $type: String_comparison_exp = {}, $status: String_comparison_exp = {}, $fullName: String_comparison_exp = {}) {
  users(
    limit: $limit
    offset: $offset
    where: {type: $type, status: $status, fullName: $fullName}
  ) {
    id
    avatar
    fullName
    email
    type
    status
    verified
  }
  users_aggregate(where: {type: $type, status: $status, fullName: $fullName}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      variables?: GetUsersQueryVariables,
      options?: UseQueryOptions<GetUsersQuery, TError, TData>
    ) =>
    useQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers'] : ['GetUsers', variables],
      fetchData<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, variables),
      options
    );
export const GetUserByIdDocument = `
    query GetUserById($id: String = "") {
  users_by_pk(id: $id) {
    id
    avatar
    email
    fullName
    status
    verified
    type
  }
}
    `;
export const useGetUserByIdQuery = <
      TData = GetUserByIdQuery,
      TError = unknown
    >(
      variables?: GetUserByIdQueryVariables,
      options?: UseQueryOptions<GetUserByIdQuery, TError, TData>
    ) =>
    useQuery<GetUserByIdQuery, TError, TData>(
      variables === undefined ? ['GetUserById'] : ['GetUserById', variables],
      fetchData<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, variables),
      options
    );
export const GetUserSpentTimeStatisticsDocument = `
    query getUserSpentTimeStatistics($userId: String = "", $startDate: DateTime = "", $endDate: DateTime = "") {
  getUserSpentTimeStatisticsByAdmin(
    getUserStatisticsDto: {userId: $userId, startDate: $startDate, endDate: $endDate}
  ) {
    daysStatistics {
      day
      totalMinutes
    }
  }
}
    `;
export const useGetUserSpentTimeStatisticsQuery = <
      TData = GetUserSpentTimeStatisticsQuery,
      TError = unknown
    >(
      variables?: GetUserSpentTimeStatisticsQueryVariables,
      options?: UseQueryOptions<GetUserSpentTimeStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetUserSpentTimeStatisticsQuery, TError, TData>(
      variables === undefined ? ['getUserSpentTimeStatistics'] : ['getUserSpentTimeStatistics', variables],
      fetchData<GetUserSpentTimeStatisticsQuery, GetUserSpentTimeStatisticsQueryVariables>(GetUserSpentTimeStatisticsDocument, variables),
      options
    );
export const GetUserStatisticsDocument = `
    query GetUserStatistics($userId: String = "", $type: String = "") {
  getUserStatisticsCountByAdmin(
    getUserCountStatisticsDto: {type: $type, userId: $userId}
  ) {
    label
    count
  }
}
    `;
export const useGetUserStatisticsQuery = <
      TData = GetUserStatisticsQuery,
      TError = unknown
    >(
      variables?: GetUserStatisticsQueryVariables,
      options?: UseQueryOptions<GetUserStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetUserStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetUserStatistics'] : ['GetUserStatistics', variables],
      fetchData<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>(GetUserStatisticsDocument, variables),
      options
    );
export const GetUserCategoriesStatisticsDocument = `
    query GetUserCategoriesStatistics($userId: String = "", $startDate: DateTime = "", $endDate: DateTime = "") {
  getUserWatchedCategoriesStatisticsByAdmin(
    getUserStatisticsDto: {endDate: $endDate, userId: $userId, startDate: $startDate}
  ) {
    categoriesDetails {
      categoryName
      color
      totalMinutes
    }
  }
}
    `;
export const useGetUserCategoriesStatisticsQuery = <
      TData = GetUserCategoriesStatisticsQuery,
      TError = unknown
    >(
      variables?: GetUserCategoriesStatisticsQueryVariables,
      options?: UseQueryOptions<GetUserCategoriesStatisticsQuery, TError, TData>
    ) =>
    useQuery<GetUserCategoriesStatisticsQuery, TError, TData>(
      variables === undefined ? ['GetUserCategoriesStatistics'] : ['GetUserCategoriesStatistics', variables],
      fetchData<GetUserCategoriesStatisticsQuery, GetUserCategoriesStatisticsQueryVariables>(GetUserCategoriesStatisticsDocument, variables),
      options
    );
export const GetChannelVideosDocument = `
    query GetChannelVideos($offset: Int, $limit: Int, $channelId: String_comparison_exp = {_eq: ""}, $title: jsonb_comparison_exp = {}) {
  videos(
    limit: $limit
    offset: $offset
    where: {channelId: $channelId, title: $title}
  ) {
    id
    thumbnailUrl
    title
    description
    classification
    genre
    link
  }
  videos_aggregate(where: {channelId: $channelId, title: $title}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetChannelVideosQuery = <
      TData = GetChannelVideosQuery,
      TError = unknown
    >(
      variables?: GetChannelVideosQueryVariables,
      options?: UseQueryOptions<GetChannelVideosQuery, TError, TData>
    ) =>
    useQuery<GetChannelVideosQuery, TError, TData>(
      variables === undefined ? ['GetChannelVideos'] : ['GetChannelVideos', variables],
      fetchData<GetChannelVideosQuery, GetChannelVideosQueryVariables>(GetChannelVideosDocument, variables),
      options
    );
export const GetVideoByIdDocument = `
    query GetVideoById($id: String = "") {
  videos_by_pk(id: $id) {
    id
    title
    link
    description
    classification
    uploadingDate
    musicType
  }
}
    `;
export const useGetVideoByIdQuery = <
      TData = GetVideoByIdQuery,
      TError = unknown
    >(
      variables?: GetVideoByIdQueryVariables,
      options?: UseQueryOptions<GetVideoByIdQuery, TError, TData>
    ) =>
    useQuery<GetVideoByIdQuery, TError, TData>(
      variables === undefined ? ['GetVideoById'] : ['GetVideoById', variables],
      fetchData<GetVideoByIdQuery, GetVideoByIdQueryVariables>(GetVideoByIdDocument, variables),
      options
    );
export const GetGenresDocument = `
    query getGenres {
  genres {
    name
  }
}
    `;
export const useGetGenresQuery = <
      TData = GetGenresQuery,
      TError = unknown
    >(
      variables?: GetGenresQueryVariables,
      options?: UseQueryOptions<GetGenresQuery, TError, TData>
    ) =>
    useQuery<GetGenresQuery, TError, TData>(
      variables === undefined ? ['getGenres'] : ['getGenres', variables],
      fetchData<GetGenresQuery, GetGenresQueryVariables>(GetGenresDocument, variables),
      options
    );
export const GetSeasonVideosDocument = `
    query GetSeasonVideos($limit: Int, $offset: Int, $seasonId: String_comparison_exp = {_eq: ""}, $title: jsonb_comparison_exp = {}) {
  videos(
    limit: $limit
    offset: $offset
    where: {seasonId: $seasonId, title: $title}
  ) {
    id
    thumbnailUrl
    title
    episode
    link
  }
  videos_aggregate(where: {seasonId: $seasonId, title: $title}) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetSeasonVideosQuery = <
      TData = GetSeasonVideosQuery,
      TError = unknown
    >(
      variables?: GetSeasonVideosQueryVariables,
      options?: UseQueryOptions<GetSeasonVideosQuery, TError, TData>
    ) =>
    useQuery<GetSeasonVideosQuery, TError, TData>(
      variables === undefined ? ['GetSeasonVideos'] : ['GetSeasonVideos', variables],
      fetchData<GetSeasonVideosQuery, GetSeasonVideosQueryVariables>(GetSeasonVideosDocument, variables),
      options
    );
export const GetVideosDocument = `
    query GetVideos {
  videos {
    id
    title
  }
}
    `;
export const useGetVideosQuery = <
      TData = GetVideosQuery,
      TError = unknown
    >(
      variables?: GetVideosQueryVariables,
      options?: UseQueryOptions<GetVideosQuery, TError, TData>
    ) =>
    useQuery<GetVideosQuery, TError, TData>(
      variables === undefined ? ['GetVideos'] : ['GetVideos', variables],
      fetchData<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, variables),
      options
    );
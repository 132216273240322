import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { m } from 'framer-motion';
import { useCallback, useState } from 'react';
import { varHover } from 'src/components/animate';
import AppLoadingAndErrorWrapper from 'src/components/appComponents/AppLoadingAndErrorWrapper';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useGetNotificationsForComingSoonVideosQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('today');

  const { data, isLoading } = useGetNotificationsForComingSoonVideosQuery();

  const todayNotifications = data?.getNeedsToPublishVideos?.today;
  const tomorrowNotifications = data?.getNeedsToPublishVideos?.tomorrow;

  const notificationsCount =
    todayNotifications &&
    tomorrowNotifications &&
    todayNotifications?.length + tomorrowNotifications?.length;

  const TABS = [
    {
      value: 'today',
      label: 'Today',
      count: todayNotifications?.length,
    },
    {
      value: 'tomorrow',
      label: 'Tomorrow',
      count: tomorrowNotifications?.length,
    },
  ];

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const noNotifications = (
    <Stack alignItems="center" my="auto">
      <Typography variant="h6" sx={{ flexGrow: 1, color: 'text.disabled' }}>
        There are no notifications
      </Typography>
    </Stack>
  );

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={(tab.value === currentTab && 'filled') || 'soft'}
              color={(tab.value === 'tomorrow' && 'info') || 'default'}
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderTodayList = (
    <>
      {todayNotifications?.length == 0 ? (
        noNotifications
      ) : (
        <Scrollbar>
          <List disablePadding>
            {todayNotifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onCloseDrawer={drawer.onFalse}
              />
            ))}
          </List>
        </Scrollbar>
      )}
    </>
  );

  const renderTomorrowList = (
    <>
      {tomorrowNotifications?.length == 0 ? (
        noNotifications
      ) : (
        <Scrollbar>
          <List disablePadding>
            {tomorrowNotifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onCloseDrawer={drawer.onFalse}
              />
            ))}
          </List>
        </Scrollbar>
      )}
    </>
  );

  return (
    <>
      {/* Notifications Icon Buton */}
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={notificationsCount} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <AppLoadingAndErrorWrapper isLoading={isLoading}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2.5, pr: 1 }}
          >
            {renderTabs}
          </Stack>

          <Divider />

          {currentTab === 'today' && renderTodayList}
          {currentTab === 'tomorrow' && renderTomorrowList}
        </AppLoadingAndErrorWrapper>
      </Drawer>
    </>
  );
}

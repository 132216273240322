export const Months = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];

export const ReportMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// Music Type
export const MUSIC_TYPE = [
  { id: 'Normal', name: { en: 'With music' } },
  { id: 'Light', name: { en: 'Light music' } },
  { id: 'No Music', name: { en: 'No music at all' } },
];

// App Permissions
export const AppPermissions = {
  VIEW_DASHBOARD: 'Dashboard.View',
  CREATE_DASHBOARD: 'Dashboard.Create',
  EDIT_DASHBOARD: 'Dashboard.Edit',
  DELETE_DASHBOARD: 'Dashboard.Delete',
  // Users Management
  VIEW_USER_MANAGEMENT: 'UserManagement.View',
  CREATE_USER_MANAGEMENT: 'UserManagement.Create',
  EDITE_USER_MANAGEMENT: 'UserManagement.Edit',
  DELETE_USER_MANAGEMENT: 'UserManagement.Delete',
  // Content Management
  VIEW_CONTENT_MANAGEMENT: 'ContentManagement.View',
  CREATE_CONTENT_MANAGEMENT: 'ContentManagement.Create',
  EDITE_CONTENT_MANAGEMENT: 'ContentManagement.Edit',
  DELETE_CONTENT_MANAGEMENT: 'ContentManagement.Delete',
  // Roles Management
  VIEW_ROLE_MANAGEMENT: 'Roles.View',
  CREATE_ROLE_MANAGEMENT: 'Roles.Create',
  EDITE_ROLE_MANAGEMENT: 'Roles.Edit',
  DELETE_ROLE_MANAGEMENT: 'Roles.Delete',
  // Chrome Extension Management
  VIEW_CHROME_EXTENSION_MANAGEMENT: 'ChromeExtensionManagement.View',
  CREATE_CHROME_EXTENSION_MANAGEMENT: 'ChromeExtensionManagement.Create',
  EDITE_CHROME_EXTENSION_MANAGEMENT: 'ChromeExtensionManagement.Edit',
  DELETE_CHROME_EXTENSION_MANAGEMENT: 'ChromeExtensionManagement.Delete',
  // AI Suggestions
  VIEW_AI_SUGGESTIONS: 'AISuggestions.View',
  CREATE_AI_SUGGESTIONS: 'AISuggestions.Create',
  EDITE_AI_SUGGESTIONS: 'AISuggestions.Edit',
  DELETE_AI_SUGGESTIONS: 'AISuggestions.Delete',
  // Badges
  VIEW_BADGE: 'Badges.View',
  CREATE_BADGE: 'Badges.Create',
  EDITE_BADGE: 'Badges.Edit',
  DELETE_BADGE: 'Badges.Delete',
  // Help Center
  VIEW_HELP_CENTER: 'HelpCenter.View',
  CREATE_HELP_CENTER: 'HelpCenter.Create',
  EDITE_HELP_CENTER: 'HelpCenter.Edit',
  DELETE_HELP_CENTER: 'HelpCenter.Delete',
  // Notifications
  VIEW_NOTIFICATION: 'Notifications.View',
  CREATE_NOTIFICATION: 'Notifications.Create',
  EDITE_NOTIFICATION: 'Notifications.Edit',
  DELETE_NOTIFICATION: 'Notifications.Delete',
};

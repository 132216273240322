import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Dashboard
const GeneralStatisticsPage = lazy(() => import('src/pages/dashboard/statistics/General'));
const ContentStatisticsPage = lazy(() => import('src/pages/dashboard/statistics/Content'));

// Users Management
const AppUsersPage = lazy(() => import('src/pages/dashboard/appUsers'));
const AddUserPage = lazy(() => import('src/pages/dashboard/appUsers/AddUser'));
const EditUserPage = lazy(() => import('src/pages/dashboard/appUsers/EditUser'));
const DashboardUsersPage = lazy(() => import('src/pages/dashboard/dashboardUsers'));
const AddDashboardUserPage = lazy(
  () => import('src/pages/dashboard/dashboardUsers/AddDashboardUser')
);
const EditDashboardUserPage = lazy(
  () => import('src/pages/dashboard/dashboardUsers/EditDashboardUser')
);

// Content Management
const ChannelsPage = lazy(() => import('src/pages/dashboard/channels'));
const AddChannelPage = lazy(() => import('src/pages/dashboard/channels/AddChannel'));
const EditChannelPage = lazy(() => import('src/pages/dashboard/channels/EditChannel'));
const ChannelDetailsPage = lazy(() => import('src/pages/dashboard/channels/ChannelDetails'));
const ChannelVideosPage = lazy(() => import('src/pages/dashboard/videos/ChannelVideosPage'));
const AddVideoPage = lazy(() => import('src/pages/dashboard/videos/AddVideo'));
const EditVideoPage = lazy(() => import('src/pages/dashboard/videos/EditVideo'));
const CategoriesPage = lazy(() => import('src/pages/dashboard/categories'));
const AddCategoryPage = lazy(() => import('src/pages/dashboard/categories/AddCategory'));
const EditCategoryPage = lazy(() => import('src/pages/dashboard/categories/EditCategory'));
const SeriesPage = lazy(() => import('src/pages/dashboard/series'));
const AddSeriesPage = lazy(() => import('src/pages/dashboard/series/AddSeries'));
const EditSeriesPage = lazy(() => import('src/pages/dashboard/series/EditSeries'));
const SeasonsPage = lazy(() => import('src/pages/dashboard/seasons'));
const AddSeasonPage = lazy(() => import('src/pages/dashboard/seasons/AddSeason'));
const EditSeasonPage = lazy(() => import('src/pages/dashboard/seasons/EditSeason'));
const EpisodesPage = lazy(() => import('src/pages/dashboard/episodes'));
const AddEpisodePage = lazy(() => import('src/pages/dashboard/episodes/AddEpisode'));
const ComingSoonVideosPage = lazy(() => import('src/pages/dashboard/comingSoonVideos'));
const ComingSoonVideoDetailsPage = lazy(
  () => import('src/pages/dashboard/comingSoonVideos/ComingSoonVideoDetails')
);

// Ai Suggestions
const AISuggestionsKeywordsPage = lazy(() => import('src/pages/dashboard/AISuggestionsKeywords'));
const AddKeywordPage = lazy(
  () => import('src/pages/dashboard/AISuggestionsKeywords/AddAISuggestionKeyword')
);
const AISuggestionsPage = lazy(() => import('src/pages/dashboard/AISuggestions'));
const AISuggestionDetailsPage = lazy(
  () => import('src/pages/dashboard/AISuggestions/AISuggestionsDetails')
);
const AISuggestionsSettingsPage = lazy(
  () => import('src/pages/dashboard/AISuggestions/AISuggestionsSettings')
);
const PublishAISuggestionPage = lazy(
  () => import('src/pages/dashboard/AISuggestions/PublishAISuggestion')
);

// Badges
const BadgesPage = lazy(() => import('src/pages/dashboard/badges'));
const AddBadgePage = lazy(() => import('src/pages/dashboard/badges/AddBadge'));
const EditBadgePage = lazy(() => import('src/pages/dashboard/badges/EditBadge'));
const BadgeVideoPage = lazy(() => import('src/pages/dashboard/badges/videos/BadgeVideos'));
const AddBadgeVideoPage = lazy(() => import('src/pages/dashboard/badges/videos/AddBadgeVideo'));

// Chrome Extension Management
const ChromeExtensionStatisticsPage = lazy(
  () => import('src/pages/dashboard/chromeExtensionStatistics')
);
const ChromeExtensionUsersPage = lazy(() => import('src/pages/dashboard/chromeExtensionUsers'));
const ChromeExtensionSuggestionsPage = lazy(
  () => import('src/pages/dashboard/chromeExtensionSuggestions')
);
const ChromeExtensionSuggestionsDetailsPage = lazy(
  () => import('src/pages/dashboard/chromeExtensionSuggestions/ChromeExtensionSuggestionsDetails')
);
const PublishChromeExtensionSuggestionPage = lazy(
  () => import('src/pages/dashboard/chromeExtensionSuggestions/PublishChromeExtensionSuggestion')
);
const ChromeExtensionSuggestionSettingsPage = lazy(
  () => import('src/pages/dashboard/chromeExtensionSuggestions/ChromeExtensionSuggestionSettings')
);

// Roles
const RolesPage = lazy(() => import('src/pages/dashboard/roles'));
const AddRolePage = lazy(() => import('src/pages/dashboard/roles/AddRole'));
const EditRolePage = lazy(() => import('src/pages/dashboard/roles/EditRole'));

// Notifications
const NotificationsPage = lazy(() => import('src/pages/dashboard/notifications'));
const AddNotificationPage = lazy(() => import('src/pages/dashboard/notifications/AddNotification'));

// Help Center
const FAQPage = lazy(() => import('src/pages/dashboard/faq'));
const AddFAQPage = lazy(() => import('src/pages/dashboard/faq/AddFAQ'));
const EditFAQPage = lazy(() => import('src/pages/dashboard/faq/EditFAQ'));
const TicketsPage = lazy(() => import('src/pages/dashboard/tickets'));
const TicketDetailsPage = lazy(() => import('src/pages/dashboard/tickets/TicketDetails'));
const PrivacyAndPolicyPage = lazy(() => import('src/pages/dashboard/privacy'));
const AppDiscoveryPage = lazy(() => import('src/pages/dashboard/appDiscovery'));
const AddAppDiscoveryPage = lazy(() => import('src/pages/dashboard/appDiscovery/AddAppDiscovery'));
const EditAppDiscoveryPage = lazy(
  () => import('src/pages/dashboard/appDiscovery/EditAppDiscovery')
);
const RreportingPage = lazy(() => import('src/pages/dashboard/reporting'));
const ReportedVideoDetailsPage = lazy(
  () => import('src/pages/dashboard/reporting/ReportedVideoDetails')
);

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'statistics',
        children: [
          { element: <GeneralStatisticsPage />, index: true },
          { path: 'content', element: <ContentStatisticsPage /> },
        ],
      },
      // Users
      {
        path: 'users',
        children: [
          { element: <AppUsersPage />, index: true },
          { path: 'addUser', element: <AddUserPage /> },
          { path: 'editUser/:userId', element: <EditUserPage /> },
          // Dashbaord User
          { path: 'admins', element: <DashboardUsersPage /> },
          { path: 'admins/addAdmin', element: <AddDashboardUserPage /> },
          { path: 'admins/editAdmin/:adminId', element: <EditDashboardUserPage /> },
        ],
      },
      // Content
      {
        path: 'content',
        children: [
          // Channels
          { element: <ChannelsPage />, index: true },
          { path: 'addChannel', element: <AddChannelPage /> },
          { path: 'editChannel/:channelId', element: <EditChannelPage /> },
          { path: 'channelInfo/:channelId', element: <ChannelDetailsPage /> },
          { path: 'videos/:channelId', element: <ChannelVideosPage /> },
          { path: 'videos/:channelId/addVideo', element: <AddVideoPage /> },
          { path: 'videos/:channelId/editVideo/:videoId', element: <EditVideoPage /> },
          // Categories
          { path: 'categories', element: <CategoriesPage /> },
          { path: 'categories/addCategory', element: <AddCategoryPage /> },
          { path: 'categories/editCategory/:categoryId', element: <EditCategoryPage /> },
          // Series
          { path: 'series', element: <SeriesPage /> },
          { path: 'series/addSeries', element: <AddSeriesPage /> },
          { path: 'series/editSeries/:seriesId', element: <EditSeriesPage /> },
          // Seasons
          { path: 'series/:serieId/seasons', element: <SeasonsPage /> },
          { path: 'series/:serieId/seasons/addSeason', element: <AddSeasonPage /> },
          { path: 'series/:serieId/seasons/editSeason/:seasonId', element: <EditSeasonPage /> },
          // Episodes
          { path: 'series/:serieId/seasons/:seasonId/episodes', element: <EpisodesPage /> },
          {
            path: 'series/:serieId/seasons/:seasonId/episodes/addEpisode',
            element: <AddEpisodePage />,
          },
          // Coming soon videos
          { path: 'comingSoon', element: <ComingSoonVideosPage /> },
          { path: 'comingSoon/editComingSoonVideo/:videoId', element: <EditVideoPage /> },
          { path: 'comingSoon/:videoId', element: <ComingSoonVideoDetailsPage /> },
        ],
      },
      // AI Suggestions
      {
        path: 'aiSuggestions',
        children: [
          { element: <AISuggestionsKeywordsPage />, index: true },
          { path: 'addKeyword', element: <AddKeywordPage /> },
          { path: 'suggestions', element: <AISuggestionsPage /> },
          { path: 'suggestions/:suggestedVideoId', element: <AISuggestionDetailsPage /> },
          { path: 'suggestions/settings', element: <AISuggestionsSettingsPage /> },
          { path: 'suggestions/:suggestedVideoId/publish', element: <PublishAISuggestionPage /> },
        ],
      },
      // Badges
      {
        path: 'badges',
        children: [
          { element: <BadgesPage />, index: true },
          { path: 'addBadge', element: <AddBadgePage /> },
          { path: 'editBadge/:badgeId', element: <EditBadgePage /> },
          { path: 'videos/:badgeId', element: <BadgeVideoPage /> },
          { path: 'videos/:badgeId/addVideo/:badgeCategoryId', element: <AddBadgeVideoPage /> },
        ],
      },
      // Chrome Extension Management
      {
        path: 'chromeExtension',
        children: [
          { element: <ChromeExtensionStatisticsPage />, index: true },
          { path: 'users', element: <ChromeExtensionUsersPage /> },
          // User Suggestions
          { path: 'userSuggestions/:extensionUserId', element: <ChromeExtensionSuggestionsPage /> },
          {
            path: 'userSuggestions/:extensionUserId/info/:suggestedVideoId',
            element: <ChromeExtensionSuggestionsDetailsPage />,
          },
          {
            path: 'userSuggestions/:extensionUserId/info/:suggestedVideoId/publish',
            element: <PublishChromeExtensionSuggestionPage />,
          },
          {
            path: 'userSuggestions/:extensionUserId/settings',
            element: <ChromeExtensionSuggestionSettingsPage />,
          },
          // All Suggestions
          { path: 'suggestions', element: <ChromeExtensionSuggestionsPage /> },
          {
            path: 'suggestions/:suggestedVideoId',
            element: <ChromeExtensionSuggestionsDetailsPage />,
          },
          {
            path: 'suggestions/:suggestedVideoId/publish',
            element: <PublishChromeExtensionSuggestionPage />,
          },
          {
            path: 'suggestions/settings',
            element: <ChromeExtensionSuggestionSettingsPage />,
          },
        ],
      },
      // Roles
      {
        path: 'roles',
        children: [
          { element: <RolesPage />, index: true },
          { path: 'addRole', element: <AddRolePage /> },
          { path: 'editRole/:roleId', element: <EditRolePage /> },
        ],
      },
      // Notifications
      {
        path: 'notifications',
        children: [
          { element: <NotificationsPage />, index: true },
          { path: 'addNotification', element: <AddNotificationPage /> },
        ],
      },
      // Help Center
      {
        path: 'helpCenter',
        children: [
          // FAQ
          { path: 'faq', element: <FAQPage /> },
          { path: 'faq/addFaq', element: <AddFAQPage /> },
          { path: 'faq/editFaq/:faqId', element: <EditFAQPage /> },
          // Tickets
          { path: 'tickets', element: <TicketsPage /> },
          { path: 'tickets/:ticketId', element: <TicketDetailsPage /> },
          // Reporting
          { path: 'reporting', element: <RreportingPage /> },
          { path: 'reporting/:reportedVideoId', element: <ReportedVideoDetailsPage /> },
          // App Discovey
          { path: 'appDiscovery', element: <AppDiscoveryPage /> },
          { path: 'appDiscovery/addAppDiscovery', element: <AddAppDiscoveryPage /> },
          {
            path: 'appDiscovery/editAppDiscovery/:appDiscoveryId',
            element: <EditAppDiscoveryPage />,
          },
          // Privacy Policy
          { path: 'privacy', element: <PrivacyAndPolicyPage /> },
        ],
      },
    ],
  },
];

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { AppPermissions } from 'src/utils/appConst';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  content: icon('ic_content'),
  badges: icon('ic_badge'),
  chromeExtension: icon('ic_chromeExtension'),
  roles: icon('ic_roles'),
  notifications: icon('ic_notification'),
  aiSuggestions: icon('ic_aiSuggestions'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.dashboard.root,
            icon: ICONS.dashboard,
            roles: [AppPermissions['VIEW_DASHBOARD']],
            children: [
              { title: 'General', path: paths.dashboard.dashboard.root },
              { title: 'Content', path: paths.dashboard.dashboard.content },
            ],
          },
        ],
      },
      // USERS MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Users Management',
            path: paths.dashboard.users.root,
            icon: ICONS.user,
            roles: [AppPermissions['VIEW_USER_MANAGEMENT']],
            children: [
              { title: 'App Users', path: paths.dashboard.users.root },
              { title: 'Dashboard Users', path: paths.dashboard.users.admins },
            ],
          },
        ],
      },
      // CONTENT MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Content Management',
            path: paths.dashboard.content.root,
            icon: ICONS.content,
            roles: [AppPermissions['VIEW_CONTENT_MANAGEMENT']],
            children: [
              { title: 'Channels', path: paths.dashboard.content.root },
              { title: 'Categories', path: paths.dashboard.content.categories },
              { title: 'Series', path: paths.dashboard.content.series },
              { title: 'Coming Soon', path: paths.dashboard.content.comingSoon },
            ],
          },
        ],
      },
      // AI SUGGESTIONS
      {
        subheader: '',
        items: [
          {
            title: 'AI Suggestions',
            path: paths.dashboard.aiSuggestions.root,
            icon: ICONS.aiSuggestions,
            roles: [AppPermissions['VIEW_AI_SUGGESTIONS']],
            children: [
              { title: 'Keywords', path: paths.dashboard.aiSuggestions.root },
              { title: 'Suggestions', path: paths.dashboard.aiSuggestions.suggestions },
            ],
          },
        ],
      },
      // BADGES MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Badges',
            path: paths.dashboard.badges.root,
            icon: ICONS.badges,
            roles: [AppPermissions['VIEW_BADGE']],
          },
        ],
      },
      // CHROME EXTENSION MANAGEMENT
      {
        subheader: '',
        items: [
          {
            title: 'Chrome Extension',
            path: paths.dashboard.chromeExtension.root,
            icon: ICONS.chromeExtension,
            roles: [AppPermissions['VIEW_CHROME_EXTENSION_MANAGEMENT']],
            children: [
              { title: 'Statistics', path: paths.dashboard.chromeExtension.root },
              { title: 'Chrome Extension Users', path: paths.dashboard.chromeExtension.users },
              { title: 'Suggestions', path: paths.dashboard.chromeExtension.suggestions },
            ],
          },
        ],
      },
      // ROLES MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Roles',
            path: paths.dashboard.roles.root,
            icon: ICONS.roles,
            roles: [AppPermissions['VIEW_ROLE_MANAGEMENT']],
          },
        ],
      },
      // NOTIFICATIONS MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Notifications',
            path: paths.dashboard.notifications.root,
            icon: ICONS.notifications,
            roles: [AppPermissions['VIEW_NOTIFICATION']],
          },
        ],
      },
      // HELP CENTER
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Help Center',
            path: paths.dashboard.helpCenter.root,
            icon: ICONS.content,
            roles: [AppPermissions['VIEW_HELP_CENTER']],
            children: [
              { title: 'FAQ', path: paths.dashboard.helpCenter.faq },
              { title: 'Tickets', path: paths.dashboard.helpCenter.tickets },
              { title: 'Reporting', path: paths.dashboard.helpCenter.reporting },
              { title: 'App Discovery', path: paths.dashboard.helpCenter.appDiscovery },
              { title: 'Privacy Policy', path: paths.dashboard.helpCenter.privacy },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
